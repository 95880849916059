import Text from '../../elements/text';

function BrowseIntegrationInfoRabbit(props) {
  return (
    <div>
      <h6>Voltaware&apos;s sensors raw event integration</h6>
      <div>
        <Text bold={true} inline={true} text="Username" />
        <Text bold={false} inline={true} text={props.integration.userName} />
      </div>
      <div>
        <Text bold={true} inline={true} text="Password" />
        <Text bold={false} inline={true} text={props.integration.password} />
      </div>
      <div>
        <Text bold={true} inline={true} text="Host" />
        <Text bold={false} inline={true} text={props.integration.host} />
      </div>
      <div>
        <Text bold={true} inline={true} text="Queues" />
        <Text bold={false} inline={true} text={props.integration.queues} />
      </div>
    </div>
  );
}

export default BrowseIntegrationInfoRabbit;
