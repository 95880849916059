import { useReducer, useRef, useEffect, useMemo } from 'react';
import '@reach/dialog/styles.css';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import ReactTooltip from 'react-tooltip';

import { isResellerAdmin } from '../../../components/utils';
import API from '../../../components/api';
import { NO_RESULTS_MESSAGE } from '../../../components/constants';

import Button from '../../../elements/button';
import ErrorMessage from '../../../elements/error-message';
import Input from '../../../elements/forms/input';
import Text from '../../../elements/text';
import Title from '../../../elements/title';
import Select from '../../../elements/forms/select';
import Table from '../../../elements/table';
import Dialog from '../../../elements/dialog';

import './browse.sass';

const filterObject = {
  search: '',
  searchBy: '',
  searchOptions: Object.assign(
    [],
    [
      {
        label: 'Expiration Date',
        value: 'EXPIRATION_DATE',
      },
      {
        label: 'Reseller Name',
        value: 'RESELLER_NAME',
      },
      {
        label: 'Order ID',
        value: 'ORDER_ID',
      },
    ]
  ),
  resellerName: '',
};

const EXPIRATION_DATE_OPTIONS = [
  { label: 'Already expired', value: 'ALREADY_EXPIRED' },
  { label: 'Expires in 1 month or less', value: 'ONE_MONTH_OR_LESS_TO_EXPIRE' },
  { label: 'Expires in 2 to 3 months', value: 'ONE_TO_THREE_MONTHS_TO_EXPIRE' },
];

function SubscriptionOrderEnding(props) {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      filter: filterObject,
      allResellers: [],
      loading: true,
      noResultsMessage: NO_RESULTS_MESSAGE,
      page: 0,
      lastPage: false,
      ordersDeadline: [],
      hasError: false,
      validationMessage: 'Please, select a property and fill the search field.',
      isSearching: false,
      showDialog: false,
      orderToRenew: null,
      renewLoading: false,
      renewHasError: false,
    }
  );
  const isMountedRef = useRef(null);

  const handleOpenDialog = (orderToRenew) => {
    setState({ showDialog: true, orderToRenew });
  };

  const handleCloseDialog = () => {
    setState({
      showDialog: false,
      orderToRenew: null,
      renewHasError: false,
    });
  };

  useEffect(() => {
    isMountedRef.current = true;
    handleSearch();

    return () => (isMountedRef.current = false);
  }, []);

  const columns = useMemo(
    () => [
      {
        id: 'orderId',
        title: 'ID',
        align: 'center',
        minWidth: 50,
      },
      {
        id: 'subscriptionName',
        title: 'Subscription',
        align: 'left',
      },
      {
        id: 'resellerName',
        title: 'Reseller',
        align: 'left',
      },
      {
        id: 'subscriptionDeadline',
        title: 'Subscription deadline',
        align: 'center',
      },
      {
        id: 'timesRenewed',
        title: 'Times renewed',
        align: 'center',
      },
      {
        id: 'datesOfRenewal',
        title: 'Dates of renewal',
        align: 'center',
        minWidth: 100,
      },
      {
        id: 'renew',
        title: 'Renew',
        align: 'center',
        minWidth: 50,
      },
    ],
    [isResellerAdmin]
  );

  const data = useMemo(() => {
    return state.ordersDeadline.map((row) => {
      const getDetails = () => {
        return (
          <div className="expandable-section">
            <h6>Order's Devices IDs</h6>
            {row?.sensorIds?.length > 0 ? (
              <Text text={row.sensorIds.join(', ')} />
            ) : (
              <Text text="This order has no sensors" />
            )}
            {row?.datesOfRenewal?.length > 7 && (
              <>
                <h6 style={{ marginTop: 15 }}>Order's Dates of Renewal</h6>
                {row.datesOfRenewal.map((date) => (
                  <Text key={uuid()} text={moment(date).format('DD/MM/YYYY')} />
                ))}
              </>
            )}
          </div>
        );
      };

      const orderToRenew = {
        orderId: row.orderId || '-',
        duration: `${row.duration ?? 12} months`,
        startingDate: moment(row.subscriptionDeadline ?? undefined)
          .add(1, 'days')
          .format('DD/MM/YYYY'),
      };

      return {
        id: uuid() || '-',
        orderId: row.orderId || '-',
        subscriptionName: row.subscriptionName || '-',
        resellerName: row.resellerName || '-',
        subscriptionDeadline: row.subscriptionDeadline
          ? moment(row.subscriptionDeadline).format('DD/MM/YYYY')
          : '-',
        timesRenewed: row.timesRenewed || '-',
        datesOfRenewal:
          row?.datesOfRenewal?.length > 0
            ? row.datesOfRenewal.slice(0, 8).map((date, index) => {
                if (index === 7) {
                  return (
                    <pre
                      key={uuid()}
                      data-tip="Expand the row to see all dates of renewal"
                    >
                      (...)
                    </pre>
                  );
                }
                return (
                  <pre key={uuid()}>{moment(date).format('DD/MM/YYYY')}</pre>
                );
              })
            : '-',
        renew: (
          <div className="renew-button">
            <Button
              click={() => handleOpenDialog(orderToRenew)}
              action="renew"
              aria-label="renew"
            />
          </div>
        ),
        details: getDetails(),
      };
    });
  }, [state.ordersDeadline]);

  const changeBy = (e) => {
    setState({
      filter: {
        ...state.filter,
        searchBy: e.target.value,
        search: '',
        resellerName: '',
      },
    });
  };

  const change = (e) => {
    setState({ filter: { ...state.filter, search: e.target.value } });
  };

  const changeReseller = (e) => {
    setState({
      filter: {
        ...state.filter,
        resellerName: e.target.value,
        search: e.target.value,
      },
    });
  };

  useEffect(() => {
    if (
      state.filter.resellerName.length > 1 &&
      state.filter.resellerName.length < 30
    ) {
      API.RESELLERS.SEARCH(state.filter.resellerName, 'NAME').then(
        (response) => {
          if (!isMountedRef.current) {
            return;
          }
          let resellers = response.data.map((item) => {
            return { id: item.id, name: item.name };
          });

          const matchIndex = resellers.findIndex(
            (reseller) => reseller.name === state.filter.resellerName
          );
          if (matchIndex >= 0) {
            resellers = [];
          }

          setState({
            allResellers: resellers,
          });
        }
      );
    } else {
      setState({ allResellers: [] });
    }
  }, [state.filter.resellerName]);

  const selectReseller = (e) => {
    e.preventDefault();
    setState({
      filter: {
        ...state.filter,
        resellerName: e.target.name,
        search: e.target.name,
      },
      allResellers: [],
    });
  };

  const validate = () => {
    const filter = state.filter;
    let validationMessage =
      'Please, select a property and fill the search field.';
    let hasError = false;
    if (
      (filter.search !== '' && filter.searchBy === '') ||
      (filter.search === '' && filter.searchBy !== '')
    ) {
      hasError = true;
    } else if (filter.searchBy === 'RESELLER_ID') {
      const numberRegex = /^\d+$/;
      if (!numberRegex.test(filter.search.replace(/\s+/g, ''))) {
        hasError = true;
        validationMessage = 'Please, type a number for the ID.';
      }
    }
    setState({ hasError, validationMessage });
    return !hasError;
  };

  const handlePreviousPage = () => {
    const previousPage = state.page - 1;
    setState({
      page: previousPage,
    });
    handleSearch(previousPage);
  };

  const handleNextPage = () => {
    const nextPage = state.page + 1;
    setState({
      page: nextPage,
    });
    handleSearch(nextPage);
  };

  const handleSearch = (page) => {
    if (!validate()) {
      window.scrollTo(0, 0);
      return false;
    }

    setState({
      loading: true,
    });

    let search = state.filter.search;
    let searchBy = state.filter.searchBy;

    if (props.resellerId) {
      search = props.resellerId;
      searchBy = 'RESELLER_ID';
    }

    API.SUBSCRIPTION.ORDER.DEADLINES.LIST(search, searchBy, page)
      .then(async (response) => {
        if (!isMountedRef.current) {
          return;
        }

        const ordersDeadline = response.data.ordersDeadline.map((order) => {
          return {
            ...order,
          };
        });

        setState({
          loading: false,
          ordersDeadline:
            ordersDeadline && ordersDeadline.length ? ordersDeadline : [],
          lastPage: response.data.lastPage,
        });
        ReactTooltip.rebuild();
      })
      .catch((error) => {
        console.error(error);

        setState({
          loading: false,
          ordersDeadline: [],
        });
      });
  };

  const submit = (e) => {
    e.preventDefault();
    e.target.querySelector('button[type="submit"]').blur();

    if (!validate()) {
      return false;
    }

    setState({ page: 0 });
    handleSearch(0);

    return false;
  };

  const renderTable = () => {
    return (
      <Table
        columns={columns}
        data={data}
        isLoading={state.loading}
        showDetails
        showPagination
        hasPrevious={state.page !== 0}
        hasNext={state.lastPage === false}
        handlePreviousPage={handlePreviousPage}
        handleNextPage={handleNextPage}
      />
    );
  };

  const handleRenew = (orderId) => {
    setState({
      renewLoading: true,
    });

    API.SUBSCRIPTION.ORDER.DEADLINES.RENEW(orderId)
      .then(async (response) => {
        setState({
          renewLoading: false,
          showDialog: false,
          renewHasError: false,
        });
        handleSearch(state.page);
      })
      .catch((error) => {
        setState({
          renewLoading: false,
          renewHasError: true,
        });
      });
  };

  return (
    <section id="page-subscription-order-browse">
      <Title element="h4" text="Orders' subscriptions deadlines" />

      <Text
        bold={true}
        text="Search for an order by Expiration date, Reseller Name, Order ID."
      />

      {state.hasError ? (
        <ErrorMessage message={state.validationMessage} />
      ) : null}

      <form action="#" onSubmit={submit}>
        <div>
          <Select
            change={changeBy}
            disabled={state.loading}
            hasError={state.hasError}
            name="searchBy"
            options={state.filter.searchOptions}
            placeholder="Property"
            value={state.filter.searchBy}
          />
        </div>
        <div>
          {{
            RESELLER_NAME: (
              <Input
                autocomplete="off"
                change={changeReseller}
                data={state.allResellers}
                disabled={state.loading}
                hasError={state.hasError}
                name="resellerName"
                placeholder="Search"
                select={selectReseller}
                type="autoComplete"
                value={state.filter.resellerName}
              />
            ),
            EXPIRATION_DATE: (
              <Select
                change={change}
                disabled={state.loading}
                hasError={state.hasError}
                name="expirationDate"
                options={EXPIRATION_DATE_OPTIONS}
                placeholder="Search"
                value={state.filter.search}
              />
            ),
          }[state.filter.searchBy] || (
            <Input
              change={change}
              disabled={state.loading}
              hasError={state.hasError}
              name="filter"
              placeholder="Search"
              type="text"
              value={state.filter.search}
            />
          )}
        </div>
        <div>
          <Button
            action="search"
            disabled={state.loading}
            type="submit"
            aria-label="search"
          />
        </div>
      </form>

      <ReactTooltip />
      {renderTable()}

      <Dialog
        title={'Are you sure?'}
        message={`The subscription will be renewed for ${state.orderToRenew?.duration} from ${state.orderToRenew?.startingDate} and won’t have any installation tolerance period.`}
        isOpen={state.showDialog}
        loading={state.renewLoading}
        handleConfirm={() => handleRenew(state.orderToRenew?.orderId)}
        handleClose={handleCloseDialog}
        hasError={state.renewHasError}
        errorMessage={'Something is wrong. Try again later or contact support.'}
      />
    </section>
  );
}

export default SubscriptionOrderEnding;
