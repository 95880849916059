import { useRef, useReducer, useEffect } from 'react';
import FileDownload from 'react-file-download';

import API from '../components/api';
import Button from '../elements/button';
import Input from '../elements/forms/input';
import InputFile from '../elements/forms/input-file';
import Text from '../elements/text';
import ErrorMessage from '../elements/error-message';
import Title from '../elements/title';

import './setup-smart-meters.sass';

function SetupSmartMeters() {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      hasError: [],
      loading: false,
      resellerName: '',
      resellerList: '',
      integratorId: '',
      file: '',
      fileErrors: [],
      loadingExampleFile: false,
    }
  );
  const isMountedRef = useRef(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    isMountedRef.current = true;

    return () => (isMountedRef.current = false);
  }, []);

  const changeReseller = (e) => {
    setState({
      resellerName: e.target.value,
    });
  };

  useEffect(() => {
    if (state.resellerName === '') {
      setState({ integratorId: '', resellerList: [] });
    } else if (
      state.resellerName.length > 1 &&
      state.resellerName.length < 30
    ) {
      API.RESELLERS.SEARCH(state.resellerName, 'NAME').then((response) => {
        if (!isMountedRef.current) {
          return;
        }

        let resellerList = response.data.map((item) => {
          return {
            id: item.id,
            name: item.name,
            integratorId: item.integratorId,
          };
        });

        const matchIndex = resellerList.findIndex(
          (reseller) => reseller.name === state.resellerName
        );
        if (matchIndex >= 0) {
          resellerList = [];
        }

        setState({
          resellerList: resellerList,
        });
      });
    } else {
      setState({ resellerList: [] });
    }
  }, [state.resellerName]);

  const selectReseller = (e) => {
    e.preventDefault();
    setState({
      resellerName: e.target.name,
      resellerList: [],
      integratorId: e.target.getAttribute('integratorid'),
    });
  };

  const handleChange = (e) => {
    setState({ [e.target.name]: e.target.value, fileErrors: [] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validate()) {
      return;
    }

    setState({
      loading: true,
      hasError: [],
      successMessage: '',
      errorMessage: '',
      fileErrors: [],
    });

    let data = new FormData();
    data.append('file', fileInputRef.current.files[0]);

    API.SMART_METERS.SETUP_VALIDATE({
      integratorId: state.integratorId,
      data: data,
    })
      .then((response) => {
        API.SMART_METERS.SETUP({
          integratorId: state.integratorId,
          data: response.data,
        })
          .then(() => {
            setState({
              loading: false,
              successMessage: 'Data uploaded with success.',
            });
          })
          .catch(() => {
            const errorMessage =
              'Something went wrong. Please try again later.';
            setState({
              loading: false,
              errorMessage: errorMessage,
            });
          });
      })
      .catch((error) => {
        let fileErrors = [];
        let errorMessage = '';
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.length >= 0
        ) {
          fileErrors = error.response.data;
          errorMessage = '';
        } else {
          errorMessage = 'Something went wrong. Please try again later.';
        }
        setState({
          loading: false,
          errorMessage: errorMessage,
          file: '',
          hasError: ['file'],
          fileErrors: fileErrors,
        });
      });
  };

  const validate = () => {
    const hasError = [];
    if (!state.integratorId) {
      hasError.push('reseller');
    }
    if (fileInputRef.current && fileInputRef.current.files.length < 1) {
      hasError.push('file');
    }
    setState({ hasError });
    return hasError.length < 1;
  };

  const handleDownloadExampleFile = () => {
    setState({
      loadingExampleFile: true,
      successMessage: '',
      errorMessage: '',
    });

    API.SMART_METERS.SETUP_EXAMPLE()
      .then((response) => {
        setState({ loadingExampleFile: false });
        FileDownload(response.data, 'ExampleFile.xlsx');
      })
      .catch((err) => {
        setState({
          loadingExampleFile: false,
          errorMessage: 'Something went wrong. Please try again later.',
        });
      });
  };

  return (
    <section id="page-setup-smart-meters">
      <Title element="h4" text="Upload Appliances and Property information" />
      {state.successMessage && (
        <Text bold={true} highlight={true} text={state.successMessage} />
      )}
      {state.errorMessage && <ErrorMessage message={state.errorMessage} />}
      {!state.errorMessage &&
        state.fileErrors.length >= 0 &&
        state.fileErrors.map((error, index) => (
          <ErrorMessage message={error} key={index} />
        ))}
      <Text
        bold={true}
        text="Please fill all the information to upload appliances and property information."
      />
      <div>
        <form action="#" onSubmit={handleSubmit}>
          <div>
            <Input
              autocomplete="off"
              change={changeReseller}
              data={state.resellerList}
              disabled={state.loading}
              hasError={state.hasError.indexOf('reseller') >= 0}
              name="resellerName"
              placeholder="Reseller Name"
              select={selectReseller}
              type="autoComplete"
              value={state.resellerName}
            />
            <InputFile
              change={handleChange}
              disabled={state.loading}
              hasError={state.hasError.indexOf('file') >= 0}
              ref={fileInputRef}
              value={state.file}
              accept=".csv, text/csv"
              name="file"
              placeholder="Select your CSV file"
            />
            <Button disabled={state.loading} label="Submit" type="submit" />
          </div>
        </form>
        <div className="file-example">
          <Text
            bold={true}
            text="You can download the excel file below to follow the right format."
          />
          <Text
            bold={true}
            text="Don't forget to save it as a CSV file (Excel files are not compatible)."
          />
          <Button
            click={handleDownloadExampleFile}
            disabled={state.loadingExampleFile}
            label="Click here to download the example file"
          />
        </div>
      </div>
    </section>
  );
}

export default SetupSmartMeters;
