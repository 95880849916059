import React from 'react';

import Button from '../../../elements/button';
import Text from '../../../elements/text';
import Title from '../../../elements/title';

import { MODES } from './index';

const UNBLOCK_MESSAGES = {
  buttonLabel: 'Unblock other stock',
  pastLabel: 'unblocked',
  accessText: 'restores access to the app and will receive report emails',
};
const BLOCK_MESSAGES = {
  buttonLabel: 'Block other stock',
  pastLabel: 'blocked',
  accessText: "won't have access to the app nor will receive report emails",
};

function StockBlockEnd(props) {
  const MESSAGES =
    props.mode === MODES.unblock ? UNBLOCK_MESSAGES : BLOCK_MESSAGES;

  return (
    <section id="page-stock-block-step-end">
      <Title element="h5" text={`Stock ${MESSAGES.pastLabel} successfully!`} />
      <Text
        text={`IDs ${props.stock} have been ${MESSAGES.pastLabel}, so its accounts ${MESSAGES.accessText}.`}
      />

      <div className="btn-actions">
        <Button click={props.reset} label={MESSAGES.buttonLabel} />
      </div>
    </section>
  );
}

export default StockBlockEnd;
