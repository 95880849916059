import { useState } from 'react';

function Select(props) {
  const [isActive, setIsActive] = useState(false);

  const handleFocus = () => {
    setIsActive(true);
  };

  const handleBlur = () => {
    setIsActive(false);
  };

  let spanClass = 'placeholder';
  if (isActive) {
    spanClass += ' active';
  } else if (props.value !== '' || props.selected) {
    spanClass += ' deactive';
  }

  return (
    <label>
      <span className={spanClass}>{props.placeholder}</span>
      <select
        className={props.hasError ? 'error' : ''}
        disabled={props.disabled}
        name={props.name}
        onChange={props.change}
        onFocus={handleFocus}
        onBlur={handleBlur}
        value={props.value}
      >
        {!props.noEmptyOption ? <option /> : null}
        {props.options &&
          props.options.map((option, index) => {
            let value = option,
              label = option,
              disabled = false;

            if (typeof option === 'object') {
              value = option.value;
              label = option.label;
              disabled = option.disabled;
            }

            return (
              <option disabled={disabled} key={value} value={value}>
                {label}
              </option>
            );
          })}
      </select>
      <span className="border" />
    </label>
  );
}

export default Select;
