import { getAuthority } from '../utils';
import routes from '../../routes';

function Private(props) {
  const { authNeeded, children, to } = props;
  const authorities = getAuthority();
  let isAuthorized;

  if (to) {
    const routeTo = typeof to === 'object' && to.pathname ? to.pathname : to;
    const route = routes.find((r) => r.path === routeTo);
    isAuthorized =
      route &&
      route.auth &&
      route.auth.some((value) => authorities.indexOf(value) >= 0);
  } else if (authNeeded && authNeeded.length) {
    isAuthorized = authNeeded.some((value) => authorities.indexOf(value) >= 0);
  } else if (authNeeded === null) {
    isAuthorized = true;
  }

  return isAuthorized ? children : null;
}

export default Private;
