import { useRef, useReducer, useEffect, useMemo } from 'react';

import API from '../../../components/api';

import ErrorMessage from '../../../elements/error-message';
import Input from '../../../elements/forms/input';
import Select from '../../../elements/forms/select';
import Text from '../../../elements/text';
import Title from '../../../elements/title';
import Table from '../../../elements/table';

import './browse.sass';

function StockBlockBrowse() {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      loading: true,
      search: '',
      blockStatus: 'all',
      stocks: [],
      searchInputError: false,
      searchError: false,
      page: 0,
      pages: 1,
      pagesLastSensor: [0],
    }
  );
  const isMountedRef = useRef(null);
  const pagesLastId = useRef([0]);

  useEffect(() => {
    isMountedRef.current = true;
    searchByStatus();

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const columns = useMemo(
    () => [
      {
        id: 'id',
        title: 'Device ID',
        align: 'center',
        minWidth: 110,
      },
      {
        id: 'is_blocked',
        title: 'Blocked',
        align: 'center',
        minWidth: 110,
      },
      {
        id: 'blocking_date',
        title: 'Blocking Date',
        align: 'center',
        minWidth: 320,
      },
    ],
    []
  );

  const data = useMemo(() => {
    return state.stocks.map((row) => {
      return {
        ...row,
        is_blocked: row.is_blocked ? 'Yes' : 'No',
        blocking_date: !row.blocking_date
          ? '-'
          : new Date(row.blocking_date).toString(),
      };
    });
  }, [state.stocks]);

  const change = (e) => {
    if (e.target.name === 'blockStatus') {
      setState({ [e.target.name]: e.target.value });
    } else {
      setState({ [e.target.name]: e.target.value });
    }
  };

  useEffect(() => {
    pagesLastId.current = [0];
    search();
  }, [state.blockStatus]);

  const validate = () => {
    const regex = /^[0-9]+$/;
    const searchInput = state.search.replace(/\s+/g, '');

    return searchInput === '' || !regex.test(searchInput) ? false : true;
  };

  const search = () => {
    setState({ searchInputError: false });

    if (state.blockStatus === 'all' && state.search.length > 0) {
      validate() ? searchById() : setState({ searchInputError: true });
    } else {
      searchByStatus();
    }
  };

  const handlePreviousPage = () => {
    pagesLastId.current.pop();
    searchByStatus(pagesLastId.current[pagesLastId.current.length - 1]);
  };

  const handleNextPage = () => {
    pagesLastId.current.push(state.lastId);
    searchByStatus(pagesLastId.current[pagesLastId.current.length - 1]);
  };

  const searchByStatus = (lastId) => {
    setState({ loading: true, searchError: false });

    const { blockStatus } = state;

    const isBlocked =
      blockStatus === 'blocked'
        ? true
        : blockStatus === 'unblocked'
        ? false
        : undefined;

    API.STOCK.BLOCK.SEARCH(lastId, isBlocked)
      .then(({ data }) => {
        if (!isMountedRef.current) {
          return;
        }

        setState({
          loading: false,
          stocks: data,
          lastId: data.length && data[data.length - 1].id,
        });
      })
      .catch((error) => {
        setState({ loading: false, searchError: true, stocks: [] });
      });
  };

  const searchById = () => {
    setState({ loading: true, searchError: false });
    const searchInput = state.search.replace(/\s+/g, '');

    API.STOCK.BLOCK.SEARCH_BY_ID(searchInput)
      .then(({ data }) => {
        if (!isMountedRef.current) {
          return;
        }

        setState({
          loading: false,
          stocks: data && typeof data === 'object' ? [data] : [],
        });
      })
      .catch((error) => {
        if (error && error.response && error.response.status === 404) {
          setState({ searchError: false, stocks: [] });
        } else {
          setState({ searchError: true });
          console.error(error);
        }
        setState({
          loading: false,
        });
      });
  };

  const submit = (e) => {
    e.preventDefault();

    e.target.querySelector('button[type="submit"]').blur();

    search();
    return false;
  };

  return (
    <section id="page-stock-block-browse">
      <Title element="h4" text="Blocked Devices" />

      <Text bold={true} text={`Search for a device by ID or Block status.`} />

      <form action="#" onSubmit={submit}>
        <div className="filters">
          <Select
            change={change}
            disabled={state.loading}
            hasError={state.error}
            noEmptyOption={true}
            name="blockStatus"
            options={[
              { label: 'All', value: 'all' },
              { label: 'Blocked', value: 'blocked' },
              { label: 'Unblocked', value: 'unblocked' },
            ]}
            placeholder="Block Status Filter"
            value={state.blockStatus}
          />

          {state.blockStatus === 'all' ? (
            <Input
              change={change}
              disabled={state.loading}
              name="search"
              placeholder="Search device ID"
              type="search"
              loading={false}
              value={state.search}
              hasError={state.searchInputError}
            />
          ) : null}
        </div>
      </form>

      {state.searchInputError ? (
        <ErrorMessage message="Please input the device ID number only" />
      ) : null}

      {state.searchError ? (
        <ErrorMessage message="Something went wrong while searching for devices. Please, try again or contact support." />
      ) : null}

      <Table
        columns={columns}
        data={data}
        isLoading={state.loading}
        showPagination
        hasPrevious={pagesLastId.current.length > 1}
        hasNext={state.stocks && state.stocks.length >= 20}
        handlePreviousPage={handlePreviousPage}
        handleNextPage={handleNextPage}
      />
    </section>
  );
}

export default StockBlockBrowse;
