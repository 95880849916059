import { Link, useLocation } from 'react-router-dom';
import { AiOutlineMenu } from 'react-icons/ai';

import './header.sass';
import logo from '../general/img/voltaware-crm-logo.svg';

function Header(props) {
  const location = useLocation();

  return (
    location.pathname !== '/login' && (
      <header>
        <div id="menu-button" onClick={props.handleOpenMobilebar}>
          <AiOutlineMenu size={30} color={'#6ab42d'} />
        </div>
        <div id="logo-button">
          <Link to="/">
            <img src={logo} alt="voltaware crm logo" />
          </Link>
        </div>
      </header>
    )
  );
}

export default Header;
