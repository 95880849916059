import React from 'react';

import Loading from '../../elements/loading';
import Text from '../../elements/text';

import './smart-meter-info.sass';

function SmartMeterInfo(props) {
  const loadingSensor = props.loadingSensor;
  const sensorLoadingSuccess = props.sensorLoadingSuccess;
  const sensor = props.sensor;
  return loadingSensor ? (
    <Loading />
  ) : sensorLoadingSuccess ? (
    <div id="smart-meter-details">
      <div>
        <Text bold={true} text={'Device Status'} />
        <Text
          classes={
            sensor.connectionState === 'connected' ? 'online' : 'offline'
          }
          bold={true}
          text={sensor.connectionState === 'connected' ? 'Online' : 'Offline'}
        />
      </div>
    </div>
  ) : (
    <div id="smart-meter-details">
      <Text bold={true} text={'Device information currently unavailable.'} />
    </div>
  );
}

export default SmartMeterInfo;
