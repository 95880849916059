import { useRef, useReducer, useEffect, useMemo } from 'react';
import FontIcon from '@material-ui/core/Icon';
import { Link } from 'react-router-dom';
import moment from 'moment';

import API from '../../components/api';

import Button from '../../elements/button';
import Title from '../../elements/title';
import Table from '../../elements/table';

function EventsExportingHistory(props) {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { loading: false, logs: [] }
  );
  const isMountedRef = useRef(null);

  useEffect(() => {
    isMountedRef.current = true;
    load();

    return () => (isMountedRef.current = false);
  }, []);

  const columns = useMemo(
    () => [
      {
        id: 'email',
        title: 'E-mail',
        align: 'left',
      },
      {
        id: 'fromDate',
        title: 'From',
        align: 'center',
      },
      {
        id: 'toDate',
        title: 'To',
        align: 'center',
      },
      {
        id: 'exportTimestamp',
        title: 'Export Date',
        align: 'center',
      },
      {
        id: 'responseEmail',
        title: 'Email Sent',
        align: 'center',
      },
      {
        id: 'url',
        title: 'Download',
        align: 'center',
      },
    ],
    []
  );

  const data = useMemo(() => {
    return state.logs.map((row) => {
      const downloadLink = (downloadUrl) => {
          return (
            <a href={downloadUrl} download>Click to download</a>
          );
      };

      return {
        ...row,
        fromDate: row.fromDate
          ? moment(row.fromDate).format('DD/MM/YYYY')
          : '',
        toDate: row.toDate ? moment(row.toDate).format('DD/MM/YYYY') : '',
        exportTimestamp: moment(row.exportTimestamp).format(
          'DD/MM/YYYY HH:mm:ss'
        ),
        responseEmail: row.responseEmail ? (
          <FontIcon className="material-icons success">{'check'}</FontIcon>
        ) : (
          <FontIcon className="material-icons error">{'close'}</FontIcon>
        ),
        id: row.email + row.exportTimestamp,
        url: downloadLink(row.url),
      };
    });
  }, [state.logs]);

  const load = () => {
    setState({ loading: true });

    API.SENSORS.EVENTS_EXPORTING.HISTORY(props.client.id)
      .then((response) => {
        if (!isMountedRef.current) {
          return;
        }

        const logs =
          response.data && response.data.logs ? response.data.logs : [];
        setState({ loading: false, logs: logs });
      })
      .catch((error) => {
        console.error(error);
        setState({ loading: false });
      });
  };

  return (
    <div id="admintools-eventsexportinghistory">
      <Title element="h6" text="Events Exporting History" />

      <Table columns={columns} data={data} isLoading={state.loading} />

      <div className="btn-actions">
        <Link
          to={{
            pathname: `/sensor/${props.client.id}/admintools/`,
            state: 'admintools',
          }}
        >
          <Button label="Back" />
        </Link>
      </div>
    </div>
  );
}

export default EventsExportingHistory;
