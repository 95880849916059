import './sensor-wifi-strength.sass';

function SensorWifiStrength(props) {
  const renderIcon = (i, number) => {
    return <div key={i} className={i < number ? 'green' : 'grey'} />;
  };

  const number = props.value;
  var tags = [];

  for (var i = 0; i < 5; i++) {
    tags.push(renderIcon(i, number));
  }

  return <div id="sensor-wifi-strength">{tags}</div>;
}

export default SensorWifiStrength;
