import { useReducer } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { IoIosArrowDown } from 'react-icons/io';
import { VscChromeClose } from 'react-icons/vsc';

import Private from '../components/auth/private';
import PrivateLink from '../components/auth/private-link';

import './mobilebar.sass';
import logout from './header-logout.svg';
import { USER_AUTHORITIES } from '../components/constants';

function Mobilebar(props) {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      reportingTab: false,
      devicesTab: false,
      stockTab: false,
      settingsTab: false,
      inventorySubtab: false,
      resellerSubtab: false,
      subscriptionSubtab: false,
      orderSubtab: false,
      countrySubtab: false,
      providerSubtab: false,
    }
  );
  const location = useLocation();

  const closeTabs = () => {
    setState({
      reportingTab: false,
      devicesTab: false,
      stockTab: false,
      settingsTab: false,
    });
  };

  const closeSubtabs = () => {
    setState({
      inventorySubtab: false,
      resellerSubtab: false,
      subscriptionSubtab: false,
      orderSubtab: false,
      countrySubtab: false,
      providerSubtab: false,
    });
  };

  const handleReportingTab = () => {
    if (!state.reportingTab) {
      closeTabs();
      closeSubtabs();
    }

    setState({
      reportingTab: !state.reportingTab,
    });
  };

  const handleDevicesTab = () => {
    if (!state.devicesTab) {
      closeTabs();
      closeSubtabs();
    }

    setState({
      devicesTab: !state.devicesTab,
    });
  };

  const handleStockTab = () => {
    if (!state.stockTab) {
      closeTabs();
    }

    if (state.stockTab) {
      closeSubtabs();
    }

    setState({
      stockTab: !state.stockTab,
    });
  };

  const handleSettingsTab = () => {
    if (!state.settingsTab) {
      closeTabs();
      closeSubtabs();
    }

    setState({
      settingsTab: !state.settingsTab,
    });
  };

  const handleInventorySubtab = () => {
    if (!state.inventorySubtab) {
      closeSubtabs();
    }

    setState({
      inventorySubtab: !state.inventorySubtab,
    });
  };

  const handleResellerSubtab = () => {
    if (!state.resellerSubtab) {
      closeSubtabs();
    }

    setState({
      resellerSubtab: !state.resellerSubtab,
    });
  };

  const handleSubscriptionSubtab = () => {
    if (!state.subscriptionSubtab) {
      closeSubtabs();
    }

    setState({
      subscriptionSubtab: !state.subscriptionSubtab,
    });
  };

  const handleOrderSubtab = () => {
    if (!state.orderSubtab) {
      closeSubtabs();
    }

    setState({
      orderSubtab: !state.orderSubtab,
    });
  };

  const handleCountrySubtab = () => {
    if (!state.countrySubtab) {
      closeSubtabs();
    }

    setState({
      countrySubtab: !state.countrySubtab,
    });
  };

  const handleProviderSubtab = () => {
    if (!state.providerSubtab) {
      closeSubtabs();
    }

    setState({
      providerSubtab: !state.providerSubtab,
    });
  };

  return (
    <>
      {location.pathname !== '/login' && (
        <>
          {props.isMobilebar && (
            <div
              className="mobilebar-panel"
              onClick={props.handleCloseMobilebar}
            />
          )}
          <div
            className={`mobilebar-wrapper ${
              props.isMobilebar && 'show-mobilebar'
            }`}
          >
            <nav>
              <div
                className="mobilebar-close"
                onClick={props.handleCloseMobilebar}
              >
                <VscChromeClose size={25} />
              </div>
              <div
                className="mobilebar-expander-primary"
                onClick={handleReportingTab}
              >
                Reporting{' '}
                <IoIosArrowDown
                  className={state.reportingTab && 'open-arrow'}
                />
              </div>
              <div
                className={`mobilebar-group-primary ${
                  state.reportingTab && 'expand-mobilebar'
                }`}
              >
                <NavLink to="/" exact onClick={props.handleCloseMobilebar}>
                  Summary
                </NavLink>
                <PrivateLink
                  to="/installations"
                  onClick={props.handleCloseMobilebar}
                >
                  Installations
                </PrivateLink>
                <PrivateLink
                  to="/disaggregation"
                  onClick={props.handleCloseMobilebar}
                >
                  Disaggregation
                </PrivateLink>
              </div>

              <Private authNeeded={[USER_AUTHORITIES.ADMIN]}>
                <div
                  className="mobilebar-expander-primary"
                  onClick={handleDevicesTab}
                >
                  Devices{' '}
                  <IoIosArrowDown
                    className={state.devicesTab && 'open-arrow'}
                  />
                </div>
                <div
                  className={`mobilebar-group-primary ${
                    state.devicesTab && 'expand-mobilebar'
                  }`}
                >
                  <NavLink to="/devices" onClick={props.handleCloseMobilebar}>
                    Monitoring
                  </NavLink>
                  <PrivateLink
                    to="/setup-smart-meters"
                    onClick={props.handleCloseMobilebar}
                  >
                    Setup Smart-Meters
                  </PrivateLink>
                </div>
              </Private>

              <Private
                authNeeded={[
                  USER_AUTHORITIES.RESELLER_ADMIN,
                  USER_AUTHORITIES.RESELLER_USER,
                  USER_AUTHORITIES.BUSINESS,
                  USER_AUTHORITIES.TECH,
                  USER_AUTHORITIES.MACHINE_LEARNING,
                ]}
              >
                <NavLink to="/devices" onClick={props.handleCloseMobilebar}>
                  Devices
                </NavLink>
              </Private>

              <Private to="/inventory">
                <div
                  className="mobilebar-expander-primary"
                  onClick={handleStockTab}
                >
                  Voltaware Sensors Stock Manager{' '}
                  <IoIosArrowDown className={state.stockTab && 'open-arrow'} />
                </div>
                <div
                  className={`mobilebar-group-primary ${
                    state.stockTab && 'expand-mobilebar'
                  }`}
                >
                  <Private to="/inventory">
                    <div
                      className="mobilebar-expander-secondary"
                      onClick={handleInventorySubtab}
                    >
                      Inventory{' '}
                      <IoIosArrowDown
                        className={state.inventorySubtab && 'open-arrow'}
                      />
                    </div>
                    <div
                      className={`mobilebar-group-secondary ${
                        state.inventorySubtab && 'expand-mobilebar'
                      }`}
                    >
                      <PrivateLink
                        to="/inventory"
                        onClick={props.handleCloseMobilebar}
                      >
                        Search
                      </PrivateLink>
                      <PrivateLink
                        to="/inventory/add"
                        onClick={props.handleCloseMobilebar}
                      >
                        Add
                      </PrivateLink>
                      <PrivateLink
                        to="/inventory/block"
                        onClick={props.handleCloseMobilebar}
                      >
                        Block
                      </PrivateLink>
                      <PrivateLink
                        to="/inventory/unblock"
                        onClick={props.handleCloseMobilebar}
                      >
                        Unblock
                      </PrivateLink>
                      <PrivateLink
                        to="/inventory/blocked"
                        onClick={props.handleCloseMobilebar}
                      >
                        Blocked Devices
                      </PrivateLink>
                    </div>
                  </Private>

                  <Private to="/resellers">
                    <div
                      className="mobilebar-expander-secondary"
                      onClick={handleResellerSubtab}
                    >
                      Resellers{' '}
                      <IoIosArrowDown
                        className={state.resellerSubtab && 'open-arrow'}
                      />
                    </div>
                    <div
                      className={`mobilebar-group-secondary ${
                        state.resellerSubtab && 'expand-mobilebar'
                      }`}
                    >
                      <PrivateLink
                        to="/resellers"
                        onClick={props.handleCloseMobilebar}
                      >
                        Search
                      </PrivateLink>
                      <PrivateLink
                        to="/resellers/add"
                        onClick={props.handleCloseMobilebar}
                      >
                        Add
                      </PrivateLink>
                      <PrivateLink
                        to="/resellers/summary"
                        onClick={props.handleCloseMobilebar}
                      >
                        Summary
                      </PrivateLink>
                    </div>
                  </Private>

                  <Private to="/subscriptions">
                    <div
                      className="mobilebar-expander-secondary"
                      onClick={handleSubscriptionSubtab}
                    >
                      Subscriptions{' '}
                      <IoIosArrowDown
                        className={state.subscriptionSubtab && 'open-arrow'}
                      />
                    </div>
                    <div
                      className={`mobilebar-group-secondary ${
                        state.subscriptionSubtab && 'expand-mobilebar'
                      }`}
                    >
                      <PrivateLink
                        to="/subscriptions"
                        onClick={props.handleCloseMobilebar}
                      >
                        Search
                      </PrivateLink>
                      <PrivateLink
                        to="/subscriptions/add"
                        onClick={props.handleCloseMobilebar}
                      >
                        Add
                      </PrivateLink>
                    </div>
                  </Private>

                  <Private to="/subscriptions/orders">
                    <div
                      className="mobilebar-expander-secondary"
                      onClick={handleOrderSubtab}
                    >
                      Orders{' '}
                      <IoIosArrowDown
                        className={state.orderSubtab && 'open-arrow'}
                      />
                    </div>
                    <div
                      className={`mobilebar-group-secondary ${
                        state.orderSubtab && 'expand-mobilebar'
                      }`}
                    >
                      <PrivateLink
                        to="/subscriptions/orders"
                        onClick={props.handleCloseMobilebar}
                      >
                        Search
                      </PrivateLink>
                      <PrivateLink
                        to="/subscriptions/orders/add"
                        onClick={props.handleCloseMobilebar}
                      >
                        Add
                      </PrivateLink>
                      <PrivateLink
                        to="/subscriptions/orders/ending"
                        onClick={props.handleCloseMobilebar}
                      >
                        Orders ending
                      </PrivateLink>
                    </div>
                  </Private>

                  <Private to="/countries">
                    <div
                      className="mobilebar-expander-secondary"
                      onClick={handleCountrySubtab}
                    >
                      Countries{' '}
                      <IoIosArrowDown
                        className={state.countrySubtab && 'open-arrow'}
                      />
                    </div>
                    <div
                      className={`mobilebar-group-secondary ${
                        state.countrySubtab && 'expand-mobilebar'
                      }`}
                    >
                      <PrivateLink
                        to="/countries"
                        onClick={props.handleCloseMobilebar}
                      >
                        Search
                      </PrivateLink>
                      <PrivateLink
                        to="/countries/add"
                        onClick={props.handleCloseMobilebar}
                      >
                        Add
                      </PrivateLink>
                    </div>
                  </Private>

                  <Private to="/providers">
                    <div
                      className="mobilebar-expander-secondary"
                      onClick={handleProviderSubtab}
                    >
                      Providers{' '}
                      <IoIosArrowDown
                        className={state.providerSubtab && 'open-arrow'}
                      />
                    </div>
                    <div
                      className={`mobilebar-group-secondary ${
                        state.providerSubtab && 'expand-mobilebar'
                      }`}
                    >
                      <PrivateLink
                        to="/providers"
                        onClick={props.handleCloseMobilebar}
                      >
                        Search
                      </PrivateLink>
                      <PrivateLink
                        to="/providers/add"
                        onClick={props.handleCloseMobilebar}
                      >
                        Add
                      </PrivateLink>
                    </div>
                  </Private>
                </div>
              </Private>

              <Private authNeeded={[USER_AUTHORITIES.RESELLER_ADMIN]}>
                <div
                  className="mobilebar-expander-primary"
                  onClick={handleStockTab}
                >
                  Voltaware Sensors Stock Manager{' '}
                  <IoIosArrowDown className={state.stockTab && 'open-arrow'} />
                </div>
                <div
                  className={`mobilebar-group-primary ${
                    state.stockTab && 'expand-mobilebar'
                  }`}
                >
                  <PrivateLink
                    to="/inventory/block"
                    onClick={props.handleCloseMobilebar}
                  >
                    Block
                  </PrivateLink>
                  <PrivateLink
                    to="/inventory/unblock"
                    onClick={props.handleCloseMobilebar}
                  >
                    Unblock
                  </PrivateLink>
                  <PrivateLink
                    to="/inventory/blocked"
                    onClick={props.handleCloseMobilebar}
                  >
                    Blocked Devices
                  </PrivateLink>
                </div>
                <PrivateLink
                  to="/subscriptions"
                  onClick={() => {
                    closeTabs();
                    props.handleCloseMobilebar();
                  }}
                >
                  Subscriptions
                </PrivateLink>
                <PrivateLink
                  to="/subscriptions/orders"
                  onClick={() => {
                    closeTabs();
                    props.handleCloseMobilebar();
                  }}
                >
                  Orders
                </PrivateLink>
              </Private>

              <Private authNeeded={[USER_AUTHORITIES.RESELLER_USER]}>
                <PrivateLink
                  to="/inventory/blocked"
                  onClick={() => {
                    closeTabs();
                    props.handleCloseMobilebar();
                  }}
                >
                  Blocked Devices
                </PrivateLink>
              </Private>

              <Private to="/users">
                <div
                  className="mobilebar-expander-primary"
                  onClick={handleSettingsTab}
                >
                  Settings{' '}
                  <IoIosArrowDown
                    className={state.settingsTab && 'open-arrow'}
                  />
                </div>
                <div
                  className={`mobilebar-group-primary ${
                    state.settingsTab && 'expand-mobilebar'
                  }`}
                >
                  <PrivateLink to="/users" onClick={props.handleCloseMobilebar}>
                    Users
                  </PrivateLink>
                </div>
              </Private>

              <Private to="/reseller-users">
                <div
                  className="mobilebar-expander-primary"
                  onClick={handleSettingsTab}
                >
                  Settings{' '}
                  <IoIosArrowDown
                    className={state.settingsTab && 'open-arrow'}
                  />
                </div>
                <div
                  className={`mobilebar-group-primary ${
                    state.settingsTab && 'expand-mobilebar'
                  }`}
                >
                  <PrivateLink
                    to="/reseller-users"
                    onClick={props.handleCloseMobilebar}
                  >
                    Users
                  </PrivateLink>
                </div>
              </Private>
            </nav>

            <Link
              to="/logout"
              className="logout"
              onClick={props.handleCloseMobilebar}
            >
              <img src={logout} alt="voltaware crm logout" /> Logout
            </Link>
          </div>
        </>
      )}
    </>
  );
}

export default Mobilebar;
