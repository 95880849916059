import { useReducer } from 'react';
import moment from 'moment';

import API from '../../components/api';

import Button from '../../elements/button';
import Text from '../../elements/text';
import ErrorMessage from '../../elements/error-message';

import './confirm.sass';

function StockAddConfirm(props) {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      loading: false,
      cancelLoading: false,
      confirmLoading: false,
      error: '',
    }
  );

  const cancel = (e) => {
    e.preventDefault();
    setState({ error: '' });
    props.reset('canceled');
  };

  const confirm = (e) => {
    e.preventDefault();

    setState({ confirmLoading: true, loading: true, error: '' });
    API.STOCK.ADD.CONFIRM(props.batchNumber, props.stocks)
      .then((response) => {
        if (response.status === 200) {
          props.reset('confirmed');
        } else {
          console.log(response);
        }
      })
      .catch((error) => {
        console.log(error);
        setState({
          confirmLoading: false,
          loading: false,
          error: error.response.data.message,
        });
      });
  };

  let date = props.productionDate
    ? moment(props.productionDate).format('DD/MM/YYYY')
    : '';

  return (
    <section>
      <Text bold={true} inline={true} text="The file selected has " />
      <Text bold={true} highlight={true} inline={true} text={props.lines} />
      <Text
        bold={true}
        inline={true}
        text=" lines. Check the data below and confirm to proceed or cancel to restart the process."
      />

      <div className="info">
        <Text bold={true} inline={true} text="Production date:" />
        <Text bold={true} highlight={true} inline={true} text={date} />
      </div>
      <div>
        <Text bold={true} inline={true} text="Batch #:" />
        <Text
          bold={true}
          highlight={true}
          inline={true}
          text={props.batchNumber}
        />
      </div>
      <div>
        <Text bold={true} inline={true} text="Clamp:" />
        <Text
          bold={true}
          highlight={true}
          inline={true}
          text={props.clampSize}
        />
      </div>
      <div>
        <Text bold={true} inline={true} text="# Phases:" />
        <Text bold={true} highlight={true} inline={true} text={props.phases} />
      </div>

      {state.error && <ErrorMessage message={state.error} />}

      <div className="btn-actions">
        <Button
          disabled={state.loading}
          loading={state.cancelLoading}
          click={cancel}
          action="cancel"
          label="Cancel"
        />
        <Button
          disabled={state.loading}
          loading={state.confirmLoading}
          click={confirm}
          label="Confirm"
        />
      </div>
    </section>
  );
}

export default StockAddConfirm;
