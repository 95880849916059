import { useReducer } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';

import API from '../../components/api';
import { testEmail, isAdmin, isResellerAdmin } from '../../components/utils';

import Button from '../../elements/button';
import ErrorMessage from '../../elements/error-message';
import Input from '../../elements/forms/input';
import Text from '../../elements/text';
import Title from '../../elements/title';

import 'react-datepicker/dist/react-datepicker.css';

import './events-exporting.sass';

function EventsExporting(props) {
  const { client } = props;
  const initialState = {
    email: (client && client.account && client.account.email) || '',
    fromDate: moment(new Date(Date.now())).subtract(7, 'days').toDate(),
    toDate: new Date(Date.now()),
    feedbackMessage: '',
    fieldsWithError: [],
    loading: false,
  };

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    initialState
  );

  const change = (e) => {
    setState({ [e.target.name]: e.target.value });
  };

  const changeFromDate = (date) => {
    setState({ fromDate: date });

    if (moment(date).isAfter(moment(state.toDate), 'days')) {
      return setState({ toDate: date });
    }

    const diff = moment(state.toDate).diff(moment(date), 'days');
    if (Math.abs(diff) > 7) {
      const updatedDate = moment(date).add(7, 'days').toDate();
      setState({ toDate: updatedDate });
    }
  };

  const changeToDate = (date) => {
    setState({ toDate: date });

    if (moment(date).isBefore(moment(state.fromDate), 'days')) {
      return setState({ fromDate: date });
    }

    const diff = moment(date).diff(moment(state.fromDate), 'days');
    if (Math.abs(diff) > 7) {
      const updatedDate = moment(date).subtract(7, 'days').toDate();
      setState({ fromDate: updatedDate });
    }
  };

  const goBack = () => window.history.back();

  const isAdminOrResellerAdmin = () => {
    return isAdmin() || isResellerAdmin();
  };

  const validate = () => {
    let fieldsWithError = [];

    if (props.client.id === '') fieldsWithError.push('id');
    if (isAdminOrResellerAdmin() && !testEmail(state.email))
      fieldsWithError.push('email');
    if (state.fromDate === '') fieldsWithError.push('fromDate');
    else {
      if (!moment(state.fromDate).isBefore(moment()))
        fieldsWithError.push('fromDate');
    }
    if (state.toDate === '') fieldsWithError.push('toDate');
    else {
      if (!moment(state.toDate).isBefore(moment()))
        fieldsWithError.push('toDate');
    }
    if (
      state.fromDate !== '' &&
      state.toDate !== '' &&
      !moment(state.fromDate).isSameOrBefore(state.toDate)
    ) {
      fieldsWithError.push('fromDate');
      fieldsWithError.push('toDate');
    }
    setState({ loading: false, fieldsWithError: fieldsWithError });

    return !fieldsWithError.length;
  };

  const send = () => {
    setState({ loading: true });

    API.SENSORS.EVENTS_EXPORTING.SEND_TO_EMAIL(
      props.client.id,
      moment(state.fromDate).format('YYYY-MM-DD'),
      moment(state.toDate).format('YYYY-MM-DD'),
      isAdminOrResellerAdmin() ? state.email : null
    )
      .then((response) => {
        let state = initialState;
        state.feedbackMessage = (
          <Text
            bold={true}
            highlight={true}
            text="Events exported sucessfully."
          />
        );
        setState(state);
      })
      .catch((error) => {
        let fieldsWithError = [],
          feedbackMessage = (
            <ErrorMessage
              message={
                'An error occurred when exporting the events. Please try again later or contact support.'
              }
            />
          );
        console.error(error);

        setState({
          feedbackMessage: feedbackMessage,
          fieldsWithError: fieldsWithError,
          loading: false,
        });
      });
  };

  const submit = (e) => {
    e.preventDefault();

    e.target.querySelector('button[type="submit"]').blur();

    setState({ feedbackMessage: '', loading: true });
    if (validate()) {
      send();
    }

    return false;
  };

  return (
    <div id="admintools-eventsexporting">
      <Title element="h6" text="Events Exporting" />

      {state.feedbackMessage ? state.feedbackMessage : null}

      <form action="#" onSubmit={submit}>
        <Text
          bold={true}
          warning={true}
          text="In order to avoid long waiting periods and possible bugs, you can only export up to one week data."
        />
        <Text
          bold={true}
          text={`Export events to device ${props.client.id}:`}
        />
        <div>
          <div>
            <Input
              change={change}
              disabled={state.loading || !isAdminOrResellerAdmin()}
              hasError={state.fieldsWithError.indexOf('email') >= 0}
              name="email"
              placeholder="Email"
              type="email"
              value={
                isAdminOrResellerAdmin()
                  ? state.email
                  : "Events will be sent to device's email"
              }
            />
          </div>
          <div>
            <label
              className={
                state.fieldsWithError.indexOf('fromDate') >= 0
                  ? 'has-error'
                  : ''
              }
            >
              <span className="placeholder deactive">Start date</span>
              <DatePicker
                disabled={state.loading}
                onChange={changeFromDate}
                selected={state.fromDate}
                maxDate={new Date()}
                dateFormat="dd/MM/yyyy"
              />
            </label>
          </div>
          <div>
            <label
              className={
                state.fieldsWithError.indexOf('toDate') >= 0 ? 'has-error' : ''
              }
            >
              <span className="placeholder deactive">End date</span>
              <DatePicker
                disabled={state.loading}
                onChange={changeToDate}
                selected={state.toDate}
                maxDate={new Date()}
                dateFormat="dd/MM/yyyy"
              />
            </label>
          </div>
          <div className="btn-actions">
            <Button
              action="cancel"
              click={goBack}
              disabled={state.loading}
              label="Cancel"
              loading={false}
            />
            <Button disabled={state.loading} label="Export" type="submit" />
          </div>
        </div>
      </form>
    </div>
  );
}

export default EventsExporting;
