import { useReducer, useRef, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

import { isResellerAdmin } from '../../../components/utils';
import API from '../../../components/api';
import {
  GET_CURRENCY_LABEL,
  GET_HARDWARE_TYPE_LABEL,
  GET_PAYMENT_TYPE_LABEL,
  GET_SUBSCRIPTION_PRODUCTS_LABELS,
  NO_RESULTS_MESSAGE,
} from '../../../components/constants';

import Button from '../../../elements/button';
import ErrorMessage from '../../../elements/error-message';
import Input from '../../../elements/forms/input';
import Text from '../../../elements/text';
import Title from '../../../elements/title';
import Select from '../../../elements/forms/select';
import Table from '../../../elements/table';

import './browse.sass';

const isBigSensorsList = (sensorIds) => sensorIds.split(',').length > 3;

const sensorIdLabel = (sensorIds) => {
  if (isBigSensorsList(sensorIds)) {
    return `${sensorIds.split(',').splice(0, 3).join(', ')} (...)`;
  } else {
    return sensorIds;
  }
};

const filterObject = {
  search: '',
  searchBy: '',
  searchOptions: Object.assign(
    [],
    [
      {
        label: 'Subscription Name',
        value: 'SUBSCRIPTION_NAME',
      },
      {
        label: 'Reseller ID',
        value: 'RESELLER_ID',
      },
      {
        label: 'Reseller Name',
        value: 'RESELLER_NAME',
      },
      {
        label: 'Device ID',
        value: 'SENSOR_ID',
      },
    ]
  ),
  resellerName: '',
};

function SubscriptionOrderBrowse(props) {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      filter: filterObject,
      allResellers: [],
      loading: true,
      noResultsMessage: props.tableOnlyMode
        ? 'There are no orders for this reseller.'
        : NO_RESULTS_MESSAGE,
      page: 0,
      pages: 1,
      pagesLastSensor: [0],
      orders: [],
      hasError: false,
      validationMessage: 'Please, select a property and fill the search field.',
      isSearching: false,
    }
  );
  const isMountedRef = useRef(null);
  const pagesLastId = useRef([0]);

  useEffect(() => {
    isMountedRef.current = true;
    handleSearch();

    return () => (isMountedRef.current = false);
  }, []);

  const columns = useMemo(
    () => [
      ...(isResellerAdmin()
        ? []
        : [{ id: 'edit', title: '', align: 'center' }]),
      {
        id: 'id',
        title: 'ID',
        align: 'center',
        minWidth: 50,
      },
      {
        id: 'subscriptionName',
        title: 'Subscription',
        align: 'left',
      },
      {
        id: 'resellerName',
        title: 'Reseller',
        align: 'left',
      },
      {
        id: 'hardwareUnitPrice',
        title: 'Hardware price',
        align: 'center',
      },
      {
        id: 'sensorIds',
        title: 'Devices IDs',
        align: 'left',
      },
      {
        id: 'startDate',
        title: 'Start date',
        align: 'center',
      },
      {
        id: 'notes',
        title: 'Notes',
        align: 'left',
        minWidth: 50,
      },
    ],
    [isResellerAdmin]
  );

  const columnsWithSubscrition = useMemo(
    () => [
      ...columns.filter(
        (column) =>
          column.id !== 'subscriptionName' && column.id !== 'resellerName'
      ),
      {
        id: 'subscriptionName',
        title: 'Subscription',
        align: 'left',
      },
      {
        id: 'subscriptionDurationText',
        title: 'Agreement duration',
        align: 'center',
      },
      {
        id: 'subscriptionHardwareType',
        title: 'Hardware type',
        align: 'left',
      },
      {
        id: 'subscriptionPaymentType',
        title: 'Payment frequency',
        align: 'left',
      },
      {
        id: 'subscriptionPaymentRateText',
        title: 'Payment rate',
        align: 'center',
      },
      {
        id: 'subscriptionToleranceText',
        title: 'Tolerance period',
        align: 'center',
      },
      {
        id: 'subscriptionProducts',
        title: 'Products',
        align: 'center',
      },
      {
        id: 'subscriptionNotes',
        title: 'Subscription Notes',
        align: 'left',
      },
    ],
    []
  );

  const data = useMemo(() => {
    return state.orders.map((row) => {
      const getDetails = () => {
        const sensorIds = (row && row.sensorIds) || 'This order has no sensors';
        return (
          <div className="expandable-section">
            <h6>{"Order's Devices IDs"}</h6>
            <Text text={sensorIds} />
          </div>
        );
      };

      return {
        ...row,
        edit: (
          <Link
            to={{
              pathname: `/subscriptions/orders/edit`,
              state: { order: row },
            }}
          >
            <Button action="edit" />
          </Link>
        ),
        subscriptionName: row.subscription.name || '-',
        resellerName: row.reseller.name || '-',
        sensorIds: (
          <div
            data-tip={
              isBigSensorsList(row.sensorIds)
                ? 'Expand the row to see the complete devices IDs list'
                : null
            }
          >
            {sensorIdLabel(row.sensorIds)}
          </div>
        ),
        startDate: moment(row.startDate).format('DD/MM/YYYY'),
        notes: row.notes || '-',
        subscriptionDurationText: row.subscription.durationText,
        subscriptionHardwareType: GET_HARDWARE_TYPE_LABEL(
          row.subscription.hardwareType
        ),
        subscriptionPaymentType: GET_PAYMENT_TYPE_LABEL(
          row.subscription.paymentType
        ),
        subscriptionPaymentRateText: row.subscription.paymentRateText,
        subscriptionToleranceText: row.subscription.toleranceText,
        subscriptionProducts:
          row.subscription.products && row.subscription.products.length
            ? GET_SUBSCRIPTION_PRODUCTS_LABELS(row.subscription.products).join(
                ', '
              )
            : '-',
        subscriptionNotes: row.subscription.notes || '-',
        details: getDetails(),
      };
    });
  }, [state.orders]);

  const changeBy = (e) => {
    setState({
      filter: {
        ...state.filter,
        searchBy: e.target.value,
        search: '',
        resellerName: '',
      },
    });
  };

  const change = (e) => {
    setState({ filter: { ...state.filter, search: e.target.value } });
  };

  const changeReseller = (e) => {
    setState({
      filter: {
        ...state.filter,
        resellerName: e.target.value,
        search: e.target.value,
      },
    });
  };

  useEffect(() => {
    if (
      state.filter.resellerName.length > 1 &&
      state.filter.resellerName.length < 30
    ) {
      API.RESELLERS.SEARCH(state.filter.resellerName, 'NAME').then(
        (response) => {
          if (!isMountedRef.current) {
            return;
          }
          let resellers = response.data.map((item) => {
            return { id: item.id, name: item.name };
          });

          const matchIndex = resellers.findIndex(
            (reseller) => reseller.name === state.filter.resellerName
          );
          if (matchIndex >= 0) {
            resellers = [];
          }

          setState({
            allResellers: resellers,
          });
        }
      );
    } else {
      setState({ allResellers: [] });
    }
  }, [state.filter.resellerName]);

  const selectReseller = (e) => {
    e.preventDefault();
    setState({
      filter: {
        ...state.filter,
        resellerName: e.target.name,
        search: e.target.name,
      },
      allResellers: [],
    });
  };

  const validate = () => {
    const filter = state.filter;
    let validationMessage =
      'Please, select a property and fill the search field.';
    let hasError = false;
    if (
      (filter.search !== '' && filter.searchBy === '') ||
      (filter.search === '' && filter.searchBy !== '')
    ) {
      hasError = true;
    } else if (filter.searchBy === 'RESELLER_ID') {
      const numberRegex = /^\d+$/;
      if (!numberRegex.test(filter.search.replace(/\s+/g, ''))) {
        hasError = true;
        validationMessage = 'Please, type a number for the ID.';
      }
    }
    setState({ hasError, validationMessage });
    return !hasError;
  };

  const getSubscription = async (id) => {
    return API.SUBSCRIPTION.DETAILS(id)
      .then((response) => {
        if (!isMountedRef.current) {
          return;
        }

        if (!response.data) return null;

        const subcription = response.data;

        return {
          ...subcription,
          durationText: `${subcription.duration} month${
            subcription.duration !== 1 ? 's' : ''
          }`,
          toleranceText: `${subcription.tolerance} month${
            subcription.tolerance !== 1 ? 's' : ''
          }`,
          paymentRateText: `${subcription.paymentRate} ${
            subcription.paymentRate > 0
              ? GET_CURRENCY_LABEL(subcription.currency) + '/u'
              : ''
          }`,
        };
      })
      .catch((error) => {
        console.error(error);
        return null;
      });
  };

  const handlePreviousPage = () => {
    pagesLastId.current.pop();
    handleSearch(pagesLastId.current[pagesLastId.current.length - 1]);
  };

  const handleNextPage = () => {
    pagesLastId.current.push(state.lastId);
    handleSearch(pagesLastId.current[pagesLastId.current.length - 1]);
  };

  const handleSearch = (lastId) => {
    if (!validate()) {
      window.scrollTo(0, 0);
      return false;
    }

    setState({
      loading: true,
    });

    let search = state.filter.search;
    let searchBy = state.filter.searchBy;

    if (props.resellerId) {
      search = props.resellerId;
      searchBy = 'RESELLER_ID';
    }

    API.SUBSCRIPTION.ORDER.LIST(search, searchBy, lastId)
      .then(async (response) => {
        if (!isMountedRef.current) {
          return;
        }

        let subscriptionsIds = response.data.orders.map(
          (order) => order && order.subscription && order.subscription.id
        );
        if (subscriptionsIds && subscriptionsIds.length)
          subscriptionsIds = subscriptionsIds.reduce(
            (uniques, id) =>
              !uniques.includes(id) ? [...uniques, id] : uniques,
            []
          );

        let subscriptions = [];
        try {
          subscriptions = await Promise.all(
            subscriptionsIds.map((subscriptionId) =>
              getSubscription(subscriptionId)
            )
          );
        } catch (error) {
          console.error(error);
        }
        subscriptions = subscriptions.filter((d) => d);

        const orders = response.data.orders.map((order) => {
          const subscription = subscriptions.find(
            (subscription) => subscription.id === order.subscription.id
          );
          return {
            ...order,
            subscription: subscription && { ...subscription },
          };
        });

        setState({
          loading: false,
          orders:
            orders && orders.length ? orders.sort((a, b) => a.id - b.id) : [],
          lastId: response.data.lastOrderIdOfThePage,
        });
        ReactTooltip.rebuild();
      })
      .catch((error) => {
        console.error(error);

        setState({
          loading: false,
          orders: [],
        });
      });
  };

  const submit = (e) => {
    e.preventDefault();
    e.target.querySelector('button[type="submit"]').blur();

    if (!validate()) {
      return false;
    }

    pagesLastId.current = [0];
    handleSearch();

    return false;
  };

  const renderTable = () => {
    const { orders } = state;
    const { tableOnlyMode } = props;

    return (
      <Table
        columns={
          tableOnlyMode && orders.some((o) => !!o.subscription)
            ? columnsWithSubscrition
            : columns
        }
        data={data}
        isLoading={state.loading}
        showDetails
        showPagination
        hasPrevious={pagesLastId.current.length > 1}
        hasNext={state.lastId !== 0}
        handlePreviousPage={handlePreviousPage}
        handleNextPage={handleNextPage}
      />
    );
  };

  if (props.tableOnlyMode) {
    return (
      <section id="page-subscription-order-browse">{renderTable()}</section>
    );
  } else {
    return (
      <section id="page-subscription-order-browse">
        <Title element="h4" text="Orders" />

        <Text
          bold={true}
          text="Search for an order by Subscription Name, Reseller ID/Name or Device ID."
        />

        {state.hasError ? (
          <ErrorMessage message={state.validationMessage} />
        ) : null}

        <form action="#" onSubmit={submit}>
          <div>
            <Select
              change={changeBy}
              disabled={state.loading}
              hasError={state.hasError}
              name="searchBy"
              options={state.filter.searchOptions}
              placeholder="Property"
              value={state.filter.searchBy}
            />
          </div>
          <div>
            {{
              RESELLER_NAME: (
                <Input
                  autocomplete="off"
                  change={changeReseller}
                  data={state.allResellers}
                  disabled={state.loading}
                  hasError={state.hasError}
                  name="resellerName"
                  placeholder="Search"
                  select={selectReseller}
                  type="autoComplete"
                  value={state.filter.resellerName}
                />
              ),
            }[state.filter.searchBy] || (
              <Input
                change={change}
                disabled={state.loading}
                hasError={state.hasError}
                name="filter"
                placeholder="Search"
                type="text"
                value={state.filter.search}
              />
            )}
          </div>
          <div>
            <Button
              action="search"
              disabled={state.loading}
              type="submit"
              aria-label="search"
            />
          </div>
        </form>

        <ReactTooltip />
        {renderTable()}
      </section>
    );
  }
}

export default SubscriptionOrderBrowse;
