import { useRef, useReducer, useLayoutEffect, useEffect, useMemo } from 'react';
import { useLocation, useParams, useHistory, Link } from 'react-router-dom';
import FileDownload from 'react-file-download';

import API from '../components/api';
import {
  DEVICES,
  DEVICES_OPTIONS,
  UNAVAILABLE_MESSAGE,
  SENSOR_STATUS,
  SENSOR_STATUS_OPTIONS,
  GET_HARDWARE_TYPE_LABEL,
} from '../components/constants';
import { DATE_FORMATTER } from '../components/date-hour-formatter';
import { isAdmin, isReseller, isBusiness } from '../components/utils';
import { COUNTRIES } from '../components/country';
import { hasVoltawareSensors, hasSmartMeters } from '../components/utils';

import Button from '../elements/button';
import ErrorMessage from '../elements/error-message';
import Input from '../elements/forms/input';
import Select from '../elements/forms/select';
import Title from '../elements/title';
import Text from '../elements/text';
import FieldWithTooltip from '../elements/field-with-tooltip';
import Table from '../elements/table';

import SensorInfo from './account/sensor-info';
import Details from './account/details';
import SmartMeterInfo from './account/smart-meter-info';
import SmartMeterDetails from './account/smart-meter-details';

import './devices.sass';

let devicesOptions = DEVICES_OPTIONS.filter(
  (option) => option.value !== DEVICES.ALL
);

if (!hasVoltawareSensors) {
  devicesOptions = devicesOptions.filter(
    (option) => option.value !== DEVICES.VOLTAWARE_SENSORS
  );
}
if (!hasSmartMeters) {
  devicesOptions = devicesOptions.filter(
    (option) => option.value !== DEVICES.LOW_RESOLUTION_METER && option.value !== DEVICES.HIGH_RESOLUTION_METER
  );
}

let searchOptions = [
  {
    label: 'Device ID',
    value: 'SENSOR_ID',
  },
  {
    label: 'Device Status',
    value: 'SENSOR_STATUS',
  },
  {
    label: 'Device Country',
    value: 'COUNTRY',
  },
  {
    label: 'User Name',
    value: 'NAME',
  },
  {
    label: 'User Email',
    value: 'EMAIL',
  },
];

function Devices() {
  const location = useLocation();
  const params = useParams();
  const history = useHistory();

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      clientId: null,
      integratorId: null,
      clients: [],
      loading: false,
      deviceType: location.pathname.includes('smart-meter')
        ? DEVICES.HIGH_RESOLUTION_METER
        : devicesOptions[0].value,
      search: '',
      searchBy: '',
      countriesOptions: [],
      tab: '',
      loadingSensor: true,
      sensorLoadingSuccess: false,
      sensor: {},
      resellers: [],
      resellerId: '',
      resellerName: '',
      isSearching: false,
      downloadError: '',
      downloadLoading: false,
      integratorIdForDownload: '',
      isReady: false,
    }
  );
  const isMountedRef = useRef(null);
  const pagesLastId = useRef([0]);
  const isFirstAccess = useRef(true);
  const isBusy = useRef(false);

  const isSmartMeter = state.deviceType === DEVICES.HIGH_RESOLUTION_METER ||
  state.deviceType === DEVICES.LOW_RESOLUTION_METER;

  useLayoutEffect(() => {
    isMountedRef.current = true;

    if (params.id && params.integratorId) {
      setState({ clientId: params.id, integratorId: params.integratorId });
      sensorDetails(params.id, params.integratorId);
    } else if (params.id) {
      setState({ clientId: params.id });
      sensorDetailsSet(params.id);
    } else {
      const searchParams = new URLSearchParams(location.search);
      let type, by, query, resellerId, resellerName;

      type = searchParams.get('type')
        ? searchParams.get('type')
        : devicesOptions[0].value;
      by = searchParams.get('by') ? searchParams.get('by') : '';
      query = searchParams.get('query') ? searchParams.get('query') : '';
      resellerId = searchParams.get('resellerId') ? searchParams.get('resellerId') : '';
      resellerName = searchParams.get('resellerName') ? searchParams.get('resellerName') : '';
      if (searchParams.get('type') || by || query || resellerId) {
        isFirstAccess.current = false;
      }
      searchParams.delete('resellerId');
      searchParams.delete('resellerName');
      history.replace({
        search: searchParams.toString(),
      });
      setState({
        deviceType: type,
        search: query,
        searchBy: by,
        resellerId: resellerId,
        resellerName: resellerName,
        isReady: true,
      });
    }

    loadCountries();
    window.addEventListener('resize', resize.bind(this));
    resize();

    return () => {
      window.removeEventListener('resize', resize.bind(this));
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    const { id, integratorId } = params;
    if (
      id &&
      id !== state.clientId &&
      integratorId &&
      integratorId !== state.integratorId
    ) {
      setState({ clientId: id, integratorId: integratorId });
      sensorDetails(id, integratorId);
    } else if (id && id !== state.clientId) {
      setState({ clientId: id });
      sensorDetails(id);
    }
  });

  useEffect(() => {
    if ((isReseller() || isFirstAccess.current === false) && state.isReady) {
      handleSearch();
    }
  }, [state.isReady]);

  const getLastEvent =
    (id, integratorId = null) =>
    async () => {
      try {
        let response;
        if (integratorId) {
          response = await API.SMART_METERS.DETAILS(id, integratorId);
        } else {
          response = await API.SENSORS.DETAILS(id);
        }

        if (!isMountedRef.current) {
          return;
        }

        if (
          response.status !== 200 ||
          (response.data && !response.data.lastEvent)
        ) {
          throw new Error();
        }
        return (
          response.data && `${DATE_FORMATTER.PARTIAL(response.data.lastEvent)}`
        );
      } catch (error) {
        if (!isMountedRef.current) {
          return;
        }

        return null;
      }
    };

  const columns = useMemo(
    () => [
      ...(state.deviceType !== DEVICES.LOW_RESOLUTION_METER
        ? [
            {
              id: 'accountId',
              title: 'Device Status',
              align: 'center',
            },
          ]
        : []),
      {
        id: 'sensorId',
        title: 'Device ID',
        align: 'center',
      },
      {
        id: 'country',
        title: 'Device Country',
        align: 'left',
      },
      {
        id: 'resellerName',
        title: 'Reseller Name',
        align: 'left',
      },
      ...(state.deviceType === DEVICES.VOLTAWARE_SENSORS
        ? [
            {
              id: 'hardwareType',
              title: 'Hardware Type',
              align: 'left',
            },
          ]
        : []),
    ],
    [state.clients]
  );

  const data = useMemo(() => {
    return state.clients.map((row) => {
      const wrapWithLink = (element) => {
        const link =
          state.deviceType === DEVICES.VOLTAWARE_SENSORS
            ? `/sensor/${row.sensorId}`
            : `/smart-meter/${row.integratorId}/${row.sensorId}`;
        return (
          <Link to={{ pathname: link, state: 'account' }}>
            <div className="device-cell">{element}</div>
          </Link>
        );
      };

      return {
        ...row,
        accountId: wrapWithLink(
          <FieldWithTooltip
            className="sensor-status-section"
            status={SENSOR_STATUS[row.sisStatus]}
            getLastEvent={getLastEvent(
              row.sensorId,
              isSmartMeter
                ? row.integratorId
                : null
            )}
          >
            <span className={`sensor-status ${row.sisStatus}`} />
          </FieldWithTooltip>
        ),
        sensorId: wrapWithLink(<div>{row.sensorId}</div>),
        country: wrapWithLink(row && row.country && row.country.name ? row.country.name : '-'),
        resellerName: wrapWithLink(row.resellerName ? row.resellerName : '-'),
        hardwareType: wrapWithLink(GET_HARDWARE_TYPE_LABEL(row.hardwareType)),
        id: row.sensorId + row.resellerName,
      };
    });
  }, [state.clients, state.deviceType]);

  const resize = () => {
    if (!isMountedRef.current) {
      return;
    }

    let currentIsMobile = window.innerWidth <= 768;
    if (currentIsMobile !== state.isMobile) {
      setState({ isMobile: currentIsMobile });
    }
  };

  const loadCountries = () => {
    let fn = isReseller() ? API.COUNTRY.GENERAL : API.COUNTRY.SUMMARY;

    fn()
      .then((response) => {
        if (!isMountedRef.current) {
          return;
        }

        let options = COUNTRIES.GET_OPTIONS(response.data);

        setState({ countriesOptions: options });
      })
      .catch((response) => {
        console.error('Error while loading countries.');
      });
  };

  const sensorDetailsSet = (id) => {
    if (isAdminBusinessOrResellerUser()) {
      sensorDetails(id);
    } else {
      let sensor = {
        id: null,
      };
      setState({
        loadingSensor: false,
        sensorLoadingSuccess: true,
        sensor: sensor,
      });
    }
  };

  const isAdminBusinessOrResellerUser = () => {
    return isAdmin() || isReseller() || isBusiness();
  };

  const sensorDetails = (id, integratorId = null) => {
    let sensor = {
      id: null,
    };

    if (state.deviceType === DEVICES.VOLTAWARE_SENSORS) {
      API.SENSORS.DETAILS(id)
        .then((response) => {
          if (!isMountedRef.current) {
            return;
          }

          if (response.data.id) {
            sensor.id = response.data.id;
            sensor.firmwareVersion =
              response.data.firmwareVersion || UNAVAILABLE_MESSAGE;
            sensor.connectionState =
              response.data.connectionState || UNAVAILABLE_MESSAGE;
            sensor.wifiSignal = response.data.wifiSignalQuality || 0;
            sensor.lastBoot = response.data.lastBoot
              ? DATE_FORMATTER.COMPLETE(response.data.lastBoot)
              : UNAVAILABLE_MESSAGE;
            sensor.machineLearningPercent = response.data.percentage || null;
            sensor.latitude = response.data.latitude || null;
            sensor.longitude = response.data.longitude || null;
            sensor.lastEventFormated = response.data.lastEvent
              ? DATE_FORMATTER.COMPLETE(response.data.lastEvent)
              : UNAVAILABLE_MESSAGE;
            sensor.firstEvent = response.data.firstEvent || UNAVAILABLE_MESSAGE;
            sensor.firstEventFormated = response.data.firstEvent
              ? DATE_FORMATTER.COMPLETE(response.data.firstEvent)
              : UNAVAILABLE_MESSAGE;

            setState({
              loadingSensor: false,
              sensorLoadingSuccess: true,
              sensor: sensor,
            });
          } else {
            setState({
              loadingSensor: false,
              sensorLoadingSuccess: false,
              sensor: sensor,
            });
          }
        })
        .catch((response) => {
          setState({
            loadingSensor: false,
            sensorLoadingSuccess: false,
            sensor: sensor,
          });
        });
    } else if (isSmartMeter && integratorId) {
      API.SMART_METERS.DETAILS(id, integratorId)
        .then((response) => {
          if (!isMountedRef.current) {
            return;
          }

          if (response.data) {
            sensor = {
              ...response.data,
              lastEventFormated: response.data.lastEvent
                ? DATE_FORMATTER.COMPLETE(response.data.lastEvent)
                : UNAVAILABLE_MESSAGE,
              firstEventFormated: response.data.firstEvent
                ? DATE_FORMATTER.COMPLETE(response.data.firstEvent)
                : UNAVAILABLE_MESSAGE,
            };

            setState({
              loadingSensor: false,
              sensorLoadingSuccess: true,
              sensor: sensor,
            });
          } else {
            setState({
              loadingSensor: false,
              sensorLoadingSuccess: false,
              sensor: sensor,
            });
          }
        })
        .catch((response) => {
          setState({
            loadingSensor: false,
            sensorLoadingSuccess: false,
            sensor: sensor,
          });
        });
    }
  };

  const changeBy = (e) => {
    setState({
      [e.target.name]: e.target.value,
      search: '',
    });
  };

  const changeDevice = (e) => {
    setState({ [e.target.name]: e.target.value, search: '' });
  };

  const change = (e) => {
    setState({ [e.target.name]: e.target.value });
  };

  const changeReseller = (e) => {
    setState({ resellerName: e.target.value, isSearching: true });
  };

  useEffect(() => {
    if (!state.isSearching) {
      return;
    }

    if (state.resellerName === '') {
      setState({ resellerId: '', resellers: [], integratorIdForDownload: '' });
    } else if (
      state.resellerName.length > 1 &&
      state.resellerName.length < 30
    ) {
      API.RESELLERS.SEARCH(state.resellerName, 'NAME').then((response) => {
        let resellers = response.data.map((item) => {
          return {
            id: item.id,
            name: item.name,
            integratorId: item.integratorId,
          };
        });

        const matchIndex = resellers.findIndex(
          (reseller) => reseller.name === state.resellerName
        );
        if (matchIndex >= 0) {
          resellers = [];
        }

        setState({ resellers: resellers });
      });
    } else {
      setState({ resellers: [] });
    }
  }, [state.resellerName]);

  const selectReseller = (e) => {
    e.preventDefault();
    setState({
      resellerName: e.target.name,
      resellerId: e.target.id,
      integratorIdForDownload: e.target.getAttribute('integratorid'),
      resellers: [],
      isSearching: false,
    });
  };

  const validate = () => {
    const sensorIdRegex = /^[+]?\d+$/;
    let hasError = false;

    if (
      (state.search !== '' && state.searchBy === '') ||
      (state.search === '' && state.searchBy !== '')
    ) {
      hasError = true;
    } else if (
      state.deviceType === DEVICES.VOLTAWARE_SENSORS &&
      state.searchBy === 'SENSOR_ID'
    ) {
      if (!sensorIdRegex.test(state.search.replace(/\s+/g, ''))) {
        hasError = true;
      }
    }
    setState({ hasError: hasError });
    return !hasError;
  };

  const handlePreviousPage = () => {
    pagesLastId.current.pop();
    handleSearch(pagesLastId.current[pagesLastId.current.length - 1]);
  };

  const handleNextPage = () => {
    pagesLastId.current.push(state.lastId);
    handleSearch(pagesLastId.current[pagesLastId.current.length - 1]);
  };

  const handleSearch = (lastId) => {
    if (isBusy.current) {
      return;
    }

    if (!validate()) {
      window.scrollTo(0, 0);
      return false;
    }

    isBusy.current = true;

    setState({ loading: true, downloadError: false });

    if (state.deviceType === DEVICES.VOLTAWARE_SENSORS) {
      API.ACCOUNTS.SEARCH({
        resellerId: state.resellerId,
        searchBy: state.searchBy,
        query: state.search,
        pagesLastId: lastId,
      })
        .then((response) => {
          if (!isMountedRef.current) {
            return;
          }

          const newState = {
            clients: response.data.devices,
            loading: false,
            lastId: response.data.lastIdOfThePage,
          };

          if (isReseller()) {
            newState.integratorIdForDownload =
              response.data.devices &&
              response.data.devices[0] &&
              response.data.devices[0].integratorId;
          }

          setState(newState);
        })
        .catch((error) => {
          console.error(error);

          setState({
            clients: [],
            loading: false,
            lastId: 0,
          });
        })
        .finally(() => {
          isBusy.current = false;
        });
    } else if (isSmartMeter) {
      API.SMART_METERS.SEARCH({
        resellerId: state.resellerId,
        searchBy: state.searchBy,
        query: state.search,
        device: state.deviceType,
        pagesLastId: lastId,
      })
        .then((response) => {
          if (!isMountedRef.current) {
            return;
          }

          const newState = {
            clients: response.data.devices,
            loading: false,
            lastId: response.data.lastIdOfThePage,
          };

          if (isReseller()) {
            newState.integratorIdForDownload =
              response.data.devices &&
              response.data.devices[0] &&
              response.data.devices[0].integratorId;
          }

          setState(newState);
        })
        .catch((error) => {
          console.error(error);

          setState({
            clients: [],
            loading: false,
            lastId: 0,
          });
        })
        .finally(() => {
          isBusy.current = false;
        });
    }
  };

  const submit = (e) => {
    e.preventDefault();
    e.target.querySelector('button[type="submit"]').blur();
    if (validate()) {
      const isOnSensorPage = location.pathname.includes('/sensor');
      history.push(
        `/devices?type=${state.deviceType}&by=${state.searchBy}&query=${state.search}` + (isOnSensorPage ? `&resellerId=${state.resellerId}&resellerName=${state.resellerName}`: '')
      );
      isFirstAccess.current = false;

      pagesLastId.current = [0];
      handleSearch();
    }
    return false;
  };

  let currentSearchOptions = searchOptions;
  if (isSmartMeter) {
    currentSearchOptions = searchOptions.filter(
      (option) => option.value !== 'NAME' && option.value !== 'EMAIL'
    );
  }

  const handleDownloadCSV = () => {
    setState({ downloadLoading: true, downloadError: false });

    API.DEVICES.REPORT({
      integratorId: state.integratorIdForDownload,
      type: state.deviceType,
    })
      .then((response) => {
        if (!isMountedRef.current) {
          return;
        }
        FileDownload(response.data, 'Devices.csv');

        setState({ downloadLoading: false });
      })
      .catch((error) => {
        if (error.response.status === 404) {
          setState({
            downloadLoading: false,
            downloadError: 'No devices found.',
          });
        } else {
          setState({
            downloadLoading: false,
            downloadError: 'Something went wrong. Please try again later.',
          });
        }
      });
  };

  return (
    <section id="devices-monitoring">
      <div>
        <Title element="h4" text="Devices Monitoring" />
        {state.downloadError ? (
          <ErrorMessage message={state.downloadError} />
        ) : null}
        {state.hasError ? (
          <ErrorMessage message="Please, fill the search field." />
        ) : null}
        <form action="#" onSubmit={submit}>
          <div>
            <Select
              change={changeDevice}
              disabled={state.loading}
              name="deviceType"
              options={devicesOptions}
              placeholder="Device"
              noEmptyOption={true}
              value={state.deviceType}
            />
          </div>
          {!isReseller() && (
            <div>
              <Input
                autocomplete="off"
                change={changeReseller}
                data={state.resellers}
                disabled={state.loading}
                name="resellerName"
                placeholder="Reseller Name"
                select={selectReseller}
                type="autoComplete"
                value={state.resellerName}
              />
            </div>
          )}
          <div>
            <Select
              change={changeBy}
              disabled={state.loading}
              name="searchBy"
              options={currentSearchOptions}
              placeholder="Property"
              value={state.searchBy}
            />
          </div>
          <div>
            {{
              SENSOR_STATUS: (
                <Select
                  change={change}
                  disabled={state.loading}
                  hasError={state.hasError}
                  name="search"
                  options={SENSOR_STATUS_OPTIONS}
                  placeholder="Search"
                  value={state.search}
                />
              ),
              COUNTRY: (
                <Select
                  change={change}
                  disabled={state.loading}
                  hasError={state.hasError}
                  name="search"
                  options={state.countriesOptions}
                  placeholder="Search"
                  value={state.search}
                />
              ),
            }[state.searchBy] || (
              <Input
                change={change}
                disabled={state.loading}
                hasError={state.hasError}
                name="search"
                placeholder="Search"
                type="text"
                value={state.search}
              />
            )}
          </div>
          <div className="search-button">
            <Button action="search" disabled={state.loading} type="submit" />
          </div>
          {state.integratorIdForDownload &&
            state.searchBy === '' &&
            state.search === '' && (
              <div className="download-csv-button">
                <Button
                  click={handleDownloadCSV}
                  disabled={state.downloadLoading}
                  label="Download CSV"
                />
              </div>
            )}
        </form>
      </div>
      {!state.clientId ? (
        isFirstAccess.current && !isReseller() ? (
          <Text text="Please, fill the desired fields." />
        ) : (
          <Table
            columns={columns}
            data={data}
            isLoading={state.loading}
            showPagination
            hasPrevious={pagesLastId.current.length > 1}
            hasNext={state.lastId !== 0}
            handlePreviousPage={handlePreviousPage}
            handleNextPage={handleNextPage}
          />
        )
      ) : (
        <div id="account">
          {location.pathname.includes('sensor') && (
            <>
              <Title element="h4" text={`Sensor ID ${state.clientId}`} />
              {isAdminBusinessOrResellerUser() ? (
                <SensorInfo
                  loadingSensor={state.loadingSensor}
                  sensorLoadingSuccess={state.sensorLoadingSuccess}
                  sensor={state.sensor}
                />
              ) : null}
              <Details
                clientId={state.clientId}
                location={location}
                loadingSensor={state.loadingSensor}
                sensor={state.sensor}
              />
            </>
          )}

          {location.pathname.includes('smart-meter') && (
            <>
              <Title element="h4" text={`Smart Meter ID ${state.clientId}`} />
              <SmartMeterInfo
                loadingSensor={state.loadingSensor}
                sensorLoadingSuccess={state.sensorLoadingSuccess}
                sensor={state.sensor}
              />
              <SmartMeterDetails
                integratorId={state.integratorId}
                clientId={state.clientId}
                location={location}
                loadingSensor={state.loadingSensor}
                sensor={state.sensor}
              />
            </>
          )}
        </div>
      )}
    </section>
  );
}

export default Devices;
