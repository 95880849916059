import React from 'react';
import Button from '../../elements/button';
import ErrorMessage from '../../elements/error-message';
import Input from '../../elements/forms/input';

import './login.sass';
import logo from './voltaware-crm-login.png';

function LoginPage(props) {
  const { errors } = props;
  return (
    <section id="page-login">
      <form action="#" onSubmit={props.submit}>
        <img src={logo} alt="voltaware crm logo" />

        {errors.includes('credentials') ? (
          <ErrorMessage
            align="center"
            message="The username or password you entered is incorrect."
          />
        ) : null}

        {errors.includes('generic') ? (
          <>
            <ErrorMessage
              align="center"
              message="An error occurred while trying to log in."
            />
            <ErrorMessage
              align="center"
              message="Please try again in a few minutes or contact support."
            />
          </>
        ) : null}

        <Input
          change={props.change}
          disabled={props.loading}
          hasError={props.errors.indexOf('user') >= 0}
          value={props.user}
          name="user"
          placeholder="Username"
          type="text"
          autoFocus
        />

        <Input
          change={props.change}
          disabled={props.loading}
          hasError={props.errors.indexOf('pass') >= 0}
          value={props.pass}
          name="pass"
          placeholder="Password"
          type="password"
        />

        <Button disabled={props.loading} label="Login" type="submit" />
      </form>
    </section>
  );
}

export default LoginPage;
