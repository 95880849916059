import { useRef, useReducer, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

import API from '../../components/api';
import {
  TARIFF_CHARGE_TYPES_OPTIONS,
  TARIFF_CHARGE_TYPES,
} from '../../components/constants';

import Button from '../../elements/button';
import ErrorMessage from '../../elements/error-message';
import Input from '../../elements/forms/input';
import Text from '../../elements/text';
import Title from '../../elements/title';
import Select from '../../elements/forms/select';
import InputTime from '../../elements/forms/input-time';
import Loading from '../../elements/loading';
import SelectLocaleTimezone from '../../elements/forms/select-locale-timezone';

import './add-edit.sass';

const MODE = { add: 'add', edit: 'edit' };

const initialState = {
  loading: false,
  mode: MODE.add,
  isoCode: '',
  name: '',
  isoLocaleCode: '',
  timeZoneId: '',
  tariffName: '',
  providerName: '',
  paymentType: '',
  chargeType: '',
  standingChargePencePerDay: '',
  compositionOffPeakStart: '00:00',
  compositionOffPeakEnd: '23:59',
  compositionOffPeakRate: '',
  compositionPeakStart: '',
  compositionPeakEnd: '',
  compositionPeakRate: '',
  feedbackMessage: '',
  fieldsWithError: [],
  isoCodeErrors: [],
  isoLocaleCodeErrors: [],
  nameErrors: [],
  timeZoneIdErrors: [],
  loadingAvailableCountries: false,
  countriesDataError: false,
  countriesAvailable: [],
};

const MESSAGES = {
  add: {
    title: 'Countries - Add',
    instruction: 'Please fill all the information to add a new country.',
    warning:
      'Please double check it before adding, as country informations impact many Voltaware services.',
    submit: 'Add Country',
  },
  edit: {
    title: 'Countries - Edit',
    instruction: 'Please fill all the information to edit the country.',
    warning:
      'Please double check it before submiting, as country informations impact many Voltaware services.',
    submit: 'Submit',
  },
};

function CountryAddEdit() {
  const location = useLocation();
  const country = location && location.state && location.state.country;

  let initialEditingState = { ...initialState };

  if (location.state && location.state.editing) {
    initialEditingState = {
      ...initialState,
      loading: false,
      mode: MODE.edit,
      isoCode: country.isoCode,
      name: country.name,
      isoLocaleCode: country.isoLocaleCode,
      timeZoneId: country.timeZoneId,
      tariffName: country.tariff.name,
      providerName: country.tariff.provider.name,
      paymentType: country.tariff.paymentType,
      chargeType: country.tariff.chargeType,
      standingChargePencePerDay: country.tariff.standingChargePencePerDay,
      compositionOffPeakStart: country.tariff.composition.offPeak.startTime,
      compositionOffPeakEnd: country.tariff.composition.offPeak.endTime,
      compositionOffPeakRate: country.tariff.composition.offPeak.rate,
      compositionPeakStart:
        country.tariff.chargeType === TARIFF_CHARGE_TYPES.dual
          ? country.tariff.composition.peak.startTime
          : '',
      compositionPeakEnd:
        country.tariff.chargeType === TARIFF_CHARGE_TYPES.dual
          ? country.tariff.composition.peak.endTime
          : '',
      compositionPeakRate:
        country.tariff.chargeType === TARIFF_CHARGE_TYPES.dual
          ? country.tariff.composition.peak.rate
          : '',
      feedbackMessage: '',
      editFeedbackMessage: '',
      fieldsWithError: [],
    };
  }

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    location.state && location.state.editing
      ? initialEditingState
      : initialState
  );

  const isMountedRef = useRef(null);

  useEffect(() => {
    isMountedRef.current = true;

    if (state.mode === MODE.add) {
      loadAvailableCountries();
    }

    if (state.mode === MODE.edit) {
      setState({
        countriesAvailable: [{ value: state.isoCode, label: state.name }],
      });
    }

    return () => (isMountedRef.current = false);
  }, []);

  const loadAvailableCountries = () => {
    setState({ loadingAvailableCountries: true });

    API.COUNTRY.AVAILABLE()
      .then(({ data }) => {
        if (!isMountedRef.current) {
          return;
        }

        if (data && data.length) {
          setState({
            countriesAvailable: data.map((d) => ({
              ...d,
              value: d.isoCode,
              label: d.name,
            })),
          });
        } else {
          setState({ countriesDataError: true });
        }

        setState({ loadingAvailableCountries: false });
      })
      .catch((error) => {
        setState({
          countriesDataError: true,
          loadingAvailableCountries: false,
        });
        console.error(error);
      });
  };

  const reset = (status, isoCountryCode) => {
    let message = '';
    const newState = initialState;
    newState.isoCountryCode = isoCountryCode;

    if (status === 'confirmed') {
      message = `Its country code is ${isoCountryCode}.`;
      newState.feedbackMessage = message;
    }

    setState(newState);
  };

  const resetLocaleTimezone = () => {
    setState({ isoLocaleCode: '', timeZoneId: '' });
  };

  const change = (e) => {
    if (!e || !e.target || !e.target.name) return;

    setState({ [e.target.name]: e.target.value });

    if (e.target.name === 'isoCode') {
      setState({
        isoLocaleCode: '',
        timeZoneId: '',
      });

      if (e.target.value) {
        const { countriesAvailable } = state;
        const selectedCountry =
          countriesAvailable && countriesAvailable.length
            ? countriesAvailable.find(
                (country) => country.isoCode === e.target.value
              )
            : {};
        setState({
          name:
            selectedCountry && selectedCountry.name ? selectedCountry.name : '',
        });
      }
    }
  };

  const changeCompositionOffPeakStart = (time) => {
    if (moment(time, 'HH:mm').isValid()) {
      setState({ compositionOffPeakStart: moment(time).format('HH:mm') });
    }
  };

  const changeCompositionOffPeakEnd = (time) => {
    if (moment(time, 'HH:mm').isValid()) {
      setState({ compositionOffPeakEnd: moment(time).format('HH:mm') });
    }
  };

  const changeCompositionPeakStart = (time) => {
    if (moment(time, 'HH:mm').isValid()) {
      setState({ compositionPeakStart: moment(time).format('HH:mm') });
    }
  };

  const changeCompositionPeakEnd = (time) => {
    if (moment(time, 'HH:mm').isValid()) {
      setState({ compositionPeakEnd: moment(time).format('HH:mm') });
    }
  };

  const validate = () => {
    const timezoneRegex =
      /^([A-Z][a-zA-Z]+)(\/)([A-Z][a-zA-Z]+(_?)+([a-zA-Z]+)?)((\/)([A-Z][a-zA-Z]+(_?)+([a-zA-Z]+)?))?$/;
    let fieldsWithError = [],
      isoCodeErrors = [],
      isoLocaleCodeErrors = [],
      timeZoneIdErrors = [];

    if (state.isoCode === '') fieldsWithError.push('isoCode');
    if (state.isoLocaleCode === '') fieldsWithError.push('isoLocaleCode');
    if (state.timeZoneId === '' || !timezoneRegex.test(state.timeZoneId))
      fieldsWithError.push('timeZoneId');
    if (state.tariffName === '') fieldsWithError.push('tariffName');
    if (state.providerName === '') fieldsWithError.push('providerName');
    if (state.paymentType === '') fieldsWithError.push('paymentType');
    if (state.chargeType === '') fieldsWithError.push('chargeType');
    if (state.standingChargePencePerDay === '')
      fieldsWithError.push('standingChargePencePerDay');
    if (state.compositionOffPeakStart === '')
      fieldsWithError.push('compositionOffPeakStart');
    if (state.compositionOffPeakEnd === '')
      fieldsWithError.push('compositionOffPeakEnd');
    const offPeakStart = moment(state.compositionOffPeakStart, 'HH:mm');
    const offPeakEnd = moment(state.compositionOffPeakEnd, 'HH:mm');
    if (!offPeakStart.isValid())
      fieldsWithError.push('compositionOffPeakStart');
    if (!offPeakEnd.isValid()) fieldsWithError.push('compositionOffPeakEnd');
    if (offPeakStart.isSame(offPeakEnd)) {
      fieldsWithError.push('compositionOffPeakStart');
      fieldsWithError.push('compositionOffPeakEnd');
    }
    if (state.compositionOffPeakRate === '')
      fieldsWithError.push('compositionOffPeakRate');

    if (state.isoCode.trim().length !== 2) fieldsWithError.push('isoCode');
    const regexIsoCode = /[a-zA-Z][a-zA-Z]/;
    if (!regexIsoCode.test(state.isoCode.trim()))
      fieldsWithError.push('isoCode');

    if (state.isoLocaleCode.trim().length !== 5)
      fieldsWithError.push('isoLocaleCode');
    const regexIsoLocaleCode = /[a-zA-Z][a-zA-Z]-[a-zA-Z][a-zA-Z]/;
    if (!regexIsoLocaleCode.test(state.isoLocaleCode.trim()))
      fieldsWithError.push('isoLocaleCode');

    if (state.chargeType === TARIFF_CHARGE_TYPES.dual) {
      if (state.compositionPeakStart === '')
        fieldsWithError.push('compositionPeakStart');
      if (state.compositionPeakEnd === '')
        fieldsWithError.push('compositionPeakEnd');
      const peakStart = moment(state.compositionPeakStart, 'HH:mm');
      const peakEnd = moment(state.compositionPeakEnd, 'HH:mm');
      if (!peakStart.isValid()) fieldsWithError.push('compositionPeakStart');
      if (!peakEnd.isValid()) fieldsWithError.push('compositionPeakEnd');
      if (peakStart.isSame(peakEnd)) {
        fieldsWithError.push('compositionPeakStart');
        fieldsWithError.push('compositionPeakEnd');
      }
      if (state.compositionPeakRate === '')
        fieldsWithError.push('compositionPeakRate');
    }

    setState({
      fieldsWithError: fieldsWithError,
      loading: false,
      isoCodeErrors: isoCodeErrors,
      isoLocaleCodeErrors: isoLocaleCodeErrors,
      timeZoneIdErrors: timeZoneIdErrors,
    });

    return !fieldsWithError.length;
  };

  const createTariff = () => {
    const tariff = {
      name: state.tariffName,
      provider: {
        name: state.providerName,
      },
      paymentType: state.paymentType,
      chargeType: state.chargeType,
      standingChargePencePerDay: state.standingChargePencePerDay,
      composition: {
        offPeak: {
          startTime: state.compositionOffPeakStart,
          endTime: state.compositionOffPeakEnd,
          rate: state.compositionOffPeakRate,
        },
        peak: null,
      },
      schedule: [
        {
          from: state.compositionOffPeakStart,
          to: state.compositionOffPeakEnd,
          key: 'off-peak',
        },
      ],
    };

    if (state.chargeType === TARIFF_CHARGE_TYPES.dual) {
      tariff.composition.peak = {
        startTime: state.compositionPeakStart,
        endTime: state.compositionPeakEnd,
        rate: state.compositionPeakRate,
      };
    }

    const offPeakStartTime = moment(state.compositionOffPeakStart, 'HH:mm');
    const offPeakEndTime = moment(state.compositionOffPeakEnd, 'HH:mm');

    if (offPeakEndTime.isBefore(offPeakStartTime)) {
      tariff.schedule = [
        {
          from: state.compositionOffPeakStart,
          to: '23:59',
          key: 'off-peak',
        },
        {
          from: '00:00',
          to: state.compositionOffPeakEnd,
          key: 'off-peak',
        },
      ];
    }

    if (state.chargeType === TARIFF_CHARGE_TYPES.dual) {
      const peakStartTime = moment(state.compositionPeakStart, 'HH:mm');
      const peakEndTime = moment(state.compositionPeakEnd, 'HH:mm');

      if (peakEndTime.isBefore(peakStartTime)) {
        tariff.schedule.push(
          {
            from: state.compositionPeakStart,
            to: '23:59',
            key: 'peak',
          },
          {
            from: '00:00',
            to: state.compositionPeakEnd,
            key: 'peak',
          }
        );
      } else {
        tariff.schedule.push({
          from: state.compositionPeakStart,
          to: state.compositionPeakEnd,
          key: 'peak',
        });
      }
    }
    return tariff;
  };

  const handleBackendErrors = (error) => {
    let fieldsWithError = [],
      isoCodeErrors = [],
      isoLocaleCodeErrors = [],
      timeZoneIdErrors = [];

    if (error.response && error.response.data) {
      if (error.response.status === 422) {
        if (error.response.data.indexOf('Iso code is not valid') >= 0) {
          fieldsWithError.push('isoCode');
          isoCodeErrors.push(error.response.data);
        } else if (error.response.data.indexOf("Couldn't find 3-letter") >= 0) {
          fieldsWithError.push('isoLocaleCode');
          isoLocaleCodeErrors.push(error.response.data);
        } else if (error.response.data.indexOf('Unknown time-zone ID') >= 0) {
          fieldsWithError.push('timeZoneId');
          timeZoneIdErrors.push(error.response.data);
        }
      } else if (error.response.data.errors) {
        error.response.data.errors.map((error, index) => {
          let message = 'Insert a valid value';
          if (error.code !== 'Pattern')
            message =
              error.defaultMessage[0].toUpperCase() +
              error.defaultMessage.slice(1);

          if (error.field === 'isoCode') {
            fieldsWithError.push('isoCode');
            isoCodeErrors.push(message);
          } else if (error.field === 'isoLocaleCode') {
            fieldsWithError.push('isoLocaleCode');
            isoLocaleCodeErrors.push(message);
          } else if (error.field === 'timeZoneId') {
            fieldsWithError.push('timeZoneId');
            timeZoneIdErrors.push(message);
          }

          return error;
        });
      }
    }

    setState({
      fieldsWithError: fieldsWithError,
      isoCodeErrors: isoCodeErrors,
      isoLocaleCodeErrors: isoLocaleCodeErrors,
      timeZoneIdErrors: timeZoneIdErrors,
    });
  };

  const send = () => {
    setState({ loading: true });

    if (state.mode === MODE.edit) {
      API.COUNTRY.EDIT(
        state.isoCode,
        state.name,
        state.isoLocaleCode,
        state.timeZoneId,
        createTariff()
      )
        .then((response) => {
          setState({
            editFeedbackMessage:
              'The country information was edited successfully.',
            loading: false,
          });
        })
        .catch((error) => {
          console.error(error);
          handleBackendErrors(error);
          setState({ loading: false });
        });
    } else {
      API.COUNTRY.ADD(
        state.isoCode,
        state.name,
        state.isoLocaleCode,
        state.timeZoneId,
        createTariff()
      )
        .then((response) => {
          reset('confirmed', response.data.countryIsoCode);
        })
        .catch((error) => {
          console.error(error);
          handleBackendErrors(error);
          setState({ loading: false });
        });
    }
  };

  const submit = (e) => {
    e.preventDefault();
    e.target.querySelector('button[type="submit"]').blur();

    setState({
      loading: true,
      feedbackMessage: '',
      editFeedbackMessage: '',
    });

    if (validate()) {
      send();
    }

    return false;
  };

  return (
    <section id="page-country-add-edit">
      <Title element="h4" text={MESSAGES[state.mode].title} />

      {state.isoCountryCode && state.feedbackMessage ? (
        <div style={{ marginBottom: 40 }}>
          <Text
            bold={true}
            highlight={true}
            text="The information about the country was successfully uploaded to the system."
          />
          <Text bold={true} highlight={true} text={state.feedbackMessage} />
        </div>
      ) : null}

      {state.editFeedbackMessage ? (
        <div style={{ marginBottom: 40 }}>
          <Text bold={true} highlight={true} text={state.editFeedbackMessage} />
        </div>
      ) : null}

      <form action="#" onSubmit={submit}>
        <Text bold={true} text={MESSAGES[state.mode].instruction} />
        <Text bold={true} warning={true} text={MESSAGES[state.mode].warning} />

        <div>
          {state.countriesDataError ? (
            <ErrorMessage message="An error occurred while loading the countries data. Please try again later or contact support." />
          ) : null}

          <div className="input-with-loading">
            <Select
              change={change}
              disabled={
                state.mode === MODE.edit ||
                state.loading ||
                state.loadingAvailableCountries ||
                state.countriesDataError
              }
              hasError={state.fieldsWithError.indexOf('isoCode') >= 0}
              options={state.countriesAvailable}
              value={state.isoCode}
              name="isoCode"
              placeholder="Country"
            />
            {state.loadingAvailableCountries ? <Loading /> : null}
          </div>

          <SelectLocaleTimezone
            isoCountryCode={state.isoCode}
            timeZoneId={state.timeZoneId}
            isoLocaleCode={state.isoLocaleCode}
            change={change}
            disabled={
              state.loading ||
              !state.isoCode ||
              !state.countriesAvailable ||
              !state.countriesAvailable.length
            }
            fieldsWithError={state.fieldsWithError}
            resetLocaleTimezone={resetLocaleTimezone}
            localePlaceHolder="Default Locale for selected country"
            timeZonePlaceHolder="Default Time Zone for selected country"
          />

          <Title element="h5" text="Tariff" />
          <Input
            change={change}
            disabled={state.loading}
            hasError={state.fieldsWithError.indexOf('tariffName') >= 0}
            value={state.tariffName}
            name="tariffName"
            placeholder="Tariff Name"
            type="text"
          />

          <Input
            change={change}
            disabled={state.loading}
            hasError={state.fieldsWithError.indexOf('providerName') >= 0}
            value={state.providerName}
            name="providerName"
            placeholder="Provider Name"
            type="text"
          />

          <Input
            change={change}
            disabled={state.loading}
            hasError={state.fieldsWithError.indexOf('paymentType') >= 0}
            value={state.paymentType}
            name="paymentType"
            placeholder="Payment Type"
            type="text"
          />

          <Button
            disabled={state.loading}
            label={MESSAGES[state.mode].submit}
            type="submit"
          />
        </div>

        <div>
          <Title element="h5" text="Tariff Charge" />
          <Select
            change={change}
            disabled={state.loading}
            hasError={state.fieldsWithError.indexOf('chargeType') >= 0}
            options={TARIFF_CHARGE_TYPES_OPTIONS}
            value={state.chargeType}
            name="chargeType"
            placeholder="Charge Type"
          />

          <Input
            change={change}
            disabled={state.loading}
            hasError={
              state.fieldsWithError.indexOf('standingChargePencePerDay') >= 0
            }
            value={state.standingChargePencePerDay}
            name="standingChargePencePerDay"
            placeholder="Standing Charge Pence Per Day"
          />

          <InputTime
            change={changeCompositionOffPeakStart}
            disabled={state.loading}
            hasError={
              state.fieldsWithError.indexOf('compositionOffPeakStart') >= 0
            }
            selected={state.compositionOffPeakStart}
            name="compositionOffPeakStart"
            value={moment(state.compositionOffPeakStart, 'HH:mm')}
            placeholder="Off-peak Start Time"
          />

          <InputTime
            change={changeCompositionOffPeakEnd}
            disabled={state.loading}
            hasError={
              state.fieldsWithError.indexOf('compositionOffPeakEnd') >= 0
            }
            selected={state.compositionOffPeakEnd}
            name="compositionOffPeakEnd"
            value={moment(state.compositionOffPeakEnd, 'HH:mm')}
            placeholder="Off-peak End Time"
          />

          <Input
            change={change}
            disabled={state.loading}
            hasError={
              state.fieldsWithError.indexOf('compositionOffPeakRate') >= 0
            }
            value={state.compositionOffPeakRate}
            name="compositionOffPeakRate"
            placeholder="Off-peak Rate"
          />

          {state.chargeType === TARIFF_CHARGE_TYPES.dual ? (
            <div>
              <InputTime
                change={changeCompositionPeakStart}
                disabled={state.loading}
                hasError={
                  state.fieldsWithError.indexOf('compositionPeakStart') >= 0
                }
                selected={state.compositionPeakStart}
                name="compositionPeakStart"
                value={
                  state.compositionPeakStart
                    ? moment(state.compositionPeakStart, 'HH:mm')
                    : null
                }
                placeholder="Peak Start Time"
              />

              <InputTime
                change={changeCompositionPeakEnd}
                disabled={state.loading}
                hasError={
                  state.fieldsWithError.indexOf('compositionPeakEnd') >= 0
                }
                selected={state.compositionPeakEnd}
                name="compositionPeakEnd"
                value={
                  state.compositionPeakEnd
                    ? moment(state.compositionPeakEnd, 'HH:mm')
                    : null
                }
                placeholder="Peak End Time"
              />

              <Input
                change={change}
                disabled={state.loading}
                hasError={
                  state.fieldsWithError.indexOf('compositionPeakRate') >= 0
                }
                value={state.compositionPeakRate}
                name="compositionPeakRate"
                placeholder="Peak Rate"
              />
            </div>
          ) : null}
        </div>
      </form>
    </section>
  );
}

export default CountryAddEdit;
