import moment from 'moment';

import { UNAVAILABLE_MESSAGE } from '../../components/constants';

import Loading from '../../elements/loading';
import Text from '../../elements/text';
import Title from '../../elements/title';

function AccountDetails(props) {
  const { client, termsAgreement } = props;
  const privacy = {
    ...client.privacy,
    updatedAtLabel: client.privacy.updatedAt
      ? moment(client.privacy.updatedAt).format('lll')
      : '',
  };

  return (
    <div className="xs-4" id="account-details">
      <Title element="h6" text="Account Details" />
      <div>
        <Text bold={true} inline={true} text="MAC Address" />
        <Text
          bold={false}
          inline={true}
          text={client.stock.macAddress || UNAVAILABLE_MESSAGE}
        />
      </div>
      <div>
        <Text bold={true} inline={true} text="SSID" />
        <Text
          bold={false}
          inline={true}
          text={client.stock.ssid || UNAVAILABLE_MESSAGE}
        />
      </div>
      <div>
        <Text bold={true} inline={true} text="Key" />
        <Text
          bold={false}
          inline={true}
          text={client.stock.key || UNAVAILABLE_MESSAGE}
        />
      </div>
      <div>
        <Text bold={true} inline={true} text="# Phases" />
        <Text
          bold={false}
          inline={true}
          text={client.stock.phases || UNAVAILABLE_MESSAGE}
        />
      </div>
      <div>
        <Text bold={true} inline={true} text="Category" />
        <Text
          bold={false}
          inline={true}
          text={client.stock.category || UNAVAILABLE_MESSAGE}
        />
      </div>
      <div>
        <Text bold={true} inline={true} text="Status" />
        <Text
          bold={false}
          inline={true}
          text={client.stock.status || UNAVAILABLE_MESSAGE}
        />
      </div>
      <div>
        <Text bold={true} inline={true} text="Clamp (A)" />
        <Text
          bold={false}
          inline={true}
          text={client.stock.clampSize || UNAVAILABLE_MESSAGE}
        />
      </div>
      <div>
        <Text bold={true} inline={true} text="Production Date" />
        <Text
          bold={false}
          inline={true}
          text={client.stock.productionDate || UNAVAILABLE_MESSAGE}
        />
      </div>
      <div>
        <Text bold={true} inline={true} text="Batch ID" />
        <Text
          bold={false}
          inline={true}
          text={client.stockUpload.batchNumber || UNAVAILABLE_MESSAGE}
        />
      </div>
      <div>
        <Text
          bold={true}
          inline={true}
          text="T&C, Privacy Policy acceptance timestamp"
        />
        <Text
          bold={false}
          inline={true}
          text={
            termsAgreement.loading ? (
              <Loading />
            ) : termsAgreement.lastAcceptance ? (
              moment(termsAgreement.lastAcceptance).format(
                'DD/MM/YYYY - HH:mm:ss'
              )
            ) : (
              '-'
            )
          }
        />
      </div>
      <div>
        <Text bold={true} inline={true} text="T&C, Privacy Policy version" />
        <Text
          bold={false}
          inline={true}
          text={
            termsAgreement.loading ? (
              <Loading />
            ) : termsAgreement.lastVersionAccepted ? (
              termsAgreement.lastVersionAccepted
            ) : (
              '-'
            )
          }
        />
      </div>
      <div>
        <Text
          inline={true}
          italic={true}
          text={`Privacy Settings ${
            privacy.updatedAt ? `(Updated on ${privacy.updatedAtLabel})` : ''
          }`}
        />
      </div>
      <div className="left-padding">
        <Text bold={true} inline={true} text="Allow Monthly Report" />
        <Text bold={false} inline={true} text={privacy.report ? 'Yes' : 'No'} />
      </div>
      <div className="left-padding">
        <Text bold={true} inline={true} text="Allow Marketing E-mails" />
        <Text
          bold={false}
          inline={true}
          text={privacy.marketing ? 'Yes' : 'No'}
        />
      </div>
      <div className="left-padding">
        <Text bold={true} inline={true} text="Allow Research" />
        <Text
          bold={false}
          inline={true}
          text={privacy.research ? 'Yes' : 'No'}
        />
      </div>
      <div className="left-padding">
        <Text bold={true} inline={true} text="Allow Models Improvements" />
        <Text bold={false} inline={true} text={privacy.models ? 'Yes' : 'No'} />
      </div>
      <div className="left-padding">
        <Text bold={true} inline={true} text="Allow Feedback Notification" />
        <Text
          bold={false}
          inline={true}
          text={privacy.feedbackNotification ? 'Yes' : 'No'}
        />
      </div>
    </div>
  );
}

export default AccountDetails;
