import './text.sass';

function Text(props) {
  let className = '';
  if (props.bold) className = 'bolder';
  if (props.italic) className = 'italic';
  if (props.highlight) className += ' highlight';
  if (props.inline) className += ' inline';
  if (props.warning) className += ' warning';
  if (props.caution) className += ' caution';
  if (props.classes) className += ' ' + props.classes;

  return <p className={className}>{props.text}</p>;
}

export default Text;
