import { useRef, useReducer, useEffect, useMemo } from 'react';

import API from '../components/api';
import { INTERNAL_USER_AUTHORITIES_OPTIONS } from '../components/constants';
import { isAdmin, isSameUserLoggedIn, testEmail } from '../components/utils';

import Button from '../elements/button';
import ErrorMessage from '../elements/error-message';
import Input from '../elements/forms/input';
import InputSelect from '../elements/forms/input-select';
import Text from '../elements/text';
import Title from '../elements/title';
import Table from '../elements/table';

import './users.sass';
import CheckCircleIcon from '../general/img/check-circle.svg';
import ProhibitedIcon from '../general/img/prohibited.svg';

function Users() {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      authorities: [],
      email: '',
      feedbackMessage: '',
      fieldsWithError: [],
      loading: false,
      loadingAdd: false,
      loadingEdit: false,
      mode: 'view',
      users: [],
      userSelected: '',
      update: 0,
    }
  );
  const isMountedRef = useRef(null);

  const columns = useMemo(
    () => [
      ...(isAdmin() ? [{ id: 'edit', title: '', align: 'center' }] : []),
      { id: 'username', title: 'Email', align: 'left' },
      { id: 'authorities', title: 'Roles', align: 'center' },
      { id: 'enabled', title: 'Enabled', align: 'center' },
      ...(isAdmin() ? [{ id: 'delete', title: '', align: 'center' }] : []),
    ],
    [isAdmin]
  );

  const data = useMemo(() => {
    return state.users.map((row) => {
      return {
        ...row,
        id: row.username,
        edit: (
          <Button
            action="edit"
            aria-label="edit"
            click={() => goToEditOrDelete(row, 'edit')}
          />
        ),
        authorities: row.authorities.join(', '),
        enabled: (
          <div
            className={`${
              isSameUserLoggedIn(row.username) ? ' disable-pointer-events' : ''
            }`}
          >
            <Button
              action="unstyled"
              click={(e) => enableDisableUser(row)}
              label={
                <img
                  alt={row.enabled ? 'enabled' : 'disabled'}
                  src={row.enabled ? CheckCircleIcon : ProhibitedIcon}
                  width="18"
                />
              }
            />
          </div>
        ),
        delete: (
          <Button
            action="delete"
            className={
              isSameUserLoggedIn(row.username) ? ' disable-pointer-events' : ''
            }
            click={() => {
              goToEditOrDelete(row, 'delete');
            }}
            aria-label="delete"
          />
        ),
      };
    });
  }, [state.users, isAdmin]);

  useEffect(() => {
    isMountedRef.current = true;

    return () => (isMountedRef.current = false);
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [state.update]);

  const fetchUsers = () => {
    setState({ loading: true });

    API.USER.LIST()
      .then((response) => {
        if (!isMountedRef.current) {
          return;
        }

        setState({
          loading: false,
          users: response.data || [],
        });
      })
      .catch((error) => {
        console.error(error);

        setState({
          loading: false,
          noResultsMessage: 'There was an error getting the users.',
          users: [],
        });
      });
  };

  const change = (e) => {
    setState({ [e.target.name]: e.target.value });
  };

  const changeEditUserEnable = (e) => {
    let { userSelected } = state;
    userSelected.enabled = e.target.checked;
    setState({ userSelected: userSelected });
  };

  const changeSelect = (arr, e) => {
    e.target.checked
      ? arr.push(e.target.value)
      : arr.splice(arr.indexOf(e.target.value), 1);
    return arr;
  };

  const changeAuthorities = (e) => {
    let authorities = state.authorities;
    authorities = changeSelect(authorities, e);
    setState({ authorities: authorities });
  };

  const changeInputSelect = (e) => {
    let userSelected = state.userSelected;
    userSelected.authorities = changeSelect(userSelected.authorities, e);
    setState({ userSelected: userSelected });
  };

  const validate = () => {
    let feedbackMessage = '',
      fieldsWithError = [];

    if (!testEmail(state.email)) fieldsWithError.push('email');
    if (!state.authorities.length) {
      fieldsWithError.push('authorities');
      feedbackMessage = (
        <ErrorMessage message="Please, select at least one authority." />
      );
    }
    setState({
      feedbackMessage: feedbackMessage,
      fieldsWithError: fieldsWithError,
      loadingAdd: false,
    });

    return !fieldsWithError.length;
  };

  const send = () => {
    setState({ loadingAdd: true });

    API.USER.ADD(state.email, state.authorities)
      .then((response) => {
        setState({
          authorities: [],
          email: '',
          feedbackMessage: (
            <Text
              bold={true}
              highlight={true}
              text={`User ${state.email} added successfully.`}
            />
          ),
          loadingAdd: false,
          update: state.update + 1,
        });
      })
      .catch((error) => {
        console.error(error);

        let message =
          error.response.data.status === 500 &&
          error.response.data.exception &&
          typeof error.response.data.exception === 'string' &&
          error.response.data.exception.indexOf('DuplicateKeyException') >= 0
            ? error.response.data.message
            : 'User could not be saved. Please try again later or contact support.';
        setState({
          feedbackMessage: <ErrorMessage message={message} />,
          loadingAdd: false,
        });
      });
  };

  const submit = (e) => {
    e.preventDefault();

    e.target.querySelector('button[type="submit"]').blur();

    if (isAdmin()) {
      setState({ feedbackMessage: '', loadingAdd: true });
      if (validate()) {
        send();
      }
    }

    return false;
  };

  const enableDisableUser = (user) => {
    if (isAdmin() && !isSameUserLoggedIn(user.username)) {
      setState({
        feedbackMessage: '',
        loadingEdit: true,
      });

      API.USER.EDIT(user.username, !user.enabled, user.authorities)
        .then((response) => {
          setState({
            feedbackMessage: (
              <Text
                bold={true}
                highlight={true}
                text={`User ${user.username} is ${
                  user.enabled ? 'disabled' : 'enabled'
                }.`}
              />
            ),
            loadingEdit: false,
            update: state.update + 1,
          });
        })
        .catch((error) => {
          console.error(error);

          setState({
            feedbackMessage: (
              <ErrorMessage message="User could not be changed. Please try again later or contact support." />
            ),
            loadingEdit: false,
          });
        });
    }
  };

  const backToUsers = () => {
    setState({
      feedbackMessage: '',
      mode: 'view',
      userSelected: '',
    });
  };

  // Edit/Delete
  const goToEditOrDelete = (user, mode) => {
    if (isAdmin() && !isSameUserLoggedIn(user.username)) {
      setState({
        feedbackMessage: '',
        mode: mode,
        userSelected: user,
      });
    }
  };

  // Edit
  const edit = () => {
    setState({ loading: true });

    API.USER.EDIT(
      state.userSelected.username,
      state.userSelected.enabled,
      state.userSelected.authorities
    )
      .then((response) => {
        setState({
          feedbackMessage: (
            <Text
              bold={true}
              highlight={true}
              text={`User ${state.userSelected.username} edited successfully.`}
            />
          ),
          mode: 'view',
          userSelected: '',
          update: state.update + 1,
        });
      })
      .catch((error) => {
        setState({
          mode: 'view',
          feedbackMessage: (
            <ErrorMessage message="Could not edit the user. Please, try again or contact support." />
          ),
          userSelected: '',
        });
      });
  };

  // Delete
  const handleDelete = () => {
    setState({ loading: true });

    API.USER.DELETE(state.userSelected.username)
      .then((response) => {
        setState({
          feedbackMessage: (
            <Text
              bold={true}
              highlight={true}
              text={`User ${state.userSelected.username} deleted  successfully.`}
            />
          ),
          mode: 'view',
          userSelected: '',
          update: state.update + 1,
        });
      })
      .catch((error) => {
        setState({
          mode: 'view',
          feedbackMessage: (
            <ErrorMessage message="Could not delete the user. Please, try again or contact support." />
          ),
          userSelected: '',
        });
      });
  };

  return (
    <section id="page-users" className="without-stock-menu">
      <Title element="h4" text="Users" />

      {state.feedbackMessage ? state.feedbackMessage : null}

      {state.mode === 'view' ? (
        <div className={!isAdmin() ? 'disable-btns' : ''}>
          {isAdmin() ? (
            <form action="#" onSubmit={submit}>
              <div>
                <Input
                  change={change}
                  disabled={state.loading || state.loadingAdd}
                  hasError={state.fieldsWithError.indexOf('email') >= 0}
                  name="email"
                  placeholder="E-mail"
                  type="text"
                  value={state.email}
                />
              </div>
              <div>
                <InputSelect
                  change={changeAuthorities}
                  checked={state.authorities}
                  disabled={state.loading || state.loadingAdd}
                  options={INTERNAL_USER_AUTHORITIES_OPTIONS}
                  name="authorities"
                  type="checkbox"
                />
              </div>
              <div className="btn-actions">
                <Button
                  disabled={state.loadingAdd || state.loadingEdit}
                  label="Add"
                  loading={state.loadingAdd}
                  type="submit"
                />
              </div>
            </form>
          ) : null}

          <Table columns={columns} data={data} isLoading={state.loading} />
        </div>
      ) : null}

      {state.mode === 'edit' ? (
        <div>
          <div>
            <Text bold={true} inline={true} text="Editing user " />
            <Text
              inline={true}
              highlight={true}
              text={state.userSelected.username}
            />
          </div>

          <div>
            <InputSelect
              change={changeEditUserEnable}
              checked={[state.userSelected.enabled]}
              disabled={state.loading}
              options={[{ label: 'Enabled', value: true }]}
              name="enabled"
              type="checkbox"
            />
          </div>

          <div>
            <Text bold={true} inline={true} text="Roles" />
            <InputSelect
              change={changeInputSelect}
              checked={state.userSelected.authorities}
              disabled={state.loading}
              options={INTERNAL_USER_AUTHORITIES_OPTIONS}
              name="authorities"
              type="checkbox"
            />
          </div>

          <div className="btn-actions">
            <Button label="Back" loading={false} click={backToUsers} />
            <Button label="Save" loading={state.loading} click={edit} />
          </div>
        </div>
      ) : null}

      {state.mode === 'delete' ? (
        <div>
          <div>
            <Text
              bold={true}
              inline={true}
              text={`Are you sure you want to delete user`}
            />
            <Text
              inline={true}
              highlight={true}
              text={` '${state.userSelected.username}' `}
            />
            <Text bold={true} inline={true} text={`?`} />
          </div>

          <div className="btn-actions">
            <Button label="Back" loading={false} click={backToUsers} />
            <Button
              label="Delete!"
              loading={state.loading}
              click={handleDelete}
            />
          </div>
        </div>
      ) : null}
    </section>
  );
}

export default Users;
