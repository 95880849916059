import { useReducer } from 'react';
import { Link } from 'react-router-dom';

import API from '../../components/api';
import Button from '../../elements/button';
import ErrorMessage from '../../elements/error-message';
import Text from '../../elements/text';
import Title from '../../elements/title';

import './purge.sass';

function AccountPurge(props) {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      feedbackMessage: '',
      loading: false,
    }
  );

  const submit = (e) => {
    e.preventDefault();

    e.target.querySelector('button[type="submit"]').blur();
    setState({ feedbackMessage: '', loading: true });

    API.SENSORS.PURGE(props.client.property.sensorId)
      .then((response) => {
        const msg = `The device was successfully purged.`;
        setState({
          feedbackMessage: <Text bold={true} highlight={true} text={msg} />,
          loading: false,
        });
      })
      .catch((error) => {
        console.error(error);
        setState({
          feedbackMessage: (
            <ErrorMessage message="An error occurred. Please try again later or contact support." />
          ),
          loading: false,
        });
      });

    return false;
  };

  return (
    <div id="admintools-account-purge">
      <Title element="h6" text="Purge Device (GDPR)" />

      {state.feedbackMessage ? (
        <div>
          {state.feedbackMessage}
          <div className="btn-actions">
            <Link to={{ pathname: `/sensors/` }}>
              <Button
                disabled={state.loading}
                label="Go back to devices search"
              />
            </Link>
          </div>
        </div>
      ) : null}

      {!state.feedbackMessage ? (
        <form action="#" onSubmit={submit}>
          <Text
            bold={true}
            text={`Are you sure that you want to purge the ${props.client.property.sensorId} device?`}
          />
          <Text
            bold={true}
            caution={true}
            text={`WARNING. Purge will completely remove all device data. This action cannot be undone.`}
          />
          <div>
            <div className="btn-actions">
              <Link
                to={{
                  pathname: `/sensor/${props.client.account.id}/admintools/`,
                  state: 'admintools',
                }}
              >
                <Button
                  action="cancel"
                  disabled={state.loading}
                  label="Cancel"
                  loading={false}
                />
              </Link>
              <Button
                disabled={state.loading}
                label="Purge device"
                type="submit"
              />
            </div>
          </div>
        </form>
      ) : null}
    </div>
  );
}

export default AccountPurge;
