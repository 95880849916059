import { useState } from 'react';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';

import './input-time.sass';

function InputTime(props) {
  const [isActive, setIsActive] = useState(false);

  const handleFocus = () => {
    setIsActive(true);
  };

  let spanClass = 'placeholder';
  if (isActive) {
    spanClass += ' active';
  } else if (props.value !== '' || props.selected) {
    spanClass += ' deactive';
  }

  return (
    <label className={props.hasError ? 'error' : ''}>
      <span className={spanClass}>{props.placeholder}</span>
      <TimePicker
        showSecond={false}
        disabled={props.disabled}
        name={props.name}
        onChange={props.change}
        onFocus={handleFocus}
        selected={props.selected}
        value={props.value}
        use12Hours
      />
    </label>
  );
}

export default InputTime;
