import './button.sass';

function Button(props) {
  let loadingClass = '';
  if (props.loading || (props.loading === undefined && props.disabled))
    loadingClass = 'loading ';

  let derivedProps = {
    className:
      loadingClass + (props.action || '') + ' ' + (props.className || ''),
    disabled: props.disabled,
    type: props.type || 'button',
    'aria-label': props['aria-label'],
    tabIndex: props.tabIndex || 0,
  };

  return (
    <button {...derivedProps} onClick={props.click}>
      {props.label}
    </button>
  );
}

export default Button;
