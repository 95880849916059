const AUTH_TOKEN_NAME = 'x-auth-token';
const IS_PRODUCTION = process.env.REACT_APP_ENV === 'production';
const LOGROCKET_KEY = IS_PRODUCTION
  ? 'pgnble/production-crm'
  : 'pgnble/test-crm';
const NO_RESULTS_MESSAGE = 'No results found.';
const UNAVAILABLE_MESSAGE = 'Unavailable';
const REMOVE_SENSOR_FROM_ORDER_MESSAGE =
  'If you remove a sensor from an order it will delete all data from it.';

// Categories
const CATEGORIES_OPTIONS = [
  {
    label: 'Sensor',
    value: 'SENSOR_WIFI',
  },
  {
    label: 'Smartbox',
    value: 'SMARTBOX',
  },
];

let CATEGORIES_VALUES = [];
CATEGORIES_OPTIONS.map((category) => CATEGORIES_VALUES.push(category.value));
// /Categories

// Clamp Sizes
const CLAMP_SIZES_OPTIONS = ['30A', '50A', '60A', '100A'];

const CLAMP_SIZES_OPTIONS_3_PHASES = ['30A', '60A', '100A'];
// /Clamp Sizes

// Currencies
const CURRENCIES_OPTIONS = [
  {
    label: '£',
    value: 'POUNDS',
  },
  {
    label: '€',
    value: 'EUROS',
  },
  {
    label: '$',
    value: 'US_DOLLARS',
  },
  {
    label: '₽',
    value: 'RUSSIAN_RUBLE',
  },
];

const GET_CURRENCY_LABEL = (value) => {
  const currency = CURRENCIES_OPTIONS.filter(
    (currency) => currency.value === value
  );
  return currency[0] ? currency[0].label : '';
};
// /Currencies

// Hardware
const HARDWARE_TYPE_OPTIONS = [
  {
    label: 'Single Phase WiFi',
    value: 'SINGLE_PHASE_WIFI',
  },
  {
    label: 'Three Phase WiFi',
    value: 'THREE_PHASE_WIFI',
  },
  {
    label: 'Three Phase GSM',
    value: 'THREE_PHASE_GSM',
  },
  {
    label: 'Smartbox',
    value: 'SMART_BOX',
  },
];

const GET_HARDWARE_TYPE_LABEL = (value) => {
  const hardwareType = HARDWARE_TYPE_OPTIONS.filter(
    (hardwareType) => hardwareType.value === value
  );
  return hardwareType[0] ? hardwareType[0].label : '';
};
// /Hardware

// Payment Type/Frequency
const PAYMENT_TYPE_OPTIONS = [
  {
    label: 'Yearly',
    value: 'YEARLY',
  },
  {
    label: 'Monthly',
    value: 'MONTHLY',
  },
  {
    label: 'One-off',
    value: 'ONE_OFF',
  },
  {
    label: 'Free',
    value: 'FREE_TRIAL',
  },
];

const GET_PAYMENT_TYPE_LABEL = (value) => {
  const payment = PAYMENT_TYPE_OPTIONS.filter(
    (payment) => payment.value === value
  );
  return payment[0] ? payment[0].label : '';
};
// /Payment Type/Frequency

// Phases
const PHASES_OPTIONS = ['1', '3'];
// /Phases

// Status
const STATUS_OPTIONS = [
  {
    label: 'All',
    value: 'ALL',
  },
  {
    label: 'In Stock',
    value: 'IN_STOCK',
  },
  {
    label: 'Allocated',
    value: 'ALLOCATED',
  },
  {
    label: 'Active',
    value: 'ACTIVE',
  },
];
// /Status

// Subscription Products
const SUBSCRIPTION_PRODUCTS_OPTIONS = [
  {
    label: 'App',
    value: 'APP',
  },
  {
    label: 'Monthly report',
    value: 'REPORT',
  },
  {
    label: 'EMD',
    value: 'EMD',
  },
];

const GET_SUBSCRIPTION_PRODUCT_LABEL = (value) => {
  const products = SUBSCRIPTION_PRODUCTS_OPTIONS.filter(
    (product) => product.value === value
  );
  return products[0] ? products[0].label : null;
};

const GET_SUBSCRIPTION_PRODUCTS_LABELS = (products) =>
  products.map((value) => GET_SUBSCRIPTION_PRODUCT_LABEL(value));
// /Subscription Products

// Tariff
const TARIFF_CHARGE_TYPES_OPTIONS = ['SINGLE', 'DUAL'];
const TARIFF_CHARGE_TYPES = { single: 'SINGLE', dual: 'DUAL' };
// /Tariff

// User Authorities
const USER_AUTHORITIES = {
  ADMIN: 'QURNSU4=',
  BUSINESS: 'QlVTSU5FU1M=',
  MACHINE_LEARNING: 'TUw=',
  RESELLER_ADMIN: 'UkVTRUxMRVJfQURNSU4=',
  RESELLER_USER: 'UkVTRUxMRVJfVVNFUg==',
  TECH: 'VEVDSA==',
  USER: 'VVNFUg==',
  VOLTA: 'Vk9MVEE=',
};

const INTERNAL_USER_AUTHORITIES_OPTIONS = [
  { label: 'Admin', value: 'ADMIN' },
  { label: 'Business', value: 'BUSINESS' },
  { label: 'Machine Learning', value: 'ML' },
  { label: 'Tech', value: 'TECH' },
];
// /User Authorities

// Privacy
const PRIVACIES = {
  REPORT: 'report',
  MARKETING: 'marketing',
  RESEARCH: 'research',
  MODELS: 'models',
};
// /Privacy

// Report Layouts
const REPORT_LAYOUTS = {
  DISAGGREGATED: 'DISG',
};
// /Report Layouts

// Sensor Status
const SENSOR_STATUS = {
  NEVER_CONNECTED: 'Never Connected',
  DISCONNECTED: 'Disconnected',
  CONNECTED: 'Connected',
};

const SENSOR_STATUS_OPTIONS = [
  {
    label: 'Connected',
    value: 'CONNECTED',
  },
  {
    label: 'Disconnected',
    value: 'DISCONNECTED',
  },
  {
    label: 'Never Connected',
    value: 'NEVER_CONNECTED',
  },
];
// /Sensor Status

// Devices
const DEVICES = {
  VOLTAWARE_SENSORS: 'VOLTAWARE_SENSORS',
  HIGH_RESOLUTION_METER: 'HIGH_RESOLUTION_METER',
  LOW_RESOLUTION_METER: 'LOW_RESOLUTION_METER',
  ALL: 'ALL',
};
const DEVICES_OPTIONS = [
  { value: DEVICES.ALL, label: 'All' },
  { value: DEVICES.VOLTAWARE_SENSORS, label: 'Voltaware Sensors' },
  { value: DEVICES.HIGH_RESOLUTION_METER, label: 'High-Res Smart Meters' },
  { value: DEVICES.LOW_RESOLUTION_METER, label: 'Low-Res Smart Meters' },
];
// /Devices

const ORDER_SENSOR = {
  ADD: 'ADD',
  REMOVE: 'REMOVE',
};

const ORDER_SENSOR_OPTIONS = [
  { label: ORDER_SENSOR.ADD, value: ORDER_SENSOR.ADD },
  { label: ORDER_SENSOR.REMOVE, value: ORDER_SENSOR.REMOVE },
];

// Disaggregation
const DISAGGREGATION_STATUS_OPTIONS = [
  {
    label: 'Success',
    value: 'SUCCESS',
  },
  {
    label: 'Error',
    value: 'ERROR',
  },
  {
    label: 'Disconnected',
    value: 'DEVICE_DISCONNECTED',
  },
  {
    label: 'Without integration',
    value: 'WITHOUT_INTEGRATION',
  },
  {
    label: 'Blacklist',
    value: 'BLACKLIST',
  },
  {
    label: 'Report Privacy Disabled',
    value: 'REPORT_PRIVACY_DISABLED',
  },
  {
    label: 'No data Report',
    value: 'NO_DATA_REPORT',
  },
];

const GET_DISAGGREGATION_STATUS_LABEL = (value) => {
  const statusType = DISAGGREGATION_STATUS_OPTIONS.filter(
    (option) => option.value === value
  );
  return statusType[0] ? statusType[0].label : '';
};

const ERRORS_OPTIONS = [
  {
    label: '30 min disable',
    value: 'WITHOUT_DISAGGREGATION',
  },
  {
    label: 'Consumption',
    value: 'CONSUMPTION',
  },
  {
    label: 'Cost',
    value: 'COST',
  },
  {
    label: 'Appliances standby',
    value: 'APPLIANCES_STANDBY',
  },
  {
    label: 'Appliances others',
    value: 'APPLIANCES_OTHERS',
  },
  {
    label: '30 min incomplete',
    value: 'THIRTY_MINUTES_INCOMPLETE',
  },
];

const GET_ERRORS_LABEL = (value) => {
  const errorsType = ERRORS_OPTIONS.filter((option) => option.value === value);
  return errorsType[0] ? errorsType[0].label : '';
};

const GRANULARITY_OPTIONS = [
  {
    label: 'Daily',
    value: 'DAILY',
  },
  {
    label: '30 min',
    value: 'THIRTY_MINUTES',
  },
  {
    label: 'Monthly',
    value: 'MONTHLY',
  },
];

const GET_GRANULARITY_LABEL = (value) => {
  const granularityType = GRANULARITY_OPTIONS.filter(
    (option) => option.value === value
  );
  return granularityType[0] ? granularityType[0].label : '';
};
// /Disaggregation

// Installations Reporting
const INSTALLATIONS_GRANULARITY_OPTIONS = [
  {
    label: 'Daily',
    value: 'DAY',
  },
  {
    label: 'Weekly',
    value: 'WEEK',
  },
  {
    label: 'Monthly',
    value: 'MONTH',
  },
];
// /Installations Reporting

export {
  AUTH_TOKEN_NAME,
  CATEGORIES_OPTIONS,
  CATEGORIES_VALUES,
  CLAMP_SIZES_OPTIONS,
  CLAMP_SIZES_OPTIONS_3_PHASES,
  CURRENCIES_OPTIONS,
  DISAGGREGATION_STATUS_OPTIONS,
  ERRORS_OPTIONS,
  GET_CURRENCY_LABEL,
  GET_DISAGGREGATION_STATUS_LABEL,
  GET_ERRORS_LABEL,
  GET_GRANULARITY_LABEL,
  GET_HARDWARE_TYPE_LABEL,
  GET_PAYMENT_TYPE_LABEL,
  GET_SUBSCRIPTION_PRODUCTS_LABELS,
  GRANULARITY_OPTIONS,
  HARDWARE_TYPE_OPTIONS,
  INTERNAL_USER_AUTHORITIES_OPTIONS,
  IS_PRODUCTION,
  LOGROCKET_KEY,
  NO_RESULTS_MESSAGE,
  PAYMENT_TYPE_OPTIONS,
  PHASES_OPTIONS,
  STATUS_OPTIONS,
  SUBSCRIPTION_PRODUCTS_OPTIONS,
  TARIFF_CHARGE_TYPES_OPTIONS,
  TARIFF_CHARGE_TYPES,
  UNAVAILABLE_MESSAGE,
  REMOVE_SENSOR_FROM_ORDER_MESSAGE,
  USER_AUTHORITIES,
  PRIVACIES,
  REPORT_LAYOUTS,
  SENSOR_STATUS,
  SENSOR_STATUS_OPTIONS,
  DEVICES,
  DEVICES_OPTIONS,
  ORDER_SENSOR,
  ORDER_SENSOR_OPTIONS,
  INSTALLATIONS_GRANULARITY_OPTIONS,
};
