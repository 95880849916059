import { useReducer, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';
import axios from 'axios';

import API from '../api';
import { AUTH_TOKEN_NAME } from '../constants';
import {
  analyticsPageview,
  analyticsSetUserId,
  logRocketSetUserAndAuthorities,
  hashUserAuthority,
} from '../utils';
import LoginPage from '../../pages/login/login';

const cookies = new Cookies();

const setToken = (token) => {
  axios.defaults.headers.common[AUTH_TOKEN_NAME] = token;
};

const getUserData = () => {
  let token = cookies.get(AUTH_TOKEN_NAME);
  if (token) {
    setToken(token);
  }
  return token;
};

function Auth(props) {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      fieldsWithError: [],
      loading: false,
      pass: '',
      redirect: getUserData() ? true : false,
      user: '',
    }
  );

  useEffect(() => analyticsPageview(), []);

  const change = (e) => {
    setState({ [e.target.name]: e.target.value });
  };

  const validate = () => {
    let fieldsWithError = [];

    if (state.user === '') fieldsWithError.push('user');
    if (state.pass === '') fieldsWithError.push('pass');
    setState({ fieldsWithError: fieldsWithError, loading: false });

    return !fieldsWithError.length;
  };

  const send = () => {
    setState({ loading: true });

    API.AUTH.LOGIN(state.user.trim(), state.pass)
      .then((response) => {
        let token = response.headers[AUTH_TOKEN_NAME];
        cookies.set(AUTH_TOKEN_NAME, token, { maxAge: 30 * 60, path: '/' });
        cookies.set(
          'authorities',
          response.data.authorities
            .map((auth) => hashUserAuthority(auth))
            .join(),
          { path: '/' }
        );
        cookies.set('username', response.data.username, { path: '/' });

        const deviceTypes =
          (response && response.data && response.data.deviceTypes) || [];
        cookies.set('deviceTypes', deviceTypes, { path: '/' });

        setToken(token);

        analyticsSetUserId();
        logRocketSetUserAndAuthorities();

        setState({ redirect: true });
      })
      .catch((error) => {
        let fieldsWithError = ['generic'];
        setState({ loading: false });

        if (
          error.response &&
          error.response.status &&
          error.response.status === 401
        )
          fieldsWithError = ['credentials'];

        setState({ fieldsWithError: fieldsWithError });
      });
  };

  const submit = (e) => {
    e.preventDefault();

    setState({ loading: true });
    if (validate()) {
      send();
    }

    return false;
  };

  const { from } = props.location.state || { from: { pathname: '/' } };
  const { redirect } = state;

  return redirect ? (
    <Redirect to={from} />
  ) : (
    <LoginPage
      change={change}
      errors={state.fieldsWithError}
      loading={state.loading}
      pass={state.pass}
      submit={submit}
      user={state.user}
    />
  );
}

export default Auth;
