/* Third Party Integration (TPI) permits funcions and constants */

// Integration permits options that a Reseller can have
const INTEGRATIONS_JSON = {
  tpiFull: {
    label: 'Full',
    completelabel: 'Full',
    value: 'FULL',
    prop: 'full',
  },
  tpiSlim: {
    label: 'Slim',
    completelabel: 'Slim',
    value: 'SLIM',
    prop: 'slim',
  },
  buildingMonitoringRoot: {
    label: 'Root Reseller',
    completelabel: 'Root Reseller (EMD)',
    value: 'ROOT',
    prop: 'buildingMonitoringRootReseller',
  },
  buildingMonitoringRegular: {
    label: 'Regular Reseller',
    completelabel: 'Regular Reseller (EMD)',
    value: 'REGULAR',
    prop: 'buildingMonitoringRegularReseller',
  },
  emailReport: {
    label: 'Periodic Report',
    completelabel: 'Periodic Report',
    value: 'REPORT_EMAIL',
    prop: 'emailReport',
  },
  behaviouralMonitoringApp: {
    label: 'Behavioural Monitoring App',
    completelabel: 'Behavioural Monitoring App',
    value: 'BEHAVIOURAL_MONITORING',
    prop: 'behaviouralMonitoringApp',
  },
  resellerAPI: {
    label: 'Services API',
    completelabel: 'Services API',
    value: 'RESELLER_API',
    prop: 'resellerAPI',
  },
  resellerAPIThirtyMinutesDisaggregation: {
    label: '30 minutes disaggregation',
    completelabel: '(30 min disag)',
    value: 'THIRTY_MINUTES_DISAGGREGATION',
    prop: 'thirtyMinutesDisaggregation',
  },
  smartMeterEvent: {
    label: 'Event',
    completelabel: 'Smart Meter (Event)',
    value: 'SMART_METER_EVENT',
    prop: 'smartMeterEvent',
  },
  smartMeterPacket: {
    label: 'High Resolution',
    completelabel: 'Smart Meter (High Resolution)',
    value: 'SMART_METER_PACKET',
    prop: 'smartMeterPacket',
  },
  smartMeterStatistical: {
    label: 'Low Resolution',
    completelabel: 'Smart Meter (Low Resolution)',
    value: 'SMART_METER_STATISTICAL',
    prop: 'smartMeterStatistical',
  },
  crm: {
    label: 'CRM Access',
    completelabel: 'CRM Access',
    value: 'CRM',
    prop: 'crm',
  },
};

const RAW_EVENTS_INTEGRATIONS_OPTIONS = [
  INTEGRATIONS_JSON.tpiFull,
  INTEGRATIONS_JSON.tpiSlim,
];

const SMART_METER_INTEGRATIONS_OPTIONS = [
  INTEGRATIONS_JSON.smartMeterEvent,
  INTEGRATIONS_JSON.smartMeterPacket,
  INTEGRATIONS_JSON.smartMeterStatistical,
];

const RESELLER_API_INTEGRATIONS_OPTIONS = [
  INTEGRATIONS_JSON.resellerAPI,
  INTEGRATIONS_JSON.resellerAPIThirtyMinutesDisaggregation,
];

const OTHER_INTEGRATIONS_OPTIONS = [
  INTEGRATIONS_JSON.crm,
  INTEGRATIONS_JSON.emailReport,
  INTEGRATIONS_JSON.behaviouralMonitoringApp,
];

const BMD_INTEGRATIONS_OPTIONS = [
  INTEGRATIONS_JSON.buildingMonitoringRoot,
  INTEGRATIONS_JSON.buildingMonitoringRegular,
];

const INTEGRATIONS_TYPES = {
  buildingMonitoring: 'BUILDING_MONITORING',
  emailReport: 'REPORT_EMAIL',
  behaviouralMonitoringApp: 'BEHAVIOURAL_MONITORING',
  rabbitServices: 'RABBIT_SERVICES',
  resellerAPI: 'RESELLER_API',
  smartMeterEvent: 'SMART_METER_EVENT',
  smartMeterPacket: 'SMART_METER_PACKET',
  smartMeterStatistical: 'SMART_METER_STATISTICAL',
};

const RABBIT_PERMS = {
  full: 'FULL',
  slim: 'SLIM',
};

const BMD_PERMS = {
  rootReseller: 'ROOT',
  regularReseller: 'REGULAR',
};

const EMAIL_REPORT_PERMS = {
  emailReport: 'REPORT_EMAIL',
};

const RESELLER_API_PERMS = {
  resellerAPI: 'RESELLER_API',
  thirtyMinutesDisaggregation: 'THIRTY_MINUTES_DISAGGREGATION',
};

// Converts Integrations data from Json array to string array
// The Select component handles a string array, our backend expects each integration in Json
const INTEGRATIONS_TO_ARRAY = (integrationsJson) => {
  let integrationsArray = integrationsJson
    .map(function (integration) {
      if (
        integration.type === INTEGRATIONS_TYPES.emailReport ||
        integration.type === INTEGRATIONS_TYPES.behaviouralMonitoringApp ||
        integration.type === INTEGRATIONS_TYPES.smartMeterEvent ||
        integration.type === INTEGRATIONS_TYPES.smartMeterPacket ||
        integration.type === INTEGRATIONS_TYPES.smartMeterStatistical
      )
        return integration.type;
      else if (integration.type === INTEGRATIONS_TYPES.resellerAPI)
        return [integration.type, ...integration.permissions];
      else if (integration.type === INTEGRATIONS_TYPES.rabbitServices)
        return integration.permissions;
      else if (integration.type === INTEGRATIONS_TYPES.buildingMonitoring)
        return integration.permissions;
      else return null;
    })
    .filter((x) => x !== null)
    .reduce(
      (arr, integration) =>
        Array.isArray(integration)
          ? [...arr, ...integration]
          : [...arr, integration],
      []
    );

  return integrationsArray.join().split(',');
};

// Integrations in a readable format, comma separated
const INTEGRATIONS_FROM_JSON_TO_TEXT = (integrationsJson) => {
  const integrationsArray = INTEGRATIONS_TO_ARRAY(integrationsJson);
  const integrationsText = INTEGRATIONS_FROM_ARRAY_TO_TEXT(integrationsArray);
  return integrationsText;
};

// Integrations in a readable format, comma separated
const INTEGRATIONS_FROM_ARRAY_TO_TEXT = (integrationsArray) => {
  let integrationsText = integrationsArray.reduce((result, obj) => {
    if (typeof obj === 'string') {
      if (obj) result.push(obj);
    } else {
      let json = {};
      if (obj.type === INTEGRATIONS_TYPES.buildingMonitoring) {
        json = INTEGRATIONS_JSON.buildingMonitoringRoot;
        if (obj.permissions[0] === BMD_PERMS.rootReseller) {
          json = INTEGRATIONS_JSON.buildingMonitoringRoot;
        } else if (obj.permissions[0] === BMD_PERMS.regularReseller) {
          json = INTEGRATIONS_JSON.buildingMonitoringRegular;
        }
      } else if (obj.type === INTEGRATIONS_TYPES.emailReport) {
        json = INTEGRATIONS_JSON.emailReport;
      } else if (obj.type === INTEGRATIONS_TYPES.behaviouralMonitoringApp) {
        json = INTEGRATIONS_JSON.behaviouralMonitoringApp;
      } else if (obj.type === INTEGRATIONS_TYPES.resellerAPI) {
        json = INTEGRATIONS_JSON.resellerAPI;
        if (
          obj.permissions &&
          obj.permissions.length &&
          obj.permissions[0] === RESELLER_API_PERMS.thirtyMinutesDisaggregation
        ) {
          json = {
            ...json,
            completelabel: `${json.completelabel} ${INTEGRATIONS_JSON.resellerAPIThirtyMinutesDisaggregation.completelabel}`,
          };
        }
      } else if (obj.type === INTEGRATIONS_TYPES.smartMeterEvent) {
        json = INTEGRATIONS_JSON.smartMeterEvent;
      } else if (obj.type === INTEGRATIONS_TYPES.smartMeterPacket) {
        json = INTEGRATIONS_JSON.smartMeterPacket;
      } else if (obj.type === INTEGRATIONS_TYPES.smartMeterStatistical) {
        json = INTEGRATIONS_JSON.smartMeterStatistical;
      } else if (obj.permissions) {
        if (obj.permissions[0] === RABBIT_PERMS.full) {
          json = INTEGRATIONS_JSON.tpiFull;
        } else if (obj.permissions[0] === RABBIT_PERMS.slim) {
          json = INTEGRATIONS_JSON.tpiSlim;
        }
      }
      if (json.completelabel) result.push(json.completelabel);
    }

    return result;
  }, []);
  return integrationsText.length
    ? integrationsText.join(', ')
    : 'No integrations';
};

const INTEGRATIONS = {
  TYPES: INTEGRATIONS_TYPES,
  RABBIT_PERMS: RABBIT_PERMS,
  BUILDING_MONITORING_PERMS: BMD_PERMS,
  EMAIL_REPORT_PERMS: EMAIL_REPORT_PERMS,
  RESELLER_API_PERMS: RESELLER_API_PERMS,
  RAW_EVENTS_INTEGRATIONS_OPTIONS,
  SMART_METER_INTEGRATIONS_OPTIONS,
  RESELLER_API_INTEGRATIONS_OPTIONS,
  OTHER_INTEGRATIONS_OPTIONS,
  BUILDING_MONITORING_OPTIONS: BMD_INTEGRATIONS_OPTIONS,
  TO_ARRAY: INTEGRATIONS_TO_ARRAY,
  TO_TEXT: INTEGRATIONS_FROM_JSON_TO_TEXT,
  TO_TEXT_FROM_ARRAY: INTEGRATIONS_FROM_ARRAY_TO_TEXT,
};

export { INTEGRATIONS };
