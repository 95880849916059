import { useRef, useReducer, useEffect } from 'react';

import API from '../../components/api';
import { UNAVAILABLE_MESSAGE } from '../../components/constants';

import Loading from '../../elements/loading';
import Text from '../../elements/text';
import Title from '../../elements/title';

function TariffPage(props) {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      loading: true,
      error: false,
      errorMessage: 'An error occurred while loading the tariff.',
      tariff: {
        name: '',
        provider: {
          name: '',
        },
        paymentType: '',
        chargeType: '',
        standingChargePencePerDay: '',
        composition: {
          offPeak: {
            startTime: '',
            endTime: '',
            rate: '',
          },
          peak: null,
        },
      },
    }
  );
  const isMountedRef = useRef(null);

  useEffect(() => {
    isMountedRef.current = true;
    if (props.isSmartMeter === true) {
      API.TARIFFS.SMART_METER_SEARCH(props.id, props.integratorId)
        .then((response) => {
          if (!isMountedRef.current) {
            return;
          }

          if (response.data)
            setState({ loading: false, tariff: response.data });
          else {
            console.error('Invalid tariff');
            setState({ loading: false });
          }
        })
        .catch((error) => {
          if (!isMountedRef.current) {
            return;
          }

          if (error.response && error.response.status === 404) {
            setState({
              errorMessage: `There's no tariff set for this device.`,
            });
          }
          setState({ error: true, loading: false });
        });
    } else {
      API.TARIFFS.SEARCH(props.id)
        .then((response) => {
          if (!isMountedRef.current) {
            return;
          }

          if (response.data)
            setState({ loading: false, tariff: response.data });
          else {
            console.error('Invalid tariff');
            setState({ loading: false });
          }
        })
        .catch((error) => {
          if (!isMountedRef.current) {
            return;
          }

          if (error.response && error.response.status === 404) {
            setState({
              errorMessage: `There's no tariff set for this device.`,
            });
          }
          setState({ error: true, loading: false });
        });
    }

    return () => (isMountedRef.current = false);
  }, []);

  const { tariff } = state;

  return (
    <div>
      <Title element="h6" text="Tariff" />
      {state.loading && state.error === false ? (
        <Loading />
      ) : !state.loading && state.error === true ? (
        <Text bold={true} inline={true} text={state.errorMessage} />
      ) : (
        <div id="tariff" className="xs-4">
          <div>
            <Text bold={true} inline={true} text="Provider" />
            <Text
              bold={false}
              inline={true}
              text={tariff.provider.name || UNAVAILABLE_MESSAGE}
            />
          </div>
          <div>
            <Text bold={true} inline={true} text="Payment Type" />
            <Text
              bold={false}
              inline={true}
              text={tariff.paymentType || UNAVAILABLE_MESSAGE}
            />
          </div>
          <div>
            <Text bold={true} inline={true} text="Tariff" />
            <Text
              bold={false}
              inline={true}
              text={tariff.name || UNAVAILABLE_MESSAGE}
            />
          </div>
          <div>
            <Text bold={true} inline={true} text="Meter Type" />
            <Text
              bold={false}
              inline={true}
              text={tariff.chargeType || UNAVAILABLE_MESSAGE}
            />
          </div>
          <div>
            <Text bold={true} inline={true} text="Standing Charge" />
            <Text
              bold={false}
              inline={true}
              text={
                typeof tariff.standingChargePencePerDay === 'number'
                  ? tariff.standingChargePencePerDay
                  : UNAVAILABLE_MESSAGE
              }
            />
          </div>
          <div>
            <Text bold={true} inline={true} text="Rate" />
            <Text
              bold={false}
              inline={true}
              text={
                tariff.composition.offPeak &&
                typeof tariff.composition.offPeak.rate === 'number'
                  ? `${tariff.composition.offPeak.rate}/kWh`
                  : UNAVAILABLE_MESSAGE
              }
            />
          </div>
          {tariff.composition.peak ? (
            <div>
              <Text bold={true} inline={true} text="Rate (peak)" />
              <Text
                bold={false}
                inline={true}
                text={
                  typeof tariff.composition.peak.rate === 'number'
                    ? `${tariff.composition.peak.rate}/kWh`
                    : UNAVAILABLE_MESSAGE
                }
              />
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
}

export default TariffPage;
