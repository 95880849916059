import { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import deepOrange from '@material-ui/core/colors/deepOrange';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';

import './general/sass/index.sass';
import './general/sass/components.sass';

import Auth from './components/auth/auth';
import AuthLogout from './components/auth/auth-logout';
import PrivateRoute from './components/auth/private-route';
import Header from './elements/header';
import Sidebar from './elements/sidebar';
import Mobilebar from './elements/mobilebar';
import routes from './routes';

// Utils
import {
  analyticsInit,
  analyticsPageview,
  logRocketInit,
} from './components/utils';

const deepOrange500 = deepOrange['500'];

function Root() {
  const [isMobilebar, setIsMobilebar] = useState(false);

  useEffect(() => {
    analyticsInit();
    logRocketInit();
  }, []);

  const handleOpenMobilebar = () => {
    setIsMobilebar(true);
  };

  const handleCloseMobilebar = () => {
    setIsMobilebar(false);
  };

  return (
    <MuiThemeProvider
      theme={createTheme({ palette: { accent1Color: deepOrange500 } })}
    >
      <BrowserRouter>
        <>
          <Header
            isMobilebar={isMobilebar}
            handleOpenMobilebar={handleOpenMobilebar}
          />
          <Sidebar />
          <Mobilebar
            isMobilebar={isMobilebar}
            handleCloseMobilebar={handleCloseMobilebar}
          />
          <Switch>
            <Route path="/login" component={Auth} />
            <Route path="/logout" component={AuthLogout} />

            {routes.map((route) => (
              <PrivateRoute
                auth={route.auth}
                key={route.path}
                path={route.path}
                exact={route.exact}
                component={route.component}
              />
            ))}
            <Redirect to="/" />
          </Switch>
        </>
      </BrowserRouter>
    </MuiThemeProvider>
  );
}

function App(props) {
  useEffect(() => {
    analyticsPageview();
  });

  return (
    <section>
      <section id="page-wrapper">{props.children}</section>
    </section>
  );
}

ReactDOM.render(<Root />, document.getElementById('root'));

export { App };
