import { useRef, useReducer, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';

import API from '../../components/api';
import { TARIFF_CHARGE_TYPES } from '../../components/constants';
import { isAdmin } from '../../components/utils';

import ErrorMessage from '../../elements/error-message';
import Input from '../../elements/forms/input';
import Text from '../../elements/text';
import Title from '../../elements/title';
import Button from '../../elements/button';
import Select from '../../elements/forms/select';
import Table from '../../elements/table';

import './browse.sass';

const filterObject = {
  hasError: false,
  search: '',
  searchBy: '',
  searchOptions: Object.assign(
    [],
    [
      {
        label: 'Country',
        value: 'NAME',
      },
      {
        label: 'ISO Code',
        value: 'ISO_CODE',
      },
      {
        label: 'ISO Locale Code',
        value: 'ISO_LOCALE_CODE',
      },
      {
        label: 'Time Zone',
        value: 'TIME_ZONE',
      },
    ]
  ),
};

function CountryBrowse() {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      filter: filterObject,
      loading: true,
      countries: [],
      hasError: false,
    }
  );

  const isMountedRef = useRef(null);

  useEffect(() => {
    isMountedRef.current = true;

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    search();
  }, []);

  const columns = useMemo(
    () => [
      ...(isAdmin() ? [{ id: 'edit', title: '', align: 'center' }] : []),
      { id: 'name', title: 'Country', align: 'left' },
      { id: 'isoCode', title: 'ISO Code', align: 'center' },
      { id: 'isoLocaleCode', title: 'ISO Locale Code', align: 'center' },
      { id: 'timeZoneId', title: 'Time Zone', align: 'left' },
    ],
    [isAdmin]
  );

  const data = useMemo(() => {
    return state.countries.map((row) => {
      const getDetails = () => {
        const tariff = row.tariff;
        const composition = row.tariff.composition;

        return (
          <div style={{ padding: '20px' }} className="tariff-info">
            <h5>Tariff Info</h5>
            <h6>{row.tariff.name}</h6>
            <div>
              <Text bold={true} inline={true} text="Provider " />
              <Text bold={false} inline={true} text={tariff.provider.name} />
            </div>
            <div>
              <Text bold={true} inline={true} text="Payment Type" />
              <Text bold={false} inline={true} text={tariff.paymentType} />
            </div>
            <div>
              <Text bold={true} inline={true} text="Charge Type" />
              <Text bold={false} inline={true} text={tariff.chargeType} />
            </div>
            <div>
              <Text
                bold={true}
                inline={true}
                text="Standing Charge Pence Per Day"
              />
              <Text
                bold={false}
                inline={true}
                text={tariff.standingChargePencePerDay}
              />
            </div>
            <div>
              <Text bold={true} inline={true} text="Composition Off-peak" />
              <Text
                bold={false}
                inline={true}
                text={`From ${composition.offPeak.startTime} to ${composition.offPeak.endTime} (rate: ${composition.offPeak.rate})`}
              />
            </div>

            {tariff.chargeType === TARIFF_CHARGE_TYPES.dual &&
            composition.peak ? (
              <div>
                <Text bold={true} inline={true} text="Composition Peak" />
                <Text
                  bold={false}
                  inline={true}
                  text={`From ${composition.peak.startTime} to ${composition.peak.endTime} (rate: ${composition.peak.rate})`}
                />
              </div>
            ) : null}
          </div>
        );
      };

      return {
        ...row,
        edit: (
          <Link
            to={{
              pathname: `/countries/edit`,
              state: { country: row, editing: true },
            }}
          >
            <Button action="edit" />
          </Link>
        ),
        details: getDetails(),
        id: row.name,
      };
    });
  }, [state.countries]);

  const changeBy = (e) => {
    setState({
      filter: { ...state.filter, searchBy: e.target.value, search: '' },
    });
  };

  const change = (e) => {
    setState({ filter: { ...state.filter, search: e.target.value } });
  };

  const validate = () => {
    const filter = state.filter;
    let hasError = false;
    if (
      (filter.search !== '' && filter.searchBy === '') ||
      (filter.search === '' && filter.searchBy !== '')
    ) {
      hasError = true;
    }
    setState({ hasError });
    return !hasError;
  };

  const submit = (e) => {
    e.preventDefault();
    e.target.querySelector('button[type="submit"]').blur();

    if (!validate()) {
      return;
    }
    search();
  };

  const search = () => {
    setState({ loading: true });

    API.COUNTRY.SEARCH(state.filter.search, state.filter.searchBy)
      .then((response) => {
        if (!isMountedRef.current) {
          return;
        }
        setState({ loading: false, countries: response.data });
      })
      .catch((error) => {
        console.error(error);
        setState({
          loading: false,
          countries: [],
        });
      });
  };

  return (
    <section id="page-country-browse">
      <Title element="h4" text="Countries" />

      <Text
        bold={true}
        text={`Search for a country by Name, ISO Code, ISO Locale Code or Time Zone.`}
      />

      {state.hasError ? (
        <ErrorMessage message="Please, select a property and fill the search field." />
      ) : null}

      <form action="#" onSubmit={submit}>
        <div>
          <Select
            change={changeBy}
            disabled={state.loading}
            hasError={state.filter.hasError}
            name="searchBy"
            options={state.filter.searchOptions}
            placeholder="Property"
            value={state.filter.searchBy}
          />
        </div>
        <div>
          <Input
            change={change}
            disabled={state.loading}
            hasError={state.filter.hasError}
            name="search"
            placeholder="Search"
            type="text"
            value={state.filter.search}
          />
        </div>
        <div>
          <Button
            action="search"
            disabled={state.loading}
            type="submit"
            aria-label="search"
          />
        </div>
      </form>
      <Table
        columns={columns}
        data={data}
        isLoading={state.loading}
        showDetails
      />
    </section>
  );
}

export default CountryBrowse;
