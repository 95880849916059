import { useRef, useReducer, useEffect, useMemo } from 'react';
import moment from 'moment';

import API from '../components/api';
import {
  GRANULARITY_OPTIONS,
  DISAGGREGATION_STATUS_OPTIONS,
  GET_DISAGGREGATION_STATUS_LABEL,
} from '../components/constants';
import { subtractDay } from '../components/utils';

import ErrorMessage from '../elements/error-message';
import Input from '../elements/forms/input';
import Button from '../elements/button';
import Select from '../elements/forms/select';
import Text from '../elements/text';
import Title from '../elements/title';
import InputDate from '../elements/forms/input-date';
import Table from '../elements/table';

import './disaggregation.sass';

const searchOptions = [
  {
    label: 'Device ID',
    value: 'DEVICE_ID',
  },
  {
    label: 'Device Status',
    value: 'STATUS',
  },
  {
    label: 'Reseller Name',
    value: 'RESELLER_ID',
  },
];

function Disaggregation() {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      hasError: false,
      loading: true,
      search: '',
      searchBy: '',
      granularity: 'DAILY',
      date: subtractDay(new Date()),
      lastId: 0,
      devices: [],
      resellers: [],
      resellerName: '',
    }
  );

  const isMountedRef = useRef(null);
  const pagesLastId = useRef([0]);
  const granularityRef = useRef(null);

  useEffect(() => {
    isMountedRef.current = true;
    window.addEventListener('resize', resize.bind(this));
    resize();
    handleSearch();

    return () => {
      window.removeEventListener('resize', resize.bind(this));
      isMountedRef.current = false;
    };
  }, []);

  const columns = useMemo(
    () => [
      {
        id: 'deviceId',
        title: 'Device ID',
        align: 'center',
      },
      {
        id: 'date',
        title: 'Date',
        align: 'center',
      },
      {
        id: 'resellerName',
        title: 'Reseller Name',
        align: 'left',
      },
      {
        id: 'status',
        title: 'Status',
        align: 'left',
      },
      {
        id: 'jsonData',
        title: 'Errors',
        align: 'left',
      },
    ],
    []
  );

  const data = useMemo(() => {
    return state.devices.map((row) => {
      const getErrors = () => {
        const errors = JSON.parse(row.jsonData);
        if (errors && typeof errors[0] === 'string') {
          return (
            <div data-tip="Expand the row to see the complete errors list">
              {errors.join(', ')}
            </div>
          );
        } else if (errors && errors.errors && errors.errors[0]['type']) {
          if (errors.length > 1) {
            return (
              <div data-tip="Expand the row to see the complete errors list">
                {errors.errors[0].type}, (...)
              </div>
            );
          } else {
            return <div>{errors.errors[0].type}</div>;
          }
        } else if (
          errors &&
          typeof errors[0] === 'object' &&
          errors[0] !== null
        ) {
          if (errors.length > 1) {
            return (
              <div data-tip="Expand the row to see the complete errors list">
                {errors[0].period} - {errors[0].errors.join(', ')}, (...)
              </div>
            );
          } else {
            return (
              <div>
                {errors[0].period} - {errors[0].errors.join(', ')}
              </div>
            );
          }
        } else {
          return <div>-</div>;
        }
      };

      const getDetails = () => {
        let display;
        if (row && row.jsonData) {
          let errors = JSON.parse(row.jsonData);

          if (errors['errors']) errors = errors['errors'];

          if (errors && typeof errors[0] === 'string') {
            display = <Text text={errors.join(', ')} />;
          } else if (errors && errors[0]['type']) {
            display = errors.map((item) => (
              <Text
                key={item.type}
                text={`${item.type} ${item.value ? ` - ${item.value}` : ''}`}
              />
            ));
          } else if (errors && errors[0]['period']) {
            display = errors.map((item) => (
              <Text
                key={item.period}
                text={`${item.period} - ${item.errors.join(', ')}`}
              />
            ));
          }
        } else {
          display = <Text text={'This device has no errors'} />;
        }

        return (
          <div className="expandable-section">
            <h6>Errors</h6>
            {display}
          </div>
        );
      };

      return {
        ...row,
        date:
          granularityRef.current === 'MONTHLY'
            ? moment(row.date).format('MMMM YYYY')
            : row.date,
        resellerName: row.resellerName ? row.resellerName : '-',
        status: GET_DISAGGREGATION_STATUS_LABEL(row.status),
        jsonData: getErrors(),
        details: getDetails(),
      };
    });
  }, [state.devices]);

  const resize = () => {
    if (!isMountedRef.current) {
      return;
    }

    let currentIsMobile = window.innerWidth <= 768;
    if (currentIsMobile !== state.isMobile) {
      setState({ isMobile: currentIsMobile });
    }
  };

  const changeBy = (e) => {
    setState({
      [e.target.name]: e.target.value,
      search: '',
      resellerName: '',
    });
  };

  const change = (e) => {
    setState({ [e.target.name]: e.target.value });
  };

  const changeDate = (value) => {
    setState({ date: value });
  };

  const handleYesterday = () => {
    setState({ date: subtractDay(new Date()) });
  };

  const changeReseller = (e) => {
    setState({ resellerName: e.target.value });
  };

  useEffect(() => {
    if (state.resellerName.length > 1 && state.resellerName.length < 30) {
      API.RESELLERS.SEARCH(state.resellerName, 'NAME').then((response) => {
        let resellers = response.data.map((item) => {
          return { id: item.id, name: item.name };
        });

        const matchIndex = resellers.findIndex(
          (reseller) => reseller.name === state.resellerName
        );
        if (matchIndex >= 0) {
          resellers = [];
        }

        setState({ resellers: resellers });
      });
    } else {
      setState({ resellers: [] });
    }
  }, [state.resellerName]);

  const selectReseller = (e) => {
    e.preventDefault();
    setState({
      resellerName: e.target.name,
      search: e.target.id,
      resellers: [],
    });
  };

  const validate = () => {
    const regex = /^[+]?\d+$/;
    let hasError = false;

    if (
      (state.search !== '' && state.searchBy === '') ||
      (state.search === '' && state.searchBy !== '') ||
      state.date === null
    ) {
      hasError = true;
    } else if (state.searchBy === 'SENSOR_ID') {
      if (!regex.test(state.search.replace(/\s+/g, ''))) {
        hasError = true;
      }
    }

    setState({ hasError });

    return !hasError;
  };

  const handlePreviousPage = () => {
    pagesLastId.current.pop();
    handleSearch(pagesLastId.current[pagesLastId.current.length - 1]);
  };

  const handleNextPage = () => {
    pagesLastId.current.push(state.lastId);
    handleSearch(pagesLastId.current[pagesLastId.current.length - 1]);
  };

  const handleSearch = (lastId) => {
    if (!validate()) {
      window.scrollTo(0, 0);
      return false;
    }
    setState({ loading: true });

    API.DISAGGREGATION.SEARCH(
      state.search,
      state.searchBy,
      state.granularity,
      moment(state.date).format('YYYY-MM-DD'),
      lastId
    )
      .then((response) => {
        if (!isMountedRef.current) {
          return;
        }
        granularityRef.current = state.granularity;

        setState({
          loading: false,
          devices: response.data.logs,
          lastId: response.data.pagesLastId,
        });
      })
      .catch((error) => {
        setState({
          loading: false,
          devices: [],
        });
      });
  };

  const submit = (e) => {
    e.preventDefault();
    e.target.querySelector('button[type="submit"]').blur();

    if (!validate()) {
      return false;
    }

    pagesLastId.current = [0];
    handleSearch();

    return false;
  };

  return (
    <section id="page-disaggregation-monitor">
      <Title element="h4" text="Disaggregation Reporting" />

      {state.hasError ? (
        <ErrorMessage message="Please, select a date, a property and fill the search field." />
      ) : null}

      <form action="#" onSubmit={submit}>
        <div>
          <Select
            change={change}
            disabled={state.loading}
            hasError={state.hasError}
            name="granularity"
            options={GRANULARITY_OPTIONS}
            placeholder="Disaggregation granularity"
            noEmptyOption={true}
            value={state.granularity}
          />
        </div>
        <div>
          {{
            MONTHLY: (
              <InputDate
                change={changeDate}
                disabled={state.loading}
                hasError={state.hasError}
                selected={state.date}
                name="date"
                todayButton="This month"
                dateFormat="LLLL yyyy"
                monthMode
                placeholder="Date"
              />
            ),
          }[state.granularity] || (
            <InputDate
              change={changeDate}
              disabled={state.loading}
              hasError={state.hasError}
              selected={state.date}
              name="date"
              maxDate={subtractDay(new Date())}
              todayButton={<div onClick={handleYesterday}>Yesterday</div>}
              placeholder="Date"
            />
          )}
        </div>
        <div>
          <Select
            change={changeBy}
            disabled={state.loading}
            hasError={state.hasError}
            name="searchBy"
            options={searchOptions}
            placeholder="Property"
            value={state.searchBy}
          />
        </div>
        <div>
          {state.searchBy !== 'STATUS' ? (
            state.searchBy === 'RESELLER_ID' ? (
              <Input
                autocomplete="off"
                change={changeReseller}
                data={state.resellers}
                disabled={state.loading}
                name="resellerName"
                placeholder="Search"
                select={selectReseller}
                type="autoComplete"
                value={state.resellerName}
              />
            ) : (
              <Input
                change={change}
                disabled={state.loading}
                hasError={state.hasError}
                name="search"
                placeholder="Search"
                type="text"
                value={state.search}
              />
            )
          ) : (
            <Select
              change={change}
              disabled={state.loading}
              hasError={state.hasError}
              name="search"
              options={DISAGGREGATION_STATUS_OPTIONS}
              placeholder="Search"
              value={state.search}
            />
          )}
        </div>

        <div>
          <Button
            action="search"
            disabled={state.loading}
            type="submit"
            aria-label="search"
          />
        </div>
      </form>

      <Table
        columns={columns}
        data={data}
        isLoading={state.loading}
        showDetails
        showPagination
        hasPrevious={pagesLastId.current.length > 1}
        hasNext={state.lastId !== 0}
        handlePreviousPage={handlePreviousPage}
        handleNextPage={handleNextPage}
      />
    </section>
  );
}

export default Disaggregation;
