import { useRef, useReducer, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';

import API from '../../components/api';
import { INTEGRATIONS } from '../../components/tpi';
import { isAdmin, isBusiness, isMachineLearning } from '../../components/utils';
import BrowseIntegrationInfo from './browse-integration-info';
import BrowseIntegrationInfoRabbit from './browse-integration-info-rabbit';
import BrowseIntegrationInfoSmartMeter from './browse-integration-info-smart-meter';

import ErrorMessage from '../../elements/error-message';
import Input from '../../elements/forms/input';
import Text from '../../elements/text';
import Title from '../../elements/title';
import Button from '../../elements/button';
import Select from '../../elements/forms/select';
import Table from '../../elements/table';

import './browse.sass';
import CheckCircleIcon from '../../general/img/check-circle.svg';
import ProhibitedIcon from '../../general/img/prohibited.svg';

const filterObject = {
  search: '',
  searchBy: '',
  searchOptions: Object.assign(
    [],
    [
      {
        label: 'Reseller ID',
        value: 'ID',
      },
      {
        label: 'Reseller Name',
        value: 'NAME',
      },
      {
        label: 'Country',
        value: 'COUNTRY',
      },
      {
        label: 'Contact Name',
        value: 'CONTACT_NAME',
      },
      {
        label: 'Immutable ID',
        value: 'IMMUTABLE_ID',
      },
    ]
  ),
  allResellers: [],
  resellerName: '',
  lastId: 0,
};

function ResellersBrowse() {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      filter: filterObject,
      allResellers: [],
      loading: true,
      resellers: [],
      hasError: false,
      validationMessage: 'Please, select a property and fill the search field.',
    }
  );
  const isMountedRef = useRef(null);
  const pagesLastResellerId = useRef([0]);

  useEffect(() => {
    isMountedRef.current = true;
    search();

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const columns = useMemo(
    () => [
      ...(!isMachineLearning()
        ? [{ id: 'edit', title: '', align: 'center', minWidth: 30 }]
        : []),
      { id: 'id', title: 'Reseller ID', align: 'center', minWidth: 70 },
      { id: 'name', title: 'Reseller Name', align: 'left', minWidth: 90 },
      { id: 'country', title: 'Country', align: 'left', minWidth: 100 },
      { id: 'email', title: 'Email', align: 'left', minWidth: 140 },
      { id: 'phone', title: 'Phone Number', align: 'left', minWidth: 105 },
      {
        id: 'contactName',
        title: 'Contact Name',
        align: 'left',
        minWidth: 100,
      },
      { id: 'notes', title: 'Notes', align: 'left', minWidth: 70 },
      { id: 'integrationTypes', title: 'CRM', align: 'center', minWidth: 40 },
      {
        id: 'integrations',
        title: 'Integration Permits',
        align: 'left',
        minWidth: 50,
      },
    ],
    [isMachineLearning]
  );

  const data = useMemo(() => {
    return state.resellers.map((row) => {
      const getDetails = () => {
        const rabbitServices = row.integrations.filter(
            (x) => x.type === INTEGRATIONS.TYPES.rabbitServices
          )[0],
          segaDashboard = row.integrations.filter(
            (x) => x.type === INTEGRATIONS.TYPES.segaDashboard
          )[0],
          buildingMonitoring = row.integrations.filter(
            (x) => x.type === INTEGRATIONS.TYPES.buildingMonitoring
          )[0],
          crmAccess = row.integrationTypes.indexOf('CRM') >= 0,
          emailAccess = row.integrationTypes.indexOf('REPORT_EMAIL') >= 0,
          resellerAPI = row.integrations.filter(
            (x) => x.type === INTEGRATIONS.TYPES.resellerAPI
          )[0],
          smartMeterEvent = row.integrations.filter(
            (x) => x.type === INTEGRATIONS.TYPES.smartMeterEvent
          )[0],
          smartMeterPacket = row.integrations.filter(
            (x) => x.type === INTEGRATIONS.TYPES.smartMeterPacket
          )[0],
          smartMeterStatistical = row.integrations.filter(
            (x) => x.type === INTEGRATIONS.TYPES.smartMeterStatistical
          )[0],
          integratorId= row.integratorId,
          doesNotHaveIntegrations =
            row.integrations.length === 0 ||
            (!segaDashboard &&
              !buildingMonitoring &&
              !crmAccess &&
              !emailAccess &&
              !smartMeterEvent &&
              !smartMeterPacket &&
              rabbitServices &&
              rabbitServices.permissions.length === 0);
        return (
          <div className="tpi-access-info">
            {!!integratorId && (
              <div className="integrator-id">
                <div>
                  <p className="bolder inline">Integrator ID</p>
                  <p className="inline">{integratorId}</p>
                </div>
              </div>
            )}
            
            <h5>Integrations Permits Info</h5>

            {rabbitServices && rabbitServices.permissions.length > 0 ? (
              <BrowseIntegrationInfoRabbit integration={rabbitServices} />
            ) : null}

            {segaDashboard ? (
              <BrowseIntegrationInfo
                integration={segaDashboard}
                title="Sega Dashboard"
              />
            ) : null}

            {resellerAPI ? (
              <BrowseIntegrationInfo
                integration={resellerAPI}
                title="Services API"
              />
            ) : null}

            {smartMeterEvent ? (
              <BrowseIntegrationInfoSmartMeter
                integration={smartMeterEvent}
                title="Smart Meter (Event)"
                integratorId={row.integratorId}
              />
            ) : null}

            {smartMeterPacket ? (
              <BrowseIntegrationInfoSmartMeter
                integration={smartMeterPacket}
                title="Smart Meter (High Resolution)"
                integratorId={row.integratorId}
              />
            ) : null}

            {smartMeterStatistical ? (
              <BrowseIntegrationInfoSmartMeter
                integration={smartMeterStatistical}
                title="Smart Meter (Low Resolution)"
                integratorId={row.integratorId}
              />
            ) : null}

            {buildingMonitoring ? (
              <div>
                <h6>
                  Building Monitoring
                  {isAdmin() || isBusiness() ? (
                    <Link
                      to={{
                        pathname: `/resellers/${row.id}/building-monitoring`,
                      }}
                    >
                      <Button action="edit" />
                    </Link>
                  ) : null}
                </h6>
              </div>
            ) : null}

            {crmAccess ? (
              <div>
                <h6>
                  CRM Access Users
                  {isAdmin() || isBusiness() ? (
                    <Link
                      to={{
                        pathname: `/resellers/${row.id}/crm-access`,
                      }}
                    >
                      <Button action="edit" />
                    </Link>
                  ) : null}
                </h6>
              </div>
            ) : null}

            {emailAccess ? (
              <div>
                <h6>Periodic Report</h6>
              </div>
            ) : null}

            {doesNotHaveIntegrations ? (
              <Text text="This reseller has no integration permits" />
            ) : null}
          </div>
        );
      };

      return {
        ...row,
        edit: (
          <Link
            to={{
              pathname: `/resellers/edit`,
              state: { resellerId: row.id },
            }}
          >
            <div className="text-center">
              <Button action="edit" />
            </div>
          </Link>
        ),
        country: row.country.name,
        notes: <div>{row.notes ? row.notes : '-'}</div>,
        integrationTypes: (
          <div className="text-center">
            <img
              alt={row.integrationTypes ? 'enabled' : 'disabled'}
              src={
                row.integrationTypes.indexOf('CRM') >= 0
                  ? CheckCircleIcon
                  : ProhibitedIcon
              }
              width="18"
            />
          </div>
        ),
        integrations: (
          <div>{INTEGRATIONS.TO_TEXT_FROM_ARRAY(row.integrations)}</div>
        ),
        details: getDetails(),
      };
    });
  }, [state.resellers]);

  const changeBy = (e) => {
    setState({
      filter: {
        ...state.filter,
        searchBy: e.target.value,
        search: '',
        resellerName: '',
      },
    });
  };

  const change = (e) => {
    setState({ filter: { ...state.filter, search: e.target.value } });
  };

  const changeReseller = (e) => {
    setState({
      filter: {
        ...state.filter,
        resellerName: e.target.value,
        search: e.target.value,
      },
    });
  };

  useEffect(() => {
    if (
      state.filter.resellerName.length > 1 &&
      state.filter.resellerName.length < 30
    ) {
      API.RESELLERS.SEARCH(state.filter.resellerName, 'NAME').then(
        (response) => {
          if (!isMountedRef.current) {
            return;
          }
          let resellers = response.data.map((item) => {
            return { id: item.id, name: item.name };
          });

          const matchIndex = resellers.findIndex(
            (reseller) => reseller.name === state.filter.resellerName
          );
          if (matchIndex >= 0) {
            resellers = [];
          }

          setState({
            allResellers: resellers,
          });
        }
      );
    } else {
      setState({ allResellers: [] });
    }
  }, [state.filter.resellerName]);

  const selectReseller = (e) => {
    e.preventDefault();
    setState({
      filter: {
        ...state.filter,
        resellerName: e.target.name,
        search: e.target.name,
      },
      allResellers: [],
    });
  };

  const validate = () => {
    const filter = state.filter;
    let hasError = false;
    let validationMessage =
      'Please, select a property and fill the search field.';

    if (
      (filter.search !== '' && filter.searchBy === '') ||
      (filter.search === '' && filter.searchBy !== '')
    ) {
      hasError = true;
    } else if (filter.searchBy === 'ID') {
      const numberRegex = /^\d+$/;
      if (!numberRegex.test(filter.search.replace(/\s+/g, ''))) {
        hasError = true;
        validationMessage = 'Please, type a number for the ID.';
      }
    }
    setState({ hasError, validationMessage });
    return !hasError;
  };

  const handlePreviousPage = () => {
    pagesLastResellerId.current.pop();
    search(pagesLastResellerId.current[pagesLastResellerId.current.length - 1]);
  };

  const handleNextPage = () => {
    pagesLastResellerId.current.push(state.lastId);
    search(pagesLastResellerId.current[pagesLastResellerId.current.length - 1]);
  };

  const submit = (e) => {
    e.preventDefault();
    e.target.querySelector('button[type="submit"]').blur();

    if (!validate()) {
      return false;
    }

    pagesLastResellerId.current = [0];
    search();

    return false;
  };

  const search = (lastId) => {
    setState({ loading: true });

    API.RESELLERS.SEARCH_PAGINATION(
      state.filter.search,
      state.filter.searchBy,
      lastId
    )
      .then((response) => {
        if (!isMountedRef.current) {
          return;
        }

        setState({
          loading: false,
          resellers: response.data.resellers,
          lastId: response.data.lastResellerIdOfThePage,
        });
      })
      .catch((error) => {
        console.error(error);
        setState({
          loading: false,
          resellers: [],
          lastId: 0,
        });
      });
  };

  return (
    <section id="page-resellers-browse">
      <Title element="h4" text="Resellers" />

      <Text
        bold={true}
        text={`Search for a reseller by ID, Name, Country or
                        Contact Name.`}
      />

      {state.hasError ? (
        <ErrorMessage message={state.validationMessage} />
      ) : null}

      <form action="#" onSubmit={submit}>
        <div>
          <Select
            change={changeBy}
            disabled={state.loading}
            hasError={state.hasError}
            name="searchBy"
            options={state.filter.searchOptions}
            placeholder="Property"
            value={state.filter.searchBy}
          />
        </div>
        <div>
          {{
            NAME: (
              <Input
                autocomplete="off"
                change={changeReseller}
                data={state.allResellers}
                disabled={state.loading}
                hasError={state.hasError}
                name="resellerName"
                placeholder="Search"
                select={selectReseller}
                type="autoComplete"
                value={state.filter.resellerName}
              />
            ),
          }[state.filter.searchBy] || (
            <Input
              change={change}
              disabled={state.loading}
              hasError={state.hasError}
              name="search"
              placeholder="Search"
              type="text"
              value={state.filter.search}
            />
          )}
        </div>
        <div>
          <Button
            action="search"
            disabled={state.loading}
            type="submit"
            aria-label="search"
          />
        </div>
      </form>
      <Table
        columns={columns}
        data={data}
        isLoading={state.loading}
        showDetails
        showPagination
        hasPrevious={pagesLastResellerId.current.length > 1}
        hasNext={state.lastId !== 0}
        handlePreviousPage={handlePreviousPage}
        handleNextPage={handleNextPage}
      />
    </section>
  );
}

export default ResellersBrowse;
