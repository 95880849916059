import { useReducer, useRef, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import API from '../../components/api';
import { USER_AUTHORITIES } from '../../components/constants';
import {
  hashUserAuthority,
  isAdmin,
  isBusiness,
  isReseller,
  isResellerAdmin,
  isSameUserLoggedIn,
  testEmail,
  unhashUserAuthority,
} from '../../components/utils';

import Button from '../../elements/button';
import ErrorMessage from '../../elements/error-message';
import Input from '../../elements/forms/input';
import Text from '../../elements/text';
import Title from '../../elements/title';
import Table from '../../elements/table';

import '../users.sass';
import CheckCircleIcon from '../../general/img/check-circle.svg';
import ProhibitedIcon from '../../general/img/prohibited.svg';

function ResellersCRMAccess() {
  const params = useParams();
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      deleteError: false,
      deleting: false,
      email: '',
      feedbackMessage: '',
      fieldsWithError: [],
      loading: false,
      loadingAdd: false,
      loadingEdit: false,
      resellerId: params.id,
      users: [],
      userToDelete: '',
      update: 0,
    }
  );
  const isMountedRef = useRef(null);
  const hasMoreThanOneAdminEnabled = useRef(false);
  const hasMoreThanOneUser = useRef(false);

  const columns = useMemo(
    () => [
      { id: 'username', title: 'Email', align: 'left' },
      { id: 'authorities', title: 'Admin', align: 'center' },
      { id: 'enabled', title: 'Enabled', align: 'center' },
      ...(isAdmin() ||
      isBusiness() ||
      (isResellerAdmin() && hasMoreThanOneUser.current)
        ? [{ id: 'delete', title: '', align: 'center' }]
        : []),
    ],
    [isAdmin, isBusiness, isResellerAdmin, hasMoreThanOneUser.current]
  );

  const data = useMemo(() => {
    return state.users.map((row) => {
      return {
        ...row,
        id: row.username,
        authorities: (
          <div
            className={`${
              isSameUserLoggedIn(row.username) ? ' disable-pointer-events' : ''
            }`}
          >
            <Button
              action="unstyled"
              aria-label="toggle admin"
              click={(e) => adminUnadminUser(row)}
              label={
                <img
                  alt={
                    isResellerAdmin(row.authorities) ? 'enabled' : 'disabled'
                  }
                  src={
                    isResellerAdmin(row.authorities)
                      ? CheckCircleIcon
                      : ProhibitedIcon
                  }
                  width="18"
                />
              }
            />
          </div>
        ),
        enabled: (
          <div
            className={`${
              isSameUserLoggedIn(row.username) ? ' disable-pointer-events' : ''
            }`}
          >
            <Button
              action="unstyled"
              aria-label="toggle user"
              click={(e) => enableDisableUser(row)}
              label={
                <img
                  alt={row.enabled ? 'enabled' : 'disabled'}
                  src={row.enabled ? CheckCircleIcon : ProhibitedIcon}
                  width="18"
                />
              }
            />
          </div>
        ),
        delete: (
          <Button
            action="delete"
            aria-label="delete"
            className={
              isSameUserLoggedIn(row.username) ? ' disable-pointer-events' : ''
            }
            click={() => {
              confirmDelete(row);
            }}
          />
        ),
      };
    });
  }, [state.users, isAdmin]);

  useEffect(() => {
    isMountedRef.current = true;

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [state.update]);

  const fetchUsers = () => {
    setState({ loading: true });

    API.RESELLERS.USERS.LIST(state.resellerId)
      .then((response) => {
        if (!isMountedRef.current) {
          return;
        }

        if (!isAdmin()) {
          hasMoreThanOneAdminEnabled.current =
            response.data &&
            response.data.filter(
              (user) => isResellerAdmin(user.authorities) && user.enabled
            ).length > 1;
          hasMoreThanOneUser.current =
            response.data && response.data.length > 1;
        }

        setState({
          loading: false,
          users: response.data || [],
        });
      })
      .catch((error) => {
        console.error(error);

        setState({
          loading: false,
          noResultsMessage: 'There was an error getting the users.',
          users: [],
        });
      });
  };

  const change = (e) => {
    setState({ [e.target.name]: e.target.value });
  };

  const validate = () => {
    let fieldsWithError = [];

    if (!testEmail(state.email)) fieldsWithError.push('email');
    setState({ fieldsWithError: fieldsWithError, loadingAdd: false });

    return !fieldsWithError.length;
  };

  const send = () => {
    setState({ loadingAdd: true });
    API.RESELLERS.USERS.ADD(state.resellerId, state.email)
      .then((response) => {
        setState({
          email: '',
          feedbackMessage: (
            <Text
              bold={true}
              highlight={true}
              text={`User ${state.email} added successfully.`}
            />
          ),
          loadingAdd: false,
          update: state.update + 1,
        });
      })
      .catch((error) => {
        console.error(error);

        let message =
          error.response.data.status === 500 &&
          error.response.data.exception &&
          typeof error.response.data.exception === 'string' &&
          error.response.data.exception.indexOf('DuplicateKeyException') >= 0
            ? error.response.data.message
            : 'Email is already being used. Please enter another email.';
        setState({
          feedbackMessage: <ErrorMessage message={message} />,
          loadingAdd: false,
        });
      });
  };

  const submit = (e) => {
    e.preventDefault();

    e.target.querySelector('button[type="submit"]').blur();

    setState({
      feedbackMessage: '',
      loadingAdd: true,
      deleteError: false,
    });
    if (validate()) {
      send();
    }

    return false;
  };

  const adminUnadminUser = (user) => {
    if (!isAdmin() && !isBusiness() && !isResellerAdmin()) return;
    if (isSameUserLoggedIn(user.username)) return;

    if (
      !isAdmin() &&
      isResellerAdmin(user.authorities) &&
      user.enabled &&
      !hasMoreThanOneAdminEnabled.current
    ) {
      setState({
        feedbackMessage: (
          <ErrorMessage message="You must have at least one admin enabled." />
        ),
      });
    } else {
      setState({
        feedbackMessage: '',
        loadingEdit: true,
      });

      API.RESELLERS.USERS.EDIT(state.resellerId, user.username, user.enabled, [
        unhashUserAuthority(
          hashUserAuthority(user.authorities[0]) ===
            USER_AUTHORITIES.RESELLER_ADMIN
            ? USER_AUTHORITIES.RESELLER_USER
            : USER_AUTHORITIES.RESELLER_ADMIN
        ),
      ])
        .then((response) => {
          setState({
            feedbackMessage: (
              <Text
                bold={true}
                highlight={true}
                text={`User ${user.username} is ${
                  isResellerAdmin(user.authorities) ? 'not' : ''
                } an admin now.`}
              />
            ),
            loadingEdit: false,
            update: state.update + 1,
          });
        })
        .catch((error) => {
          console.error(error);

          setState({
            feedbackMessage: (
              <ErrorMessage message="User could not be changed. Please try again later or contact support." />
            ),
            loadingEdit: false,
          });
        });
    }
  };

  const enableDisableUser = (user) => {
    if (!isAdmin() && !isBusiness() && !isResellerAdmin()) return;
    if (isSameUserLoggedIn(user.username)) return;

    if (
      !isAdmin() &&
      user.enabled &&
      isResellerAdmin(user.authorities) &&
      !hasMoreThanOneAdminEnabled.current
    ) {
      setState({
        feedbackMessage: (
          <ErrorMessage message="You must have at least one admin enabled." />
        ),
      });
    } else if (isAdmin() || hasMoreThanOneUser.current) {
      setState({
        feedbackMessage: '',
        loadingEdit: true,
      });

      API.RESELLERS.USERS.EDIT(
        state.resellerId,
        user.username,
        !user.enabled,
        user.authorities
      )
        .then((response) => {
          setState({
            feedbackMessage: (
              <Text
                bold={true}
                highlight={true}
                text={`User ${user.username} is ${
                  user.enabled ? 'disabled' : 'enabled'
                }.`}
              />
            ),
            loadingEdit: false,
            update: state.update + 1,
          });
        })
        .catch((error) => {
          console.error(error);

          setState({
            feedbackMessage: (
              <ErrorMessage message="User could not be changed. Please try again later or contact support." />
            ),
            loadingEdit: false,
          });
        });
    }
  };

  const backFromDelete = () => {
    setState({
      feedbackMessage: '',
      deleteError: false,
      deleting: false,
      userToDelete: '',
    });
  };

  const confirmDelete = (user) => {
    if (isSameUserLoggedIn(user.username)) return;

    isAdmin() ||
    !isResellerAdmin(user.authorities) ||
    hasMoreThanOneAdminEnabled.current
      ? setState({
          feedbackMessage: '',
          deleteError: false,
          deleting: true,
          userToDelete: user,
        })
      : setState({
          feedbackMessage: (
            <ErrorMessage message="You must have at least one admin enabled." />
          ),
        });
  };

  const handleDelete = () => {
    setState({ loading: true });

    API.RESELLERS.USERS.DELETE(state.resellerId, state.userToDelete.username)
      .then((response) => {
        setState({
          userToDelete: '',
          feedbackMessage: (
            <Text
              bold={true}
              highlight={true}
              text={`User ${state.userToDelete.username} deleted successfully.`}
            />
          ),
          deleting: false,
          deleteError: false,
          update: state.update + 1,
        });
      })
      .catch((error) => {
        setState({
          userToDelete: '',
          deleting: false,
          deleteError: true,
        });
      });
  };

  return (
    <section
      id="page-resellers-crm-access"
      className={isReseller() ? 'without-stock-menu' : ''}
    >
      <Title element="h4" text="Resellers - CRM Access" />

      {state.feedbackMessage ? state.feedbackMessage : null}

      {state.deleteError ? (
        <ErrorMessage message="Could not delete the user. Please, try again or contact support." />
      ) : null}

      {state.deleting ? (
        <div>
          <div>
            <Text
              bold={true}
              inline={true}
              text={`Are you sure you want to delete user`}
            />
            <Text
              inline={true}
              highlight={true}
              text={` '${state.userToDelete.username}' `}
            />
            <Text bold={true} inline={true} text={`?`} />
          </div>

          <div className="btn-actions">
            <Button label="Back" loading={false} click={backFromDelete} />
            <Button
              label="Delete!"
              loading={state.loading}
              click={handleDelete}
            />
          </div>
        </div>
      ) : (
        <div
          className={
            (isReseller() && !isResellerAdmin()) ||
            (!isAdmin() && !isBusiness() && !hasMoreThanOneUser.current)
              ? 'disable-btns'
              : ''
          }
        >
          {isAdmin() || isBusiness() || isResellerAdmin() ? (
            <form action="#" onSubmit={submit}>
              <div>
                <Input
                  change={change}
                  disabled={state.loading || state.loadingAdd}
                  hasError={state.fieldsWithError.indexOf('email') >= 0}
                  name="email"
                  placeholder="E-mail"
                  type="text"
                  value={state.email}
                />
              </div>
              <div className="btn-actions">
                <Button
                  disabled={state.loadingAdd || state.loadingEdit}
                  label="Add"
                  loading={state.loadingAdd}
                  type="submit"
                />
              </div>
            </form>
          ) : null}
          <Table columns={columns} data={data} isLoading={state.loading} />
        </div>
      )}
    </section>
  );
}

export default ResellersCRMAccess;
