import {useReducer, useEffect, useRef} from 'react';
import './field-with-tooltip.sass';

function FieldWithTooltip(props) {
  const [state, setState] = useReducer(
    (state, newState) => ({...state, ...newState}),
    {
      inputSelect: false,
      isTooltipActive: false,
      lastEvent: null,
      isLoaded: false,
      cursorPositionX: 0,
      cursorPositionY: 0,
      busy: false,
      height: null,
      width: null,
      textAlign: 'center',
      fontSize: '0.7rem',
    }
  );

  const isMountedRef = useRef(null);

  useEffect(() => {
    isMountedRef.current = true;

    return () => {
      isMountedRef.current = false;
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  const handleMouseEnter = async () => {
    setState({isTooltipActive: true});
    window.addEventListener('mousemove', handleMouseMove);

    if (state.isLoaded) {
      return;
    }

    setState({isLoaded: true});
    const lastEvent = await props.getLastEvent();

    if (!isMountedRef.current) {
      return;
    }

    setState({
      inputSelect: props.inputSelect,
      lastEvent: lastEvent,
      height: props.height,
      width: props.width,
      textAlign: props.textAlign,
    });
  };

  const handleMouseLeave = () => {
    window.removeEventListener('mousemove', handleMouseMove);
    setState({isTooltipActive: false});
  };

  const handleMouseMove = (e) => {
    if (state.busy) {
      return;
    }
    setState({busy: true});
    setTimeout(
      () =>
        setState({
          cursorPositionX: e.clientX,
          cursorPositionY: e.clientY,
          busy: false,
        }),
      [10]
    );
  };

  return (
    <div className={`field-with-tooltip${ state.inputSelect ? ' input-select-component' : '' }`}>
      <div
        className={props.className}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {props.children}
      </div>
      <div
        className={`tooltip ${ state.isTooltipActive && 'tooltip-show' }`}
        style={{
          top: state.cursorPositionY - 45,
          left: state.cursorPositionX - 65,
          ...(state.height ? {height: state.height} : {}),
          ...(state.width ? {width: state.width} : {}),
        }}
      >
        <div>{props.status}</div>
        {state.lastEvent && <div style={{textAlign: props.textAlign, fontSize: props.fontSize}}>{state.lastEvent}</div>}
      </div>
    </div>
  );
}

export default FieldWithTooltip;
