import { NavLink } from 'react-router-dom';

import Private from '../../components/auth/private';
import { USER_AUTHORITIES } from '../../components/constants';

function SmartMeterDetailsNav(props) {
  const { client, integratorId, property } = props;

  return (
    <nav>
      <NavLink
        exact
        to={{
          pathname: `/smart-meter/${integratorId}/${client.id}`,
          state: 'account',
        }}
      >
        Account
      </NavLink>

      <Private
        authNeeded={[
          USER_AUTHORITIES.ADMIN,
          USER_AUTHORITIES.RESELLER_ADMIN,
          USER_AUTHORITIES.RESELLER_USER,
          USER_AUTHORITIES.BUSINESS,
          USER_AUTHORITIES.MACHINE_LEARNING,
          USER_AUTHORITIES.TECH,
        ]}
      >
        <NavLink
          exact
          to={{
            pathname: `/smart-meter/${integratorId}/${client.id}/tariff`,
            state: 'tariff',
          }}
        >
          Tariff
        </NavLink>
      </Private>

      <NavLink
        exact
        to={{
          pathname: `/smart-meter/${integratorId}/${client.id}/admintools`,
          state: 'admintools',
        }}
      >
        Admin Tools
      </NavLink>

      <Private
        authNeeded={[
          USER_AUTHORITIES.ADMIN,
          USER_AUTHORITIES.RESELLER_ADMIN,
          USER_AUTHORITIES.RESELLER_USER,
          USER_AUTHORITIES.BUSINESS,
          USER_AUTHORITIES.MACHINE_LEARNING,
        ]}
      >
        <NavLink
          exact
          to={{
            pathname: `/smart-meter/${integratorId}/${client.id}/propertydetails`,
            state: 'propertydetails',
          }}
        >
          Property Details
        </NavLink>
      </Private>

      {property && property.exists ? (
        <Private
          authNeeded={[
            USER_AUTHORITIES.ADMIN,
            USER_AUTHORITIES.RESELLER_ADMIN,
            USER_AUTHORITIES.RESELLER_USER,
            USER_AUTHORITIES.BUSINESS,
            USER_AUTHORITIES.MACHINE_LEARNING,
          ]}
        >
          <NavLink
            exact
            to={{
              pathname: `/smart-meter/${integratorId}/${client.id}/appliances`,
              state: 'appliances',
            }}
          >
            Appliances
          </NavLink>
        </Private>
      ) : null}
    </nav>
  );
}

export default SmartMeterDetailsNav;
