import { useReducer, useEffect, useRef } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

import API from '../../components/api';
import Select from './select';
import Loading from '../loading';
import ErrorMessage from '../error-message';
import './select-locale-timezone.sass';

function SelectLocaleTimezone(props) {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      dataError: false,
      loadingData: false,
      countryLocales: [],
      countryTimeZones: [],
    }
  );
  const isFirstRender = useRef(true);

  useEffect(() => {
    isFirstRender.current = true;
  }, []);

  useEffect(() => {
    if (isFirstRender.current === false) {
      props.resetLocaleTimezone();
    }

    if (props.isoCountryCode) {
      loadCountryData(props.isoCountryCode);
    }
  }, [props.isoCountryCode]);

  const loadCountryData = (isoCountryCode) => {
    setState({ loadingData: true, dataError: false });

    Promise.all([
      API.LOCALES.SEARCH(isoCountryCode),
      API.TIME_ZONES.SEARCH(isoCountryCode),
    ])
      .then((values) => {
        setState({
          loadingData: false,
          countryLocales:
            values[0].data && values[0].data.length
              ? values[0].data.map((d) => ({
                  ...d,
                  label: d.code,
                  value: d.code,
                }))
              : [],
          countryTimeZones:
            values[1].data && values[1].data.length
              ? values[1].data.map((d) => ({
                  ...d,
                  label: getTimeZoneWithOffset(d.id),
                  value: d.id,
                }))
              : [],
        });
      })
      .catch((error) => {
        setState({ dataError: true, loadingData: false });
        console.error(
          'An error occurred while loading the locale and/or timezone data.',
          error
        );
      });
  };

  const getTimeZoneWithOffset = (timeZoneId) => {
    if (!timeZoneId) {
      return '';
    }
    return `${timeZoneId} (${moment(new Date(), 'ZZ')
      .tz(timeZoneId)
      .format('Z')})`;
  };

  return (
    <div className="select-locale-timezone">
      <div className="input-with-loading">
        <Select
          change={props.change}
          disabled={props.loading || !props.isoCountryCode || state.loadingData}
          hasError={props.fieldsWithError.indexOf('isoLocaleCode') >= 0}
          options={props.isoCountryCode ? state.countryLocales : []}
          value={props.isoLocaleCode}
          name="isoLocaleCode"
          placeholder={props.localePlaceHolder || 'Locale for selected country'}
        />
        {state.loadingData ? <Loading /> : null}
      </div>

      <div className="input-with-loading">
        <Select
          change={props.change}
          disabled={props.loading || !props.isoCountryCode || state.loadingData}
          hasError={props.fieldsWithError.indexOf('timeZoneId') >= 0}
          options={props.isoCountryCode ? state.countryTimeZones : []}
          value={props.timeZoneId}
          name="timeZoneId"
          placeholder={
            props.timeZonePlaceHolder || 'Time Zone for selected country'
          }
        />
        {state.loadingData ? <Loading /> : null}
      </div>

      {state.dataError ? (
        <ErrorMessage message="An error occurred while loading the locale and/or timezone data. Please try again later or contact support." />
      ) : null}
    </div>
  );
}

SelectLocaleTimezone.propTypes = {
  isoCountryCode: PropTypes.string.isRequired,
  timeZoneId: PropTypes.string.isRequired,
  isoLocaleCode: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
  resetLocaleTimezone: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  fieldsWithError: PropTypes.arrayOf(PropTypes.string),
  localePlaceHolder: PropTypes.string,
  timeZonePlaceHolder: PropTypes.string,
};

export default SelectLocaleTimezone;
