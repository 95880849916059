import { useReducer } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import DatePicker from 'react-datepicker';

import API from '../../components/api';
import Button from '../../elements/button';
import ErrorMessage from '../../elements/error-message';
import Text from '../../elements/text';
import Title from '../../elements/title';

import './delete-sensor-events.sass';

function DeleteSensorEvents(props) {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      fromDate: moment(new Date(Date.now())).subtract(7, 'days').toDate(),
      toDate: new Date(Date.now()),
      fieldsWithError: [],
      feedbackMessage: '',
      loading: false,
    }
  );

  const changeFromDate = (date) => {
    setState({ fromDate: date });

    if (moment(date).isAfter(moment(state.toDate), 'days')) {
      return setState({ toDate: date });
    }
  };

  const changeToDate = (date) => {
    setState({ toDate: date });

    if (moment(date).isBefore(moment(state.fromDate), 'days')) {
      return setState({ fromDate: date });
    }
  };

  const validate = () => {
    let fieldsWithError = [];

    if (state.fromDate === '') fieldsWithError.push('fromDate');
    else {
      if (!moment(state.fromDate).isBefore(moment()))
        fieldsWithError.push('fromDate');
    }
    if (state.toDate === '') fieldsWithError.push('toDate');
    else {
      if (!moment(state.toDate).isBefore(moment()))
        fieldsWithError.push('toDate');
    }
    if (
      state.fromDate !== '' &&
      state.toDate !== '' &&
      !moment(state.fromDate).isSameOrBefore(state.toDate)
    ) {
      fieldsWithError.push('fromDate');
      fieldsWithError.push('toDate');
    }
    setState({ loading: false, fieldsWithError: fieldsWithError });

    return !fieldsWithError.length;
  };

  const submit = (e) => {
    e.preventDefault();

    e.target.querySelector('button[type="submit"]').blur();
    setState({ feedbackMessage: '', loading: true });

    if (!validate()) {
      return;
    }

    API.SENSORS.DELETE_SENSOR_EVENTS(
      props.client.property.sensorId,
      moment(state.fromDate).format('YYYY-MM-DD'),
      moment(state.toDate).format('YYYY-MM-DD'),
    )
      .then((response) => {
        const msg = `The events from the selected period have been deleted successfully.`;
        setState({
          fieldsWithError: [],
          feedbackMessage: <Text bold={true} highlight={true} text={msg} />,
          loading: false,
        });
      })
      .catch((error) => {
        console.error(error);
        setState({
          fieldsWithError: [],
          feedbackMessage: (
            <ErrorMessage message="An error occurred. Please try again later or contact support." />
          ),
          loading: false,
        });
      });

    return false;
  };

  return (
    <div id="admintools-delete-sensor-events">
      <Title element="h6" text="Delete Sensor Events" />

      {state.feedbackMessage ? (
        <div>
          {state.feedbackMessage}
          <div className="btn-actions">
            <Link to={{ pathname: `/sensors/` }}>
              <Button
                disabled={state.loading}
                label="Go back to devices search"
              />
            </Link>
          </div>
        </div>
      ) : null}

      {!state.feedbackMessage ? (
        <form action="#" onSubmit={submit}>
          <Text
            bold={true}
            text={`Are you sure that you want to delete the events from the device ${props.client.property.sensorId}?`}
          />
          <Text
            bold={true}
            caution={true}
            text={`WARNING. All sensor events from the date range will be permanently removed, data can't be recovered.`}
          />
          <div>
            <label
              className={
                state.fieldsWithError.indexOf('fromDate') >= 0
                  ? 'has-error'
                  : ''
              }
            >
              <span className="placeholder deactive">Start date</span>
              <DatePicker
                disabled={state.loading}
                onChange={changeFromDate}
                selected={state.fromDate}
                maxDate={new Date()}
                dateFormat="dd/MM/yyyy"
              />
            </label>
          </div>
          <div>
            <label
              className={
                state.fieldsWithError.indexOf('toDate') >= 0 ? 'has-error' : ''
              }
            >
              <span className="placeholder deactive">End date</span>
              <DatePicker
                disabled={state.loading}
                onChange={changeToDate}
                selected={state.toDate}
                maxDate={new Date()}
                dateFormat="dd/MM/yyyy"
              />
            </label>
          </div>
          <div>
            <div className="btn-actions">
              <Link
                to={{
                  pathname: `/sensor/${props.client.account.id}/admintools/`,
                  state: 'admintools',
                }}
              >
                <Button
                  action="cancel"
                  disabled={state.loading}
                  label="Cancel"
                  loading={false}
                />
              </Link>
              <Button
                disabled={state.loading}
                label="Delete"
                type="submit"
              />
            </div>
          </div>
        </form>
      ) : null}
    </div>
  );
}

export default DeleteSensorEvents;
