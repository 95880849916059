import { useState } from 'react';
import PropTypes from 'prop-types';
import MaterialTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Collapse from '@material-ui/core/Collapse';
import IoIosArrowDown from '@material-ui/icons/KeyboardArrowDown';
import IoIosArrowUp from '@material-ui/icons/KeyboardArrowUp';
import { CSSTransition } from 'react-transition-group';

import ErrorMessage from '../elements/error-message';
import Loading from '../elements/loading';
import './table.sass';

function Row({ row, columns, showDetails }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow className={showDetails ? 'row-detail-open' : ''}>
        {showDetails && (
          <TableCell className="detail-open">
            <div
              aria-label="expand row"
              data-testid="expand-row"
              onClick={() => setOpen(!open)}
            >
              {open ? <IoIosArrowUp /> : <IoIosArrowDown />}
            </div>
          </TableCell>
        )}
        {columns.map((column) => {
          return (
            <TableCell key={column.id} align={column.align}>
              {row[column.id]}
            </TableCell>
          );
        })}
      </TableRow>
      {showDetails && (
        <TableRow>
          <TableCell className="detail-cell" colSpan={columns.length + 1}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              {row.details}
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}

Row.propTypes = {
  row: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  showDetails: PropTypes.bool.isRequired,
};

function Table({
  columns,
  data,
  isLoading,
  showDetails = false,
  showPagination = false,
  hasPrevious = false,
  hasNext = false,
  handlePreviousPage = null,
  handleNextPage = null,
}) {
  return (
    <div>
      <TableContainer>
        <MaterialTable size="small" aria-label="table">
          <TableHead>
            <TableRow>
              {showDetails && <TableCell key="details" align="center" />}
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align="center"
                  style={{
                    minWidth: column.minWidth ? `${column.minWidth}px` : 'auto',
                  }}
                >
                  {column.title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <Row
                key={row.id}
                row={row}
                columns={columns}
                showDetails={showDetails}
              />
            ))}
          </TableBody>
        </MaterialTable>
        <CSSTransition
          in={isLoading}
          timeout={500}
          classNames="panel-transition"
          mountOnEnter
          unmountOnExit
        >
          <div className="panel">
            <Loading width={'40px'} />
          </div>
        </CSSTransition>
        {data.length <= 0 && (
          <div className="no-data-message">
            {!isLoading && <ErrorMessage message="No results found" />}
          </div>
        )}
      </TableContainer>
      {showPagination && (
        <div className="pagination">
          <button
            disabled={!hasPrevious || isLoading}
            onClick={handlePreviousPage}
          >
            Previous
          </button>
          <button disabled={!hasNext || isLoading} onClick={handleNextPage}>
            Next
          </button>
        </div>
      )}
    </div>
  );
}

Table.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  showDetails: PropTypes.bool,
  showPagination: PropTypes.bool,
  hasPrevious: PropTypes.bool,
  hasNext: PropTypes.bool,
  handlePreviousPage: PropTypes.func,
  handleNextPage: PropTypes.func,
};

export default Table;
