import { useReducer, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';

import API from '../../components/api';
import { COUNTRIES } from '../../components/country';

import Button from '../../elements/button';
import Input from '../../elements/forms/input';
import Text from '../../elements/text';
import Textarea from '../../elements/forms/textarea';
import Title from '../../elements/title';
import Select from '../../elements/forms/select';
import Loading from '../../elements/loading';
import ErrorMessage from '../../elements/error-message';

import './add.sass';

const initialState = {
  loading: false,
  id: '',
  name: '',
  country: '',
  email: '',
  notes: '',
  phone: '',
  contact: '',
  feedbackMessage: '',
  fieldsWithError: [],
  loadingCountries: true,
  countriesOptions: [],
  countriesValues: [],
  loadCountriesError: false,
};

function ResellersAdd() {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    initialState
  );
  const isMountedRef = useRef(null);

  useEffect(() => {
    isMountedRef.current = true;
    loadCountries();

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const loadCountries = () => {
    API.COUNTRY.SUMMARY()
      .then((response) => {
        if (!isMountedRef.current) {
          return;
        }

        if (response.status === 200) {
          let options = COUNTRIES.GET_OPTIONS(response.data),
            values = COUNTRIES.GET_VALUES(response.data);

          initialState.countriesOptions = options;
          initialState.countriesValues = values;
          setState({
            countriesOptions: options,
            countriesValues: values,
            loadingCountries: false,
          });
        } else {
          setState({ loadingCountries: false, loadCountriesError: true });
          console.error('Error while loading countries.');
        }
      })
      .catch((response) => {
        setState({ loadingCountries: false, loadCountriesError: true });
        console.error('Error while loading countries.');
      });
  };

  const reset = (status, id) => {
    let message = '',
      state = initialState;
    state.id = id;
    state.loadingCountries = false;

    if (status === 'confirmed') {
      message = `His ID number is ${id}.`;
      state.feedbackMessage = message;
    }

    setState(state);
  };

  const change = (e) => {
    setState({ [e.target.name]: e.target.value });
  };

  const validate = () => {
    let fieldsWithError = [];

    if (state.name === '') fieldsWithError.push('name');
    if (
      state.country === '' ||
      state.countriesValues.indexOf(state.country) < 0
    )
      fieldsWithError.push('country');
    if (state.email === '') fieldsWithError.push('email');
    if (state.phone === '') fieldsWithError.push('phone');
    if (state.contact === '') fieldsWithError.push('contact');
    setState({ fieldsWithError: fieldsWithError, loading: false });

    return !fieldsWithError.length;
  };

  const send = () => {
    setState({ loading: true });

    API.RESELLERS.ADD(
      state.name,
      state.country,
      state.email,
      state.phone,
      state.contact,
      state.notes
    )
      .then((response) => {
        reset('confirmed', response.data.resellerId);
      })
      .catch((error) => {
        console.error(error);
        setState({ loading: false });
      });
  };

  const submit = (e) => {
    e.preventDefault();

    e.target.querySelector('button[type="submit"]').blur();

    setState({ loading: true, feedbackMessage: '' });
    if (validate()) {
      send();
    }

    return false;
  };

  return (
    <section id="page-resellers-add">
      <Title element="h4" text="Resellers - Add" />

      {state.loadCountriesError ? (
        <ErrorMessage message="Could not load countries. Please, try again or contact support." />
      ) : null}

      {state.id && state.feedbackMessage ? (
        <div style={{ marginBottom: 40 }}>
          <Text
            bold={true}
            highlight={true}
            text="The information about the reseller was successfully uploaded to the system."
          />
          <Text bold={true} highlight={true} text={state.feedbackMessage} />
          <Text
            bold={true}
            classes="add-integrations-text"
            text="Do you want to add integrations now?"
          />
          <Link
            to={{
              pathname: `/resellers/edit`,
              state: { resellerId: state.id, mode: 'add' },
            }}
          >
            <Button
              className="add-integrations-button"
              disabled={state.loading}
              label="Add integrations"
              loading={false}
            />
          </Link>
        </div>
      ) : null}

      {state.loadingCountries ? (
        <Loading />
      ) : (
        <form action="#" onSubmit={submit}>
          <Text
            bold={true}
            text="Please fill all the information to add a new reseller."
          />
          <div>
            <Input
              change={change}
              disabled={state.loading}
              hasError={state.fieldsWithError.indexOf('name') >= 0}
              value={state.name}
              name="name"
              placeholder="Reseller name"
              type="text"
            />

            <Select
              change={change}
              disabled={state.loading}
              hasError={state.fieldsWithError.indexOf('country') >= 0}
              options={state.countriesOptions}
              value={state.country}
              name="country"
              placeholder="Country"
            />

            <Input
              change={change}
              disabled={state.loading}
              hasError={state.fieldsWithError.indexOf('email') >= 0}
              value={state.email}
              name="email"
              placeholder="Email"
              type="email"
            />
            <Input
              change={change}
              disabled={state.loading}
              hasError={state.fieldsWithError.indexOf('phone') >= 0}
              value={state.phone}
              name="phone"
              placeholder="Phone number"
              type="number"
            />

            <Input
              change={change}
              disabled={state.loading}
              hasError={state.fieldsWithError.indexOf('contact') >= 0}
              value={state.contact}
              name="contact"
              placeholder="Contact name"
              type="text"
            />

            <Textarea
              change={change}
              disabled={state.loading}
              name="notes"
              placeholder="Notes"
              value={state.notes}
            />
          </div>

          <Button disabled={state.loading} label="Add reseller" type="submit" />
        </form>
      )}
    </section>
  );
}

export default ResellersAdd;
