import { useRef, useReducer, useEffect } from 'react';
import { Link } from 'react-router-dom';

import API from '../../components/api';
import { isAdmin, isResellerAdmin } from '../../components/utils';

import Button from '../../elements/button';
import ErrorMessage from '../../elements/error-message';
import Input from '../../elements/forms/input';
import InputSelect from '../../elements/forms/input-select';
import Loading from '../../elements/loading';
import Text from '../../elements/text';
import Title from '../../elements/title';

import './appliances-page.sass';

const appliancesLabels = {
  AC: 'A/C',
  DISHWASHER: 'Dishwasher',
  ELECTRIC_BOILER: 'Electric Boiler',
  ELECTRIC_HEATING: 'Electric Heating',
  GAS_HEATING: 'Electric Heating',
  ELECTRIC_OVEN: 'Electric Oven',
  ELECTRIC_SHOWER: 'Electric Shower',
  ELECTRIC_STOVE: 'Electric Stove',
  ELECTRIC_VEHICLE: 'Electric Vehicle',
  FRIDGE: 'Fridge/Freezer',
  KETTLE: 'Kettle',
  TUMBLE_DRYER: 'Tumble Dryer',
  WASHING_MACHINE: 'Washing Machine',
  HUMIDIFIER: 'Humidifier',
  DEHUMIDIFIER: 'Dehumidifier',
  JACUZZI: 'Jacuzzi',
  ELECTRIC_LAWN_MOWER: 'Electric Lawn Mower',
  POOL_PUMP: 'Pool Pump',
  OTHER: 'Others',
};

const applianceTypeLabels = {
  AC: {
    CENTRALISED: 'Centralised',
    MINI_SPLIT: 'Mini-Split',
    WINDOW: 'Window',
    OTHER: 'Other',
    I_DONT_KNOW: "I Don't Know",
  },
  ELECTRIC_OVEN: {
    LABEL: 'Oven',
    MICROWAVE: 'Microwave',
    MINI: 'Mini',
    REGULAR: 'Regular',
  },
  ELECTRIC_STOVE: {
    LABEL: 'Stove',
    ELECTRIC: 'Electric',
    INDUCTION: 'Induction',
  },
  ELECTRIC_VEHICLE: {
    BIKE: 'Bike',
    MOTORBIKE: 'Motorbike',
    CAR: 'Car',
    SCOOTER: 'Scooter',
  },
  FRIDGE: {
    LABEL: 'Fridge',
    FRIDGE: 'Fridge',
    FREEZER: 'Freezer',
    FRIDGE_AND_FREEZER: 'Fridge & Freezer',
    SMALL_ELECTRIC_WINE_CELLAR: 'Small Electric Wine Cellar',
    LARGE_ELECTRIC_WINE_CELLAR: 'Large Electric Wine Cellar',
  },
  TUMBLE_DRYER: {
    LABEL: 'Tumble Dryer',
    CONDENSER: 'Condenser',
    HEAT_PUMP: 'Heat Pump',
    VENTED: 'Vented',
    I_DONT_KNOW: "I Don't Know",
  },
  ELECTRIC_HEATING: {
    LABEL: 'Electric Heating',
    HEAT_PUMP: 'Heat Pump',
    HEAT_STORAGE: 'Heat Storage',
    RADIATOR: 'Radiator',
    UNDERFLOOR: 'Underfloor',
    OTHER: 'Other',
  },
};

const appliancesErrorMessages = {
  AC: `Please fill in ${appliancesLabels.AC} details.`,
  DISHWASHER: `Please fill in ${appliancesLabels.DISHWASHER} quantity.`,
  ELECTRIC_BOILER: `Please fill in ${appliancesLabels.ELECTRIC_BOILER} details.`,
  ELECTRIC_HEATING: `Please fill in ${appliancesLabels.ELECTRIC_HEATING} details.`,
  ELECTRIC_OVEN: `Please fill in ${appliancesLabels.ELECTRIC_OVEN} quantity for at least one type.`,
  ELECTRIC_SHOWER: `Please fill in ${appliancesLabels.ELECTRIC_SHOWER} quantity.`,
  ELECTRIC_STOVE: `Please fill in ${appliancesLabels.ELECTRIC_STOVE} quantity for at least one type.`,
  ELECTRIC_VEHICLE: `Please fill in ${appliancesLabels.ELECTRIC_VEHICLE} quantity and respective wattage for at least one type.`,
  FRIDGE: `Please fill in ${appliancesLabels.FRIDGE} quantity for at least one type.`,
  KETTLE: `Please fill in ${appliancesLabels.KETTLE} quantity.`,
  TUMBLE_DRYER: `Please fill in ${appliancesLabels.TUMBLE_DRYER} details.`,
  WASHING_MACHINE: `Please fill in ${appliancesLabels.WASHING_MACHINE} quantity.`,
  OTHER: `Please fill in ${appliancesLabels.OTHER} name.`,
};

function AppliancesPage(props) {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      initialData: {},
      appliancesDetail: {},
      activeAppliances: [],
      electricHeatingType: null,
      version: '1.0',
      loading: false,
      loadingData: true,
      fieldsWithError: [],
    }
  );
  const isMountedRef = useRef(null);
  const userIsAdminOrResellerAdmin = isAdmin() || isResellerAdmin();

  const defaultApplianceValue = (appliance, existingData) => {
    let appl =  { key: appliance, amount: '0' };
    const existing = existingData?.[0];

    switch(appliance) {
      case "AC": {
        const centralised = existingData && existingData?.find((appl) => appl.type === "CENTRALISED");
        const miniSplit = existingData && existingData?.find((appl) => appl.type === "MINI_SPLIT");
        const window = existingData && existingData?.find((appl) => appl.type === "WINDOW");
        const other = existingData && existingData?.find((appl) => appl.type === "OTHER");
        const iDontKnow = existingData && existingData?.find((appl) => appl.type === "I_DONT_KNOW");
        const usage_type = [centralised, miniSplit, window, other, iDontKnow].filter(type => Boolean(type))?.[0]?.usage_type;

        return [
          { ...appl, type: "CENTRALISED", usage_type: null, ...centralised, ...(usage_type && { usage_type }) },
          { ...appl, type: "MINI_SPLIT", usage_type: null, ...miniSplit, ...(usage_type && { usage_type }) },
          { ...appl, type: "WINDOW", usage_type: null, ...window, ...(usage_type && { usage_type }) },
          { ...appl, type: "OTHER", usage_type: null, ...other, ...(usage_type && { usage_type }) },
          { ...appl, type: "I_DONT_KNOW", usage_type: null, ...iDontKnow, ...(usage_type && { usage_type }) },
        ];
      }
      case "ELECTRIC_BOILER": {
        return [ { ...appl, usage_type: null, coupled_with_solar_thermal_system: null, water_tank: null, ...existing } ];
      }
      case "ELECTRIC_OVEN": {
        const microwave = existingData && existingData?.find((appl) => appl.type === "MICROWAVE");
        const mini = existingData && existingData?.find((appl) => appl.type === "MINI");
        const regular = existingData && existingData?.find((appl) => appl.type === "REGULAR");
        
        return [
          { ...appl, type: 'MICROWAVE', ...microwave },
          { ...appl, type: 'MINI', ...mini },
          { ...appl, type: 'REGULAR', ...regular },
        ];
      }
      case "ELECTRIC_STOVE": {
        const electric = existingData && existingData?.find((appl) => appl.type === "ELECTRIC");
        const induction = existingData && existingData?.find((appl) => appl.type === "INDUCTION");
        
        return [
          { ...appl, type: 'ELECTRIC', ...electric },
          { ...appl, type: 'INDUCTION', ...induction },
        ];
      }
      case "FRIDGE": {
        const fridge = existingData && existingData?.find((appl) => appl.type === "FRIDGE");
        const freezer = existingData && existingData?.find((appl) => appl.type === "FREEZER");
        const fridgeAndFreezer = existingData && existingData?.find((appl) => appl.type === "FRIDGE_AND_FREEZER");
        const smallElectricWineCellar = existingData && existingData?.find((appl) => appl.type === "SMALL_ELECTRIC_WINE_CELLAR");
        const largeElectricWineCellar = existingData && existingData?.find((appl) => appl.type === "LARGE_ELECTRIC_WINE_CELLAR");
        
        return [
          { ...appl, type: 'FRIDGE', ...fridge },
          { ...appl, type: 'FREEZER', ...freezer },
          { ...appl, type: 'FRIDGE_AND_FREEZER', ...fridgeAndFreezer },
          { ...appl, type: 'SMALL_ELECTRIC_WINE_CELLAR', ...smallElectricWineCellar },
          { ...appl, type: 'LARGE_ELECTRIC_WINE_CELLAR', ...largeElectricWineCellar },
        ];
      }
      case "TUMBLE_DRYER": {
        const condenser = existingData && existingData?.find((appl) => appl.type === "CONDENSER");
        const heatPump = existingData && existingData?.find((appl) => appl.type === "HEAT_PUMP");
        const vented = existingData && existingData?.find((appl) => appl.type === "VENTED");
        const iDontKnow = existingData && existingData?.find((appl) => appl.type === "I_DONT_KNOW");
        const usage_types = [condenser, heatPump, vented, iDontKnow].filter(type => Boolean(type))?.[0]?.usage_types;
        
        return [
          { ...appl, type: 'CONDENSER', usage_types: [], ...condenser, ...(usage_types && { usage_types }) },
          { ...appl, type: 'HEAT_PUMP', usage_types: [], ...heatPump, ...(usage_types && { usage_types }) },
          { ...appl, type: 'VENTED', usage_types: [], ...vented, ...(usage_types && { usage_types }) },
          { ...appl, type: 'I_DONT_KNOW', usage_types: [], ...iDontKnow, ...(usage_types && { usage_types }) },
        ];
      }
      case "ELECTRIC_VEHICLE": {
        const bike = existingData && existingData?.find((appl) => appl.type === "BIKE");
        const motorbike = existingData && existingData?.find((appl) => appl.type === "MOTORBIKE");
        const car = existingData && existingData?.find((appl) => appl.type === "CAR");
        const scooter = existingData && existingData?.find((appl) => appl.type === "SCOOTER");

        return [
          { ...appl, type: 'BIKE', wattages: [], ...bike },
          { ...appl, type: 'MOTORBIKE', wattages: [], ...motorbike },
          { ...appl, type: 'CAR', wattages: [], ...car },
          { ...appl, type: 'SCOOTER', wattages: [], ...scooter },
        ];
      }
      case "ELECTRIC_HEATING": {
        const heatPump = existingData && existingData?.find((appl) => appl.type === "HEAT_PUMP");
        const heatStorage = existingData && existingData?.find((appl) => appl.type === "HEAT_STORAGE");
        const radiator = existingData && existingData?.find((appl) => appl.type === "RADIATOR");
        const underfloor = existingData && existingData?.find((appl) => appl.type === "UNDERFLOOR");
        const other = existingData && existingData?.find((appl) => appl.type === "OTHER");

        return [
          { ...appl, type: 'HEAT_PUMP', ...heatPump },
          { ...appl, type: 'HEAT_STORAGE', ...heatStorage },
          { ...appl, type: 'RADIATOR', ...radiator },
          { ...appl, type: 'UNDERFLOOR', ...underfloor },
          { ...appl, type: 'OTHER', ...other },
        ];
      }
      case "GAS_HEATING": {
        return [ { ...appl, amount: '1', any_electric_heater: null, ...existing } ];
      }
      case "OTHER": case "HUMIDIFIER": case "DEHUMIDIFIER": case "JACUZZI": case "ELECTRIC_LAWN_MOWER": {
        return [ { ...appl, amount: '1', ...(appliance === 'OTHER' && { name: '' }), ...existing } ];
      }
      default:
        return [ { ...appl, ...existing } ];
    }
  }

  useEffect(() => {
    isMountedRef.current = true;
    const { id, integratorId, isSmartMeter } = props;
    let apiFunction, params;
    if (isSmartMeter) {
      apiFunction = API.SMART_METERS.PROPERTY_DETAILS.APPLIANCES.GET;
      params = [id, integratorId];
    } else {
      apiFunction = API.STOCK.PROPERTY_DETAILS.APPLIANCES.GET;
      params = [id];
    }

    apiFunction(...params)
      .then((response) => {
        if (!isMountedRef.current) {
          return;
        }

        let electricHeatingType = null;
        const activeAppliances = response.data.appliances.length ? [...new Set(response.data.appliances.map((appl) => appl.key))] : [];
        const appliancesDetail = activeAppliances.length ? activeAppliances.reduce((acc, appliance) => {
          const existingData = response.data.appliances.filter((appl) => appl.key === appliance);
          const data = defaultApplianceValue(appliance, existingData);

          if (appliance === 'ELECTRIC_HEATING' || appliance === 'GAS_HEATING') {
            electricHeatingType = appliance;
            if (!activeAppliances.includes('ELECTRIC_HEATING')) {
              activeAppliances.push('ELECTRIC_HEATING');
            }
            if (!activeAppliances.includes('GAS_HEATING')) {
              activeAppliances.push('GAS_HEATING');
            }
          }

          return {...acc, [appliance]: data};
        }, {}) : {};

        if (appliancesDetail?.ELECTRIC_HEATING && appliancesDetail?.GAS_HEATING) {
          electricHeatingType = 'ELECTRIC_HEATING';
          delete appliancesDetail.GAS_HEATING;
        }

        let state = {
          appliancesDetail: appliancesDetail,
          initialData: JSON.parse(JSON.stringify(appliancesDetail)),
          ...(response.data?.version && { version: response.data.version }),
          activeAppliances: activeAppliances.sort(),
          electricHeatingType: electricHeatingType,
          loadingData: false,
        };

        setState(state);
      })
      .catch((error) => {
        if (error && error.response && error.response.status === 404) {
          setState({
            appliancesDetail: {},
            loadingData: false,
          });
        } else console.error(error);
      });

    return () => (isMountedRef.current = false);
  }, []);

  const getApplianceLabelByValue = (value) => appliancesLabels[value] || '-';

  const change = (e) => {
    const { value: appliance, checked } = e.target || {};
    let appliancesDetail = state.appliancesDetail, activeAppliances = [];
    let electricHeatingType = state.electricHeatingType;

    if (checked) {
      let initial = state.initialData?.[appliance];
      if (appliance === 'ELECTRIC_HEATING') {
        electricHeatingType = appliance;
        const gasHeating =  state.initialData.GAS_HEATING;
        if (gasHeating) {
          electricHeatingType = 'GAS_HEATING';
          initial = gasHeating;
        }

        appliancesDetail[electricHeatingType] = defaultApplianceValue(electricHeatingType, initial);
        if (!state.activeAppliances.includes(appliance)) {
          activeAppliances = [ ...state.activeAppliances, 'ELECTRIC_HEATING', 'GAS_HEATING' ].sort();
        }
      } else {
        appliancesDetail[appliance] = defaultApplianceValue(appliance, initial);
        if (!state.activeAppliances.includes(appliance)) {
          activeAppliances = [ ...state.activeAppliances, appliance ].sort();
        }
      }

      if (electricHeatingType === 'GAS_HEATING') {
        activeAppliances = activeAppliances.filter(appl => appl !== 'GAS_HEATING');
        const electricHeatingIndex = activeAppliances.findIndex(appl => appl === 'ELECTRIC_HEATING');
        activeAppliances.splice(electricHeatingIndex + 1, 0, 'GAS_HEATING');
      }
    } else {
      if (appliance === 'ELECTRIC_HEATING') {
        activeAppliances = state.activeAppliances.filter((appl) => appl !== 'ELECTRIC_HEATING' && appl !== 'GAS_HEATING');
        delete appliancesDetail[electricHeatingType];
        electricHeatingType = null;
      } else {
        activeAppliances = state.activeAppliances.filter((appl) => appl !== appliance);
        delete appliancesDetail[appliance];
      }
    }

    setState({
      appliancesDetail: appliancesDetail,
      activeAppliances: activeAppliances,
      electricHeatingType: electricHeatingType,
    });
  };

  const changeApplianceData = (e) => {
    const appliance = e.target.name.split('.')[0];
    const value = e.target.value;
    let updated = state.appliancesDetail;
    let electricHeatingType = state.electricHeatingType;
    let activeAppliances = [...state.activeAppliances];

    switch(appliance) {
      case "AC": case "ELECTRIC_OVEN": case "ELECTRIC_STOVE": case "FRIDGE": case "TUMBLE_DRYER": {
        const [, change, type] = e.target.name.split('.');

        if (change === 'type') {
          if (parseInt(value, 10) < 0) return;
          updated[appliance] = updated[appliance].map((appl) => appl.type === type ? { ...appl, amount: value } : appl);
        } else {
          if (appliance === "AC") {
            updated[appliance] = updated[appliance].map((appl) => ({ ...appl, usage_type: value }));
          }

          if (appliance === "TUMBLE_DRYER") {
            const seasons = updated[appliance][0].usage_types;
            const finalSeasons = seasons.includes(value)
              ? updated[appliance][0].usage_types.filter(season => season !== value)
              : [...seasons, value];
            updated[appliance] = updated[appliance].map(appl => ({ ...appl, usage_types: finalSeasons }));
          }
        }
        break;
      }
      case "ELECTRIC_VEHICLE": {
        const [, change, type, wattage] = e.target.name.split('.');
        
        if (change === 'type') {
          if (parseInt(value, 10) < 0) return;
          updated[appliance] = updated[appliance]
            .map((appl) => {
              if (appl.type === type) {
                const shouldDecrease = parseInt(value, 10) < parseInt(appl.amount, 10);
                const wattages = shouldDecrease ? appl.wattages.slice(0, parseInt(value, 10)) : [ ...appl.wattages, 0 ];

                return { ...appl, wattages, amount: value };
              }

              return appl;
            });
        } else {
          const intendedValue = (parseInt(value, 10) < 0) ? '0' : value;
          updated[appliance] = updated[appliance]
            .map((appl) => appl.type === type
              ? { ...appl, wattages: appl.wattages.map((w, i) => i === parseInt(wattage, 10) ? intendedValue : w) }
              : appl
            );
        }

        break;
      }
      case "ELECTRIC_HEATING": {
        const [, change, type] = e.target.name.split('.');

        if (change === 'type') {
          if (parseInt(value, 10) < 0) return;
          updated[appliance] = updated[appliance].map((appl) => appl.type === type ? { ...appl, amount: value } : appl);
        } else {
          electricHeatingType = value;
          updated[value] = defaultApplianceValue(value);
          
          if (value === 'ELECTRIC_HEATING') {
            delete updated.GAS_HEATING;
          } else {
            delete updated.ELECTRIC_HEATING;
          }
          const electricHeatingIndex = activeAppliances.findIndex(item => item === 'ELECTRIC_HEATING');
          const gasHeatingIndex = activeAppliances.findIndex(item => item === 'GAS_HEATING');
          activeAppliances[electricHeatingIndex] = 'GAS_HEATING';
          activeAppliances[gasHeatingIndex] = 'ELECTRIC_HEATING';
        }
        break;
      }
      default: {
        const change = e.target.name.split('.')[1];
        if (change === 'amount' && parseInt(value, 10) < 0) return;

        updated[appliance][0][change] = value;
      }
    }

    setState({
      appliancesDetail: updated,
      activeAppliances: activeAppliances,
      electricHeatingType: electricHeatingType,
    });
  }

  const isAmountValid = (appliance, some = false) => {
    return some
      ? appliance.some(appl => Number.isInteger(+appl.amount) && +appl.amount > 0)
      : appliance.every(appl => Number.isInteger(+appl.amount) && +appl.amount > 0);
  }
  const isAcValid = (ac) => {
    if (!isAmountValid(ac, true)) return false;

    return ac.every(appl => appl.usage_type !== null);
  };
  const isTumbleDryerValid = (tumbleDryer) => {
    if (!isAmountValid(tumbleDryer, true)) return false;
    const seasonQty = tumbleDryer[0].usage_types.length;

    return seasonQty > 0 && tumbleDryer.every(appl => appl.usage_types.length === seasonQty);
  };
  const isElectricBoilerValid = (electricBoiler) => {
    if (!isAmountValid(electricBoiler)) return false;

    return electricBoiler[0].usage_type !== null && electricBoiler[0].coupled_with_solar_thermal_system !== null && electricBoiler[0].water_tank !== null;
  }
  const isElectricVehicleValid = (electricVehicle) => {
    return electricVehicle
      .some((ev) => 
        Number.isInteger(+ev.amount)
        && +ev.amount > 0
        && ev.wattages.every((w) => {
          return Number.isInteger(+w) && +w >= 0;
        })
      );
  }
  const isElectricHeatingValid = (electricHeating, type) => {
    return type === 'ELECTRIC_HEATING'
      ? isAmountValid(electricHeating, true)
      : electricHeating[0].any_electric_heater !== null;
  }

  const validate = () => {
    const { appliancesDetail } = state;
    const fieldsWithError = [];

    Object.keys(appliancesDetail).forEach((applianceKey) => {
      switch (applianceKey) {
        case 'AC': {
          if (!isAcValid(appliancesDetail[applianceKey]))
            fieldsWithError.push(applianceKey);
          break;
        }
        case 'ELECTRIC_OVEN': case 'ELECTRIC_STOVE': case 'FRIDGE': {
          if (!isAmountValid(appliancesDetail[applianceKey], true))
            fieldsWithError.push(applianceKey);
          break;
        }
        case 'TUMBLE_DRYER': {
          if (!isTumbleDryerValid(appliancesDetail[applianceKey]))
            fieldsWithError.push(applianceKey);
          break;
        }
        case 'ELECTRIC_BOILER': {
          if (!isElectricBoilerValid(appliancesDetail[applianceKey]))
            fieldsWithError.push(applianceKey);
          break;
        }
        case 'ELECTRIC_VEHICLE': {
          if (!isElectricVehicleValid(appliancesDetail[applianceKey]))
            fieldsWithError.push(applianceKey);
          break;
        }
        case 'ELECTRIC_HEATING': case 'GAS_HEATING': {
          if (!isElectricHeatingValid(appliancesDetail[applianceKey], applianceKey))
            fieldsWithError.push(applianceKey);
          break;
        }
        case 'OTHER': {
          if (!isAmountValid(appliancesDetail[applianceKey]) || !appliancesDetail[applianceKey][0].name.length)
            fieldsWithError.push(applianceKey);
          break;
        }
        default:
          if (!isAmountValid(appliancesDetail[applianceKey]))
            fieldsWithError.push(applianceKey);
      }
    });
    setState({ fieldsWithError: fieldsWithError });

    return !fieldsWithError.length;
  };

  const submit = (e) => {
    e.preventDefault();

    e.target.querySelector('button[type="submit"]').blur();

    if (!validate()) return false;

    // Fix and clean up appliances
    const details = JSON.parse(JSON.stringify(state.appliancesDetail));
    const appliances = Object.keys(state.appliancesDetail)
      .sort()
      .reduce((acc, appliance) => {
        const data = details[appliance];
        return [
          ...acc,
          ...data
            .map(item => ({
              ...item,
              amount: +item.amount,
              ...(item.any_electric_heater && typeof item.any_electric_heater !== 'boolean' && { any_electric_heater: item.any_electric_heater === 'true' }),
              ...(item.water_tank && typeof item.water_tank !== 'boolean' && { water_tank: item.water_tank === 'true' }),
            }))
            .filter(item => item.amount > 0)
        ];
      }, []);

    if (userIsAdminOrResellerAdmin) {
      setState({ feedbackMessage: '', loading: true });

      const { id, integratorId, isSmartMeter } = props;
      let apiFunction, params;
      const payload = {
        version: state.version,
        appliances: appliances,
      };
      if (isSmartMeter) {
        apiFunction = API.SMART_METERS.PROPERTY_DETAILS.APPLIANCES.PUT;
        params = [id, integratorId, payload];
      } else {
        apiFunction = API.STOCK.PROPERTY_DETAILS.APPLIANCES.POST;
        params = [id, payload];
      }

      apiFunction(...params)
        .then((response) => {
          const msg = `Appliances saved successfully.`;
          setState({
            feedbackMessage: <Text bold={true} highlight={true} text={msg} />,
            loading: false,
          });
        })
        .catch((error) => {
          console.error(error);

          setState({
            feedbackMessage: (
              <ErrorMessage message="An error occurred. Please try again later or contact support." />
            ),
            loading: false,
          });
        });
    }

    return false;
  };

  const { fieldsWithError } = state;

  return (
    <div id="appliances-page">
      <Title element="h6" text="Appliances" />

      {state.feedbackMessage ? state.feedbackMessage : null}

      {state.loadingData ? (
        <Loading />
      ) : (
        <form action="#" onSubmit={submit}>
          <div>
            <div>
              <Text bold={true} text="Supported appliances" />
              <hr className="separator mb mt" />
            </div>
            <InputSelect
              change={change}
              checked={state.activeAppliances}
              disabled={state.loading || !userIsAdminOrResellerAdmin}
              options={[
                { label: getApplianceLabelByValue('AC'), value: 'AC' },
                {
                  label: getApplianceLabelByValue('DISHWASHER'),
                  value: 'DISHWASHER',
                },
                {
                  label: getApplianceLabelByValue('ELECTRIC_BOILER'),
                  value: 'ELECTRIC_BOILER',
                },
                {
                  label: getApplianceLabelByValue('ELECTRIC_HEATING'),
                  value: 'ELECTRIC_HEATING',
                },
                {
                  label: getApplianceLabelByValue('ELECTRIC_OVEN'),
                  value: 'ELECTRIC_OVEN',
                },
                {
                  label: getApplianceLabelByValue('ELECTRIC_SHOWER'),
                  value: 'ELECTRIC_SHOWER',
                },
                {
                  label: getApplianceLabelByValue('ELECTRIC_STOVE'),
                  value: 'ELECTRIC_STOVE',
                },
                {
                  label: getApplianceLabelByValue('ELECTRIC_VEHICLE'),
                  value: 'ELECTRIC_VEHICLE',
                },
                {
                  label: getApplianceLabelByValue('FRIDGE'),
                  value: 'FRIDGE',
                },
                {
                  label: getApplianceLabelByValue('KETTLE'),
                  value: 'KETTLE',
                },
                {
                  label: getApplianceLabelByValue('TUMBLE_DRYER'),
                  value: 'TUMBLE_DRYER',
                },
                {
                  label: getApplianceLabelByValue('WASHING_MACHINE'),
                  value: 'WASHING_MACHINE',
                }
              ]}
              name="appliances"
              type="checkbox"
            />
            <div>
              <Text bold={true} text="Other appliances" />
              <hr className="separator mb mt" />
            </div>
            <InputSelect
              change={change}
              checked={state.activeAppliances}
              disabled={state.loading || !userIsAdminOrResellerAdmin}
              options={[
                {
                  label: getApplianceLabelByValue('HUMIDIFIER'),
                  value: 'HUMIDIFIER',
                },
                {
                  label: getApplianceLabelByValue('DEHUMIDIFIER'),
                  value: 'DEHUMIDIFIER',
                },
                {
                  label: getApplianceLabelByValue('JACUZZI'),
                  value: 'JACUZZI',
                },
                {
                  label: getApplianceLabelByValue('ELECTRIC_LAWN_MOWER'),
                  value: 'ELECTRIC_LAWN_MOWER',
                },
                {
                  label: getApplianceLabelByValue('POOL_PUMP'),
                  value: 'POOL_PUMP',
                },
                {
                  label: getApplianceLabelByValue('OTHER'),
                  value: 'OTHER',
                },
              ]}
              name="appliancesOthers"
              type="checkbox"
            />

            {userIsAdminOrResellerAdmin ? (
              <div className="btn-actions">
                <Link
                  to={{
                    pathname: props.isSmartMeter
                      ? `/smart-meter/${props.integratorId}/${props.id}`
                      : `/sensor/${props.id}`,
                  }}
                >
                  <Button
                    action="cancel"
                    disabled={state.loading}
                    label="Cancel"
                    loading={false}
                  />
                </Link>
                <Button disabled={state.loading} label="Save" type="submit" />
              </div>
            ) : null}

            {fieldsWithError && fieldsWithError.length ? (
              <div className="error-messages">
                {fieldsWithError.map((field) => (
                  <div
                    key={`error-msg-${field.toLowerCase()}`}
                    className={`error-msg-${field.toLowerCase()}`}
                  >
                    <ErrorMessage message={appliancesErrorMessages[field]} />
                  </div>
                ))}
              </div>
            ) : null}
          </div>

          <div>
            {state.activeAppliances.map((appliance) => {
              if (['ELECTRIC_HEATING', 'GAS_HEATING'].includes(appliance) && !state.appliancesDetail[appliance]) return null;

              const applianceDetail = state.appliancesDetail[appliance][0];
              const applianceUnits = state.appliancesDetail[appliance];

              return (
                <div key={appliance}>
                  {!['HUMIDIFIER', 'DEHUMIDIFIER', 'JACUZZI', 'ELECTRIC_LAWN_MOWER', 'POOL_PUMP'].includes(appliance) && 
                    <Text bold={true} highlight={true} text={getApplianceLabelByValue(appliance)} />
                  }
                  {appliance === 'AC' ? (
                    <>
                      <Text text="What type of AC do you have?" />
                      {applianceUnits.map((unit, index) => (
                        <Input
                          key={`${appliance}-type-${unit.type}`}
                          change={changeApplianceData}
                          disabled={state.loading || !userIsAdminOrResellerAdmin}
                          min={0}
                          name={`${appliance}.type.${unit.type}`}
                          placeholder={applianceTypeLabels[appliance][unit.type]}
                          type="number"
                          value={state.appliancesDetail[appliance][index].amount}
                        />
                      ))}
                      <InputSelect
                        change={changeApplianceData}
                        checked={applianceDetail.usage_type}
                        click={changeApplianceData}
                        disabled={state.loading || !userIsAdminOrResellerAdmin}
                        name="AC.usage_type"
                        options={[
                          { label: 'Summer', value: 'SUMMER' },
                          { label: 'Winter', value: 'WINTER' },
                          { label: 'Both', value: 'BOTH' },
                        ]}
                        placeholder="Do you use your AC in Winter or Summer?"
                        type="radio"
                      />
                    </>
                  ) : null}

                  {appliance === 'ELECTRIC_BOILER' ? (
                    <>
                      <Input
                        change={changeApplianceData}
                        disabled={state.loading || !userIsAdminOrResellerAdmin}
                        min={0}
                        name={"ELECTRIC_BOILER.amount"}
                        placeholder={`How many ${getApplianceLabelByValue(appliance)} units do you have?`}
                        type="number"
                        value={applianceDetail.amount}
                      />
                      <InputSelect
                        change={changeApplianceData}
                        checked={applianceDetail.usage_type}
                        click={changeApplianceData}
                        disabled={state.loading || !userIsAdminOrResellerAdmin}
                        name="ELECTRIC_BOILER.usage_type"
                        options={[
                          { label: 'Space Heating', value: 'SPACE_HEATING' },
                          { label: 'Water Heating', value: 'WATER_HEATING' },
                          { label: 'Both', value: 'BOTH' },
                        ]}
                        placeholder="Do you use your Electric Boiler for"
                        type="radio"
                      />
                      <InputSelect
                        change={changeApplianceData}
                        checked={applianceDetail.coupled_with_solar_thermal_system}
                        click={changeApplianceData}
                        disabled={state.loading || !userIsAdminOrResellerAdmin}
                        name="ELECTRIC_BOILER.coupled_with_solar_thermal_system"
                        options={[
                          { label: 'Yes', value: 'YES' },
                          { label: 'No', value: 'NO' },
                          { label: 'Not Sure', value: 'NOT_SURE' },
                        ]}
                        placeholder="Is your Electric Boiler coupled with a solar thermal system?"
                        type="radio"
                      />
                      <InputSelect
                        change={changeApplianceData}
                        checked={applianceDetail.water_tank}
                        click={changeApplianceData}
                        disabled={state.loading || !userIsAdminOrResellerAdmin}
                        name="ELECTRIC_BOILER.water_tank"
                        options={[
                          { label: 'Yes', value: true },
                          { label: 'No', value: false },
                        ]}
                        placeholder="Does your Electric Boiler have a water tank?"
                        type="radio"
                      />
                    </>
                  ) : null}

                  {(appliance === 'ELECTRIC_HEATING' || appliance === 'GAS_HEATING') ? (
                    <>
                      <Text text="What is your main space heating source?" />
                      <InputSelect
                        change={changeApplianceData}
                        checked={state.electricHeatingType}
                        click={changeApplianceData}
                        disabled={state.loading || !userIsAdminOrResellerAdmin}
                        name="ELECTRIC_HEATING.choice"
                        options={[
                          { label: 'Electric Heating', value: 'ELECTRIC_HEATING' },
                          { label: 'Gas Heating', value: 'GAS_HEATING' },
                        ]}
                        type="radio"
                      />
                    </>
                  ) : null}

                  {appliance === 'GAS_HEATING' ? (
                    <InputSelect
                      change={changeApplianceData}
                      checked={applianceDetail.any_electric_heater}
                      click={changeApplianceData}
                      disabled={state.loading || !userIsAdminOrResellerAdmin}
                      name="GAS_HEATING.any_electric_heater"
                      options={[
                        { label: 'Yes', value: true },
                        { label: 'No', value: false },
                      ]}
                      placeholder="Do you have any Electric Heating units?"
                      type="radio"
                    />
                  ) : null}

                  {['ELECTRIC_OVEN', 'ELECTRIC_STOVE', 'FRIDGE', 'TUMBLE_DRYER', 'ELECTRIC_HEATING'].includes(appliance) ? (
                    <>
                      <Text text={`What type of ${applianceTypeLabels[appliance].LABEL} do you have?`} />
                      {applianceUnits.map((unit, index) => (
                        <Input
                          key={`${appliance}-type-${unit.type}`}
                          change={changeApplianceData}
                          disabled={state.loading || !userIsAdminOrResellerAdmin}
                          min={0}
                          name={`${appliance}.type.${unit.type}`}
                          placeholder={applianceTypeLabels[appliance][unit.type]}
                          type="number"
                          value={state.appliancesDetail[appliance][index].amount}
                        />
                      ))}
                      {appliance === 'TUMBLE_DRYER' ? (
                        <InputSelect
                          change={changeApplianceData}
                          checked={applianceDetail.usage_types}
                          click={changeApplianceData}
                          disabled={state.loading || !userIsAdminOrResellerAdmin}
                          name="TUMBLE_DRYER.usage_types"
                          options={[
                            { label: 'Summer', value: 'SUMMER' },
                            { label: 'Autumn', value: 'AUTUMN' },
                            { label: 'Winter', value: 'WINTER' },
                            { label: 'Spring', value: 'SPRING' },
                          ]}
                          placeholder={`When do you use your ${applianceTypeLabels[appliance].LABEL}?`}
                          type="checkbox"
                        />
                      ) : null}
                    </>
                  ) : null}

                  {appliance === 'ELECTRIC_VEHICLE' ? (
                    <>
                      <Text text={`What type of Electric Vehicles do you have?`} />
                      {applianceUnits.map((unit, index) => (
                        <div key={`${appliance}-type-${unit.type}`}>
                          <Input
                            change={changeApplianceData}
                            disabled={state.loading || !userIsAdminOrResellerAdmin}
                            min={0}
                            name={`${appliance}.type.${unit.type}`}
                            placeholder={applianceTypeLabels[appliance][unit.type]}
                            type="number"
                            value={state.appliancesDetail[appliance][index].amount}
                          />
                          {state.appliancesDetail[appliance][index].amount > 0 &&
                            [...Array(parseInt(state.appliancesDetail[appliance][index].amount, 10))].map((_, i) => (
                              <Input
                                change={changeApplianceData}
                                disabled={state.loading || !userIsAdminOrResellerAdmin}
                                input={changeApplianceData}
                                key={i}
                                max={20000}
                                min={0}
                                name={`${appliance}.wattage.${unit.type}.${i}`}
                                placeholder={`What is the wattage of your ${applianceTypeLabels[appliance][unit.type]} ${i + 1} charger?`}
                                step={500}
                                type="number"
                                tip="Type 0 (zero) for unknown wattage."
                                value={state.appliancesDetail[appliance][index].wattages[i]}
                              />
                            ))
                          }
                        </div>
                      ))}
                    </>
                  ) : null}

                  {['DISHWASHER', 'KETTLE', 'ELECTRIC_SHOWER', 'WASHING_MACHINE'].includes(appliance) ? (
                    <Input
                      change={changeApplianceData}
                      disabled={state.loading || !userIsAdminOrResellerAdmin}
                      min={0}
                      name={`${appliance}.amount`}
                      placeholder={`How many ${getApplianceLabelByValue(appliance)} units do you have?`}
                      type="number"
                      value={applianceDetail.amount}
                    />
                  ) : null}

                  {appliance === 'OTHER' ? (
                    <Input
                      key="OTHER-name"
                      change={changeApplianceData}
                      disabled={state.loading || !userIsAdminOrResellerAdmin}
                      name="OTHER.name"
                      placeholder="Type the other appliance name here"
                      type="text"
                      value={state.appliancesDetail.OTHER[0].name}
                    />
                  ) : null}
                </div>
              );
            })}
          </div>
        </form>
      )}
    </div>
  );
}

export default AppliancesPage;
