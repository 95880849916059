import { useRef, useReducer, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { isResellerAdmin } from '../../components/utils';
import API from '../../components/api';
import {
  GET_CURRENCY_LABEL,
  GET_HARDWARE_TYPE_LABEL,
  GET_PAYMENT_TYPE_LABEL,
  GET_SUBSCRIPTION_PRODUCTS_LABELS,
  NO_RESULTS_MESSAGE,
} from '../../components/constants';

import Button from '../../elements/button';
import ErrorMessage from '../../elements/error-message';
import Input from '../../elements/forms/input';
import Text from '../../elements/text';
import Title from '../../elements/title';
import Select from '../../elements/forms/select';
import Table from '../../elements/table';

import './browse.sass';

const filterObject = {
  search: '',
  searchBy: '',
  searchOptions: Object.assign(
    [],
    [
      {
        label: 'Subscription ID',
        value: 'ID',
      },
      {
        label: 'Subscription Name',
        value: 'NAME',
      },
    ]
  ),
};

function SubscriptionBrowse() {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      filter: filterObject,
      loading: true,
      noResultsMessage: NO_RESULTS_MESSAGE,
      page: 0,
      pages: 1,
      pagesLastSubscription: [0],
      subscriptions: [],
      hasError: false,
      validationMessage: 'Please, select a property and fill the search field.',
      isSearching: false,
    }
  );
  const isMountedRef = useRef(null);
  const pagesLastId = useRef([0]);

  useEffect(() => {
    isMountedRef.current = true;
    handleSearch();

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const columns = useMemo(
    () => [
      ...(isResellerAdmin()
        ? []
        : [{ id: 'edit', title: '', align: 'center', minWidth: 50 }]),
      { id: 'id', title: 'ID', align: 'center', minWidth: 50 },
      {
        id: 'name',
        title: 'Name',
        align: 'left',
        minWidth: 100,
      },
      {
        id: 'durationText',
        title: 'Agreement duration',
        align: 'center',
        minWidth: 100,
      },
      {
        id: 'hardwareType',
        title: 'Hardware type',
        align: 'left',
        minWidth: 100,
      },
      {
        id: 'paymentType',
        title: 'Payment frequency',
        align: 'left',
        minWidth: 50,
      },
      {
        id: 'paymentRateText',
        title: 'Payment rate',
        align: 'center',
        minWidth: 100,
      },
      {
        id: 'toleranceText',
        title: 'Tolerance period',
        align: 'center',
        minWidth: 100,
      },
      {
        id: 'products',
        title: 'Products',
        align: 'center',
        minWidth: 100,
      },
      {
        id: 'notes',
        title: 'Notes',
        align: 'left',
        minWidth: 100,
      },
    ],
    [isResellerAdmin]
  );

  const data = useMemo(() => {
    return state.subscriptions.map((row) => {
      return {
        ...row,
        edit: (
          <Link
            to={{
              pathname: '/subscriptions/edit',
              state: { subscription: row },
            }}
          >
            <Button action="edit" />
          </Link>
        ),
        hardwareType: GET_HARDWARE_TYPE_LABEL(row.hardwareType),
        paymentType: GET_PAYMENT_TYPE_LABEL(row.paymentType),
        products: row.products.length
          ? GET_SUBSCRIPTION_PRODUCTS_LABELS(row.products).join(', ')
          : '-',
        notes: row.notes || '-',
      };
    });
  }, [state.subscriptions]);

  const changeBy = (e) => {
    setState({
      filter: { ...state.filter, searchBy: e.target.value, search: '' },
    });
  };

  const change = (e) => {
    setState({ filter: { ...state.filter, search: e.target.value } });
  };

  const validate = () => {
    const filter = state.filter;
    let hasError = false;
    let validationMessage =
      'Please, select a property and fill the search field.';
    if (
      (filter.search !== '' && filter.searchBy === '') ||
      (filter.search === '' && filter.searchBy !== '')
    ) {
      hasError = true;
    } else if (filter.searchBy === 'ID') {
      const numberRegex = /^\d+$/;
      if (!numberRegex.test(filter.search.replace(/\s+/g, ''))) {
        hasError = true;
        validationMessage = 'Please, type a number for the ID.';
      }
    }
    setState({ hasError, validationMessage });
    return !hasError;
  };

  const handlePreviousPage = () => {
    pagesLastId.current.pop();
    handleSearch(pagesLastId.current[pagesLastId.current.length - 1]);
  };

  const handleNextPage = () => {
    pagesLastId.current.push(state.lastId);
    handleSearch(pagesLastId.current[pagesLastId.current.length - 1]);
  };

  const handleSearch = (lastId) => {
    if (!validate()) {
      window.scrollTo(0, 0);
      return false;
    }

    setState({ loading: true });

    API.SUBSCRIPTION.LIST(state.filter.search, state.filter.searchBy, lastId)
      .then((response) => {
        if (!isMountedRef.current) {
          return;
        }

        const newData = response.data.subscriptions.map((d) => {
          d.durationText = `${d.duration} month${d.duration !== 1 ? 's' : ''}`;
          d.toleranceText = `${d.tolerance} month${
            d.tolerance !== 1 ? 's' : ''
          }`;
          d.paymentRateText = `${d.paymentRate} ${
            d.paymentRate > 0 ? GET_CURRENCY_LABEL(d.currency) + '/u' : ''
          }`;
          return d;
        });
        setState({
          loading: false,
          subscriptions: newData,
          lastId: response.data.lastSubscriptionIdOfThePage,
        });
      })
      .catch((error) => {
        console.error(error);

        setState({
          loading: false,
          subscriptions: [],
        });
      });
  };

  const submit = (e) => {
    e.preventDefault();
    e.target.querySelector('button[type="submit"]').blur();

    if (!validate()) {
      return false;
    }

    pagesLastId.current = [0];
    handleSearch();

    return false;
  };

  return (
    <section id="page-subscription-browse">
      <Title element="h4" text="Subscriptions" />
      <Text bold={true} text="Search for a subscription by ID or Name." />
      {state.hasError ? (
        <ErrorMessage message={state.validationMessage} />
      ) : null}
      <form action="#" onSubmit={submit}>
        <div>
          <Select
            change={changeBy}
            disabled={state.loading}
            hasError={state.hasError}
            name="searchBy"
            options={state.filter.searchOptions}
            placeholder="Property"
            value={state.filter.searchBy}
          />
        </div>
        <div>
          <Input
            change={change}
            disabled={state.loading}
            hasError={state.hasError}
            name="filter"
            placeholder="Search"
            type="text"
            value={state.filter.search}
          />
        </div>
        <div>
          <Button
            action="search"
            disabled={state.loading}
            type="submit"
            aria-label="search"
          />
        </div>
      </form>
      <Table
        columns={columns}
        data={data}
        isLoading={state.loading}
        showPagination
        hasPrevious={pagesLastId.current.length > 1}
        hasNext={state.lastId !== 0}
        handlePreviousPage={handlePreviousPage}
        handleNextPage={handleNextPage}
      />
    </section>
  );
}

export default SubscriptionBrowse;
