/* Countries data trasformation functions */

const getCountriesValues = (countriesData) => {
  return countriesData.map((x) => {
    return x.isoCode;
  });
};

const getCountriesOptions = (countriesData) => {
  return countriesData.map((x) => {
    return { label: x.name, value: x.isoCode };
  });
};

const getCountryByCode = (code, countriesOptions) => {
  let country;

  for (let i = 0, max = countriesOptions.length; i < max; i++) {
    if (countriesOptions[i].value === code) {
      country = countriesOptions[i];
      break;
    }
  }

  return country;
};

const COUNTRIES = {
  GET_OPTIONS: getCountriesOptions,
  GET_VALUES: getCountriesValues,
  GET_COUNTRY_BY_CODE: getCountryByCode,
};

export { COUNTRIES };
