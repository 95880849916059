import { useState, forwardRef } from 'react';

const InputFile = forwardRef((props, ref) => {
  const [isActive, setIsActive] = useState(false);

  let spanClass = 'placeholder';
  if (isActive) {
    spanClass += ' active';
  } else if (props.value !== '' || props.selected) {
    spanClass += ' deactive';
  }
  let loadingClass = '';

  if (
    (props.loading !== undefined && props.loading) ||
    (props.loading === undefined && props.disabled)
  )
    loadingClass = 'loading';

  const handleFocus = () => {
    setIsActive(true);
  };

  const handleBlur = () => {
    setIsActive(false);
  };

  return (
    <label className={`input-file ${loadingClass}`}>
      <span className={spanClass}>{props.placeholder}</span>
      <input
        accept={props.accept}
        className={props.hasError ? 'error' : ''}
        disabled={props.disabled}
        name={props.name}
        onChange={props.change}
        ref={ref}
        onFocus={handleFocus}
        onBlur={handleBlur}
        value={props.value}
        type="file"
      />
      <span className="border" />
    </label>
  );
});

export default InputFile;
