import { DialogOverlay, DialogContent } from '@reach/dialog';
import PropTypes from 'prop-types';

import './dialog.sass';
import Button from './button';

function Dialog({
  title,
  message,
  isOpen,
  handleConfirm,
  handleClose,
  loading = false,
  hasError = true,
  errorMessage,
}) {
  return (
    <DialogOverlay
      isOpen={isOpen}
      onDismiss={handleClose}
      className="dialog-overlay"
    >
      <DialogContent
        aria-label="confirmation dialog"
        className="dialog-content"
      >
        {hasError && errorMessage && (
          <p className={'error-message'}>{errorMessage}</p>
        )}
        {title && <h4>{title}</h4>}
        <p>{message}</p>
        <div className="btn-group">
          <Button
            label="Confirm"
            loading={loading}
            click={handleConfirm}
            type="submit"
          />
          <Button
            label="Cancel"
            tabIndex={1}
            click={handleClose}
            className="cancel"
          />
        </div>
      </DialogContent>
    </DialogOverlay>
  );
}

Dialog.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
};

export default Dialog;
