import { useRef, useReducer, useEffect } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

import API from '../../components/api';
import { REPORT_LAYOUTS } from '../../components/constants';
import { testEmail, isAdmin, isResellerAdmin } from '../../components/utils';

import Button from '../../elements/button';
import ErrorMessage from '../../elements/error-message';
import Input from '../../elements/forms/input';
import Select from '../../elements/forms/select';
import Text from '../../elements/text';
import Title from '../../elements/title';

import './email-report-create-send.sass';

function EmailReportCreateSend(props) {
  const isCreate = props.mode === 'periodicreportcreate';
  const { client } = props;

  const initialState = {
    date: '',
    feedbackMessage: '',
    fieldsWithError: [],
    loading: true,
    reportLink: '',
    email: (client && client.account && client.account.email) || '',
    monthsOptions: [],
  };

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    initialState
  );
  const isMountedRef = useRef(null);

  let texts;

  if (isCreate) {
    texts = {
      failed:
        'An error occurred while generating the report. Please try again later or contact support.',
      sendLabel: 'Generate',
      successfull: 'Periodic Report generated succesfully.',
      text: `Generate a Periodic Report of device ${client.id}:`,
      title: 'Generate a Periodic Report',
    };
  } else {
    texts = {
      failed:
        'An error occurred while sending the report. Please try again later or contact support.',
      sendLabel: 'Send',
      successfull: 'Periodic Report sent succesfully.',
      text: `Send Periodic Report of device ${client.id}:`,
      title: 'Send a Periodic Report',
    };
  }

  useEffect(() => {
    isMountedRef.current = true;
    if (!isCreate) {
      API.STOCK.REPORT.HISTORY({
        id: props.client.id,
        page: '',
        datesHistory: true,
        integratorId: props.integratorId,
      })
        .then((response) => {
          if (!isMountedRef.current) {
            return;
          }
          const monthsOptions = [];
          response.data.reverse().map((date) => {
            return monthsOptions.push({
              label: moment(date).format('MMMM/YYYY'),
              value: moment(date).format('YYYY-MM-DD'),
            });
          });
          setState({
            loading: false,
            monthsOptions,
          });
        })
        .catch((error) => {
          console.error(error);
          setState({
            loading: false,
          });
        });
    } else if (
      props.sensor &&
      props.sensor.firstEvent &&
      !state.monthsOptions.length
    ) {
      getMonthsOptions(props.sensor.firstEvent);
    } else if (props.activatedAt && !state.monthsOptions.length)
      getMonthsOptions(props.activatedAt);

    return () => (isMountedRef.current = false);
  }, []);

  useEffect(() => {
    if (
      isCreate &&
      props.sensor &&
      props.sensor.firstEvent &&
      !state.monthsOptions.length
    ) {
      getMonthsOptions(props.sensor.firstEvent);
    } else if (isCreate && props.activatedAt && !state.monthsOptions.length) {
      getMonthsOptions(props.activatedAt);
    } else if (isCreate) {
      setState({ loading: false });
    }
  }, [props.sensor && props.sensor.firstEvent]);

  const getMonthsOptions = (firstEvent) => {
    let month,
      currentMonth = moment().startOf('month');
    const installedMonth = moment(firstEvent).startOf('month'),
      numberOfMonthsToIterate = currentMonth.diff(installedMonth, 'months');
    currentMonth.subtract(1, 'month');

    const monthsOptions = [];
    Array(numberOfMonthsToIterate)
      .fill()
      .map((_, i) => {
        month = currentMonth.clone().subtract(i, 'months');
        return monthsOptions.push({
          label: month.format('MMMM/YYYY'),
          value: month.format('YYYY-MM-DD'),
        });
      });
    setState({ monthsOptions, loading: false });
  };

  const change = (e) => {
    setState({ [e.target.name]: e.target.value });
  };

  const isAdminOrResellerAdmin = () => {
    return isAdmin() || isResellerAdmin();
  };

  const validate = () => {
    const date = moment(state.date);
    let fieldsWithError = [];

    if (props.client.id === '') fieldsWithError.push('id');
    if (!isCreate && isAdminOrResellerAdmin() && !testEmail(state.email))
      fieldsWithError.push('email');
    if (date === '') fieldsWithError.push('date');
    else {
      if (!date.isBefore(moment())) fieldsWithError.push('date');
    }
    setState({ loading: false, fieldsWithError: fieldsWithError });

    return !fieldsWithError.length;
  };

  const send = () => {
    setState({ loading: true });
    const email = isAdminOrResellerAdmin() ? state.email : null,
      sensorId = props.client.id,
      date = moment(state.date);

    let apiCall = isCreate
      ? API.STOCK.REPORT.CREATE({
          sensorId: sensorId,
          layout: REPORT_LAYOUTS.DISAGGREGATED,
          month: date.format('MMMM').toUpperCase(),
          year: date.format('YYYY'),
          integratorId: props.integratorId,
        })
      : API.STOCK.REPORT.SEND({
          sensorId: sensorId,
          email: email,
          layout: REPORT_LAYOUTS.DISAGGREGATED,
          month: date.format('MMMM').toUpperCase(),
          year: date.format('YYYY'),
          integratorId: props.integratorId,
        });

    apiCall
      .then((response) => {
        let state = initialState;
        state.feedbackMessage = (
          <Text bold={true} highlight={true} text={texts.successfull} />
        );
        state.reportLink = response.data;
        setState(state);
      })
      .catch((error) => {
        let feedbackMessage = <ErrorMessage message={texts.failed} />;
        console.error(error);

        setState({
          feedbackMessage: feedbackMessage,
          loading: false,
        });
      });
  };

  const submit = (e) => {
    e.preventDefault();

    e.target.querySelector('button[type="submit"]').blur();

    setState({ feedbackMessage: '', loading: true, reportLink: '' });
    if (validate()) {
      send();
    }

    return false;
  };

  return (
    <div id="admintools-emailreport-create-send">
      <Title element="h6" text={texts.title} />

      {state.feedbackMessage ? state.feedbackMessage : null}
      {state.reportLink ? (
        <div className="report-link">
          <Text
            bold={true}
            highlight={true}
            inline={true}
            text="You can check it "
          />
          <a href={state.reportLink} rel="noopener noreferrer" target="_blank">
            here
          </a>
        </div>
      ) : null}

      <form action="#" onSubmit={submit}>
        <Text bold={true} text={texts.text} />
        <div>
          {!isCreate ? (
            <Input
              change={change}
              disabled={state.loading || !isAdminOrResellerAdmin()}
              hasError={state.fieldsWithError.indexOf('email') >= 0}
              name="email"
              placeholder="Email"
              type="email"
              value={
                isAdminOrResellerAdmin()
                  ? state.email
                  : "Events will be sent to device's email"
              }
            />
          ) : null}
          <Select
            change={change}
            disabled={state.loading}
            hasError={state.fieldsWithError.indexOf('date') >= 0}
            name="date"
            options={state.monthsOptions}
            placeholder="Reported month"
            value={state.date}
          />
          <div className="btn-actions">
            <Link
              to={{
                pathname: props.isSmartMeter
                  ? `/smart-meter/${props.integratorId}/${props.client.id}/admintools`
                  : `/sensor/${props.client.id}/admintools`,
                state: 'admintools',
              }}
            >
              <Button
                action="cancel"
                disabled={state.loading}
                label="Cancel"
                loading={false}
              />
            </Link>
            <Button
              disabled={state.loading}
              label={texts.sendLabel}
              type="submit"
            />
          </div>
        </div>
      </form>
    </div>
  );
}

export default EmailReportCreateSend;
