import { useReducer, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import API from '../../components/api';
import { testEmail } from '../../components/utils';

import Button from '../../elements/button';
import ErrorMessage from '../../elements/error-message';
import Input from '../../elements/forms/input';
import Text from '../../elements/text';
import Title from '../../elements/title';
import Table from '../../elements/table';

import '../users.sass';
import CheckCircleIcon from '../../general/img/check-circle.svg';
import ProhibitedIcon from '../../general/img/prohibited.svg';

function ResellersBuildingMonitoring() {
  const params = useParams();
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      email: '',
      feedbackMessage: '',
      fieldsWithError: [],
      loading: false,
      loadingDisableAll: false,
      loadingEnableAll: false,
      resellerId: params.id,
      users: [],
      deleting: false,
      deleteError: false,
      userToDelete: '',
      update: 0,
    }
  );

  useEffect(() => {
    get();
  }, [state.update]);

  const columns = useMemo(
    () => [
      { id: 'username', title: 'E-mail', align: 'left' },
      { id: 'enabled', title: 'Enabled', align: 'center' },
      { id: 'delete', title: '', align: 'center', minWidth: 45 },
    ],
    []
  );

  const data = useMemo(() => {
    return state.users.map((row) => {
      return {
        ...row,
        id: row.username,
        enabled: (
          <div className="text-center">
            <img
              alt={row.enabled ? 'enabled' : 'disabled'}
              src={row.enabled ? CheckCircleIcon : ProhibitedIcon}
              width="18"
            />
          </div>
        ),
        delete: (
          <Button
            action="delete"
            aria-label="delete"
            click={() => {
              confirmDelete(row);
            }}
          />
        ),
      };
    });
  }, [state.users]);

  const get = () => {
    setState({ loading: true });

    API.RESELLERS.INTEGRATIONS.BUILDING_MONITORING.USERS.GET(state.resellerId)
      .then((response) => {
        setState({
          loading: false,
          users: response.data.users || [],
        });
      })
      .catch((error) => {
        console.error(error);

        setState({
          loading: false,
          noResultsMessage: 'There was an error getting the users.',
          users: [],
        });
      });
  };

  const change = (e) => {
    setState({ [e.target.name]: e.target.value });
  };

  const validate = () => {
    let fieldsWithError = [];

    if (!testEmail(state.email)) fieldsWithError.push('email');
    setState({ fieldsWithError: fieldsWithError, loading: false });

    return !fieldsWithError.length;
  };

  const send = () => {
    setState({ loading: true });

    API.RESELLERS.INTEGRATIONS.BUILDING_MONITORING.USERS.ADD(
      state.resellerId,
      state.email
    )
      .then((response) => {
        setState({
          email: '',
          feedbackMessage: (
            <Text
              bold={true}
              highlight={true}
              text={`User ${state.email} added successfully.`}
            />
          ),
          loading: false,
          update: state.update + 1,
        });
      })
      .catch((error) => {
        console.error(error);

        let message =
          error.response.data.status === 500 &&
          error.response.data.exception &&
          typeof error.response.data.exception === 'string' &&
          error.response.data.exception.indexOf('DuplicateKeyException') >= 0
            ? error.response.data.message
            : 'Email is already being used. Please enter another email.';
        setState({
          feedbackMessage: <ErrorMessage message={message} />,
          loading: false,
        });
      });
  };

  const submit = (e) => {
    e.preventDefault();

    e.target.querySelector('button[type="submit"]').blur();

    setState({ feedbackMessage: '', loading: true, deleteError: false });
    if (validate()) {
      send();
    }

    return false;
  };

  const enableDisableAll = (e, flag) => {
    e.target.blur();

    let fn, loadingStateName;
    if (flag) {
      fn = API.RESELLERS.INTEGRATIONS.BUILDING_MONITORING.USERS.ENABLE_ALL;
      loadingStateName = 'loadingEnableAll';
    } else {
      fn = API.RESELLERS.INTEGRATIONS.BUILDING_MONITORING.USERS.DISABLE_ALL;
      loadingStateName = 'loadingDisableAll';
    }
    setState({ [loadingStateName]: true, feedbackMessage: '' });

    fn(state.resellerId)
      .then((response) => {
        setState({
          feedbackMessage: (
            <Text
              bold={true}
              highlight={true}
              text={`All users ${flag ? 'enabled' : 'disabled'}.`}
            />
          ),
          [loadingStateName]: false,
          update: state.update + 1,
        });
      })
      .catch((error) => {
        console.error(error);

        setState({
          feedbackMessage: (
            <ErrorMessage message="Users could not be enabled. Please try again later or contact support." />
          ),
          [loadingStateName]: false,
        });
      });
  };

  const backFromDelete = () => {
    setState({
      feedbackMessage: '',
      deleteError: false,
      deleting: false,
      userToDelete: '',
    });
  };

  const confirmDelete = (user) => {
    setState({
      feedbackMessage: '',
      deleteError: false,
      deleting: true,
      userToDelete: user,
    });
  };

  const handleDelete = () => {
    setState({ loading: true });
    API.RESELLERS.INTEGRATIONS.BUILDING_MONITORING.USERS.DELETE(
      state.resellerId,
      state.userToDelete.username
    )
      .then((response) => {
        setState({
          userToDelete: '',
          feedbackMessage: (
            <Text
              bold={true}
              highlight={true}
              text={`User ${state.userToDelete.username} deleted successfully.`}
            />
          ),
          deleting: false,
          deleteError: false,
          update: state.update + 1,
        });
      })
      .catch((error) => {
        setState({
          userToDelete: '',
          deleting: false,
          deleteError: true,
        });
      });
  };

  return (
    <section id="page-resellers-building-monitoring">
      <Title element="h4" text="Resellers - Building Monitoring" />

      {state.feedbackMessage ? state.feedbackMessage : null}

      {state.deleteError ? (
        <ErrorMessage message="Could not delete the user. Please, try again or contact support." />
      ) : null}

      {state.deleting ? (
        <div>
          <div>
            <Text
              bold={true}
              inline={true}
              text={`Are you sure you want to delete user`}
            />
            <Text
              inline={true}
              highlight={true}
              text={` '${state.userToDelete.username}' `}
            />
            <Text bold={true} inline={true} text={`?`} />
          </div>

          <div className="btn-actions">
            <Button label="Back" loading={false} click={backFromDelete} />
            <Button label="Delete!" click={handleDelete} />
          </div>
        </div>
      ) : null}

      {!state.deleting ? (
        <div>
          <form action="#" onSubmit={submit}>
            <div>
              <Input
                change={change}
                disabled={state.loading}
                hasError={state.fieldsWithError.indexOf('email') >= 0}
                name="email"
                placeholder="E-mail"
                type="text"
                value={state.email}
              />
            </div>
            <div className="btn-actions">
              <Button
                disabled={state.loadingDisableAll || state.loadingEnableAll}
                label="Add"
                loading={state.loading}
                type="submit"
              />
            </div>
          </form>

          <div className="enable-disable-btns">
            <Button
              click={(e) => enableDisableAll(e, true)}
              disabled={state.loading || state.loadingDisableAll}
              label="Enable all"
              loading={state.loadingEnableAll}
              type="button"
            />
            <Button
              click={(e) => enableDisableAll(e, false)}
              disabled={state.loading || state.loadingEnableAll}
              label="Disable all"
              loading={state.loadingDisableAll}
              type="button"
            />
          </div>

          <Table columns={columns} data={data} isLoading={state.loading} />
        </div>
      ) : null}
    </section>
  );
}

export default ResellersBuildingMonitoring;
