import { Link } from 'react-router-dom';

import { encrypt } from '../../components/utils';
import { INTEGRATIONS } from '../../components/tpi';

import Button from '../../elements/button';
import Text from '../../elements/text';

function BrowseIntegrationInfo(props) {
  const { permissions } = props.integration;
  const thirtyMinutesDisagEnabled =
    permissions &&
    permissions.length &&
    permissions[0] ===
      INTEGRATIONS.RESELLER_API_PERMS.thirtyMinutesDisaggregation;

  const isDev = process.env.NODE_ENV === 'development' || window?.location?.href?.includes('test-');
  const url = `https://${isDev ? 'test-' : ''}reseller-web.voltaware.com/login?x=${encrypt(props.integration.userName)}&y=${encrypt(props.integration.password)}`;

  return (
    <div>
      <h6>
        {props.title}
        {props.link ? (
          <Link {...props.link}>
            <Button action="edit" />
          </Link>
        ) : null}
      </h6>
      <div>
        <Text bold={true} inline={true} text="Client ID" />
        <Text bold={false} inline={true} text={props.integration.userName} />
      </div>
      <div>
        <Text bold={true} inline={true} text="Client Secret" />
        <Text bold={false} inline={true} text={props.integration.password} />
      </div>
      <div>
        <Text bold={true} inline={true} text="30 minutes disaggregation" />
        <Text
          bold={false}
          inline={true}
          text={thirtyMinutesDisagEnabled ? 'ENABLED' : 'DISABLED'}
        />
      </div>
      <div>
        <a href={url} target='_blank' rel="noreferrer">
          <Text
            bold={true}
            inline={true}
            classes="highlight-link"
            text="Access Resellers API"
          />
        </a>
      </div>
    </div>
  );
}

export default BrowseIntegrationInfo;
