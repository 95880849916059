import { useState, useEffect, useRef } from 'react';

import Button from '../button';
import InputAutoComplete from './input-auto-complete';
import './form.sass';
import './input.sass';

const onAutoFillStart = (el) => el.classList.add('deactive');
const onAnimationStart = ({ target, animationName }) => {
  switch (animationName) {
    case 'onAutoFillStart':
      return onAutoFillStart(target.previousElementSibling);
    default:
      return;
  }
};

function Input(props) {
  const inputRef = useRef(null);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.addEventListener(
        'animationstart',
        onAnimationStart,
        false
      );
    }
  }, []);

  useEffect(() => {
    if (props.disabled) {
      handleBlur();
    }
  }, [props.disabled]);

  const focusInput = () => {
    inputRef.current.focus();
  };

  const handleFocus = () => {
    setIsActive(true);
  };

  const handleBlur = () => {
    setIsActive(false);
  };

  let spanClass = 'placeholder';
  if (isActive) {
    spanClass += ' active';
  } else if (props.value !== '' || props.selected) {
    spanClass += ' deactive';
  }

  let value = props.value;
  if (value === undefined) {
    value = '';
  }

  return (
    <label className="input-field">
      <span className={spanClass}>{props.placeholder}</span>
      <input
        autoComplete={
          props.autocomplete !== undefined ? props.autocomplete : 'on'
        }
        className={props.hasError ? 'error' : ''}
        disabled={props.disabled}
        max={
          (props.type === 'number' && props.max !== undefined && props.max) ||
          undefined
        }
        maxLength={props.maxLength || ''}
        min={
          (props.type === 'number' && props.min !== undefined && props.min) ||
          undefined
        }
        name={props.name}
        onChange={props.change}
        onInput={props.input}
        onFocus={handleFocus}
        onBlur={handleBlur}
        ref={inputRef}
        step={
          (props.type === 'number' && props.step !== undefined && props.step) ||
          undefined
        }
        type={props.type}
        value={value}
        autoFocus={props.autoFocus}
      />
      <span className="border" />

      {props.tip ? <p className="input-tip">{props.tip}</p> : null}

      {props.type === 'search' ? (
        <Button
          action="search"
          click={focusInput}
          disabled={props.disabled}
          loading={props.loading}
          type="submit"
        />
      ) : null}

      {props.type === 'autoComplete' && props.data ? (
        <InputAutoComplete
          data={props.data}
          dataKey={props.dataKey}
          dataLabel={props.dataLabel}
          loading={props.loading}
          name={props.name}
          select={props.select}
          showing={isActive}
        />
      ) : null}
    </label>
  );
}

export default Input;
