import Button from '../../../elements/button';
import Title from '../../../elements/title';
import Text from '../../../elements/text';
import ErrorMessage from '../../../elements/error-message';

import { MODES } from './index';

const UNBLOCK_MESSAGES = {
  buttonLabel: 'Unblock',
  pastLabel: 'unblocked',
  infinitiveLabel: 'unblock',
};
const BLOCK_MESSAGES = {
  buttonLabel: 'Block',
  pastLabel: 'blocked',
  infinitiveLabel: 'block',
};

function StockBlockConfirm(props) {
  const MESSAGES =
    props.mode === MODES.unblock ? UNBLOCK_MESSAGES : BLOCK_MESSAGES;

  const errorMessages = () => {
    if (!props.errors) {
      return null;
    }

    const { errorMessage, stockBlockStatus } = props.errors;

    const inverseMode =
      props.mode === MODES.block ? MODES.unblock : MODES.block;
    const currentModeStocks = stockBlockStatus[`${props.mode}edSensors`];
    const inverseModeStocks = stockBlockStatus[`${inverseMode}edSensors`];

    return (
      <div className="errors">
        {errorMessage ? <ErrorMessage message={errorMessage} /> : null}
        {stockBlockStatus && currentModeStocks && currentModeStocks.length ? (
          <ErrorMessage
            message={`These stocks are already ${
              props.mode
            }ed: ${currentModeStocks.join(', ')}`}
          />
        ) : null}

        {stockBlockStatus && inverseModeStocks && inverseModeStocks.length ? (
          <ErrorMessage
            message={`These stocks are ${inverseMode}ed: ${inverseModeStocks.join(
              ', '
            )}`}
          />
        ) : null}
      </div>
    );
  };

  return (
    <section id="page-stock-block-step-confirm">
      <Title
        element="h6"
        text={`Please, review the device IDs to be ${MESSAGES.pastLabel} and press ${MESSAGES.buttonLabel} if they are correct.`}
      />

      <div className="stock-ids-info">
        <Text
          bold={true}
          inline={true}
          text={`Devices IDs to ${MESSAGES.infinitiveLabel}:`}
        />
        <Text inline={true} text={props.stock} />
      </div>

      {errorMessages()}

      <div className="btn-actions">
        <Button
          click={(e) => props.back(e, 1)}
          disabled={props.disabled}
          label="Back"
          loading={false}
        />
        <Button
          click={props.submit}
          label={MESSAGES.buttonLabel}
          loading={props.disabled}
        />
      </div>
    </section>
  );
}

export default StockBlockConfirm;
