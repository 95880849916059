import { useRef, useReducer, useEffect } from 'react';
import { Link } from 'react-router-dom';

import API from '../../../components/api';

import Button from '../../../elements/button';
import Loading from '../../../elements/loading';
import Text from '../../../elements/text';
import Title from '../../../elements/title';

function PasswordGenerate(props) {
  const reducer = (state, action) => {
    switch (action.type) {
      case 'startLoading':
        return { ...state, loading: true, isTimeRunning: false };
      case 'endLoading':
        return { ...state, loading: false, isTimeRunning: false };
      case 'startTimer':
        return {
          ...state,
          loading: false,
          password: action.payload.password,
          timeActive: action.payload.timeActive,
          isTimeRunning: true,
        };
      case 'subtractTimer':
        return { ...state, timeActive: state.timeActive - 1000 };
      case 'deleteTimer':
        return { ...state, loading: false, password: '', timeActive: '' };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, {
    loading: true,
    password: '',
    timeActive: 0,
    isTimeRunning: false,
  });
  const timer = useRef(null);

  useEffect(() => {
    generate();
    return () => clearInterval(timer.current);
  }, []);

  const tick = () => {
    timer.current = setInterval(() => {
      let timeActive = state.timeActive - 1000;
      dispatch({ type: 'subtractTimer' });

      if (timeActive <= 0) {
        clearInterval(timer.current);
      }
    }, 1000);
  };

  const getTimeInMs = (time) => {
    let ms = 0,
      split = time.split(':');
    ms += parseInt(split[0], 0) * 60 * 1000;
    ms += parseInt(split[1], 0) * 1000;
    return ms;
  };

  const getTimeFormatted = () => {
    let seconds, minutes;
    seconds = Math.floor(state.timeActive / 1000);
    minutes = Math.floor(seconds / 60);
    seconds = seconds % 60;
    minutes = minutes % 60;
    seconds = seconds < 10 ? `0${seconds}` : seconds;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${minutes}:${seconds}`;
  };

  const generate = (e) => {
    if (e) e.preventDefault();

    dispatch({ type: 'startLoading' });

    API.ACCOUNTS.TEMPORARY_PASSWORD.GENERATE(props.id, props.integratorId)
      .then((response) => {
        dispatch({
          type: 'startTimer',
          payload: {
            password: response.data.password,
            timeActive: getTimeInMs(response.data.expiration),
          },
        });
      })
      .catch((error) => {
        console.error(error);
        dispatch({ type: 'endLoading' });
      });
  };

  useEffect(() => {
    if (state.isTimeRunning === true) {
      tick();
    }
  }, [state.isTimeRunning]);

  const endTimer = (e) => {
    e.preventDefault();

    clearInterval(timer.current);
    dispatch({ type: 'startLoading' });

    API.ACCOUNTS.TEMPORARY_PASSWORD.DELETE(props.id, props.integratorId)
      .then((response) => {
        dispatch({ type: 'deleteTimer' });
      })
      .catch((error) => {
        console.error(error);
        dispatch({ type: 'endLoading' });
      });
  };

  const { id } = props;

  return (
    <div id="generate-password">
      <Title element="h6" text="Temporary Password" />

      {state.loading ? (
        <Loading />
      ) : (
        <div>
          {state.timeActive > 0 ? (
            <div>
              <div>
                <Text
                  inline={true}
                  text={`The temporary password for device ${id} is:`}
                />
                <Text highlight={true} inline={true} text={state.password} />
              </div>
              <div>
                <Text inline={true} text="This password will be active for" />
                <Text
                  highlight={true}
                  inline={true}
                  text={getTimeFormatted()}
                />
                <Text inline={true} text="mins" />
              </div>
            </div>
          ) : (
            <div>
              <Text
                inline={true}
                text="There is no temporary password enabled for this device."
              />
            </div>
          )}
          <div className="btn-actions">
            <Link
              to={{
                pathname: props.isSmartMeter
                  ? `/smart-meter/${props.integratorId}/${id}/admintools`
                  : `/sensor/${id}/admintools`,
                state: 'admintools',
              }}
            >
              <Button
                disabled={state.loading}
                label="Back to menu"
                loading={false}
              />
            </Link>
            {state.timeActive > 0 ? (
              <Button
                action="cancel"
                click={endTimer}
                disabled={state.loading}
                label="End timer"
              />
            ) : (
              <Button
                click={generate}
                disabled={state.loading}
                label="Generate password"
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default PasswordGenerate;
