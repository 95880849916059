import { useReducer, useRef, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import FileDownload from 'react-file-download';
import moment from 'moment';

import { ORDER_SENSOR_OPTIONS } from '../../../components/constants';
import { ORDER_SENSOR } from '../../../components/constants';
import { REMOVE_SENSOR_FROM_ORDER_MESSAGE } from '../../../components/constants';

import API from '../../../components/api';
import { COUNTRIES } from '../../../components/country';
import { CURRENCIES_OPTIONS } from '../../../components/constants';
import Button from '../../../elements/button';
import ErrorMessage from '../../../elements/error-message';
import Input from '../../../elements/forms/input';
import InputFile from '../../../elements/forms/input-file';
import Loading from '../../../elements/loading';
import Text from '../../../elements/text';
import Textarea from '../../../elements/forms/textarea';
import Title from '../../../elements/title';
import Select from '../../../elements/forms/select';
import InputSelect from '../../../elements/forms/input-select';
import SelectLocaleTimezone from '../../../elements/forms/select-locale-timezone';

import './add.sass';

const currencyOptions = CURRENCIES_OPTIONS.map((currency) => {
  let data = { ...currency };
  data.label += '/u';
  return data;
});

function SubscriptionOrderAdd() {
  const location = useLocation();
  const history = useHistory();

  let feedbackMessage =
    location && location.state && location.state.feedbackMessage ? (
      <Text
        bold={true}
        highlight={true}
        text={location.state.feedbackMessage}
      />
    ) : (
      ''
    );
  const orderId =
    (location &&
      location.state &&
      location.state.order &&
      location.state.order.id) ||
    null;

  const initialState = {
    sensorEditMode: '',
    attachs: [],
    countriesOptions: [],
    countryCode: '',
    timeZoneId: '',
    isoLocaleCode: '',
    currency: '',
    errors: undefined,
    feedbackMessage: feedbackMessage,
    sensorsFeedbackMessage: '',
    fieldsWithError: [],
    file: '',
    hardwareUnitPrice: '',
    id: '',
    loading: false,
    loadingAdd: false,
    loadingAttach: false,
    loadingAttachAdd: false,
    loadingCountries: true,
    loadingDelete: false,
    loadingReseller: false,
    loadingSubscription: false,
    mode: 'add',
    notes: '',
    resellerId: '',
    resellerName: '',
    resellers: [],
    sensorIds: '',
    sensorIdsToManage: '',
    startDate: '',
    subscriptionId: '',
    subscriptionName: '',
    subscriptions: [],
  };

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    orderId
      ? {
          ...initialState,
          id: orderId,
          loading: true,
          loadingAttach: true,
          mode: 'loading',
          sensorEditMode: ORDER_SENSOR.ADD,
        }
      : initialState
  );

  const isMountedRef = useRef(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    isMountedRef.current = true;
    if (orderId) {
      getOrderData();
    }

    const message = (
      <ErrorMessage message="Could not load countries. Please, try again or contact support." />
    );

    API.COUNTRY.SUMMARY()
      .then((response) => {
        if (!isMountedRef.current) {
          return;
        }

        if (response.status === 200) {
          setState({
            countriesOptions: COUNTRIES.GET_OPTIONS(response.data),
            loadingCountries: false,
          });
        } else {
          setState({ feedbackMessage: message, loadingCountries: false });
          console.error('Error while loading countries.');
        }
      })
      .catch((response) => {
        setState({ feedbackMessage: message, loadingCountries: false });
        console.error('Error while loading countries.');
      });

    return () => (isMountedRef.current = false);
  }, []);

  const getOrderData = () => {
    API.SUBSCRIPTION.ORDER.DETAILS(state.id)
      .then((response) => {
        if (!isMountedRef.current) {
          return;
        }

        const { data } = response;
        setState({
          currency: data.subscription.currency,
          hardwareUnitPrice: data.hardwareUnitPrice,
          mode: 'edit',
          notes: data.notes,
          resellerId: data.reseller.id,
          resellerName: data.reseller.name,
          sensorIds: data.sensorIds,
          startDate: moment(data.startDate).format('DD/MM/YYYY'),
          subscriptionId: data.subscription.id,
          subscriptionName: data.subscription.name,
        });

        getAttachs();
      })
      .catch((error) => {
        console.error(error);
        setState({
          feedbackMessage: (
            <ErrorMessage message="Could not load the order. Please, try again or contact support." />
          ),
          loading: false,
          mode: 'loading-error',
        });
      });
  };

  const change = (e) => {
    setState({ [e.target.name]: e.target.value });
  };

  const changeSubscription = (e) => {
    setState({
      subscriptionName: e.target.value,
    });
  };

  useEffect(() => {
    if (state.subscriptionName === '') {
      setState({
        subscriptionId: '',
        subscriptions: [],
      });
    } else if (
      state.subscriptionName.length > 0 &&
      state.subscriptionName.length < 30
    ) {
      let searchBy;
      if (isNumber(state.subscriptionName)) {
        searchBy = 'ID';
      } else {
        searchBy = 'NAME';
      }

      API.SUBSCRIPTION.LIST(state.subscriptionName, searchBy)
        .then((response) => {
          if (!isMountedRef.current) {
            return;
          }
          let subscriptions = response.data.subscriptions.map((item) => {
            return { id: item.id, name: item.name };
          });

          const matchIndex = subscriptions.findIndex(
            (subscription) => subscription.name === state.subscriptionName
          );
          if (matchIndex >= 0) {
            subscriptions = [];
          }

          setState({ subscriptions: subscriptions });
        })
        .catch((error) => {
          console.error(error);
          setState({
            subscriptions: [],
          });
        });
    } else {
      setState({ subscriptions: [] });
    }
  }, [state.subscriptionName]);

  const selectSubscription = (e) => {
    e.preventDefault();
    setState({
      subscriptionName: e.target.name,
      subscriptionId: e.target.id,
      subscriptions: [],
    });
  };

  const changeReseller = (e) => {
    setState({
      resellerName: e.target.value,
    });
  };

  useEffect(() => {
    if (state.resellerName === '') {
      setState({
        resellerId: '',
        resellers: [],
      });
    } else if (
      state.resellerName.length > 0 &&
      state.resellerName.length < 30
    ) {
      let searchBy;
      if (isNumber(state.resellerName)) {
        searchBy = 'ID';
      } else {
        searchBy = 'NAME';
      }

      API.RESELLERS.SEARCH(state.resellerName, searchBy)
        .then((response) => {
          if (!isMountedRef.current) {
            return;
          }

          let resellers = response.data.map((item) => {
            return { id: item.id, name: item.name };
          });

          const matchIndex = resellers.findIndex(
            (reseller) => reseller.name === state.resellerName
          );
          if (matchIndex >= 0) {
            resellers = [];
          }

          setState({ resellers: resellers });
        })
        .catch((error) => {
          console.error(error);
          setState({
            resellers: [],
          });
        });
    } else {
      setState({ resellers: [] });
    }
  }, [state.resellerName]);

  const selectReseller = (e) => {
    e.preventDefault();
    setState({
      resellerName: e.target.name,
      resellerId: e.target.id,
      resellers: [],
    });
  };

  const validate = () => {
    let fieldsWithError = [];
    let isAddingSensors = state.sensorEditMode === ORDER_SENSOR.ADD;
    const regex = /^[0-9]+(?:[-,]?[0-9]+)/,
      sensorIds = state.sensorIds.replace(/\s+/g, ''),
      sensorIdsToManage = state.sensorIdsToManage.replace(/\s+/g, '');

    if (state.subscriptionId === '') fieldsWithError.push('subscription');
    if (state.resellerId === '') fieldsWithError.push('reseller');
    if (state.hardwareUnitPrice === '' || state.hardwareUnitPrice < 0)
      fieldsWithError.push('hardwareUnitPrice');
    if (sensorIds === '' || !regex.test(sensorIds))
      fieldsWithError.push('sensorIds');
    if (sensorIdsToManage !== '' && !regex.test(sensorIdsToManage))
      fieldsWithError.push('sensorIdsToManage');

    if (!state.id || (sensorIdsToManage.length > 0 && isAddingSensors)) {
      if (state.countryCode === '') fieldsWithError.push('countryCode');
      if (!state.isoLocaleCode) fieldsWithError.push('isoLocaleCode');
      if (!state.timeZoneId) fieldsWithError.push('timeZoneId');
    }

    setState({
      fieldsWithError: fieldsWithError,
      loading: false,
      loadingAdd: false,
    });

    return !fieldsWithError.length;
  };

  const resetLocaleTimezone = () => {
    setState({ isoLocaleCode: '', timeZoneId: '' });
  };

  const goToDelete = () => setState({ feedbackMessage: '', mode: 'delete' });
  const goBack = () => setState({ mode: state.id ? 'edit' : 'add' });

  const handleDelete = () => {
    setState({ loading: true, loadingDelete: true });

    API.SUBSCRIPTION.ORDER.DELETE(state.id)
      .then((response) => {
        history.push('/subscriptions/orders/add', {
          feedbackMessage: `The order ${state.id} was deleted successfully!`,
        });
      })
      .catch((error) => {
        console.error(error);

        setState({
          feedbackMessage: (
            <ErrorMessage message="There was an error deleting the order. Please, try again or contact support." />
          ),
          loading: false,
          loadingDelete: false,
        });
      });
  };

  const send = () => {
    setState({ loading: true, loadingAdd: true });

    let fn = state.id
      ? API.SUBSCRIPTION.ORDER.EDIT(state.id, state.notes)
      : API.SUBSCRIPTION.ORDER.ADD(
          state.subscriptionId,
          state.resellerId,
          state.hardwareUnitPrice,
          state.sensorIds,
          state.countryCode,
          state.isoLocaleCode,
          state.timeZoneId,
          state.notes
        );

    fn.then((response) => {
      let message = `The order ${response.data.id} was ${
        state.id ? 'edited' : 'created'
      } successfully!`;

      !state.id
        ? history.push(`/subscriptions/orders/edit`, {
            order: { id: response.data.id },
            feedbackMessage: message,
          })
        : setState({
            sensorsFeedbackMessage: '',
            feedbackMessage: (
              <Text
                name="feedbackMessage"
                bold={true}
                highlight={true}
                text={message}
              />
            ),
            id: response.data.id,
            loading: false,
            loadingAdd: false,
          });
    }).catch((error) => {
      let feedbackMessage =
        'There was an error saving the order. Please, try again or contact support.';
      let errors = catchErrors(error);

      if (
        error.response.status === 422 &&
        errors !== undefined &&
        Array.isArray(errors) &&
        errors.length > 0
      )
        feedbackMessage = '';

      setState({
        sensorsFeedbackMessage: '',
        errors: errors,
        feedbackMessage: <ErrorMessage message={feedbackMessage} />,
        loading: false,
        loadingAdd: false,
      });
    });
  };

  const saveSensors = (e) => {
    e.preventDefault();
    document.activeElement.blur();

    if (validate()) {
      if (state.sensorEditMode === ORDER_SENSOR.ADD) addSensors();

      if (state.sensorEditMode === ORDER_SENSOR.REMOVE) removeSensors();
    }
  };

  const changeSensorEditMode = (e) => {
    let value = e.target.value;
    setState({ sensorEditMode: value });
  };

  const addSensors = () => {
    setState({ loadingAddSensors: true });

    API.SUBSCRIPTION.ORDER.SENSORS.ADD(
      state.id,
      state.sensorIdsToManage,
      state.countryCode,
      state.isoLocaleCode,
      state.timeZoneId
    )
      .then((response) => {
        const message = `The sensors were added successfully`;

        setState({
          errors: null,
          feedbackMessage: '',
          sensorsFeedbackMessage: (
            <Text bold={true} highlight={true} text={message} />
          ),
          loadingAddSensors: false,
        });

        getOrderData();
        clearSensorRelatedFields();
      })
      .catch((error) => {
        console.error(error);

        let errors = catchErrors(error);

        setState({
          errors: errors,
          feedbackMessage: '',
          sensorsFeedbackMessage: (
            <ErrorMessage message="There was an error while adding sensors. Please, try again or contact support." />
          ),
          loadingAddSensors: false,
        });
      });
  };

  const removeSensors = () => {
    setState({ loadingRemoveSensors: true });

    API.SUBSCRIPTION.ORDER.SENSORS.REMOVE(state.id, state.sensorIdsToManage)
      .then((response) => {
        let message = `The sensors were removed successfully`;

        setState({
          errors: null,
          feedbackMessage: '',
          sensorsFeedbackMessage: (
            <Text bold={true} highlight={true} text={message} />
          ),
          loadingRemoveSensors: false,
        });

        getOrderData();
        clearSensorRelatedFields();
      })
      .catch((error) => {
        console.error(error);

        let errors = catchErrors(error);

        setState({
          errors: errors,
          feedbackMessage: '',
          sensorsFeedbackMessage: (
            <ErrorMessage message="There was an error while removing sensors. Please, try again or contact support." />
          ),
          loadingRemoveSensors: false,
        });
      });
  };

  const clearSensorRelatedFields = () => {
    setState({
      sensorIdsToManage: '',
      countryCode: '',
    });

    resetLocaleTimezone();
  };

  const downloadAttach = (attach) => {
    attach.loading = true;
    setState({ loading: true });

    API.SUBSCRIPTION.ORDER.ATTACH.GET(state.id, attach.id)
      .then((response) => {
        attach.loading = false;
        setState({ loading: false });
        FileDownload(response.data, attach.filename);
      })
      .catch((error) => {
        console.error(error);
        attach.loading = false;
        setState({
          feedbackMessage: (
            <ErrorMessage message="There was an error download this file. Please, try again or contact support." />
          ),
          loading: false,
        });
      });
  };

  const getAttachs = () => {
    setState({ loading: true, loadingAttach: true });

    API.SUBSCRIPTION.ORDER.ATTACH.LIST(state.id)
      .then((response) => {
        if (!isMountedRef.current) {
          return;
        }

        setState({
          attachs: response.data,
          loading: false,
          loadingAttach: false,
        });
      })
      .catch((error) => {
        console.error(error);
        setState({
          feedbackMessage: (
            <ErrorMessage message="There was an error loading the attachments. Please, try again or contact support." />
          ),
          loading: false,
          loadingAttach: false,
        });
      });
  };

  const sendAttach = () => {
    setState({ loading: true, loadingAttachAdd: true });

    let data = new FormData();
    data.append('file', fileInputRef.current.files[0]);

    API.SUBSCRIPTION.ORDER.ATTACH.ADD(state.id, data)
      .then((response) => {
        setState({
          feedbackMessage: (
            <Text
              bold={true}
              highlight={true}
              text="Attachment was uploaded successfully."
            />
          ),
          loading: false,
          loadingAttachAdd: false,
        });
        getAttachs();
      })
      .catch((error) => {
        console.error(error);
        setState({
          feedbackMessage: (
            <ErrorMessage message="There was an error uploading the file. Please, try again or contact support." />
          ),
          loading: false,
          loadingAttachAdd: false,
        });
      });
  };

  const catchErrors = (error) => {
    let errors = undefined;

    if (!error.response) return errors;

    if (error.response.status === 400 || error.response.status === 500)
      return error.response.data;

    if (error.response.status === 422 && error.response.data) {
      if (Array.isArray(error.response.data.errors))
        errors = error.response.data.errors;
      else if (typeof error.response.data.errors === 'string')
        errors = [error.response.data.errors];

      if (
        error.response.data.message !== undefined &&
        error.response.data.message.length > 0
      ) {
        if (errors === undefined) errors = [error.response.data.message];
        else errors.unshift(error.response.data.message);
      }
    }

    return errors;
  };

  const submit = (e) => {
    e.preventDefault();
    e.target.querySelector('button[type="submit"]').blur();

    setState({ feedbackMessage: '', loading: true, loadingAdd: true });

    if (validate()) {
      send();
    }

    return false;
  };

  const isNumber = (value) => {
    return /^-?\d+$/.test(value);
  };

  return (
    <section id="page-subscription-order-add">
      <Title
        element="h4"
        text={`Orders - ${
          state.mode !== 'delete' ? (state.id ? 'Edit' : 'Add') : 'Delete'
        }`}
      />

      {state.feedbackMessage ? state.feedbackMessage : null}
      {state.sensorsFeedbackMessage ? state.sensorsFeedbackMessage : null}

      {typeof state.errors !== 'undefined' ? (
        <div className="errors">
          {Array.isArray(state.errors)
            ? state.errors.map((error, index) => (
                <ErrorMessage key={index} message={error} />
              ))
            : (() => {
                let messages = [];
                const { errors } = state;

                if (errors && errors.inStock && errors.inStock.length)
                  messages.push(
                    <Text
                      highlight={true}
                      key={0}
                      text={`IDs ${errors.inStock.join(', ')} status: IN STOCK`}
                    />
                  );

                if (errors && errors.notFound && errors.notFound.length)
                  messages.push(
                    <ErrorMessage
                      key={1}
                      message={`IDs ${errors.notFound.join(
                        ', '
                      )} status: NOT FOUND`}
                    />
                  );

                if (errors && errors.allocated && errors.allocated.length)
                  messages.push(
                    <ErrorMessage
                      key={2}
                      message={`IDs ${errors.allocated.join(
                        ', '
                      )} status: ALLOCATED`}
                    />
                  );

                if (errors && errors.active && errors.active.length)
                  messages.push(
                    <ErrorMessage
                      key={3}
                      message={`IDs ${errors.active.join(', ')} status: ACTIVE`}
                    />
                  );

                return messages;
              })()}
        </div>
      ) : null}

      {state.mode === 'loading' ? <Loading /> : null}
      {state.mode === 'add' || state.mode === 'edit' ? (
        <div>
          <Text
            bold={true}
            text={`Please fill all the information to ${
              state.mode === 'edit' ? 'edit the' : 'add a new'
            } order.`}
          />

          <div>
            <Input
              autocomplete="off"
              change={changeSubscription}
              data={state.subscriptions}
              disabled={state.loading || state.id}
              hasError={state.fieldsWithError.indexOf('subscription') >= 0}
              loading={state.loadingSubscription}
              name="subscription"
              placeholder="Subscription ID or name"
              select={selectSubscription}
              type="autoComplete"
              value={state.subscriptionName}
            />
          </div>

          <div>
            <Input
              autocomplete="off"
              change={changeReseller}
              data={state.resellers}
              disabled={state.loading || state.id}
              hasError={state.fieldsWithError.indexOf('reseller') >= 0}
              name="reseller"
              placeholder="Reseller ID or name"
              select={selectReseller}
              type="autoComplete"
              value={state.resellerName}
            />
          </div>

          <div className="forms-wrapper">
            <form action="#" onSubmit={submit}>
              <div>
                <div className="payment-currency-wrapper">
                  <Input
                    change={change}
                    disabled={state.loading || state.id}
                    hasError={
                      state.fieldsWithError.indexOf('hardwareUnitPrice') >= 0
                    }
                    name="hardwareUnitPrice"
                    placeholder="Hardware price"
                    type="number"
                    value={state.hardwareUnitPrice}
                  />

                  <Select
                    disabled={true}
                    options={currencyOptions}
                    value={state.currency}
                  />
                </div>

                <Textarea
                  change={change}
                  disabled={state.loading}
                  name="notes"
                  placeholder="Notes"
                  value={state.notes}
                />
                {state.id ? (
                  <InputFile
                    change={sendAttach}
                    disabled={state.loading}
                    hasError={state.fieldsWithError.indexOf('file') >= 0}
                    loading={state.loadingAttachAdd}
                    name="file"
                    placeholder="Invoice/Contract"
                    ref={fileInputRef}
                    value={state.file}
                  />
                ) : (
                  <Text text="You'll be able to attach invoices here after creating the order." />
                )}

                {state.loadingAttach ? (
                  <Loading width={20} />
                ) : (
                  state.attachs.map((attach) => (
                    <Button
                      className={`unstyled${attach.loading ? ' loading' : ''}`}
                      click={() => downloadAttach(attach)}
                      key={attach.id}
                      label={attach.filename}
                    />
                  ))
                )}

                <div className="btn-actions">
                  <Button
                    disabled={state.loading}
                    label={`${state.id ? 'Save' : 'Add'} order`}
                    loading={state.loadingAdd}
                    type="submit"
                  />
                  {state.id ? (
                    <Button
                      action="cancel"
                      click={goToDelete}
                      disabled={state.loading}
                      label="Delete"
                      loading={false}
                    />
                  ) : null}
                </div>
              </div>
            </form>
            <form onSubmit={saveSensors}>
              <div className="negative-top">
                {state.startDate ? (
                  <Text
                    classes="start-date"
                    text={`Start date: ${state.startDate}`}
                  />
                ) : null}

                <Text text="Accepted formats of input:" />
                <Text inline={true} text="1) 785" />
                <Text inline={true} text="2) 456, 459" />
                <Text inline={true} text="3) 620-654" />

                <Input
                  change={change}
                  disabled={state.loading || state.id}
                  hasError={state.fieldsWithError.indexOf('sensorIds') >= 0}
                  name="sensorIds"
                  placeholder={state.id ? 'Current Devices IDs' : 'Devices IDs'}
                  value={state.sensorIds}
                />

                {state.id ? (
                  <>
                    <InputSelect
                      change={changeSensorEditMode}
                      checked={state.sensorEditMode}
                      disabled={state.loading}
                      options={ORDER_SENSOR_OPTIONS}
                      name="sensorEditOption"
                      placeholder="Select edit option"
                      type="checkbox"
                    />

                    {state.sensorEditMode === ORDER_SENSOR.REMOVE && (
                      <Text
                        bold={true}
                        warning={true}
                        text={REMOVE_SENSOR_FROM_ORDER_MESSAGE}
                      />
                    )}

                    <Input
                      change={change}
                      disabled={state.loading}
                      hasError={
                        state.fieldsWithError.indexOf('sensorIdsToManage') >= 0
                      }
                      name="sensorIdsToManage"
                      placeholder={'Devices IDs to'.concat(
                        `${
                          state.sensorEditMode === ORDER_SENSOR.ADD
                            ? ' add'
                            : ' remove'
                        }`
                      )}
                      value={state.sensorIdsToManage}
                    />
                  </>
                ) : null}

                {!state.id ||
                (state.sensorEditMode === ORDER_SENSOR.ADD &&
                  state.sensorIdsToManage.length !== 0) ? (
                  <>
                    <Select
                      change={change}
                      disabled={state.loading || state.loadingCountries}
                      hasError={
                        state.fieldsWithError.indexOf('countryCode') >= 0
                      }
                      name="countryCode"
                      options={state.countriesOptions}
                      placeholder="Allocation country"
                      value={state.countryCode}
                    />

                    <SelectLocaleTimezone
                      isoCountryCode={state.countryCode}
                      timeZoneId={state.timeZoneId}
                      isoLocaleCode={state.isoLocaleCode}
                      change={change}
                      disabled={
                        !state.countriesOptions ||
                        !state.countriesOptions.length
                      }
                      fieldsWithError={state.fieldsWithError}
                      resetLocaleTimezone={resetLocaleTimezone}
                    />
                  </>
                ) : null}

                {state.id ? (
                  <>
                    <Button
                      disabled={state.loading}
                      label={`${
                        state.sensorEditMode === ORDER_SENSOR.ADD
                          ? ' Add'
                          : ' Remove'
                      }`.concat(' sensors')}
                      loading={
                        state.loadingRemoveSensors || state.loadingAddSensors
                      }
                      click={(e) => saveSensors(e)}
                      type="submit"
                    />
                  </>
                ) : null}
              </div>
            </form>
          </div>
        </div>
      ) : null}

      {state.mode === 'delete' ? (
        <div>
          <Text
            bold={true}
            warning={true}
            text={`The sensor(s) will be deallocated from reseller and all the data will be lost.`}
          />
          <Text
            bold={true}
            text={`Are you sure to delete order ${state.id}?`}
          />
          <div className="btn-actions">
            <Button
              click={goBack}
              disabled={state.loading}
              label="Back"
              loading={false}
              type="submit"
            />
            <Button
              action="cancel"
              click={handleDelete}
              disabled={state.loading}
              label="Delete!"
              loading={state.loadingDelete}
            />
          </div>
        </div>
      ) : null}
    </section>
  );
}

export default SubscriptionOrderAdd;
