import { useReducer } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

import API from '../../components/api';
import {
  CATEGORIES_OPTIONS,
  CLAMP_SIZES_OPTIONS,
  CLAMP_SIZES_OPTIONS_3_PHASES,
  PHASES_OPTIONS,
} from '../../components/constants';

import Button from '../../elements/button';
import ErrorMessage from '../../elements/error-message';
import InputDate from '../../elements/forms/input-date';
import Text from '../../elements/text';
import Title from '../../elements/title';
import Select from '../../elements/forms/select';

import './edit.sass';

function StockEdit() {
  const location = useLocation();
  const { stock } = location.state.stock;

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      category: stock.category,
      clampSize: stock.clampSize,
      feedbackMessage: '',
      fieldsWithError: [],
      loading: false,
      phases: `${stock.phases}`,
      productionDate: moment(stock.productionDate).toDate(),
      sensorId: stock.sensorId,
      status: stock.status,
    }
  );

  const change = (e) => {
    if (e.target.name === 'phases') {
      setState({ [e.target.name]: e.target.value, clampSize: '' });
    } else {
      setState({ [e.target.name]: e.target.value });
    }
  };

  const changeDate = (value) => {
    setState({ productionDate: value });
  };

  const validate = () => {
    if (state.sensorId === '') {
      setState({ loading: false });
      return false;
    }
    let fieldsWithError = [];

    if (state.category === '') fieldsWithError.push('category');
    if (
      state.clampSize === '' ||
      CLAMP_SIZES_OPTIONS.indexOf(state.clampSize) < 0
    )
      fieldsWithError.push('clampSize');
    if (
      state.status === 'IN_STOCK' &&
      (state.phases === '' || PHASES_OPTIONS.indexOf(state.phases) < 0)
    )
      fieldsWithError.push('phases');
    if (state.productionDate === '') fieldsWithError.push('productionDate');

    setState({ fieldsWithError: fieldsWithError, loading: false });

    return !fieldsWithError.length;
  };

  const send = () => {
    setState({ loading: true });

    let data = {
      category: state.category,
      clampSize: state.clampSize,
      phases:
        state.status === 'IN_STOCK'
          ? state.phases
          : `${location.state.stock.stock.phases}`,
      productionDate: moment(state.productionDate).format('YYYY-MM-DD'),
    };

    API.STOCK.EDIT(state.sensorId, data)
      .then((response) => {
        setState({
          feedbackMessage: (
            <Text
              bold={true}
              highlight={true}
              text="Stock edited sucessfully."
            />
          ),
          loading: false,
        });
      })
      .catch((error) => {
        console.error(error);
        setState({
          feedbackMessage: (
            <ErrorMessage message="Could not edit stock. Please, try again later." />
          ),
          loading: false,
        });
      });
  };

  const submit = (e) => {
    e.preventDefault();

    e.target.querySelector('button[type="submit"]').blur();

    setState({ loading: true, feedbackMessage: '' });
    if (validate()) {
      send();
    }

    return false;
  };

  const clampSizeOptions =
    state.phases === PHASES_OPTIONS[1]
      ? CLAMP_SIZES_OPTIONS_3_PHASES
      : CLAMP_SIZES_OPTIONS;

  return (
    <section id="page-stock-edit">
      <Title element="h4" text="Device - Edit" />

      {state.feedbackMessage ? state.feedbackMessage : null}

      <form action="#" onSubmit={submit}>
        <div>
          <Text
            bold={true}
            text={`Choose the new infos for stock ${state.sensorId} and press Submit.`}
          />
          <Select
            change={change}
            disabled={state.loading}
            hasError={state.fieldsWithError.indexOf('category') >= 0}
            name="category"
            options={CATEGORIES_OPTIONS}
            placeholder="Category"
            value={state.category}
          />

          <InputDate
            change={changeDate}
            disabled={state.loading}
            hasError={state.fieldsWithError.indexOf('productionDate') >= 0}
            selected={state.productionDate}
            todayButton="Today"
            name="productionDate"
            placeholder="Production date"
          />

          {state.status === 'IN_STOCK' ? (
            <Select
              change={change}
              disabled={state.loading}
              hasError={state.fieldsWithError.indexOf('phases') >= 0}
              options={PHASES_OPTIONS}
              value={state.phases}
              name="phases"
              placeholder="# of phases"
            />
          ) : null}

          <Select
            change={change}
            disabled={state.loading}
            hasError={state.fieldsWithError.indexOf('clampSize') >= 0}
            options={clampSizeOptions}
            value={state.clampSize}
            name="clampSize"
            placeholder="Clamp size"
          />

          <Button disabled={state.loading} label="Submit" type="submit" />
        </div>
      </form>
    </section>
  );
}

export default StockEdit;
