import { useReducer } from 'react';

import Button from '../../../elements/button';
import ErrorMessage from '../../../elements/error-message';
import Input from '../../../elements/forms/input';
import Text from '../../../elements/text';

import { MODES } from './index';

function StockBlockIDs(props) {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      hasError: false,
    }
  );

  const actionLabel = props.mode === MODES.unblock ? 'unblock' : 'block';

  const submit = (e) => {
    const regex = /^[0-9]+(?:[-,]?[0-9]+)/,
      value = props.value.replace(/\s+/g, '');

    e.preventDefault();

    if (value === '' || !regex.test(value)) {
      setState({ hasError: true });
    } else {
      setState({ hasError: false });
      props.submit(e);
    }

    return false;
  };

  return (
    <section id="page-stock-block-ids-step">
      <form action="#" onSubmit={submit}>
        <Text
          bold={true}
          text={`Input the device ID or a range of IDs you wish to ${actionLabel} and press next.`}
        />
        <Text text="Accepted formats of input:" />
        <div className="formats-text">
          <Text inline={true} text="1) 785" />
          <Text inline={true} text="2) 456, 459" />
          <Text inline={true} text="3) 620-654" />
        </div>

        <div>
          <Input
            change={props.change}
            disabled={props.disabled}
            hasError={state.hasError}
            name="stock"
            placeholder="Type IDs here"
            type="text"
            value={props.value}
          />
        </div>

        {state.hasError ? (
          <ErrorMessage message="Please type a valid IDs format" />
        ) : null}

        <div className="btn-actions">
          <Button label="Next" loading={props.disabled} type="submit" />
        </div>
      </form>
    </section>
  );
}

export default StockBlockIDs;
