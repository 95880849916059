import { useRef, useReducer, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import FontIcon from '@material-ui/core/Icon';

import API from '../../components/api';
import { COUNTRIES } from '../../components/country';

import ErrorMessage from '../../elements/error-message';
import Loading from '../../elements/loading';
import Text from '../../elements/text';
import Title from '../../elements/title';
import Button from '../../elements/button';
import Select from '../../elements/forms/select';
import Table from '../../elements/table';

import './browse.sass';

const modes = { browse: 'browse', delete: 'delete' };

function ProviderBrowse() {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      loading: false,
      mode: modes.browse,
      providerToDelete: null,
      deleteError: false,
      deleteSuccess: false,
      providers: [],
      country: '',
      loadingCountries: true,
      countriesOptions: [],
      countriesValues: [],
      loadCountriesError: false,
    }
  );

  const isMountedRef = useRef(null);

  const columns = useMemo(
    () => [
      { id: 'edit', title: '', align: 'center' },
      { id: 'id', title: 'Provider ID', align: 'center' },
      { id: 'name', title: 'Provider Name', align: 'left' },
      { id: 'iso_country_code', title: 'Country ISO Code', align: 'center' },
      { id: 'delete', title: '', align: 'center' },
    ],
    []
  );

  const data = useMemo(() => {
    return state.providers.map((row) => {
      return {
        ...row,
        edit: (
          <Link
            to={{
              pathname: `/providers/edit`,
              state: { provider: row, editing: true },
            }}
          >
            <Button action="edit" />
          </Link>
        ),
        delete: (
          <FontIcon
            onClick={() => setDeleteMode(row)}
            className="material-icons"
          >
            {'delete'}
          </FontIcon>
        ),
      };
    });
  }, [state.providers]);

  useEffect(() => {
    isMountedRef.current = true;
    loadCountries();

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const change = (e) => {
    setState({
      [e.target.name]: e.target.value,
      deleteSuccess: false,
      deleteError: false,
    });
  };

  useEffect(() => {
    search();
  }, [state.country]);

  const setDeleteMode = (provider) => {
    setState({
      mode: modes.delete,
      providerToDelete: provider,
      deleteSuccess: false,
      deleteError: false,
    });
  };

  const setBrowseMode = (provider) => {
    setState({
      mode: modes.browse,
      providerToDelete: null,
    });
  };

  const handleDelete = () => {
    setState({ loading: true });
    API.STOCK.PROVIDER.DELETE(state.providerToDelete.id)
      .then((response) => {
        const newProviders = state.providers.filter(
          (provider) => provider.id !== state.providerToDelete.id
        );

        setState({
          loading: false,
          deleteSuccess: true,
          mode: modes.browse,
          providers: newProviders,
        });
      })
      .catch((response) => {
        setState({
          loading: false,
          deleteError: true,
          mode: modes.browse,
        });
        console.error('Error while deleting provider.');
      });
  };

  const search = (prevState, instance) => {
    if (state.country && !instance) {
      setState({ loading: true });

      API.STOCK.PROVIDER.SEARCH(state.country).then((response) => {
        if (!isMountedRef.current) {
          return;
        }

        const providers =
          response.data && response.data.length
            ? response.data.map((provider) => ({
                ...provider,
                country: provider.iso_country_code,
              }))
            : [];
        setState({ loading: false, providers });
      });
    }
  };

  const loadCountries = () => {
    API.COUNTRY.SUMMARY()
      .then((response) => {
        if (!isMountedRef.current) {
          return;
        }

        if (response.status === 200) {
          let options = COUNTRIES.GET_OPTIONS(response.data),
            values = COUNTRIES.GET_VALUES(response.data);

          setState({
            countriesOptions: options,
            countriesValues: values,
            loadingCountries: false,
          });
        } else {
          setState({ loadingCountries: false, loadCountriesError: true });
          console.error('Error while loading countries.');
        }
      })
      .catch((response) => {
        setState({ loadingCountries: false, loadCountriesError: true });
        console.error('Error while loading countries.');
      });
  };

  const submit = (e) => {
    e.preventDefault();
    e.target.querySelector('button[type="submit"]').blur();

    return false;
  };

  return (
    <section id="page-provider-browse">
      {state.mode === modes.browse ? (
        <div>
          <Title element="h4" text="Providers" />
          <Text bold={true} text={`Search for a provider by Country.`} />
          <form action="#" onSubmit={submit}>
            <div>
              {state.loadCountriesError ? (
                <ErrorMessage message="Could not load countries. Please, try again or contact support." />
              ) : null}

              <Select
                change={change}
                disabled={state.loading}
                options={state.countriesOptions}
                value={state.country}
                name="country"
                placeholder="Country"
              />
            </div>
          </form>
          {state.deleteError ? (
            <ErrorMessage message="Could not delete provider. Please, try again or contact support." />
          ) : null}
          {state.deleteSuccess ? (
            <Text
              bold={true}
              highlight={true}
              text="The provider was deleted successfully."
            />
          ) : null}

          {state.country && (
            <Table columns={columns} data={data} isLoading={state.loading} />
          )}
        </div>
      ) : (
        <div>
          <Title element="h4" text="Provider - Delete" />
          <div>
            <Text
              bold={true}
              inline={true}
              text={`Are you sure that you want to delete the provider`}
            />
            <Text
              inline={true}
              highlight={true}
              text={` '${state.providerToDelete.name} (${state.providerToDelete.country})' `}
            />
            <Text bold={true} inline={true} text={`?`} />
          </div>

          <div className="btn-actions">
            <Button label="Back" loading={false} click={setBrowseMode} />
            <Button
              label="Delete!"
              loading={state.loading}
              click={handleDelete}
            />
          </div>

          {state.loading ? <Loading /> : null}
        </div>
      )}
    </section>
  );
}

export default ProviderBrowse;
