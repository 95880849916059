import FontIcon from '@material-ui/core/Icon';

import { UNAVAILABLE_MESSAGE } from '../../components/constants';
import { isAdmin, isReseller, isBusiness } from '../../components/utils';

import Text from '../../elements/text';
import Title from '../../elements/title';

function SensorStatus(props) {
  const isAdminBusinessOrResellerUser = () => {
    return isAdmin() || isReseller() || isBusiness();
  };

  const { firstEventFormated, lastEventFormated, latitude, longitude } =
    props.sensor;

  return (
    <div className="xs-4" id="sensor-status">
      {isAdminBusinessOrResellerUser() ? (
        <>
          <Title element="h6" text="Device Status" />
          <div>
            <Text bold={true} inline={true} text={'First Event'} />
            <Text
              bold={false}
              inline={true}
              text={firstEventFormated || UNAVAILABLE_MESSAGE}
            />
          </div>
          <div>
            <Text bold={true} inline={true} text={'Last Event'} />
            <Text
              bold={false}
              inline={true}
              text={lastEventFormated || UNAVAILABLE_MESSAGE}
            />
          </div>
          <div>
            <Text bold={true} inline={true} text="Location" />

            {latitude && longitude ? (
              <div id="locationInfo">
                <a
                  href={`https://www.google.com/maps?q=${props.sensor.latitude},${props.sensor.longitude}`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <FontIcon className="material-icons">{'map'}</FontIcon>
                </a>
                <a
                  href={`https://www.google.com/maps?q=${props.sensor.latitude},${props.sensor.longitude}`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Text inline={true} text={`${latitude}, ${longitude}`} />
                </a>
              </div>
            ) : (
              <Text bold={false} inline={true} text={UNAVAILABLE_MESSAGE} />
            )}
          </div>
        </>
      ) : null}
    </div>
  );
}

export default SensorStatus;
