import { Redirect, Route } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'universal-cookie';

import { App } from '../../index';
import { getAuthority } from '../utils';
import { AUTH_TOKEN_NAME, USER_AUTHORITIES } from '../constants';

const cookies = new Cookies();

const getUserData = () => {
  let token = cookies.get(AUTH_TOKEN_NAME);
  if (token) setToken(token);
  return token;
};

const setToken = (token) => {
  axios.defaults.headers.common[AUTH_TOKEN_NAME] = token;
};

function PrivateRoute({ component: Component, auth, ...rest }) {
  const isAuthorized = (auth) => {
    const authorities = getAuthority();
    return (
      !auth ||
      authorities.indexOf(USER_AUTHORITIES.ADMIN) >= 0 ||
      (auth.length &&
        authorities &&
        authorities.length &&
        auth.some((value) => authorities.indexOf(value) >= 0))
    );
  };

  const isAuthenticatedAndAuthorized = (auth) => {
    const authenticated = getUserData(),
      authorized = isAuthorized(auth);
    return authorized && authenticated;
  };

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticatedAndAuthorized(auth) ? (
          <App history={props.history}>
            <Component {...props} />
          </App>
        ) : (
          <Redirect
            to={{ pathname: '/logout', state: { from: props.location } }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
