import { useReducer, useRef } from 'react';
import FileDownload from 'react-file-download';
import moment from 'moment';

import API from '../../components/api';
import {
  CLAMP_SIZES_OPTIONS,
  CLAMP_SIZES_OPTIONS_3_PHASES,
  PHASES_OPTIONS,
} from '../../components/constants';

import StockAddConfirm from './confirm';

import Button from '../../elements/button';
import ErrorMessage from '../../elements/error-message';
import Input from '../../elements/forms/input';
import InputDate from '../../elements/forms/input-date';
import InputFile from '../../elements/forms/input-file';
import Text from '../../elements/text';
import Title from '../../elements/title';
import Select from '../../elements/forms/select';

import './add.sass';

const initialState = {
  loading: false,
  loadingExampleFile: false,
  step: 1,
  batchNumber: '',
  clampSize: '',
  file: '',
  fileLines: '',
  phases: '',
  productionDate: '',
  feedbackMessage: '',
  fieldsWithError: [],
  fileErrors: [],
  stocks: [],
};

function StockAdd() {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    initialState
  );
  const fileInputRef = useRef(null);

  const reset = (status) => {
    let message = '',
      state = initialState;

    if (status === 'canceled')
      message = <ErrorMessage message="The operation was canceled." />;
    else if (status === 'confirmed') {
      message = `${state.fileLines} IDs were added to the stock successfully!`;
      message = <Text bold={true} highlight={true} text={message} />;
    }
    state.feedbackMessage = message;
    state.stocks = [];

    setState(state);
  };

  const change = (e) => {
    if (e.target.name === 'phases') {
      setState({ [e.target.name]: e.target.value, clampSize: '' });
    } else {
      setState({ [e.target.name]: e.target.value });
    }
  };

  const changeDate = (value) => {
    setState({ productionDate: value });
  };

  const validate = () => {
    let fieldsWithError = [];

    if (state.productionDate === '') fieldsWithError.push('productionDate');
    if (state.batchNumber === '') fieldsWithError.push('batchNumber');
    if (
      state.clampSize === '' ||
      CLAMP_SIZES_OPTIONS.indexOf(state.clampSize) < 0
    )
      fieldsWithError.push('clampSize');
    if (state.phases === '' || PHASES_OPTIONS.indexOf(state.phases) < 0)
      fieldsWithError.push('phases');
    if (
      state.step === 2 &&
      fileInputRef.current &&
      fileInputRef.current.files.length < 1
    )
      fieldsWithError.push('file');
    setState({ fieldsWithError: fieldsWithError, loading: false });

    return !fieldsWithError.length;
  };

  const checkStep = () => {
    state.step === 1 ? setState({ loading: false, step: 2 }) : send();
  };

  const send = () => {
    setState({ loading: true });
    let data = new FormData();
    data.append('batch_number', state.batchNumber);
    data.append('clamp_size', state.clampSize);
    data.append('file', fileInputRef.current.files[0]);
    data.append('phases', state.phases);
    data.append(
      'production_date',
      moment(state.productionDate).format('YYYY-MM-DD')
    );
    API.STOCK.ADD.UPLOAD(data)
      .then((response) => {
        setState({
          fileLines: response.data.numberOfLines,
          stocks: response.data.stocks,
          loading: false,
          step: 3,
        });
      })
      .catch((error) => {
        let fieldErrors = [],
          fileErrors = [],
          message = '';
        console.error(error);

        if (error.response && error.response.status === 422) {
          if (error.response.data.errors[0] === 'invalid-file-format') {
            fieldErrors.push('file');
            fileErrors.push(
              'The format of your file is invalid, please check it and try again.'
            );
          } else fileErrors = error.response.data.errors;
        }
        setState({
          feedbackMessage: message,
          fieldsWithError: fieldErrors,
          fileErrors: fileErrors,
          loading: false,
          file: '',
        });
      });
  };

  const submit = (e) => {
    e.preventDefault();

    e.target.querySelector('button[type="submit"]').blur();

    setState({ loading: true, feedbackMessage: '' });
    if (validate()) checkStep();

    return false;
  };

  const exampleFile = (e) => {
    e.preventDefault();

    setState({ loadingExampleFile: true });

    API.STOCK.EXAMPLE().then((response) => {
      setState({ loadingExampleFile: false });
      FileDownload(response.data, 'ExampleFile.xlsx');
    });
  };

  let infoText =
    'Fill the information below about the devices you wish to add to the system and press next.';

  const clampSizeOptions =
    state.phases === PHASES_OPTIONS[1]
      ? CLAMP_SIZES_OPTIONS_3_PHASES
      : CLAMP_SIZES_OPTIONS;

  return (
    <section id="page-stock-add">
      <Title element="h4" text="Devices - Add" />

      {state.feedbackMessage ? state.feedbackMessage : null}

      {state.step < 3 ? (
        <form action="#" data-step={state.step} onSubmit={submit}>
          <div>
            <Text bold={true} text={infoText} />
            <InputDate
              change={changeDate}
              disabled={state.loading}
              hasError={state.fieldsWithError.indexOf('productionDate') >= 0}
              selected={state.productionDate}
              todayButton="Today"
              name="production_date"
              placeholder="Production date"
            />

            <Input
              change={change}
              disabled={state.loading}
              hasError={state.fieldsWithError.indexOf('batchNumber') >= 0}
              value={state.batchNumber}
              name="batchNumber"
              placeholder="Production batch number (###)"
              type="number"
            />

            <Select
              change={change}
              disabled={state.loading}
              hasError={state.fieldsWithError.indexOf('phases') >= 0}
              options={PHASES_OPTIONS}
              value={state.phases}
              name="phases"
              placeholder="# of phases"
            />

            <Select
              change={change}
              disabled={state.loading}
              hasError={state.fieldsWithError.indexOf('clampSize') >= 0}
              options={clampSizeOptions}
              value={state.clampSize}
              name="clampSize"
              placeholder="Clamp size"
            />
          </div>

          <div>
            {state.step > 1 ? (
              <div id="file-upload-section">
                <Title
                  element="h6"
                  text="Please upload a CSV file with the devices information."
                />
                <Text
                  bold={true}
                  text="You can download the excel file below to follow the right format."
                />
                <Text
                  bold={true}
                  text="Don't forget to save it as a CSV file (Excel files are not compatible)."
                />
                <Button
                  click={exampleFile}
                  disabled={state.loadingExampleFile}
                  label="Click here to download the example file"
                />

                {state.fileErrors
                  ? state.fileErrors.map((error, index) => {
                      return <ErrorMessage message={error} key={index} />;
                    })
                  : null}
                <InputFile
                  change={change}
                  disabled={state.loading}
                  hasError={state.fieldsWithError.indexOf('file') >= 0}
                  ref={fileInputRef}
                  value={state.file}
                  accept=".csv, text/csv"
                  name="file"
                  placeholder="Select your CSV file"
                />
              </div>
            ) : null}
          </div>

          <Button disabled={state.loading} label="Next" type="submit" />
        </form>
      ) : (
        <StockAddConfirm
          batchNumber={state.batchNumber}
          clampSize={state.clampSize}
          lines={state.fileLines}
          phases={state.phases}
          productionDate={state.productionDate}
          stocks={state.stocks}
          reset={reset}
        />
      )}
    </section>
  );
}

export default StockAdd;
