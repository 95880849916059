import { useReducer } from 'react';
import { Link } from 'react-router-dom';

import API from '../../../components/api';

import Button from '../../../elements/button';
import ErrorMessage from '../../../elements/error-message';
import Loading from '../../../elements/loading';
import Text from '../../../elements/text';
import Title from '../../../elements/title';

function PasswordReset(props) {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      loading: false,
      step: 'confirm',
    }
  );

  const send = (e) => {
    e.preventDefault();
    setState({ loading: true });

    API.AUTH.RESET(props.id, props.integratorId)
      .then((response) =>
        setState({
          loading: false,
          success: response.status === 200,
          step: 'done',
        })
      )
      .catch((response) => {
        console.error(response);
        setState({ loading: false, success: false, step: 'done' });
      });
  };

  const reset = () => setState({ step: 'confirm', success: false });

  const { id } = props;

  return (
    <div>
      <Title element="h6" text="Reset password" />

      {state.loading ? (
        <Loading />
      ) : state.step === 'confirm' ? (
        <div>
          <Text
            bold={true}
            inline={true}
            text={`Reset password for device ${id}?`}
          />
          <div className="btn-actions">
            <Link
              to={{
                pathname: props.isSmartMeter
                  ? `/smart-meter/${props.integratorId}/${id}/admintools`
                  : `/sensor/${id}/admintools`,
                state: 'admintools',
              }}
            >
              <Button action="cancel" label="Cancel" />
            </Link>
            <Button click={send} label="Reset" />
          </div>
        </div>
      ) : state.step === 'done' ? (
        <div>
          {state.success === true ? (
            <Text
              bold={true}
              text={`Device ${id} password reset successful. Please check your email.`}
            />
          ) : (
            <ErrorMessage
              message={`Device ${id} password could not be reset. Please try again later.`}
            />
          )}
          <div className="btn-actions">
            <Link
              to={{
                pathname: props.isSmartMeter
                  ? `/smart-meter/${props.integratorId}/${id}/admintools`
                  : `/sensor/${id}/admintools`,
                state: 'admintools',
              }}
            >
              <Button click={reset} label="Ok" />
            </Link>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default PasswordReset;
