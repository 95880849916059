import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'universal-cookie';

import { AUTH_TOKEN_NAME } from '../constants';
import API from '../api';

function AuthLogout(props) {
  const cookies = new Cookies();
  useEffect(() => removeTokens(), []);

  const removeTokens = () => {
    API.AUTH.LOGOUT().catch((error) => {
      console.error(error);
      console.error('Could not logout!');
    });

    cookies.remove(AUTH_TOKEN_NAME, { path: '/' });
    cookies.remove('authority', { path: '/' });
    cookies.remove('username', { path: '/' });
    delete axios.defaults.headers.common[AUTH_TOKEN_NAME];
  };

  const from =
    props.location && props.location.state && props.location.state.from
      ? props.location.state.from
      : '/';

  return <Redirect to={{ pathname: '/login', state: { from: from } }} />;
}

export default AuthLogout;
