import { useState } from 'react';

function Textarea(props) {
  const [isActive, setIsActive] = useState(false);

  const handleFocus = () => {
    setIsActive(true);
  };

  const handleBlur = () => {
    setIsActive(false);
  };

  let spanClass = 'placeholder';
  if (isActive) {
    spanClass += ' active';
  } else if (props.value !== '' || props.selected) {
    spanClass += ' deactive';
  }

  return (
    <label className="textarea">
      <span className={spanClass}>{props.placeholder}</span>
      <textarea
        className={props.hasError ? 'error' : ''}
        disabled={props.disabled}
        name={props.name}
        onChange={props.change}
        onFocus={handleFocus}
        onBlur={handleBlur}
        value={props.value || ''}
      />
      <span className="border" />
    </label>
  );
}

export default Textarea;
