import './error-message.sass';

function ErrorMessage(props) {
  let className = 'error-message';
  if (props.align) className += ` ${props.align}`;

  return <p className={className}>{props.message}</p>;
}

export default ErrorMessage;
