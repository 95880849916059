import React from 'react';
import { Link } from 'react-router-dom';

import Button from '../../elements/button';
import Text from '../../elements/text';
import { INTEGRATIONS } from '../../components/tpi';

function BrowseIntegrationInfoSmartMeter(props) {
  const topicSet = (props) => {
    const { integration, integratorId } = props;
    const type =
      integration.type === INTEGRATIONS.TYPES.smartMeterPacket
        ? 'packet'
        : (integration.type === INTEGRATIONS.TYPES.smartMeterStatistical ? 'statistical' : 'event');
    const version =
      integration.type === INTEGRATIONS.TYPES.smartMeterPacket ? 'v2' : 'v1';

    return type + '/' + version + '/' + integratorId;
  };

  return (
    <div>
      <h6>
        {props.title}
        {props.link ? (
          <Link {...props.link}>
            <Button action="edit" />
          </Link>
        ) : null}
      </h6>
      <div>
        <Text bold={true} inline={true} text="Username" />
        <Text bold={false} inline={true} text={props.integration.userName} />
      </div>
      <div>
        <Text bold={true} inline={true} text="Password" />
        <Text bold={false} inline={true} text={props.integration.password} />
      </div>
      <div>
        <Text bold={true} inline={true} text="Host" />
        <Text bold={false} inline={true} text={props.integration.host} />
      </div>
      <div>
        <Text bold={true} inline={true} text="Topic" />
        <Text bold={false} inline={true} text={topicSet(props)} />
      </div>
    </div>
  );
}

export default BrowseIntegrationInfoSmartMeter;
