import { NavLink } from 'react-router-dom';

import Private from '../../components/auth/private';
import { USER_AUTHORITIES } from '../../components/constants';
import { isAdmin } from '../../components/utils';

function DetailsNav(props) {
  const { client } = props;

  return (
    <nav>
      <NavLink
        exact
        to={{ pathname: `/sensor/${client.id}`, state: 'account' }}
      >
        Account
      </NavLink>

      <Private
        authNeeded={[
          USER_AUTHORITIES.ADMIN,
          USER_AUTHORITIES.RESELLER_ADMIN,
          USER_AUTHORITIES.RESELLER_USER,
          USER_AUTHORITIES.BUSINESS,
          USER_AUTHORITIES.MACHINE_LEARNING,
          USER_AUTHORITIES.TECH,
        ]}
      >
        <NavLink
          exact
          to={{ pathname: `/sensor/${client.id}/tariff`, state: 'tariff' }}
        >
          Tariff
        </NavLink>
      </Private>

      <NavLink
        exact
        to={{
          pathname: `/sensor/${client.id}/admintools`,
          state: 'admintools',
        }}
      >
        Admin Tools
      </NavLink>

      {(client.stock.status === 'ACTIVE' || isAdmin()) ? (
        <Private
          authNeeded={[
            USER_AUTHORITIES.ADMIN,
            USER_AUTHORITIES.RESELLER_ADMIN,
            USER_AUTHORITIES.RESELLER_USER,
            USER_AUTHORITIES.BUSINESS,
            USER_AUTHORITIES.MACHINE_LEARNING,
          ]}
        >
          <NavLink
            exact
            to={{
              pathname: `/sensor/${client.id}/propertydetails`,
              state: 'propertydetails',
            }}
          >
            Property Details
          </NavLink>
        </Private>
      ) : null}

      {((client.stock.status === 'ACTIVE' && props.property.exists) || isAdmin()) ? (
        <Private
          authNeeded={[
            USER_AUTHORITIES.ADMIN,
            USER_AUTHORITIES.RESELLER_ADMIN,
            USER_AUTHORITIES.RESELLER_USER,
            USER_AUTHORITIES.BUSINESS,
            USER_AUTHORITIES.MACHINE_LEARNING,
          ]}
        >
          <NavLink
            exact
            to={{
              pathname: `/sensor/${client.id}/appliances`,
              state: 'appliances',
            }}
          >
            Appliances
          </NavLink>
        </Private>
      ) : null}

    </nav>
  );
}

export default DetailsNav;
