import Cookies from 'universal-cookie';
import ReactGA from 'react-ga4';
import LogRocket from 'logrocket';
import CryptoJS from 'crypto-js';
import { LOGROCKET_KEY } from './constants';

import {
  IS_PRODUCTION,
  AUTH_TOKEN_NAME,
  USER_AUTHORITIES,
  DEVICES,
} from './constants';

const cookies = new Cookies();

// Google Analytics
const analyticsInit = () => {
  if (IS_PRODUCTION) {
    ReactGA.initialize('UA-144492000-1');
    analyticsSetUserId();
  }
};

const analyticsSetUserId = () => {
  const username = cookies.get('username');
  if (IS_PRODUCTION && cookies.get(AUTH_TOKEN_NAME) && username)
    ReactGA.set({ userId: username });
};

const analyticsPageview = () => {
  if (IS_PRODUCTION)
    ReactGA._gaCommandSendPageview(window.location.pathname.replace(/\/\d+/, ''));
};

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const testEmail = (email) => emailRegex.test(email);

//LogRocket
const logRocketInit = () => {
  // To not log localhost
  if (process.env.NODE_ENV === 'production') {
    LogRocket.init(LOGROCKET_KEY);
  }
};

const logRocketSetUserAndAuthorities = () => {
  // To not log localhost
  if (process.env.NODE_ENV === 'production') {
    const user = cookies.get('username');
    const hashedAuthorities = cookies.get('authorities').split(',');
    const authorities = hashedAuthorities
      .map((item) => unhashUserAuthority(item))
      .join(', ');

    LogRocket.identify(user, {
      user,
      authorities,
    });
  }
};

// User Authorities
const getAuthority = () => {
  let authorities = cookies.get('authorities');
  return authorities ? authorities.split(',') : [];
};

const isAdmin = () => {
  const authority = getAuthority();
  return authority.indexOf(USER_AUTHORITIES.ADMIN) >= 0;
};

const isBusiness = () => {
  const authority = getAuthority();
  return authority.indexOf(USER_AUTHORITIES.BUSINESS) >= 0;
};

const isMachineLearning = () => {
  const authority = getAuthority();
  return authority.indexOf(USER_AUTHORITIES.MACHINE_LEARNING) >= 0;
};

const isReseller = () => {
  const authority = getAuthority();
  return (
    authority.indexOf(USER_AUTHORITIES.RESELLER_ADMIN) >= 0 ||
    authority.indexOf(USER_AUTHORITIES.RESELLER_USER) >= 0
  );
};

const isResellerAdmin = (authorities) => {
  const auth =
    authorities && authorities[0]
      ? hashUserAuthority(authorities[0])
      : getAuthority()[0];
  return auth === USER_AUTHORITIES.RESELLER_ADMIN;
};

const isSameUserLoggedIn = (username) => cookies.get('username') === username;

const hashUserAuthority = (authority) => window.btoa(authority);
const unhashUserAuthority = (hash) => {
  try {
    return window.atob(hash);
  } catch (DOMException) {
    return hash;
  }
};

// /User Authorities

// Devices Types

const hasSmartMeters = () => {
  if (!isReseller()) return true;
  const deviceTypes = cookies.get('deviceTypes') || [];
  return deviceTypes.includes(DEVICES.LOW_RESOLUTION_METER) || deviceTypes.includes(DEVICES.HIGH_RESOLUTION_METER);
};

const hasVoltawareSensors = () => {
  if (!isReseller()) return true;
  const deviceTypes = cookies.get('deviceTypes') || [];
  return deviceTypes.includes(DEVICES.VOLTAWARE_SENSORS);
};

const addDay = (date, increment = 1) => {
  date.setDate(date.getDate() + increment);
  return date;
};

const subtractDay = (date, decrement = 1) => {
  date.setDate(date.getDate() - decrement);
  return date;
};

const getFirstDayOfTheWeek = (date) => {
  let firstDay = new Date(date.getTime());
  const diff = date.getDay();
  firstDay = subtractDay(firstDay, diff);
  return firstDay;
};

const getLastDayOfTheWeek = (date) => {
  let lastDay = new Date(date.getTime());
  const diff = 6 - date.getDay();
  lastDay = addDay(lastDay, diff);
  if (lastDay > new Date()) {
    lastDay = new Date();
  }
  return lastDay;
};

// /Devices Types

// Shallow base64 encryption/decryption
const encrypt = (text) => {
  return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(text));
};

const decrypt = (data) => {
  return CryptoJS.enc.Base64.parse(data).toString(CryptoJS.enc.Utf8);
};

export {
  analyticsInit,
  analyticsPageview,
  analyticsSetUserId,
  logRocketInit,
  logRocketSetUserAndAuthorities,
  emailRegex,
  getAuthority,
  hashUserAuthority,
  isAdmin,
  isBusiness,
  isMachineLearning,
  isReseller,
  isResellerAdmin,
  isSameUserLoggedIn,
  testEmail,
  unhashUserAuthority,
  hasSmartMeters,
  hasVoltawareSensors,
  addDay,
  subtractDay,
  getFirstDayOfTheWeek,
  getLastDayOfTheWeek,
  encrypt,
  decrypt,
};
