// Auths
import { USER_AUTHORITIES } from './components/constants';

// Pages
import Summary from './pages/summary';
import Devices from './pages/devices';
import Users from './pages/users';
/// Devices
import CountryAddEdit from './pages/country/add-edit';
import CountryBrowse from './pages/country/browse';
import ResellersAdd from './pages/resellers/add';
import ResellersEdit from './pages/resellers/edit';
import ResellersBrowse from './pages/resellers/browse';
import ResellersSummary from './pages/resellers/summary';
import ResellersBuildingMonitoring from './pages/resellers/building-monitoring';
import ResellersCRMAccess from './pages/resellers/crm-access';
import StockAdd from './pages/stock/add';
import StockBrowse from './pages/stock/browse';
import StockBlock from './pages/stock/block/index';
import StockBlockBrowse from './pages/stock/block/browse';
import StockEdit from './pages/stock/edit';
import SubscriptionAdd from './pages/subscription/add';
import SubscriptionBrowse from './pages/subscription/browse';
import SubscriptionOrderAdd from './pages/subscription/order/add';
import SubscriptionOrderBrowse from './pages/subscription/order/browse';
import SubscriptionOrderEnding from './pages/subscription/order/ending';
import ProviderBrowse from './pages/provider/browse';
import ProviderAddEdit from './pages/provider/add-edit';
import Disaggregation from './pages/disaggregation';
import Installations from './pages/installations';
import SetupSmartMeters from './pages/setup-smart-meters';

const routes = [
  {
    path: '/',
    exact: true,
    component: Summary,
  },
  {
    path: '/users',
    exact: true,
    component: Users,
    auth: [
      USER_AUTHORITIES.ADMIN,
      USER_AUTHORITIES.BUSINESS,
      USER_AUTHORITIES.MACHINE_LEARNING,
      USER_AUTHORITIES.TECH,
    ],
  },
  {
    path: '/devices',
    exact: true,
    component: Devices,
  },
  {
    path: '/sensors',
    exact: true,
    component: Devices,
  },
  {
    path: '/sensor/:id',
    exact: true,
    component: Devices,
  },
  {
    path: '/sensor/:id/tariff',
    exact: true,
    component: Devices,
    auth: [
      USER_AUTHORITIES.ADMIN,
      USER_AUTHORITIES.BUSINESS,
      USER_AUTHORITIES.MACHINE_LEARNING,
      USER_AUTHORITIES.RESELLER_ADMIN,
      USER_AUTHORITIES.RESELLER_USER,
      USER_AUTHORITIES.TECH,
    ],
  },
  {
    path: '/sensor/:id/admintools',
    exact: true,
    component: Devices,
  },
  {
    path: '/sensor/:id/admintools/passwdgenerate',
    exact: true,
    component: Devices,
    auth: [USER_AUTHORITIES.ADMIN, USER_AUTHORITIES.RESELLER_ADMIN],
  },
  {
    path: '/sensor/:id/admintools/passwdreset',
    exact: true,
    component: Devices,
    auth: [USER_AUTHORITIES.ADMIN, USER_AUTHORITIES.RESELLER_ADMIN],
  },
  {
    path: '/sensor/:id/admintools/changelocale',
    exact: true,
    component: Devices,
    auth: [USER_AUTHORITIES.ADMIN],
  },
  {
    path: '/sensor/:id/admintools/periodicreport/create',
    exact: true,
    component: Devices,
    auth: [
      USER_AUTHORITIES.ADMIN,
      USER_AUTHORITIES.RESELLER_ADMIN,
      USER_AUTHORITIES.MACHINE_LEARNING,
    ],
  },
  {
    path: '/sensor/:id/admintools/periodicreport/history',
    exact: true,
    component: Devices,
    auth: [
      USER_AUTHORITIES.ADMIN,
      USER_AUTHORITIES.BUSINESS,
      USER_AUTHORITIES.MACHINE_LEARNING,
      USER_AUTHORITIES.RESELLER_ADMIN,
      USER_AUTHORITIES.RESELLER_USER,
    ],
  },
  {
    path: '/sensor/:id/admintools/periodicreport/send',
    exact: true,
    component: Devices,
    auth: [USER_AUTHORITIES.ADMIN, USER_AUTHORITIES.RESELLER_ADMIN],
  },
  {
    path: '/sensor/:id/admintools/eventsexporting',
    exact: true,
    component: Devices,
    auth: [
      USER_AUTHORITIES.ADMIN,
      USER_AUTHORITIES.BUSINESS,
      USER_AUTHORITIES.MACHINE_LEARNING,
      USER_AUTHORITIES.RESELLER_ADMIN,
      USER_AUTHORITIES.RESELLER_USER,
      USER_AUTHORITIES.TECH,
    ],
  },
  {
    path: '/sensor/:id/admintools/eventsexportinghistory',
    exact: true,
    component: Devices,
    auth: [
      USER_AUTHORITIES.ADMIN,
      USER_AUTHORITIES.BUSINESS,
      USER_AUTHORITIES.MACHINE_LEARNING,
      USER_AUTHORITIES.RESELLER_ADMIN,
      USER_AUTHORITIES.RESELLER_USER,
      USER_AUTHORITIES.TECH,
    ],
  },
  {
    path: '/sensor/:id/admintools/accountpurge',
    exact: true,
    component: Devices,
    auth: [USER_AUTHORITIES.ADMIN],
  },
  {
    path: '/sensor/:id/admintools/deletesensorevents',
    exact: true,
    component: Devices,
    auth: [USER_AUTHORITIES.ADMIN],
  },
  {
    path: '/sensor/:id/admintools/activatesmartbox',
    exact: true,
    component: Devices,
    auth: [USER_AUTHORITIES.ADMIN],
  },
  {
    path: '/sensor/:id/propertydetails',
    exact: true,
    component: Devices,
    auth: [
      USER_AUTHORITIES.ADMIN,
      USER_AUTHORITIES.BUSINESS,
      USER_AUTHORITIES.MACHINE_LEARNING,
      USER_AUTHORITIES.RESELLER_ADMIN,
      USER_AUTHORITIES.RESELLER_USER,
    ],
  },
  {
    path: '/sensor/:id/appliances',
    exact: true,
    component: Devices,
    auth: [
      USER_AUTHORITIES.ADMIN,
      USER_AUTHORITIES.BUSINESS,
      USER_AUTHORITIES.MACHINE_LEARNING,
      USER_AUTHORITIES.RESELLER_ADMIN,
      USER_AUTHORITIES.RESELLER_USER,
    ],
  },
  {
    path: '/smart-meters',
    exact: true,
    component: Devices,
  },
  {
    path: '/smart-meter/:integratorId/:id',
    exact: true,
    component: Devices,
  },
  {
    path: '/smart-meter/:integratorId/:id/tariff',
    exact: true,
    component: Devices,
    auth: [
      USER_AUTHORITIES.ADMIN,
      USER_AUTHORITIES.BUSINESS,
      USER_AUTHORITIES.MACHINE_LEARNING,
      USER_AUTHORITIES.RESELLER_ADMIN,
      USER_AUTHORITIES.RESELLER_USER,
      USER_AUTHORITIES.TECH,
    ],
  },
  {
    path: '/smart-meter/:integratorId/:id/admintools',
    exact: true,
    component: Devices,
  },
  {
    path: '/smart-meter/:integratorId/:id/admintools/smartmeterapp',
    exact: true,
    component: Devices,
    auth: [USER_AUTHORITIES.ADMIN],
  },
  {
    path: '/smart-meter/:integratorId/:id/admintools/passwdgenerate',
    exact: true,
    component: Devices,
    auth: [USER_AUTHORITIES.ADMIN, USER_AUTHORITIES.RESELLER_ADMIN],
  },
  {
    path: '/smart-meter/:integratorId/:id/admintools/passwdreset',
    exact: true,
    component: Devices,
    auth: [USER_AUTHORITIES.ADMIN, USER_AUTHORITIES.RESELLER_ADMIN],
  },
  {
    path: '/smart-meter/:integratorId/:id/admintools/periodicreport/create',
    exact: true,
    component: Devices,
    auth: [
      USER_AUTHORITIES.ADMIN,
      USER_AUTHORITIES.RESELLER_ADMIN,
      USER_AUTHORITIES.MACHINE_LEARNING,
    ],
  },
  {
    path: '/smart-meter/:integratorId/:id/admintools/periodicreport/history',
    exact: true,
    component: Devices,
    auth: [
      USER_AUTHORITIES.ADMIN,
      USER_AUTHORITIES.BUSINESS,
      USER_AUTHORITIES.MACHINE_LEARNING,
      USER_AUTHORITIES.RESELLER_ADMIN,
      USER_AUTHORITIES.RESELLER_USER,
    ],
  },
  {
    path: '/smart-meter/:integratorId/:id/admintools/periodicreport/send',
    exact: true,
    component: Devices,
    auth: [USER_AUTHORITIES.ADMIN, USER_AUTHORITIES.RESELLER_ADMIN],
  },
  {
    path: '/smart-meter/:integratorId/:id/propertydetails',
    exact: true,
    component: Devices,
    auth: [
      USER_AUTHORITIES.ADMIN,
      USER_AUTHORITIES.BUSINESS,
      USER_AUTHORITIES.MACHINE_LEARNING,
      USER_AUTHORITIES.RESELLER_ADMIN,
      USER_AUTHORITIES.RESELLER_USER,
    ],
  },
  {
    path: '/smart-meter/:integratorId/:id/appliances',
    exact: true,
    component: Devices,
    auth: [
      USER_AUTHORITIES.ADMIN,
      USER_AUTHORITIES.BUSINESS,
      USER_AUTHORITIES.MACHINE_LEARNING,
      USER_AUTHORITIES.RESELLER_ADMIN,
      USER_AUTHORITIES.RESELLER_USER,
    ],
  },
  {
    path: '/setup-smart-meters',
    exact: true,
    component: SetupSmartMeters,
    auth: [USER_AUTHORITIES.ADMIN],
  },
  {
    path: '/disaggregation',
    exact: true,
    component: Disaggregation,
    auth: [
      USER_AUTHORITIES.ADMIN,
      USER_AUTHORITIES.BUSINESS,
      USER_AUTHORITIES.TECH,
      USER_AUTHORITIES.MACHINE_LEARNING,
    ],
  },
  {
    path: '/installations',
    exact: true,
    component: Installations,
    auth: [
      USER_AUTHORITIES.ADMIN,
      USER_AUTHORITIES.BUSINESS,
      USER_AUTHORITIES.TECH,
      USER_AUTHORITIES.MACHINE_LEARNING,
    ],
  },

  {
    path: '/inventory',
    exact: true,
    component: StockBrowse,
    auth: [
      USER_AUTHORITIES.ADMIN,
      USER_AUTHORITIES.BUSINESS,
      USER_AUTHORITIES.MACHINE_LEARNING,
      USER_AUTHORITIES.TECH,
    ],
  },
  {
    path: '/inventory/add',
    exact: true,
    component: StockAdd,
    auth: [USER_AUTHORITIES.ADMIN],
  },
  {
    path: '/inventory/edit',
    exact: true,
    component: StockEdit,
    auth: [USER_AUTHORITIES.ADMIN],
  },
  {
    path: '/inventory/block',
    exact: true,
    component: StockBlock,
    auth: [
      USER_AUTHORITIES.ADMIN,
      USER_AUTHORITIES.BUSINESS,
      USER_AUTHORITIES.RESELLER_ADMIN,
    ],
  },
  {
    path: '/inventory/unblock',
    exact: true,
    component: StockBlock,
    auth: [
      USER_AUTHORITIES.ADMIN,
      USER_AUTHORITIES.BUSINESS,
      USER_AUTHORITIES.RESELLER_ADMIN,
    ],
  },
  {
    path: '/inventory/blocked',
    exact: true,
    component: StockBlockBrowse,
    auth: [
      USER_AUTHORITIES.ADMIN,
      USER_AUTHORITIES.BUSINESS,
      USER_AUTHORITIES.RESELLER_ADMIN,
      USER_AUTHORITIES.RESELLER_USER,
    ],
  },
  {
    path: '/resellers',
    exact: true,
    component: ResellersBrowse,
    auth: [
      USER_AUTHORITIES.ADMIN,
      USER_AUTHORITIES.BUSINESS,
      USER_AUTHORITIES.MACHINE_LEARNING,
      USER_AUTHORITIES.TECH,
    ],
  },
  {
    path: '/resellers/add',
    exact: true,
    component: ResellersAdd,
    auth: [
      USER_AUTHORITIES.ADMIN,
      USER_AUTHORITIES.BUSINESS,
      USER_AUTHORITIES.MACHINE_LEARNING,
      USER_AUTHORITIES.TECH,
    ],
  },
  {
    path: '/resellers/edit',
    exact: true,
    component: ResellersEdit,
    auth: [
      USER_AUTHORITIES.ADMIN,
      USER_AUTHORITIES.BUSINESS,
      USER_AUTHORITIES.TECH,
    ],
  },
  {
    path: '/resellers/summary',
    exact: true,
    component: ResellersSummary,
    auth: [
      USER_AUTHORITIES.ADMIN,
      USER_AUTHORITIES.BUSINESS,
      USER_AUTHORITIES.MACHINE_LEARNING,
      USER_AUTHORITIES.TECH,
    ],
  },
  {
    path: '/resellers/:id/building-monitoring',
    exact: true,
    component: ResellersBuildingMonitoring,
    auth: [USER_AUTHORITIES.ADMIN, USER_AUTHORITIES.BUSINESS],
  },
  {
    path: '/resellers/:id/crm-access',
    exact: true,
    component: ResellersCRMAccess,
    auth: [USER_AUTHORITIES.ADMIN, USER_AUTHORITIES.BUSINESS],
  },
  {
    path: '/reseller-users',
    exact: true,
    component: ResellersCRMAccess,
    auth: [USER_AUTHORITIES.RESELLER_ADMIN],
  },

  // Subscription
  {
    path: '/subscriptions',
    exact: true,
    component: SubscriptionBrowse,
    auth: [USER_AUTHORITIES.ADMIN, USER_AUTHORITIES.RESELLER_ADMIN],
  },
  {
    path: '/subscriptions/add',
    exact: true,
    component: SubscriptionAdd,
    auth: [USER_AUTHORITIES.ADMIN],
  },
  {
    path: '/subscriptions/edit',
    exact: true,
    component: SubscriptionAdd,
    auth: [USER_AUTHORITIES.ADMIN],
  },
  /// Subscription Order
  {
    path: '/subscriptions/orders',
    exact: true,
    component: SubscriptionOrderBrowse,
    auth: [USER_AUTHORITIES.ADMIN, USER_AUTHORITIES.RESELLER_ADMIN],
  },
  {
    path: '/subscriptions/orders/add',
    exact: true,
    component: SubscriptionOrderAdd,
    auth: [USER_AUTHORITIES.ADMIN],
  },
  {
    path: '/subscriptions/orders/edit',
    exact: true,
    component: SubscriptionOrderAdd,
    auth: [USER_AUTHORITIES.ADMIN],
  },
  {
    path: '/subscriptions/orders/ending',
    exact: true,
    component: SubscriptionOrderEnding,
    auth: [USER_AUTHORITIES.ADMIN],
  },
  /// /Subscription Order
  // /Subscription

  {
    path: '/countries',
    exact: true,
    component: CountryBrowse,
    auth: [
      USER_AUTHORITIES.ADMIN,
      USER_AUTHORITIES.BUSINESS,
      USER_AUTHORITIES.MACHINE_LEARNING,
    ],
  },
  {
    path: '/countries/add',
    exact: true,
    component: CountryAddEdit,
    auth: [USER_AUTHORITIES.ADMIN],
  },
  {
    path: '/countries/edit',
    exact: true,
    component: CountryAddEdit,
    auth: [USER_AUTHORITIES.ADMIN],
  },
  {
    path: '/providers/add',
    exact: true,
    component: ProviderAddEdit,
    auth: [USER_AUTHORITIES.ADMIN, USER_AUTHORITIES.BUSINESS],
  },
  {
    path: '/providers',
    exact: true,
    component: ProviderBrowse,
    auth: [USER_AUTHORITIES.ADMIN, USER_AUTHORITIES.BUSINESS],
  },
  {
    path: '/providers/edit',
    exact: true,
    component: ProviderAddEdit,
    auth: [USER_AUTHORITIES.ADMIN, USER_AUTHORITIES.BUSINESS],
  },
];

export default routes;
