import React from 'react';

import './input-auto-complete.sass';

function InputAutoComplete(props) {
  const data = props.data.slice(0, 5);

  return props.showing && !props.loading ? (
    <div className="input-auto-complete-wrapper">
      {data
        ? data.map((item) => (
            <button
              key={item.id}
              id={item.id}
              integratorid={item.integratorId}
              name={item.name}
              onMouseDown={props.select}
              type="button"
            >
              {item.name.length > 50
                ? `${item.name.slice(0, 50)}...`
                : item.name}
            </button>
          ))
        : null}
    </div>
  ) : null;
}

export default InputAutoComplete;
