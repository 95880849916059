import { useRef, useReducer, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import FontIcon from '@material-ui/core/Icon';

import API from '../../components/api';

import Button from '../../elements/button';
import ErrorMessage from '../../elements/error-message';
import Text from '../../elements/text';
import Title from '../../elements/title';
import Table from '../../elements/table';

import './email-report-history.sass';

function EmailReportHistory(props) {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      deleting: false,
      feedbackMessage: '',
      hasNext: false,
      hasPrevious: false,
      history: [],
      loading: false,
      nextPage: null,
      previousPage: null,
      reportToDelete: null,
      totalPages: 1,
    }
  );
  const isMountedRef = useRef(null);
  const page = useRef(1);

  useEffect(() => {
    isMountedRef.current = true;
    handleSearch();

    return () => (isMountedRef.current = false);
  }, []);

  const columns = useMemo(
    () => [
      {
        id: 'email',
        title: 'E-mail',
        align: 'center',
      },
      {
        id: 'reportLayout',
        title: 'Report type',
        align: 'center',
      },
      {
        id: 'sentDate',
        title: 'Sent timestamp',
        align: 'center',
      },
      {
        id: 'reportMonth',
        title: 'Reported month',
        align: 'center',
      },
      {
        id: 'sent',
        title: 'E-mail sent',
        align: 'center',
      },
      {
        id: 'downloadLink',
        title: 'Actions',
        align: 'center',
      },
    ],
    []
  );

  const data = useMemo(() => {
    return state.history.map((row) => {
      return {
        ...row,
        sent: (
          <FontIcon className="material-icons">
            {row.sent ? 'done' : 'clear'}
          </FontIcon>
        ),
        downloadLink:
          row.downloadLink || !row.sent ? (
            <div>
              {row.downloadLink ? (
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <a
                  className="btn open-in-new"
                  href={row.downloadLink}
                  target="_blank"
                  rel="noreferrer"
                />
              ) : null}
              {!row.sent ? (
                <Button
                  action="delete"
                  click={() => {
                    confirmPurge(row);
                  }}
                />
              ) : null}
            </div>
          ) : (
            '-'
          ),
        id: row.email || row.reportToken,
      };
    });
  }, [state.history]);

  const handlePreviousPage = () => {
    page.current = page.current - 1;
    handleSearch(page.current);
  };

  const handleNextPage = () => {
    page.current = page.current + 1;
    handleSearch(page.current);
  };

  const handleSearch = (pageToSearch) => {
    setState({ loading: true });

    API.STOCK.REPORT.HISTORY({
      id: props.id,
      page: pageToSearch || 1,
      datesHistory: null,
      integratorId: props.integratorId,
    }).then((response) => {
      if (!isMountedRef.current) {
        return;
      }

      page.current = response.data.currentPage;
      setState({
        ...response.data,
        loading: false,
      });
    });
  };

  const backFromPurge = () => {
    setState({
      feedbackMessage: '',
      deleting: false,
      reportToDelete: '',
    });
  };

  const confirmPurge = (report) => {
    setState({
      feedbackMessage: '',
      deleting: true,
      reportToDelete: report,
    });
  };

  const purge = () => {
    setState({ loading: true });

    API.STOCK.REPORT.PURGE({
      id: props.id,
      token: state.reportToDelete.reportToken,
      integratorId: props.integratorId,
    })
      .then((response) => {
        if (!isMountedRef.current) {
          return;
        }

        setState({
          deleting: false,
          feedbackMessage: (
            <Text
              bold={true}
              highlight={true}
              text="Report purged successfully."
            />
          ),
          reportToken: '',
        });
      })
      .catch((error) => {
        setState({
          deleting: false,
          feedbackMessage: (
            <ErrorMessage message="Could not purge this report. Please, try again or contact support." />
          ),
          reportToDelete: '',
        });
      });
  };

  return (
    <div id="periodic-report-history-page">
      {state.feedbackMessage}

      {state.deleting ? (
        <div>
          <div>
            <Text
              bold={true}
              text="Are you sure you want to purge this report?"
            />
          </div>

          <div className="btn-actions">
            <Button label="Back" loading={false} click={backFromPurge} />
            <Button label="Purge" click={purge} />
          </div>
        </div>
      ) : (
        <div>
          <Title element="h6" text="Periodic Report History" />

          <Table
            columns={columns}
            data={data}
            isLoading={state.loading}
            showPagination
            hasPrevious={state.hasPrevious}
            hasNext={state.hasNext}
            handlePreviousPage={handlePreviousPage}
            handleNextPage={handleNextPage}
          />

          <div className="btn-actions">
            <Link
              to={{
                pathname: props.isSmartMeter
                  ? `/smart-meter/${props.integratorId}/${props.id}/admintools`
                  : `/sensor/${props.id}/admintools`,
                state: 'admintools',
              }}
            >
              <Button disabled={state.loading} label="Back" loading={false} />
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}

export default EmailReportHistory;
