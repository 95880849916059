import './title.sass';

function Title(props) {
  return typeof props.element === 'string' ? (
    <props.element>
      {props.text}
      {props.children}
    </props.element>
  ) : null;
}

export default Title;
