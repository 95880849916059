import { useRef, useReducer, useEffect } from 'react';
import { Link } from 'react-router-dom';

import API from '../../components/api';
import { testEmail } from '../../components/utils';

import Button from '../../elements/button';
import Input from '../../elements/forms/input';
import Select from '../../elements/forms/select';
import Text from '../../elements/text';
import Title from '../../elements/title';
import Loading from '../../elements/loading';
import ErrorMessage from '../../elements/error-message';
import './smart-meter-app';

const initialState = {
  feedbackMessage: '',
  fieldsWithError: [],
  loading: false,
  email: '',
  countries: [],
  country: '',
  countryName: '',
  countryLocales: [],
  isoLocaleCode: '',
  loadingCountries: false,
  step: 'confirm',
  success: false,
};

function SmartMeterApp(props) {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    initialState
  );

  const isMountedRef = useRef(null);

  const { client } = props;

  useEffect(() => {
    isMountedRef.current = true;
    loadCountries();
    return () => (isMountedRef.current = false);
  }, []);

  const loadCountries = () => {
    setState({ loadingCountries: true });

    API.COUNTRY.SUMMARY()
      .then((response) => {
        if (!isMountedRef.current) {
          return;
        }

        if (response.status === 200) {
          setState({
            countries: response.data.map((x) => {
              return { label: x.name, value: x.isoCode };
            }),
            loadingCountries: false,
          });
        } else
          setState({
            loadingCountries: false,
            step: 'errorLoadingCountries',
          });
      })
      .catch((response) => {
        setState({
          loadingCountries: false,
          step: 'errorLoadingCountries',
        });
      });
  };

  useEffect(() => {
    if (!state.country) {
      return;
    }

    loadLocales();
  }, [state.country]);

  const loadLocales = () => {
    API.LOCALES.SEARCH(state.country)
      .then((values) => {
        setState({
          loadingData: false,
          countryLocales:
            values.data && values.data.length
              ? values.data.map((d) => ({
                  ...d,
                  label: d.code,
                  value: d.code,
                }))
              : [],
        });
      })
      .catch((error) => {
        setState({ dataError: true, loadingData: false });
      });
  };

  const change = (e) => {
    setState({ [e.target.name]: e.target.value });
  };

  const changeCountry = (e) => {
    const country = state.countries.filter(
      (country) => country.value === e.target.value
    )[0];
    const countryName = country && country.label ? country.label : '';
    setState({
      country: e.target.value,
      countryName: countryName,
      isoLocaleCode: '',
    });
  };

  const validate = () => {
    let fieldsWithError = [];

    if (!testEmail(state.email)) {
      fieldsWithError.push('email');
    }

    if (!state.country) {
      fieldsWithError.push('country');
    }

    if (!state.isoLocaleCode) {
      fieldsWithError.push('isoLocaleCode');
    }

    setState({ fieldsWithError: fieldsWithError });

    return !fieldsWithError.length;
  };

  const submit = (e) => {
    e.preventDefault();

    e.target.querySelector('button[type="submit"]').blur();

    if (validate()) {
      send();
    }

    return false;
  };

  const send = () => {
    setState({ feedbackMessage: '', step: 'loading' });

    API.SMART_METERS.SMART_METER_APP({
      integratorId: props.integratorId,
      sensorId: props.client.id,
      email: state.email,
      userDefinedLanguage: state.isoLocaleCode,
    })
      .then((response) => {
        setState({
          step: 'done',
          success: response.status === 204 || response.status === 200,
        });
      })
      .catch((response) => {
        setState({ step: 'done', success: false });
      });
  };

  return (
    <div id="smart-meter-app">
      <Title element="h6" text="Smart Meter App" />

      {state.step === 'loading' ? <Loading /> : null}

      {state.step === 'errorLoadingCountries' ? (
        <div>
          <ErrorMessage message="Could not load countries. Please try again later." />
          <div className="btn-actions">
            <Link
              to={{
                pathname: `/smart-meter/${props.integratorId}/${props.client.id}/admintools`,
                state: 'admintools',
              }}
            >
              <Button click={this.reset} label="Ok" />
            </Link>
          </div>
        </div>
      ) : null}

      {state.step === 'confirm' ? (
        <form action="#" onSubmit={submit}>
          <Text
            bold={true}
            text={`Enable Smart Meter App for device ${client.id}:`}
          />
          <div>
            <Input
              change={change}
              disabled={state.loading}
              hasError={state.fieldsWithError.indexOf('email') >= 0}
              name="email"
              placeholder="Email"
              type="email"
              value={state.email}
            />
            <Select
              change={changeCountry}
              disabled={state.loadingCountries}
              hasError={state.fieldsWithError.includes('country')}
              name="country"
              options={state.countries}
              placeholder="Country"
              value={state.country}
            />
            <Select
              change={change}
              disabled={state.loading || !state.country || state.loadingData}
              hasError={state.fieldsWithError.indexOf('isoLocaleCode') >= 0}
              options={state.country ? state.countryLocales : []}
              value={state.isoLocaleCode}
              name="isoLocaleCode"
              placeholder={
                state.localePlaceHolder || 'Locale for selected country'
              }
            />

            <div className="btn-actions">
              <Link
                to={{
                  pathname: `/smart-meter/${props.integratorId}/${props.client.id}/admintools`,
                  state: 'admintools',
                }}
              >
                <Button
                  action="cancel"
                  disabled={state.loading}
                  label="Cancel"
                  loading={false}
                />
              </Link>
              <Button disabled={state.loading} label="Enable" type="submit" />
            </div>
          </div>
        </form>
      ) : null}

      {state.step === 'done' && (
        <div>
          {state.success === true ? (
            <Text bold={true} text="Smart Meter App activated with success." />
          ) : (
            <ErrorMessage
              message={`Could not enable Smart Meter App. Please try again later.`}
            />
          )}
          <div className="btn-actions">
            <Link
              to={{
                pathname: `/smart-meter/${props.integratorId}/${props.client.id}/admintools`,
                state: 'admintools',
              }}
            >
              <Button disabled={state.loading} label="Ok" loading={false} />
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}

export default SmartMeterApp;
