import { useReducer } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { IoIosArrowDown } from 'react-icons/io';

import Private from '../components/auth/private';
import PrivateLink from '../components/auth/private-link';

import './sidebar.sass';
import logout from './header-logout.svg';
import { USER_AUTHORITIES } from '../components/constants';

function Sidebar() {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      reportingTab: false,
      devicesTab: false,
      stockTab: false,
      settingsTab: false,
      inventorySubtab: false,
      resellerSubtab: false,
      subscriptionSubtab: false,
      orderSubtab: false,
      countrySubtab: false,
      providerSubtab: false,
    }
  );
  const location = useLocation();

  const closeTabs = () => {
    setState({
      reportingTab: false,
      devicesTab: false,
      stockTab: false,
      settingsTab: false,
    });
  };

  const closeSubtabs = () => {
    setState({
      inventorySubtab: false,
      resellerSubtab: false,
      subscriptionSubtab: false,
      orderSubtab: false,
      countrySubtab: false,
      providerSubtab: false,
    });
  };

  const handleReportingTab = () => {
    if (!state.reportingTab) {
      closeTabs();
      closeSubtabs();
    }

    setState({
      reportingTab: !state.reportingTab,
    });
  };

  const handleDevicesTab = () => {
    if (!state.devicesTab) {
      closeTabs();
      closeSubtabs();
    }

    setState({
      devicesTab: !state.devicesTab,
    });
  };

  const handleStockTab = () => {
    if (!state.stockTab) {
      closeTabs();
    }

    if (state.stockTab) {
      closeSubtabs();
    }

    setState({
      stockTab: !state.stockTab,
    });
  };

  const handleSettingsTab = () => {
    if (!state.settingsTab) {
      closeTabs();
      closeSubtabs();
    }

    setState({
      settingsTab: !state.settingsTab,
    });
  };

  const handleInventorySubtab = () => {
    if (!state.inventorySubtab) {
      closeSubtabs();
    }

    setState({
      inventorySubtab: !state.inventorySubtab,
    });
  };

  const handleResellerSubtab = () => {
    if (!state.resellerSubtab) {
      closeSubtabs();
    }

    setState({
      resellerSubtab: !state.resellerSubtab,
    });
  };

  const handleSubscriptionSubtab = () => {
    if (!state.subscriptionSubtab) {
      closeSubtabs();
    }

    setState({
      subscriptionSubtab: !state.subscriptionSubtab,
    });
  };

  const handleOrderSubtab = () => {
    if (!state.orderSubtab) {
      closeSubtabs();
    }

    setState({
      orderSubtab: !state.orderSubtab,
    });
  };

  const handleCountrySubtab = () => {
    if (!state.countrySubtab) {
      closeSubtabs();
    }

    setState({
      countrySubtab: !state.countrySubtab,
    });
  };

  const handleProviderSubtab = () => {
    if (!state.providerSubtab) {
      closeSubtabs();
    }

    setState({
      providerSubtab: !state.providerSubtab,
    });
  };

  return (
    <>
      {location.pathname !== '/login' && (
        <div className="sidebar-wrapper">
          <nav>
            <div
              className="sidebar-expander-primary"
              onClick={handleReportingTab}
            >
              Reporting{' '}
              <IoIosArrowDown className={state.reportingTab && 'open-arrow'} />
            </div>
            <div
              className={`sidebar-group-primary ${
                state.reportingTab && 'expand-sidebar'
              }`}
            >
              <NavLink to="/" exact>
                Summary
              </NavLink>
              <PrivateLink to="/installations">Installations</PrivateLink>
              <PrivateLink to="/disaggregation">Disaggregation</PrivateLink>
            </div>

            <Private authNeeded={[USER_AUTHORITIES.ADMIN]}>
              <div
                className="sidebar-expander-primary"
                onClick={handleDevicesTab}
              >
                Devices{' '}
                <IoIosArrowDown className={state.devicesTab && 'open-arrow'} />
              </div>
              <div
                className={`sidebar-group-primary ${
                  state.devicesTab && 'expand-sidebar'
                }`}
              >
                <NavLink to="/devices">Monitoring</NavLink>
                <PrivateLink to="/setup-smart-meters">
                  Setup Smart-Meters
                </PrivateLink>
              </div>
            </Private>

            <Private
              authNeeded={[
                USER_AUTHORITIES.RESELLER_ADMIN,
                USER_AUTHORITIES.RESELLER_USER,
                USER_AUTHORITIES.BUSINESS,
                USER_AUTHORITIES.TECH,
                USER_AUTHORITIES.MACHINE_LEARNING,
              ]}
            >
              <NavLink to="/devices" onClick={closeTabs}>
                Devices
              </NavLink>
            </Private>

            <Private to="/inventory">
              <div
                className="sidebar-expander-primary"
                onClick={handleStockTab}
              >
                Voltaware Sensors Stock Manager{' '}
                <IoIosArrowDown className={state.stockTab && 'open-arrow'} />
              </div>
              <div
                className={`sidebar-group-primary ${
                  state.stockTab && 'expand-sidebar'
                }`}
              >
                <Private to="/inventory">
                  <div
                    className="sidebar-expander-secondary"
                    onClick={handleInventorySubtab}
                  >
                    Inventory{' '}
                    <IoIosArrowDown
                      className={state.inventorySubtab && 'open-arrow'}
                    />
                  </div>
                  <div
                    className={`sidebar-group-secondary ${
                      state.inventorySubtab && 'expand-sidebar'
                    }`}
                  >
                    <PrivateLink to="/inventory">Search</PrivateLink>
                    <PrivateLink to="/inventory/add">Add</PrivateLink>
                    <PrivateLink to="/inventory/block">Block</PrivateLink>
                    <PrivateLink to="/inventory/unblock">Unblock</PrivateLink>
                    <PrivateLink to="/inventory/blocked">
                      Blocked Devices
                    </PrivateLink>
                  </div>
                </Private>

                <Private to="/resellers">
                  <div
                    className="sidebar-expander-secondary"
                    onClick={handleResellerSubtab}
                  >
                    Resellers{' '}
                    <IoIosArrowDown
                      className={state.resellerSubtab && 'open-arrow'}
                    />
                  </div>
                  <div
                    className={`sidebar-group-secondary ${
                      state.resellerSubtab && 'expand-sidebar'
                    }`}
                  >
                    <PrivateLink to="/resellers">Search</PrivateLink>
                    <PrivateLink to="/resellers/add">Add</PrivateLink>
                    <PrivateLink to="/resellers/summary">Summary</PrivateLink>
                  </div>
                </Private>

                <Private to="/subscriptions">
                  <div
                    className="sidebar-expander-secondary"
                    onClick={handleSubscriptionSubtab}
                  >
                    Subscriptions{' '}
                    <IoIosArrowDown
                      className={state.subscriptionSubtab && 'open-arrow'}
                    />
                  </div>
                  <div
                    className={`sidebar-group-secondary ${
                      state.subscriptionSubtab && 'expand-sidebar'
                    }`}
                  >
                    <PrivateLink to="/subscriptions">Search</PrivateLink>
                    <PrivateLink to="/subscriptions/add">Add</PrivateLink>
                  </div>
                </Private>

                <Private to="/subscriptions/orders">
                  <div
                    className="sidebar-expander-secondary"
                    onClick={handleOrderSubtab}
                  >
                    Orders{' '}
                    <IoIosArrowDown
                      className={state.orderSubtab && 'open-arrow'}
                    />
                  </div>
                  <div
                    className={`sidebar-group-secondary ${
                      state.orderSubtab && 'expand-sidebar'
                    }`}
                  >
                    <PrivateLink to="/subscriptions/orders">Search</PrivateLink>
                    <PrivateLink to="/subscriptions/orders/add">
                      Add
                    </PrivateLink>
                    <PrivateLink to="/subscriptions/orders/ending">
                      Orders ending
                    </PrivateLink>
                  </div>
                </Private>

                <Private to="/countries">
                  <div
                    className="sidebar-expander-secondary"
                    onClick={handleCountrySubtab}
                  >
                    Countries{' '}
                    <IoIosArrowDown
                      className={state.countrySubtab && 'open-arrow'}
                    />
                  </div>
                  <div
                    className={`sidebar-group-secondary ${
                      state.countrySubtab && 'expand-sidebar'
                    }`}
                  >
                    <PrivateLink to="/countries">Search</PrivateLink>
                    <PrivateLink to="/countries/add">Add</PrivateLink>
                  </div>
                </Private>

                <Private to="/providers">
                  <div
                    className="sidebar-expander-secondary"
                    onClick={handleProviderSubtab}
                  >
                    Providers{' '}
                    <IoIosArrowDown
                      className={state.providerSubtab && 'open-arrow'}
                    />
                  </div>
                  <div
                    className={`sidebar-group-secondary ${
                      state.providerSubtab && 'expand-sidebar'
                    }`}
                  >
                    <PrivateLink to="/providers">Search</PrivateLink>
                    <PrivateLink to="/providers/add">Add</PrivateLink>
                  </div>
                </Private>
              </div>
            </Private>

            <Private authNeeded={[USER_AUTHORITIES.RESELLER_ADMIN]}>
              <div
                className="sidebar-expander-primary"
                onClick={handleStockTab}
              >
                Voltaware Sensors Stock Manager{' '}
                <IoIosArrowDown className={state.stockTab && 'open-arrow'} />
              </div>
              <div
                className={`sidebar-group-primary ${
                  state.stockTab && 'expand-sidebar'
                }`}
              >
                <PrivateLink to="/inventory/block">Block</PrivateLink>
                <PrivateLink to="/inventory/unblock">Unblock</PrivateLink>
                <PrivateLink to="/inventory/blocked">
                  Blocked Devices
                </PrivateLink>
              </div>
              <PrivateLink to="/subscriptions" onClick={closeTabs}>
                Subscriptions
              </PrivateLink>
              <PrivateLink to="/subscriptions/orders" onClick={closeTabs}>
                Orders
              </PrivateLink>
            </Private>

            <Private authNeeded={[USER_AUTHORITIES.RESELLER_USER]}>
              <PrivateLink to="/inventory/blocked" onClick={closeTabs}>
                Blocked Devices
              </PrivateLink>
            </Private>

            <Private to="/users">
              <div
                className="sidebar-expander-primary"
                onClick={handleSettingsTab}
              >
                Settings{' '}
                <IoIosArrowDown className={state.settingsTab && 'open-arrow'} />
              </div>
              <div
                className={`sidebar-group-primary ${
                  state.settingsTab && 'expand-sidebar'
                }`}
              >
                <PrivateLink to="/users">Users</PrivateLink>
              </div>
            </Private>

            <Private to="/reseller-users">
              <div
                className="sidebar-expander-primary"
                onClick={handleSettingsTab}
              >
                Settings{' '}
                <IoIosArrowDown className={state.settingsTab && 'open-arrow'} />
              </div>
              <div
                className={`sidebar-group-primary ${
                  state.settingsTab && 'expand-sidebar'
                }`}
              >
                <PrivateLink to="/reseller-users">Users</PrivateLink>
              </div>
            </Private>
          </nav>

          <Link to="/logout" className="logout">
            <img src={logout} alt="voltaware crm logout" /> Logout
          </Link>
        </div>
      )}
    </>
  );
}

export default Sidebar;
