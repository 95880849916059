import { useReducer, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import API from '../../components/api';
import { INTEGRATIONS } from '../../components/tpi';
import { COUNTRIES } from '../../components/country';

import Button from '../../elements/button';
import Input from '../../elements/forms/input';
import InputSelect from '../../elements/forms/input-select';
import Text from '../../elements/text';
import Textarea from '../../elements/forms/textarea';
import Title from '../../elements/title';
import Select from '../../elements/forms/select';
import Loading from '../../elements/loading';
import ErrorMessage from '../../elements/error-message';

import './edit.sass';

const initialState = {
  loading: false,
  id: '',
  name: '',
  country: '',
  email: '',
  notes: '',
  phone: '',
  contact: '',
  integrations: [],
  feedbackMessage: '',
  fieldsWithError: [],
  loadingCountries: true,
  countriesOptions: [],
  countriesValues: [],
  loadCountriesError: false,
  loadResellerError: false,
};

function ResellersEdit() {
  const location = useLocation();

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { ...initialState, isIntegrationsPage: location.state.mode === 'add' }
  );
  const isMountedRef = useRef(null);

  useEffect(() => {
    isMountedRef.current = true;
    loadCountries();
    loadReseller();

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const loadReseller = () => {
    API.RESELLERS.SEARCH(location.state.resellerId, 'ID')
      .then((response) => {
        if (!isMountedRef.current) {
          return;
        }

        const reseller = response.data[0];
        let integrations = INTEGRATIONS.TO_ARRAY(reseller.integrations);
        if (
          reseller.integrationTypes &&
          reseller.integrationTypes.length &&
          reseller.integrationTypes.indexOf('CRM') >= 0
        )
          integrations.push('CRM');

        setState({
          loading: false,
          id: reseller.id,
          name: reseller.name,
          country: reseller.country.isoCode,
          email: reseller.email,
          notes: reseller.notes || '',
          phone: reseller.phone,
          contact: reseller.contactName,
          integrationsJson: reseller.integrations,
          integrations: integrations,
          feedbackMessage: '',
          editFeedbackMessage: '',
          fieldsWithError: [],
          loadCountriesError: false,
          integrationError: false,
          loadingIntegration: false,
        });
      })
      .catch((response) => {
        setState({ loadResellerError: true });
        console.error(
          'Could not load reseller details. Please, try reloading the page or contact support.'
        );
      });
  };

  const loadCountries = () => {
    API.COUNTRY.SUMMARY()
      .then((response) => {
        if (!isMountedRef.current) {
          return;
        }

        if (response.status === 200) {
          let options = COUNTRIES.GET_OPTIONS(response.data),
            values = COUNTRIES.GET_VALUES(response.data);

          initialState.countriesOptions = options;
          initialState.countriesValues = values;
          setState({
            countriesOptions: options,
            countriesValues: values,
            loadingCountries: false,
          });
        } else {
          setState({ loadingCountries: false, loadCountriesError: true });
          console.error('Error while loading countries.');
        }
      })
      .catch((response) => {
        setState({ loadingCountries: false, loadCountriesError: true });
        console.error('Error while loading countries.');
      });
  };

  const changeInputSelect = async (e) => {
    e.persist();

    setState({ loadingIntegration: true });
    let value = state[e.target.name],
      index;

    //Check integrations and permissions
    if (e.target.checked) {
      value.push(e.target.value);

      if (e.target.value === INTEGRATIONS.RABBIT_PERMS.full) {
        try {
          let index = value.indexOf(INTEGRATIONS.RABBIT_PERMS.slim);
          if (index > -1) {
            value.splice(index, 1);
            const integration = state.integrationsJson.find(
              (integration) =>
                integration.type === INTEGRATIONS.TYPES.rabbitServices
            );
            const integrationId = integration ? integration.id : null;
            await API.RESELLERS.INTEGRATIONS.DELETE(integrationId);
          }
          const reseller = await API.RESELLERS.INTEGRATIONS.ADD(
            state.id,
            INTEGRATIONS.TYPES.rabbitServices,
            [INTEGRATIONS.RABBIT_PERMS.full]
          );
          setState({
            integrationsJson: [...reseller.data.integrations],
            [e.target.name]: value,
            loadingIntegration: false,
            integrationError: false,
          });
        } catch (error) {
          console.log(error);
          return setState({
            integrationError: true,
            loadingIntegration: false,
          });
        }
      }

      if (e.target.value === INTEGRATIONS.RABBIT_PERMS.slim) {
        try {
          let index = value.indexOf(INTEGRATIONS.RABBIT_PERMS.full);
          if (index > -1) {
            value.splice(index, 1);
            const integration = state.integrationsJson.find(
              (integration) =>
                integration.type === INTEGRATIONS.TYPES.rabbitServices
            );
            const integrationId = integration ? integration.id : null;
            await API.RESELLERS.INTEGRATIONS.DELETE(integrationId);
          }
          const reseller = await API.RESELLERS.INTEGRATIONS.ADD(
            state.id,
            INTEGRATIONS.TYPES.rabbitServices,
            [INTEGRATIONS.RABBIT_PERMS.slim]
          );
          setState({
            integrationsJson: [...reseller.data.integrations],
            [e.target.name]: value,
            loadingIntegration: false,
            integrationError: false,
          });
        } catch (error) {
          console.log(error);
          return setState({
            integrationError: true,
            loadingIntegration: false,
          });
        }
      }

      if (e.target.value === INTEGRATIONS.TYPES.resellerAPI) {
        try {
          const reseller = await API.RESELLERS.INTEGRATIONS.ADD(
            state.id,
            INTEGRATIONS.TYPES.resellerAPI,
            []
          );
          setState({
            integrationsJson: [...reseller.data.integrations],
            [e.target.name]: value,
            loadingIntegration: false,
            integrationError: false,
          });
        } catch (error) {
          console.log(error);
          return setState({
            integrationError: true,
            loadingIntegration: false,
          });
        }
      }

      if (
        e.target.value ===
        INTEGRATIONS.RESELLER_API_PERMS.thirtyMinutesDisaggregation
      ) {
        try {
          const integrationId = state.integrationsJson.find(
            (integration) => integration.type === INTEGRATIONS.TYPES.resellerAPI
          ).id;
          await API.RESELLERS.INTEGRATIONS.PERMISSIONS.ADD(
            integrationId,
            INTEGRATIONS.RESELLER_API_PERMS.thirtyMinutesDisaggregation
          );
          setState({
            [e.target.name]: value,
            loadingIntegration: false,
            integrationError: false,
          });
        } catch (error) {
          console.log(error);
          return setState({
            integrationError: true,
            loadingIntegration: false,
          });
        }
      }

      if (
        e.target.value === INTEGRATIONS.BUILDING_MONITORING_PERMS.rootReseller
      ) {
        try {
          let index = value.indexOf(
            INTEGRATIONS.BUILDING_MONITORING_PERMS.regularReseller
          );
          if (index > -1) {
            value.splice(index, 1);
            const integration = state.integrationsJson.find(
              (integration) =>
                integration.type === INTEGRATIONS.TYPES.buildingMonitoring
            );
            const integrationId = integration ? integration.id : null;
            await API.RESELLERS.INTEGRATIONS.DELETE(integrationId);
          }
          const reseller = await API.RESELLERS.INTEGRATIONS.ADD(
            state.id,
            INTEGRATIONS.TYPES.buildingMonitoring,
            [INTEGRATIONS.BUILDING_MONITORING_PERMS.rootReseller]
          );
          setState({
            integrationsJson: [...reseller.data.integrations],
            [e.target.name]: value,
            loadingIntegration: false,
            integrationError: false,
          });
        } catch (error) {
          console.log(error);
          return setState({
            integrationError: true,
            loadingIntegration: false,
          });
        }
      }

      if (
        e.target.value ===
        INTEGRATIONS.BUILDING_MONITORING_PERMS.regularReseller
      ) {
        try {
          let index = value.indexOf(
            INTEGRATIONS.BUILDING_MONITORING_PERMS.rootReseller
          );
          if (index > -1) {
            value.splice(index, 1);
            const integration = state.integrationsJson.find(
              (integration) =>
                integration.type === INTEGRATIONS.TYPES.buildingMonitoring
            );
            const integrationId = integration ? integration.id : null;
            await API.RESELLERS.INTEGRATIONS.DELETE(integrationId);
          }
          const reseller = await API.RESELLERS.INTEGRATIONS.ADD(
            state.id,
            INTEGRATIONS.TYPES.buildingMonitoring,
            [INTEGRATIONS.BUILDING_MONITORING_PERMS.regularReseller]
          );
          setState({
            integrationsJson: [...reseller.data.integrations],
            [e.target.name]: value,
            loadingIntegration: false,
            integrationError: false,
          });
        } catch (error) {
          console.log(error);
          return setState({
            integrationError: true,
            loadingIntegration: false,
          });
        }
      }

      if (e.target.value === INTEGRATIONS.TYPES.smartMeterEvent ||
          e.target.value === INTEGRATIONS.TYPES.smartMeterPacket ||
          e.target.value === INTEGRATIONS.TYPES.smartMeterStatistical
        ) {
        try {
          const reseller = await API.RESELLERS.INTEGRATIONS.ADD(
            state.id,
            e.target.value,
            []
          );
          setState({
            integrationsJson: [...reseller.data.integrations],
            [e.target.name]: value,
            loadingIntegration: false,
            integrationError: false,
          });
        } catch (error) {
          console.log(error);
          return setState({
            integrationError: true,
            loadingIntegration: false,
          });
        }
      }

      if (
        e.target.value === 'CRM' ||
        e.target.value === INTEGRATIONS.TYPES.emailReport
      ) {
        try {
          const reseller = await API.RESELLERS.INTEGRATIONS.ADD(
            state.id,
            e.target.value,
            []
          );
          setState({
            integrationsJson: [...reseller.data.integrations],
            [e.target.name]: value,
            loadingIntegration: false,
            integrationError: false,
          });
        } catch (error) {
          console.log(error);
          return setState({
            integrationError: true,
            loadingIntegration: false,
          });
        }
      }
    } else if (!e.target.checked) {
      //Uncheck integrations and permissions

      if (
        e.target.value === INTEGRATIONS.RABBIT_PERMS.full ||
        e.target.value === INTEGRATIONS.RABBIT_PERMS.slim
      ) {
        try {
          index = value.indexOf(e.target.value);
          value.splice(index, 1);
          const integration = state.integrationsJson.find(
            (integration) =>
              integration.type === INTEGRATIONS.TYPES.rabbitServices
          );
          const integrationId = integration ? integration.id : null;
          await API.RESELLERS.INTEGRATIONS.DELETE(integrationId);
          const newIntegrationsJson = state.integrationsJson.filter(
            (integration) => integration.id !== integrationId
          );
          setState({
            [e.target.name]: value,
            loadingIntegration: false,
            integrationsJson: newIntegrationsJson,
            integrationError: false,
          });
        } catch (error) {
          console.log(error);
          return setState({
            integrationError: true,
            loadingIntegration: false,
          });
        }
      }

      if (e.target.value === INTEGRATIONS.TYPES.resellerAPI) {
        try {
          index = value.indexOf(e.target.value);
          value.splice(index, 1);

          const thirtyIndex = value.indexOf(
            INTEGRATIONS.RESELLER_API_PERMS.thirtyMinutesDisaggregation
          );
          if (thirtyIndex > -1) {
            value.splice(thirtyIndex, 1);
          }

          const integration = state.integrationsJson.find(
            (integration) => integration.type === INTEGRATIONS.TYPES.resellerAPI
          );
          const integrationId = integration ? integration.id : null;
          await API.RESELLERS.INTEGRATIONS.DELETE(integrationId);
          const newIntegrationsJson = state.integrationsJson.filter(
            (integration) => integration.id !== integrationId
          );
          setState({
            [e.target.name]: value,
            loadingIntegration: false,
            integrationsJson: newIntegrationsJson,
            integrationError: false,
          });
        } catch (error) {
          console.log(error);
          return setState({
            integrationError: true,
            loadingIntegration: false,
          });
        }
      }

      if (
        e.target.value ===
        INTEGRATIONS.RESELLER_API_PERMS.thirtyMinutesDisaggregation
      ) {
        try {
          index = value.indexOf(e.target.value);
          value.splice(index, 1);
          const integration = state.integrationsJson.find(
            (integration) => integration.type === INTEGRATIONS.TYPES.resellerAPI
          );
          const integrationId = integration ? integration.id : null;
          await API.RESELLERS.INTEGRATIONS.PERMISSIONS.DELETE(
            integrationId,
            INTEGRATIONS.RESELLER_API_PERMS.thirtyMinutesDisaggregation
          );
          setState({
            [e.target.name]: value,
            loadingIntegration: false,
            integrationError: false,
          });
        } catch (error) {
          console.log(error);
          return setState({
            integrationError: true,
            loadingIntegration: false,
          });
        }
      }

      if (
        e.target.value ===
          INTEGRATIONS.BUILDING_MONITORING_PERMS.rootReseller ||
        e.target.value ===
          INTEGRATIONS.BUILDING_MONITORING_PERMS.regularReseller
      ) {
        try {
          index = value.indexOf(e.target.value);
          value.splice(index, 1);
          const integration = state.integrationsJson.find(
            (integration) =>
              integration.type === INTEGRATIONS.TYPES.buildingMonitoring
          );
          const integrationId = integration ? integration.id : null;
          await API.RESELLERS.INTEGRATIONS.DELETE(integrationId);
          const newIntegrationsJson = state.integrationsJson.filter(
            (integration) => integration.id !== integrationId
          );
          setState({
            [e.target.name]: value,
            loadingIntegration: false,
            integrationsJson: newIntegrationsJson,
            integrationError: false,
          });
        } catch (error) {
          console.log(error);
          return setState({
            integrationError: true,
            loadingIntegration: false,
          });
        }
      }

      if (
        e.target.value === INTEGRATIONS.TYPES.smartMeterEvent ||
        e.target.value === INTEGRATIONS.TYPES.smartMeterPacket ||
        e.target.value === INTEGRATIONS.TYPES.smartMeterStatistical
      ) {
        try {
          index = value.indexOf(e.target.value);
          value.splice(index, 1);
          const integration = state.integrationsJson.find(
            (integration) => integration.type === e.target.value
          );
          const integrationId = integration ? integration.id : null;
          await API.RESELLERS.INTEGRATIONS.DELETE(integrationId);
          const newIntegrationsJson = state.integrationsJson.filter(
            (integration) => integration.id !== integrationId
          );
          setState({
            [e.target.name]: value,
            loadingIntegration: false,
            integrationsJson: newIntegrationsJson,
            integrationError: false,
          });
        } catch (error) {
          console.log(error);
          return setState({
            integrationError: true,
            loadingIntegration: false,
          });
        }
      }

      if (
        e.target.value === 'CRM' ||
        e.target.value === INTEGRATIONS.TYPES.emailReport
      ) {
        try {
          index = value.indexOf(e.target.value);
          value.splice(index, 1);
          const integration = state.integrationsJson.find(
            (integration) => integration.type === e.target.value
          );
          const integrationId = integration ? integration.id : null;
          await API.RESELLERS.INTEGRATIONS.DELETE(integrationId);
          const newIntegrationsJson = state.integrationsJson.filter(
            (integration) => integration.id !== integrationId
          );
          setState({
            [e.target.name]: value,
            loadingIntegration: false,
            integrationsJson: newIntegrationsJson,
            integrationError: false,
          });
        } catch (error) {
          console.log(error);
          return setState({
            integrationError: true,
            loadingIntegration: false,
          });
        }
      }
    }
  };

  const change = (e) => {
    setState({ [e.target.name]: e.target.value });
  };

  const validate = () => {
    let fieldsWithError = [];

    if (state.name === '') fieldsWithError.push('name');
    if (
      state.country === '' ||
      state.countriesValues.indexOf(state.country) < 0
    )
      fieldsWithError.push('country');
    if (state.email === '') fieldsWithError.push('email');
    if (state.phone === '') fieldsWithError.push('phone');
    if (state.contact === '') fieldsWithError.push('contact');
    setState({ fieldsWithError: fieldsWithError, loading: false });
    return !fieldsWithError.length;
  };

  const send = () => {
    setState({ loading: true });

    API.RESELLERS.EDIT(
      state.id,
      state.name,
      state.country,
      state.email,
      state.phone,
      state.contact,
      state.notes
    )
      .then((response) => {
        setState({
          editFeedbackMessage:
            'The reseller information was edited successfully.',
          loading: false,
        });
      })
      .catch((error) => {
        console.error(error);
        setState({ loading: false });
      });
  };

  const submit = (e) => {
    e.preventDefault();

    e.target.querySelector('button[type="submit"]').blur();

    setState({
      loading: true,
      feedbackMessage: '',
      editFeedbackMessage: '',
    });
    if (validate()) {
      send();
    }

    return false;
  };

  const createResellerIntegrationOptions = () => {
    const { RESELLER_API_INTEGRATIONS_OPTIONS, RESELLER_API_PERMS } =
      INTEGRATIONS;
    const { integrations } = state;

    const removeOptionWhenIntegrationNotSelected = (option) =>
      option.value === RESELLER_API_PERMS.thirtyMinutesDisaggregation &&
      !integrations.includes(RESELLER_API_PERMS.resellerAPI)
        ? false
        : true;

    return RESELLER_API_INTEGRATIONS_OPTIONS.filter(
      removeOptionWhenIntegrationNotSelected
    );
  };

  return (
    <section id="page-resellers-edit">
      <Title element="h4" text="Resellers - Edit" />

      <nav>
        <div
          id="general-information"
          onClick={() => setState({ isIntegrationsPage: false })}
          className={!state.isIntegrationsPage ? 'active' : ''}
        >
          General Information
        </div>
        <div
          id="integrations"
          onClick={() => setState({ isIntegrationsPage: true })}
          className={state.isIntegrationsPage ? 'active' : ''}
        >
          Integrations
        </div>
      </nav>

      {state.loadResellerError && (
        <ErrorMessage message="Could not load reseller details. Please, try reloading the page or contact support" />
      )}

      {!state.isIntegrationsPage && (
        <>
          {state.loadCountriesError ? (
            <ErrorMessage message="Could not load countries. Please, try again or contact support." />
          ) : null}

          {state.id && state.feedbackMessage ? (
            <div style={{ marginBottom: 40 }}>
              <Text
                bold={true}
                highlight={true}
                text="The information about the reseller was successfully uploaded to the system."
              />
              <Text bold={true} highlight={true} text={state.feedbackMessage} />
            </div>
          ) : null}

          {state.editFeedbackMessage ? (
            <div style={{ marginBottom: 40 }}>
              <Text
                bold={true}
                highlight={true}
                text={state.editFeedbackMessage}
              />
            </div>
          ) : null}

          {state.loadingCountries ? (
            <Loading />
          ) : (
            <form action="#" onSubmit={submit}>
              <Text
                bold={true}
                text="Please fill all the information to edit the reseller."
              />
              <div>
                <Input
                  change={change}
                  disabled={state.loading}
                  hasError={state.fieldsWithError.indexOf('name') >= 0}
                  value={state.name}
                  name="name"
                  placeholder="Reseller name"
                  type="text"
                />

                <Select
                  change={change}
                  disabled={state.loading}
                  hasError={state.fieldsWithError.indexOf('country') >= 0}
                  options={state.countriesOptions}
                  value={state.country}
                  name="country"
                  placeholder="Country"
                />

                <Input
                  change={change}
                  disabled={state.loading}
                  hasError={state.fieldsWithError.indexOf('email') >= 0}
                  value={state.email}
                  name="email"
                  placeholder="Email"
                  type="email"
                />
                <Input
                  change={change}
                  disabled={state.loading}
                  hasError={state.fieldsWithError.indexOf('phone') >= 0}
                  value={state.phone}
                  name="phone"
                  placeholder="Phone number"
                  type="number"
                />

                <Input
                  change={change}
                  disabled={state.loading}
                  hasError={state.fieldsWithError.indexOf('contact') >= 0}
                  value={state.contact}
                  name="contact"
                  placeholder="Contact name"
                  type="text"
                />

                <Textarea
                  change={change}
                  disabled={state.loading}
                  name="notes"
                  placeholder="Notes"
                  value={state.notes}
                />
              </div>

              <Button disabled={state.loading} label="Submit" type="submit" />
            </form>
          )}
        </>
      )}
      {state.isIntegrationsPage &&
        (state.loadingIntegration ? (
          <Loading />
        ) : (
          <div id="integrations">
            {state.integrationError && (
              <ErrorMessage message="Could not update integrations. Please, try reloading the page or contact support." />
            )}
            <InputSelect
              change={changeInputSelect}
              checked={state.integrations}
              disabled={state.loading}
              options={INTEGRATIONS.RAW_EVENTS_INTEGRATIONS_OPTIONS}
              name="integrations"
              placeholder="Voltaware's sensors raw event integration"
              type="checkbox"
              inputSelectTooltipClass="resellers-integrations-q1"
              inputSelectTooltip={<div className="tooltip-element-container">Enable this only when the user requested to use our AMPQ Sensor API to receive raw data from the sensors. <br/>Full will give them access to transients and harmonics and slim won't. By enabling it, it will generate credentials that you will need to send them.</div>}
            />

            <InputSelect
              change={changeInputSelect}
              checked={state.integrations}
              disabled={state.loading}
              options={createResellerIntegrationOptions()}
              nestedSelects={true}
              name="integrations"
              placeholder="Services API integration"
              type="checkbox"
              inputSelectTooltipClass="resellers-integrations-q2"
              inputSelectTooltip={<div className="tooltip-element-container">Enable this only when the user requested to use our HTTP Services API. <br/>By enabling it, it will generate credentials that you will need to send them.</div>}
            />

            <InputSelect
              change={changeInputSelect}
              checked={state.integrations}
              disabled={state.loading}
              options={INTEGRATIONS.BUILDING_MONITORING_OPTIONS}
              name="integrations"
              placeholder="EMD integration"
              type="checkbox"
              inputSelectTooltipClass="resellers-integrations-q3"
              inputSelectTooltip={<div className="tooltip-element-container">Enable this in case you want to give access to a reseller to our EMD Dashboard.</div>}
            />

            <InputSelect
              change={changeInputSelect}
              checked={state.integrations}
              disabled={state.loading}
              options={INTEGRATIONS.SMART_METER_INTEGRATIONS_OPTIONS}
              name="integrations"
              placeholder="Smart meter integrations"
              type="checkbox"
              warning="WARNING. Once you remove Smart Meter integrations, data will no longer be collected."
              inputSelectTooltipClass="resellers-integrations-q4"
              inputSelectTooltip={<div className="tooltip-element-container">Only enable the type of messages they will send to us. By enabling it, it will generate credentials that you will need to send them.</div>}
            />

            <InputSelect
              change={changeInputSelect}
              checked={state.integrations}
              disabled={state.loading}
              options={INTEGRATIONS.OTHER_INTEGRATIONS_OPTIONS}
              name="integrations"
              placeholder="Other integrations"
              type="checkbox"
            />
          </div>
        ))}
    </section>
  );
}

export default ResellersEdit;
