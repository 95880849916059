import { useRef, useReducer, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import API from '../../components/api';
import {
  testEmail,
  isAdmin,
  isReseller,
  isBusiness,
} from '../../components/utils';

import AdminTools from '../admin-tools/admin-tools';
import AccountDetails from './account-details';
import AccountPurge from './purge';
import DeleteSensorEvents from './delete-sensor-events';
import AppliancesPage from './appliances-page';
import ChangeLocale from '../admin-tools/change-locale';
import DetailsNav from './details-nav';
import EmailReportCreateSend from '../email-report/email-report-create-send';
import EmailReportHistory from '../email-report/email-report-history';
import EventsExporting from '../events-exporting/events-exporting';
import EventsExportingHistory from '../events-exporting/events-exporting-history';
import PasswordGenerate from '../admin-tools/password/password-generate';
import PasswordReset from '../admin-tools/password/password-reset';
import PropertyDetails from './property-details';
import SensorStatus from './sensor-status';
import TariffPage from './tariff';
import UserDetails from './user-details';
import ActivateSmartCable from './activate-smart-cable';

import ErrorMessage from '../../elements/error-message';
import Loading from '../../elements/loading';
import Text from '../../elements/text';

import './details.sass';

const ACCOUNT_DATA_ERROR_MSG =
  'An error occurred while fetching device data. Please try again later or contact support.';

function Details(props) {
  const location = useLocation();
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      client: {},
      clientCopy: {},
      clientEditLoading: false,
      clientFieldsWithError: [],
      editing: false,
      feedbackMessage: '',
      errorMessage: '',
      accountFetchError: false,
      loading: true,
      report: {
        loading: true,
        enabled: false,
        layout: '',
        activatedAt: '',
      },
      propertyDetails: {
        loading: true,
        bedroom_amount: 0,
        home_size: 0,
        home_type: '',
        home_usage_type: '',
        people: [
          { age_range: "ZERO_TO_FIVE", amount: 0 },
          { age_range: "SIX_TO_EIGHTEEN", amount: 0 },
          { age_range: "ADULT", amount: 0 },
          { age_range: "PENSIONER", amount: 0 },
        ],
        people_amount: 0,
        people_amount_in_day_time: 0,
        power_generator_source: '',
        property_type: '',
        version: '1.0',
      },
      termsAgreement: {
        loading: true,
      },
    }
  );
  const isMountedRef = useRef(null);

  useEffect(() => {
    isMountedRef.current = true;
    loadClient();

    return () => (isMountedRef.current = false);
  }, []);

  useEffect(() => {
    const id = parseInt(props.clientId, 1);
    if (id && id !== state.client.id) {
      clientSet(id);
    }
  }, [props.clientId]);

  const loadClient = (onlyClient = false) => {
    if (isAdminBusinessOrResellerUser()) {
      clientSet(props.clientId, onlyClient);
    } else {
      restrictedClientSet(props.clientId, onlyClient);
    }
  };

  const reloadClient = () => {
    setState({ accountDataError: false });
    loadClient(true);
  };

  const propertyChanged = (property) => setState({ property: property });

  const clientSet = (id, onlyClient) => {
    API.ACCOUNTS.DETAILS(id)
      .then((response) => {
        if (!isMountedRef.current) {
          return;
        }

        if (
          response.data.account &&
          response.data.account.id &&
          response.data.reseller &&
          response.data.property
        ) {
          let client = {
            id: response.data.account.id,
            account: response.data.account,
            reseller: response.data.reseller,
            property: response.data.property,
            privacy: response.data.privacy,
            stock: response.data.stock,
            stockUpload: response.data.stockUpload,
          };
          setState({
            client: client,
            clientCopy: clone(client),
            loading: false,
          });
          if (!onlyClient) {
            accountTermsAgreementSet(client.property.sensorId);
            emailReportSet(client.property.sensorId);
            propertyDetailsSet(client.property.sensorId);
          }
        } else {
          throw new Error(
            'A data inconsistency was found while fetching device data.'
          );
        }
      })
      .catch((error) => {
        console.error(error);
        setState({
          loading: false,
          errorMessage: <ErrorMessage message={ACCOUNT_DATA_ERROR_MSG} />,
          accountFetchError: true,
        });
      });
  };

  const restrictedClientSet = (id, onlyClient) => {
    API.ACCOUNTS.RESTRICTED_DETAILS(id)
      .then((response) => {
        if (!isMountedRef.current) {
          return;
        }

        const { stock, privacy, stockUpload, account, reseller } =
          response.data;
        if (account && account.id && stock && privacy && stockUpload) {
          let client = {
            id: account.id,
            account: account,
            reseller: reseller,
            privacy: privacy,
            stock: stock,
            stockUpload: stockUpload,
          };
          setState({
            client: client,
            clientCopy: clone(client),
            loading: false,
          });
          if (!onlyClient) {
            accountTermsAgreementSet(client.id);
            emailReportSet(client.id);
            propertyDetailsSet(client.id);
          }
        } else {
          throw new Error(
            'A data inconsistency was found while fetching device data.'
          );
        }
      })
      .catch((error) => {
        console.error(error);
        setState({
          loading: false,
          errorMessage: <ErrorMessage message={ACCOUNT_DATA_ERROR_MSG} />,
          accountFetchError: true,
        });
      });
  };

  const emailReportSet = (id) => {
    API.STOCK.DETAILS(id).then((response) => {
      if (!isMountedRef.current) {
        return;
      }

      let report = {
        loading: false,
        enabled: response.data.receiveReportEmail,
        layout: response.data.reportLayout,
        activatedAt: response.data.activatedAt,
      };
      setState({ report: report });
    });
  };

  const accountTermsAgreementSet = (id) => {
    API.ACCOUNTS.TERMS_AGREEMENT(id)
      .then((response) => {
        if (!isMountedRef.current) {
          return;
        }

        setState({ termsAgreement: { ...response.data, loading: false } });
      })
      .catch((error) => {
        if (error && error.response && error.response.status === 404) {
          setState({
            termsAgreement: { loading: false },
          });
        } else console.error(error);
      });
  };

  const propertyDetailsSet = (id) => {
    API.STOCK.PROPERTY_DETAILS.GET(id)
      .then((response) => {
        if (!isMountedRef.current) {
          return;
        }

        const hasPeople = Boolean(response.data?.people?.length);

        let details = {
          exists: true,
          loading: false,
          bedroom_amount: 0,
          home_size: 0,
          home_type: '',
          home_usage_type: '',
          people_amount: 0,
          people_amount_in_day_time: 0,
          power_generator_source: '',
          property_type: '',
          version: '1.0',
          ...response.data,
          ...(hasPeople &&
            { people:  [
              { age_range: "ZERO_TO_FIVE", amount: 0 },
              { age_range: "SIX_TO_EIGHTEEN", amount: 0 },
              { age_range: "ADULT", amount: 0 },
              { age_range: "PENSIONER", amount: 0 },
              ].reduce((acc, range) => {
                const prev = response.data.people.find(r => r.age_range === range.age_range);
                const ageRangeData = { ...range, amount: prev?.amount ?? 0 };
                return [ ...acc, ageRangeData ];
              }, []),
            }
          ),
        };
        setState({ propertyDetails: details });
      })
      .catch((error) => {
        if (error && error.response && error.response.status === 404) {
          let details = state.propertyDetails;
          details.exists = false;
          details.loading = false;
          setState({ propertyDetails: details });
        } else console.error(error);
      });
  };

  const clone = (obj) => {
    return JSON.parse(JSON.stringify(obj));
  };

  // Client/Account edit
  const clientEditToggle = (editing) => {
    setState({
      client: clone(state.clientCopy),
      clientFieldsWithError: [],
      editing: editing,
      feedbackMessage: '',
    });
  };

  const clientToggleEdit = (e) => {
    e.target.blur();
    clientEditToggle(!state.editing);
  };

  const clientChange = (e) => {
    let client = state.client;
    client.account[e.target.name] = e.target.value;
    setState({ client: client });
  };

  const clientEditCancel = () => {
    clientEditToggle(false);
  };

  const clientEditValidate = () => {
    let clientFieldsWithError = [];

    if (state.client.account.id === '') clientFieldsWithError.push('id');
    if (!testEmail(state.client.account.email))
      clientFieldsWithError.push('email');
    setState({
      clientEditLoading: false,
      clientFieldsWithError: clientFieldsWithError,
    });

    return !clientFieldsWithError.length;
  };

  const clientEditSave = () => {
    setState({ clientEditLoading: true });

    API.ACCOUNTS.EDIT(
      state.client.account.id,
      state.client.account.firstName,
      state.client.account.lastName,
      state.client.account.email,
      state.client.account.mobileNumber
    )
      .then((response) => {
        setState({
          clientCopy: clone(state.client),
          clientEditLoading: false,
          editing: false,
          feedbackMessage: (
            <Text
              bold={true}
              highlight={true}
              text="User Details saved sucessfully."
            />
          ),
        });
      })
      .catch((error) => {
        let clientFieldsWithError = [],
          feedbackMessage = (
            <ErrorMessage message="User Details could not be saved. Please try again later." />
          );
        console.error(error);

        if (
          error.response &&
          error.response.data &&
          error.response.data.errors &&
          error.response.data.errors.length
        ) {
          clientFieldsWithError = error.response.data.errors.reduce(
            (result, element) => {
              if (element.field === 'email') {
                result.push(element.field);
                feedbackMessage = (
                  <ErrorMessage message="Please enter a valid email." />
                );
              }
              return result;
            },
            []
          );
        }

        if (
          error.response &&
          error.response.status &&
          error.response.status === 422 &&
          error.response.data &&
          error.response.data.message
        ) {
          let duplicateEmailRegex = /Email already assigned to a device/;
          if (duplicateEmailRegex.test(error.response.data.message) === true) {
            clientFieldsWithError = 'email';
            feedbackMessage = (
              <ErrorMessage message="This email address already exists. Please enter another email address." />
            );
          }
        }

        setState({
          clientEditLoading: false,
          clientFieldsWithError: clientFieldsWithError,
          feedbackMessage: feedbackMessage,
        });
      });
  };

  const clientEditSubmit = (e) => {
    e.preventDefault();

    e.target.querySelector('button[type="submit"]').blur();

    setState({ clientEditLoading: true, feedbackMessage: '' });
    if (clientEditValidate()) clientEditSave();

    return false;
  };

  const isAdminBusinessOrResellerUser = () => {
    return isAdmin() || isReseller() || isBusiness();
  };

  const { client, propertyDetails } = state;
  const { loadingSensor } = props;
  const locationState = location.state;

  if (state.loading) return <Loading />;

  return (
    <div id="client-details">
      {!state.accountFetchError ? (
        <DetailsNav client={client} property={propertyDetails} />
      ) : null}

      <div id="client-details-columns">
        {!locationState || locationState === 'account' ? (
          <div>
            {state.accountFetchError ? (
              state.errorMessage
            ) : (
              <>
                <UserDetails
                  change={clientChange}
                  client={client}
                  editCancel={clientEditCancel}
                  editing={state.editing}
                  feedbackMessage={state.feedbackMessage}
                  fieldsWithError={state.clientFieldsWithError}
                  loading={state.clientEditLoading}
                  submit={clientEditSubmit}
                  toggleEdit={clientToggleEdit}
                  isSmartMeter={false}
                />

                <AccountDetails
                  client={client}
                  termsAgreement={state.termsAgreement}
                />

                {loadingSensor ? (
                  <Loading />
                ) : (
                  <SensorStatus sensor={props.sensor} />
                )}
              </>
            )}
          </div>
        ) : null}
        {locationState === 'tariff' ? <TariffPage id={client.id} /> : null}
        {locationState === 'admintools' ? (
          <AdminTools
            id={client.id}
            privacy={client.privacy}
            category={client.stock && client.stock.category}
          />
        ) : null}
        {locationState === 'propertydetails' ? (
          <PropertyDetails
            client={client}
            property={propertyDetails}
            propertyChanged={propertyChanged}
          />
        ) : null}
        {locationState === 'appliances' ? (
          <AppliancesPage id={client.id} />
        ) : null}

        {locationState === 'passwdreset' ? (
          <PasswordReset id={client.id} />
        ) : null}
        {locationState === 'passwdgenerate' ? (
          <PasswordGenerate id={client.id} />
        ) : null}
        {locationState === 'changelocale' ? (
          <ChangeLocale id={client.id} reloadClient={reloadClient} />
        ) : null}

        {locationState === 'periodicreporthistory' ? (
          <EmailReportHistory id={client.id} />
        ) : null}
        {locationState === 'periodicreportcreate' ||
        locationState === 'periodicreportsend' ? (
          <EmailReportCreateSend
            client={client}
            mode={locationState}
            sensor={props.sensor}
            activatedAt={state.report.activatedAt}
          />
        ) : null}

        {locationState === 'eventsexporting' ? (
          <EventsExporting client={client} />
        ) : null}
        {locationState === 'eventsexportinghistory' ? (
          <EventsExportingHistory client={client} />
        ) : null}

        {locationState === 'accountpurge' ? (
          <AccountPurge client={client} />
        ) : null}

        {locationState === 'deletesensorevents' ? (
          <DeleteSensorEvents client={client} />
        ) : null}

        {locationState === 'activatesmartcable' &&
        client.stock.category === 'SMARTBOX' ? (
          <ActivateSmartCable client={client} />
        ) : null}
      </div>
    </div>
  );
}

export default Details;
