import FieldWithTooltip from '../../elements/field-with-tooltip';

import HelpOutlineIcon from '@material-ui/icons/Help';

import './input-select.sass';

function InputSelect(props) {
  let divClass = 'input-select';
  if (props.hasError) divClass += ' error';
  if (props.nestedSelects) divClass += ' nested-selects';

  const inputSelectTooltip = props.inputSelectTooltip ? props.inputSelectTooltip : null;

  return (
    <div className={divClass}>
      <div className="flex-container">
        <span className="placeholder">{props.placeholder}</span>
        {inputSelectTooltip &&
          <FieldWithTooltip
            inputSelect={true}
            className={props.inputSelectTooltipClass}
            getLastEvent={() => inputSelectTooltip}
            height="auto"
            width="240px"
            textAlign="left"
            fontSize="0.8rem"
          >
            <div className="field-with-tooltip-card">
              <HelpOutlineIcon style={{fontSize: 15, color: '#5d9f28'}} />
            </div>
          </FieldWithTooltip>}
      </div>
      {props.warning && <p className="no-spacing top-spacing warning">{props.warning}</p>}
      {props.options.map((option, index) => {
        return (
          <label key={index}>
            <div className="flex-container">
              <input
                checked={
                  props.checked !== null && props.checked !== undefined
                    ? typeof props.checked === 'boolean'
                      ? props.checked === option.value
                      : props.checked.indexOf(option.value) >= 0
                    : false
                }
                disabled={props.disabled}
                name={props.name}
                onChange={!props.click ? props.change : undefined}
                onClick={props.click}
                value={option.value}
                type={props.type}
                readOnly={!!props.click}
              />

              {option.label}
              {(props.inputOptionTooltips?.length && !props.inputOptionTooltips[index].hide) &&
                <FieldWithTooltip
                  inputSelect={true}
                  className={`${ props.inputSelectTooltipClass }-option-${ index + 1 }`}
                  getLastEvent={() => props.inputOptionTooltips[index].text}
                  height="auto"
                  width="240px"
                  textAlign="left"
                  fontSize="0.8rem"
                >
                  <div className="field-with-tooltip-card">
                    <HelpOutlineIcon style={{fontSize: 15, color: '#5d9f28'}} />
                  </div>
                </FieldWithTooltip>}
              <span className="check" />
            </div>
          </label>
        );
      })}
    </div>
  );
}

export default InputSelect;
