import { React } from 'react';
import { MdSmartphone } from 'react-icons/md';
import ReactTooltip from 'react-tooltip';

import Private from '../../components/auth/private';
import { USER_AUTHORITIES } from '../../components/constants';

import Button from '../../elements/button';
import Title from '../../elements/title';
import Input from '../../elements/forms/input';
import Text from '../../elements/text';

function UserDetails(props) {
  const { change, client, editing, loading, isSmartMeter } =
    props;

  return (
    <div id="user-details" className="xs-4">
      {props.feedbackMessage ? props.feedbackMessage : null}

      <form action="#" onSubmit={props.submit}>
        <Title element="h6" text="User Details">
          {!isSmartMeter ? (
            <Private
              authNeeded={[
                USER_AUTHORITIES.ADMIN,
                USER_AUTHORITIES.RESELLER_ADMIN,
              ]}
            >
              <Button
                action="title-edit"
                click={props.toggleEdit}
                disabled={loading}
                label=""
                loading={false}
              />
            </Private>
          ) : null}
        </Title>
        {!isSmartMeter ? (
          <>
            <div>
              <Input
                change={change}
                disabled={loading || !editing}
                name="firstName"
                placeholder="First name"
                type="text"
                value={client.account.firstName || ''}
              />
            </div>
            <div>
              <Input
                change={change}
                disabled={loading || !editing}
                name="lastName"
                placeholder="Last name"
                type="text"
                value={client.account.lastName || ''}
              />
            </div>
            <div>
              <Input
                change={change}
                disabled={loading || !editing}
                hasError={props.fieldsWithError.indexOf('email') >= 0}
                name="email"
                placeholder="Email"
                type="email"
                value={client.account.email || ''}
              />
            </div>
            <div>
              <Input
                change={change}
                disabled={loading || !editing}
                name="mobileNumber"
                placeholder="Phone Number"
                type="text"
                value={client.account.mobileNumber || ''}
              />
            </div>
            <div>
              <Input
                change={change}
                disabled={true}
                name="client.reseller"
                placeholder="Reseller"
                type="text"
                value={(client.reseller && client.reseller.name) || ''}
              />
            </div>
          </>
        ) : null}
        {client.property != null ? (
          <>
            <div>
              <Input
                change={change}
                disabled={true}
                name="client.country"
                placeholder="Country"
                type="text"
                value={client.property.isoCountryCode || ''}
              />
            </div>
            <div>
              <Input
                change={change}
                disabled={true}
                name="client.currency"
                placeholder="Currency"
                type="text"
                value={
                  client.property.currencyInfo.internationalCurrencySymbol || ''
                }
              />
            </div>
            <div>
              <Input
                change={change}
                disabled={true}
                name="client.language"
                placeholder="Language"
                type="text"
                value={client.property.isoLocaleCode || ''}
              />
            </div>
            <div>
              <Input
                change={change}
                disabled={true}
                name="client.timezone"
                placeholder="Timezone"
                type="text"
                value={client.property.timeZoneId || ''}
              />
            </div>
            {isSmartMeter && client.account && (
              <div id="smart-meter-app-email">
                <Text inline={true} text={client.account.email} />
                <MdSmartphone
                  size="2em"
                  data-tip="Smart Meter App is enabled"
                />
                <ReactTooltip />
              </div>
            )}
          </>
        ) : null}
        {editing ? (
          <div className="btn-actions">
            <Button
              action="cancel"
              click={props.editCancel}
              disabled={loading}
              label="Cancel"
              loading={false}
            />
            <Button disabled={loading} label="Save" type="submit" />
          </div>
        ) : null}
      </form>
    </div>
  );
}

export default UserDetails;
