import { useReducer } from 'react';
import { useLocation } from 'react-router-dom';

import API from '../../components/api';
import {
  CURRENCIES_OPTIONS,
  HARDWARE_TYPE_OPTIONS,
  PAYMENT_TYPE_OPTIONS,
  SUBSCRIPTION_PRODUCTS_OPTIONS,
} from '../../components/constants';

import Button from '../../elements/button';
import ErrorMessage from '../../elements/error-message';
import Input from '../../elements/forms/input';
import InputSelect from '../../elements/forms/input-select';
import Text from '../../elements/text';
import Textarea from '../../elements/forms/textarea';
import Title from '../../elements/title';
import Select from '../../elements/forms/select';

import './add.sass';

const currencyOptions = CURRENCIES_OPTIONS.map((currency) => {
  let data = { ...currency };
  data.label += '/u';
  return data;
});

let initialState = {
  currency: 'POUNDS',
  duration: '',
  feedbackMessage: '',
  fieldsWithError: [],
  hardwareType: '',
  id: null,
  loading: false,
  name: '',
  notes: '',
  paymentRate: '',
  paymentType: '',
  products: [],
  tolerance: '',
};

function SubscriptionAdd() {
  const location = useLocation();
  const subscription =
    (location && location.state && location.state.subscription) || null;

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    subscription
      ? {
          ...initialState,
          currency: subscription.currency,
          duration: subscription.duration,
          hardwareType: subscription.hardwareType,
          hasOrder: subscription.hasOrder,
          id: subscription.id,
          name: subscription.name,
          notes: subscription.notes,
          paymentRate: subscription.paymentRate,
          paymentType: subscription.paymentType,
          products: subscription.products,
          tolerance: subscription.tolerance,
        }
      : initialState
  );

  const change = (e) => {
    setState({ [e.target.name]: e.target.value });
  };

  const changePaymentType = (e) => {
    change(e);
    if (e.target.value === 'FREE_TRIAL') setState({ paymentRate: 0 });
  };

  const changeProduct = (e) => {
    let products = state.products;
    e.target.checked
      ? products.push(e.target.value)
      : products.splice(products.indexOf(e.target.value), 1);
    setState({ products: products });
  };

  const validate = () => {
    let fieldsWithError = [];

    if (state.name === '' || state.name.length > 50)
      fieldsWithError.push('name');
    if (state.currency === '') fieldsWithError.push('currency');
    if (state.duration === '' || state.duration < 1)
      fieldsWithError.push('duration');
    if (state.hardwareType === '') fieldsWithError.push('hardwareType');
    if (state.paymentType === '') fieldsWithError.push('paymentType');
    if (
      state.paymentRate === '' ||
      state.paymentRate < 0 ||
      (state.paymentType !== 'FREE_TRIAL' && state.paymentRate < 1)
    )
      fieldsWithError.push('paymentRate');
    if (state.tolerance === '' || state.tolerance < 0)
      fieldsWithError.push('tolerance');
    if (!state.products.length) fieldsWithError.push('products');
    setState({ fieldsWithError: fieldsWithError, loading: false });

    return !fieldsWithError.length;
  };

  const send = () => {
    setState({ loading: true });

    let fn = state.id ? API.SUBSCRIPTION.EDIT : API.SUBSCRIPTION.ADD;

    fn(
      state.name,
      state.duration,
      state.hardwareType,
      state.paymentType,
      state.paymentRate,
      state.currency,
      state.tolerance,
      state.products,
      state.notes,
      state.id
    )
      .then((response) => {
        const id = state.id;

        setState({
          feedbackMessage: (
            <Text
              bold={true}
              highlight={true}
              text={`Subscription ${state.name} was ${
                id ? 'edited' : 'created'
              } successfully!`}
            />
          ),
          id: response.data.id,
          loading: false,
        });
      })
      .catch((error) => {
        console.error(error);

        setState({
          feedbackMessage: (
            <ErrorMessage message="There was an error saving the subscription. Please, try again or contact support." />
          ),
          loading: false,
        });
      });
  };

  const submit = (e) => {
    e.preventDefault();

    e.target.querySelector('button[type="submit"]').blur();

    setState({ feedbackMessage: '', loading: true });
    if (validate()) send();

    return false;
  };

  return (
    <section id="page-subscription-add">
      <Title
        element="h4"
        text={`Subscriptions - ${state.id ? 'Edit' : 'Add'}`}
      />
      {state.feedbackMessage ? state.feedbackMessage : null}
      <Text
        bold={true}
        text={`Please fill all the information to ${
          state.id ? 'edit the' : 'add a new'
        } subscription.`}
      />

      <form action="#" onSubmit={submit}>
        <div>
          <Input
            change={change}
            disabled={state.loading}
            hasError={state.fieldsWithError.indexOf('name') >= 0}
            maxLength="50"
            name="name"
            placeholder="Subscription name"
            value={state.name}
          />

          <Input
            change={change}
            disabled={state.loading || state.hasOrder}
            hasError={state.fieldsWithError.indexOf('duration') >= 0}
            name="duration"
            placeholder="Min term of agreement (in months)"
            type="number"
            value={state.duration}
          />

          <Select
            change={change}
            disabled={state.loading || state.hasOrder}
            hasError={state.fieldsWithError.indexOf('hardwareType') >= 0}
            name="hardwareType"
            options={HARDWARE_TYPE_OPTIONS}
            placeholder="Hardware type"
            value={state.hardwareType}
          />

          <Select
            change={changePaymentType}
            disabled={state.loading || state.hasOrder}
            hasError={state.fieldsWithError.indexOf('paymentType') >= 0}
            name="paymentType"
            options={PAYMENT_TYPE_OPTIONS}
            placeholder="Frequency of payment"
            value={state.paymentType}
          />

          <div className="payment-currency-wrapper">
            <Input
              change={change}
              disabled={
                state.loading ||
                state.hasOrder ||
                state.paymentType === 'FREE_TRIAL'
              }
              hasError={state.fieldsWithError.indexOf('paymentRate') >= 0}
              name="paymentRate"
              placeholder="Rate (based on frequency)"
              type="number"
              value={state.paymentRate}
            />

            <Select
              change={change}
              disabled={state.loading || state.hasOrder}
              hasError={state.fieldsWithError.indexOf('currency') >= 0}
              name="currency"
              options={currencyOptions}
              value={state.currency}
            />
          </div>

          <Input
            change={change}
            disabled={state.loading || state.hasOrder}
            hasError={state.fieldsWithError.indexOf('tolerance') >= 0}
            name="tolerance"
            placeholder="Tolerance period (in months)"
            type="number"
            value={state.tolerance}
          />
        </div>

        <div>
          <InputSelect
            change={changeProduct}
            checked={state.products}
            disabled={state.loading}
            name="products"
            options={SUBSCRIPTION_PRODUCTS_OPTIONS}
            placeholder="Products"
            type="checkbox"
          />

          <Textarea
            change={change}
            disabled={state.loading || state.hasOrder}
            name="notes"
            placeholder="Notes"
            value={state.notes}
          />
        </div>

        <Button
          disabled={state.loading}
          label={`${state.id ? 'Save' : 'Add'} subscription`}
          type="submit"
        />
      </form>
    </section>
  );
}

export default SubscriptionAdd;
