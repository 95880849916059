import { useRef, useReducer, useEffect, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

import API from '../../components/api';
import { COUNTRIES } from '../../components/country';

import Button from '../../elements/button';
import Input from '../../elements/forms/input';
import Text from '../../elements/text';
import Title from '../../elements/title';
import Select from '../../elements/forms/select';
import Loading from '../../elements/loading';
import ErrorMessage from '../../elements/error-message';

import './add-edit.sass';

const mode = { add: 'add', edit: 'edit' };

const initialState = {
  loading: false,
  mode: mode.add,
  id: '',
  name: '',
  country: '',
  feedbackMessage: '',
  fieldsWithError: [],
  loadingCountries: true,
  countriesOptions: [],
  countriesValues: [],
  loadCountriesError: false,
};

const messages = {
  add: {
    title: 'Providers - Add',
    instruction: 'Please fill all the information to add a new provider.',
    submit: 'Add provider',
  },
  edit: {
    title: 'Providers - Edit',
    instruction: 'Please fill all the information to edit the provider.',
    submit: 'Submit',
  },
};

function ProviderAddEdit(props) {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    initialState
  );
  const location = useLocation();
  const isMountedRef = useRef(null);

  useLayoutEffect(() => {
    if (
      location &&
      location.state &&
      location.state.editing &&
      location.state.provider
    ) {
      setState({
        loading: false,
        mode: mode.edit,
        id: location.state.provider.id,
        name: location.state.provider.name,
        country: location.state.provider.country,
        feedbackMessage: '',
        editFeedbackMessage: '',
        fieldsWithError: [],
        loadingCountries: true,
        countriesOptions: [],
        countriesValues: [],
        loadCountriesError: false,
      });
    }
  }, []);

  useEffect(() => {
    isMountedRef.current = true;
    loadCountries();
    return () => (isMountedRef.current = false);
  }, []);

  const loadCountries = () => {
    API.COUNTRY.SUMMARY()
      .then((response) => {
        if (!isMountedRef.current) {
          return;
        }

        if (response.status === 200) {
          let options = COUNTRIES.GET_OPTIONS(response.data),
            values = COUNTRIES.GET_VALUES(response.data);

          initialState.countriesOptions = options;
          initialState.countriesValues = values;
          setState({
            countriesOptions: options,
            countriesValues: values,
            loadingCountries: false,
          });
        } else {
          setState({ loadingCountries: false, loadCountriesError: true });
          console.error('Error while loading countries.');
        }
      })
      .catch((response) => {
        setState({ loadingCountries: false, loadCountriesError: true });
        console.error('Error while loading countries.');
      });
  };

  const reset = (status, id) => {
    let message = '';

    const state = initialState;
    state.id = id;
    state.loadingCountries = false;

    if (status === 'confirmed') {
      message = `The provider ID number is ${id}.`;
      state.feedbackMessage = message;
    }

    setState(state);
  };

  const change = (e) => {
    setState({ [e.target.name]: e.target.value });
  };

  const validate = () => {
    let fieldsWithError = [];

    if (state.name === '') fieldsWithError.push('name');
    if (
      state.country === '' ||
      state.countriesValues.indexOf(state.country) < 0
    )
      fieldsWithError.push('country');
    setState({ fieldsWithError: fieldsWithError, loading: false });

    return !fieldsWithError.length;
  };

  const send = () => {
    setState({ loading: true });

    if (state.mode === mode.edit) {
      API.STOCK.PROVIDER.EDIT(state.id, state.name, state.country)
        .then((response) => {
          setState({
            editFeedbackMessage:
              'The provider information was edited successfully.',
            loading: false,
          });
        })
        .catch((error) => {
          console.error(error);
          setState({ loading: false });
        });
    } else {
      API.STOCK.PROVIDER.ADD(state.name, state.country)
        .then((response) => {
          reset('confirmed', response.data.id);
        })
        .catch((error) => {
          console.error(error);
          setState({ loading: false });
        });
    }
  };

  const submit = (e) => {
    e.preventDefault();

    e.target.querySelector('button[type="submit"]').blur();

    setState({
      loading: true,
      feedbackMessage: '',
      editFeedbackMessage: '',
    });

    if (validate()) {
      send();
    }

    return false;
  };

  return (
    <section id="page-provider-add-edit">
      <Title element="h4" text={messages[state.mode].title} />

      {state.loadCountriesError ? (
        <ErrorMessage message="Could not load countries. Please, try again or contact support." />
      ) : null}

      {state.id && state.feedbackMessage ? (
        <div style={{ marginBottom: 40 }}>
          <Text
            bold={true}
            highlight={true}
            text="The provider was created successfully."
          />
          <Text bold={true} highlight={true} text={state.feedbackMessage} />
        </div>
      ) : null}

      {state.editFeedbackMessage ? (
        <div style={{ marginBottom: 40 }}>
          <Text bold={true} highlight={true} text={state.editFeedbackMessage} />
        </div>
      ) : null}

      {state.loadingCountries ? (
        <Loading />
      ) : (
        <form action="#" onSubmit={submit}>
          <Text bold={true} text={messages[state.mode].instruction} />
          <div>
            <Input
              change={change}
              disabled={state.loading}
              hasError={state.fieldsWithError.indexOf('name') >= 0}
              value={state.name}
              name="name"
              placeholder="Provider name"
              type="text"
            />

            <Select
              change={change}
              disabled={state.loading}
              hasError={state.fieldsWithError.indexOf('country') >= 0}
              options={state.countriesOptions}
              value={state.country}
              name="country"
              placeholder="Country"
            />

            <Button
              disabled={state.loading}
              label={messages[state.mode].submit}
              type="submit"
            />
          </div>
        </form>
      )}
    </section>
  );
}

export default ProviderAddEdit;
