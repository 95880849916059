import moment from 'moment-timezone';
import 'moment/locale/en-gb';

moment().tz('Europe/London').format();
moment.locale('en-gb');

const formatCompleteDateHour = (dateHour) => {
  return (
    moment(dateHour).utc().format('DD/MM/YYYY HH:mm:ss') +
    ' - ' +
    moment(dateHour).locale('en-gb').fromNow()
  );
};

const formatPartialDateHour = (dateHour) => {
  return moment(dateHour).utc().format('DD/MM/YYYY HH:mm:ss');
};

const DATE_FORMATTER = {
  COMPLETE: formatCompleteDateHour,
  PARTIAL: formatPartialDateHour,
};

export { DATE_FORMATTER };
