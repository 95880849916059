import { useRef, useReducer, useEffect } from 'react';
import { Link } from 'react-router-dom';

import API from '../../components/api';

import Button from '../../elements/button';
import ErrorMessage from '../../elements/error-message';
import Loading from '../../elements/loading';
import Select from '../../elements/forms/select';
import Text from '../../elements/text';
import Title from '../../elements/title';
import SelectLocaleTimezone from '../../elements/forms/select-locale-timezone';

const initialState = {
  countries: [],
  country: '',
  countryName: '',
  timeZoneId: '',
  isoLocaleCode: '',
  loadingCountries: false,
  step: 'confirm',
  success: false,
  fieldsWithError: [],
};

//TODO check if this component, and its tests, can be deleted, as locale changes are meant to be done in Orders only.
function ChangeLocale(props) {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    initialState
  );
  const isMountedRef = useRef(null);

  useEffect(() => {
    isMountedRef.current = true;
    loadCountries();

    return () => (isMountedRef.current = false);
  }, []);

  const reset = () => {
    let newState = initialState;
    newState.countries = state.countries;
    if (state.step === 'errorLoadingCountries')
      newState.step = 'errorLoadingCountries';
    setState(newState);
  };

  const resetLocaleTimezone = () => {
    setState({ isoLocaleCode: '', timeZoneId: '' });
  };

  const change = (e) => {
    setState({ [e.target.name]: e.target.value });
  };

  const changeCountry = (e) => {
    const country = state.countries.filter(
      (country) => country.value === e.target.value
    )[0];
    const countryName = country && country.label ? country.label : '';
    setState({ country: e.target.value, countryName: countryName });
  };

  const loadCountries = () => {
    setState({ loadingCountries: true });

    API.COUNTRY.SUMMARY()
      .then((response) => {
        if (!isMountedRef.current) {
          return;
        }

        if (response.status === 200) {
          setState({
            countries: response.data.map((x) => {
              return { label: x.name, value: x.isoCode };
            }),
            loadingCountries: false,
          });
        } else
          setState({
            loadingCountries: false,
            step: 'errorLoadingCountries',
          });
      })
      .catch((response) => {
        console.error(response);
        setState({
          loadingCountries: false,
          step: 'errorLoadingCountries',
        });
      });
  };

  const validate = () => {
    let fieldsWithError = [];

    if (state.country === '') fieldsWithError.push('country');
    if (!state.isoLocaleCode) fieldsWithError.push('isoLocaleCode');
    if (!state.timeZoneId) fieldsWithError.push('timeZoneId');

    setState({
      fieldsWithError: fieldsWithError,
    });

    return !fieldsWithError.length;
  };

  const submit = (e) => {
    e.preventDefault();
    if (validate()) changeLocale();
    return false;
  };

  const changeLocale = (e) => {
    setState({ step: 'loading' });

    const { country, isoLocaleCode, timeZoneId } = state;

    API.SENSORS.CHANGE_LOCALE(props.id, country, isoLocaleCode, timeZoneId)
      .then((response) => {
        setState({ step: 'done', success: response.status === 200 });
        props.reloadClient();
      })
      .catch((response) => {
        console.error(response);
        setState({ step: 'done', success: false });
      });
  };

  const { step } = state;
  const { id } = props;

  return (
    <div>
      <Title element="h6" text="Change Locale" />

      {step === 'loading' ? <Loading /> : null}

      {step === 'errorLoadingCountries' ? (
        <div>
          <ErrorMessage message="Could not load countries. Please try again later." />
          <div className="btn-actions">
            <Link
              to={{
                pathname: `/sensor/${id}/admintools`,
                state: 'admintools',
              }}
            >
              <Button click={reset} label="Ok" />
            </Link>
          </div>
        </div>
      ) : null}

      {step === 'confirm' ? (
        <div>
          <Text bold={true} text={`Locale change for device ${id}.`} />
          <Text text="Choose new locale country:" />
          <br />
          {state.loadingCountries ? (
            <Loading />
          ) : (
            <div>
              <Select
                change={changeCountry}
                disabled={state.loadingCountries}
                hasError={state.fieldsWithError.includes('country')}
                name="country"
                options={state.countries}
                placeholder="Country"
                value={state.country}
              />

              <SelectLocaleTimezone
                isoCountryCode={state.country}
                timeZoneId={state.timeZoneId}
                isoLocaleCode={state.isoLocaleCode}
                change={change}
                disabled={!state.countries || !state.countries.length}
                fieldsWithError={state.fieldsWithError}
                resetLocaleTimezone={resetLocaleTimezone}
              />
            </div>
          )}

          <div className="btn-actions">
            <Link
              to={{
                pathname: `/sensor/${id}/admintools`,
                state: 'admintools',
              }}
            >
              <Button action="cancel" label="Cancel" />
            </Link>
            <Button click={submit} label="Confirm" />
          </div>
        </div>
      ) : null}

      {step === 'done' ? (
        <div>
          {state.success === true ? (
            <Text
              bold={true}
              text={`Locale changed successfully to ${state.countryName} for device ${id}.`}
            />
          ) : (
            <ErrorMessage
              message={`Could not change locale of device ${id}. Please try again later.`}
            />
          )}
          <div className="btn-actions">
            <Link
              to={{
                pathname: `/sensor/${id}/admintools`,
                state: 'admintools',
              }}
            >
              <Button click={reset} label="Ok" />
            </Link>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default ChangeLocale;
