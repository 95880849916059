import { useRef, useReducer, useEffect, useMemo } from 'react';
import moment from 'moment';

import API from '../components/api';
import {
  DEVICES_OPTIONS,
  SENSOR_STATUS,
  SENSOR_STATUS_OPTIONS,
  HARDWARE_TYPE_OPTIONS,
  GET_HARDWARE_TYPE_LABEL,
  INSTALLATIONS_GRANULARITY_OPTIONS,
} from '../components/constants';
import { DATE_FORMATTER } from '../components/date-hour-formatter';
import { getFirstDayOfTheWeek, getLastDayOfTheWeek } from '../components/utils';

import ErrorMessage from '../elements/error-message';
import Input from '../elements/forms/input';
import Button from '../elements/button';
import Select from '../elements/forms/select';
import Title from '../elements/title';
import InputDate from '../elements/forms/input-date';
import FieldWithTooltip from '../elements/field-with-tooltip';
import Table from '../elements/table';

import './installations.sass';

const devicesOptions = DEVICES_OPTIONS.filter(
  (option) => option.value !== 'ALL'
);

const sensorStatusOptions = SENSOR_STATUS_OPTIONS.filter(
  (option) => option.value !== 'NEVER_CONNECTED'
);

const searchOptions = [
  {
    label: 'Status',
    value: 'SENSOR_STATUS',
  },
  {
    label: 'Hardware Type',
    value: 'DEVICE_TYPE',
  },
  {
    label: 'Reseller Name',
    value: 'RESELLER_ID',
  },
];

let currentSearchOptions = searchOptions;

function Installations() {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      hasError: false,
      loading: true,
      search: '',
      searchBy: '',
      device: 'VOLTAWARE_SENSORS',
      granularity: 'DAY',
      date: new Date(),
      page: 0,
      pages: 1,
      pagesLastSensor: [0],
      devices: [],
      resellers: [],
      resellerName: '',
      startDate: getFirstDayOfTheWeek(new Date()),
      endDate: getLastDayOfTheWeek(new Date()),
    }
  );

  const isMountedRef = useRef(null);
  const pagesLastId = useRef([0]);

  useEffect(() => {
    isMountedRef.current = true;
    window.addEventListener('resize', resize.bind(this));
    resize();
    handleSearch();

    return () => {
      window.removeEventListener('resize', resize.bind(this));
      isMountedRef.current = false;
    };
  }, []);

  const columns = useMemo(
    () => [
      ...(state.device !== 'LOW_RESOLUTION_METER'
        ? [{ id: 'sisStatus', title: 'Device Status', align: 'center' }]
        : []),
      {
        id: 'sensorId',
        title: 'Device ID',
        align: 'center',
      },
      ...(state.device === 'VOLTAWARE_SENSORS'
        ? [{ id: 'hardwareType', title: 'Hardware Type', align: 'left' }]
        : []),
      {
        id: 'resellerName',
        title: 'Reseller Name',
        align: 'left',
      },
      {
        id: 'firstEvent',
        title: 'First Event',
        align: 'center',
      },
      {
        id: 'lastEvent',
        title: 'Last Event',
        align: 'center',
      },
    ],
    [state.devices]
  );

  const getLastEvent = (lastEvent) => () => {
    if (!lastEvent) {
      return null;
    }
    return DATE_FORMATTER.PARTIAL(lastEvent);
  };

  const data = useMemo(() => {
    return state.devices.map((row) => {
      return {
        ...row,
        id: row.sensorId,
        sisStatus: (
          <FieldWithTooltip
            className="sensor-status-section"
            status={SENSOR_STATUS[row.sisStatus]}
            getLastEvent={getLastEvent(row.lastEvent)}
          >
            <span className={`sensor-status ${row.sisStatus}`} />
          </FieldWithTooltip>
        ),
        hardwareType: <div>{GET_HARDWARE_TYPE_LABEL(row.hardwareType)}</div>,
        resellerName: <div>{row.resellerName ? row.resellerName : '-'}</div>,
        firstEvent: (
          <div>
            {row.firstEvent ? DATE_FORMATTER.PARTIAL(row.firstEvent) : '-'}
          </div>
        ),
        lastEvent: (
          <div>
            {row.lastEvent ? DATE_FORMATTER.PARTIAL(row.lastEvent) : '-'}
          </div>
        ),
      };
    });
  }, [state.devices]);

  const resize = () => {
    if (!isMountedRef.current) {
      return;
    }

    let currentIsMobile = window.innerWidth <= 768;
    if (currentIsMobile !== state.isMobile) {
      setState({ isMobile: currentIsMobile });
    }
  };

  const changeBy = (e) => {
    setState({
      [e.target.name]: e.target.value,
      search: '',
      resellerName: '',
    });
  };

  const change = (e) => {
    setState({ [e.target.name]: e.target.value });
  };

  const changeDate = (value) => {
    setState({ date: value });
  };

  const changeWeek = (value) => {
    setState({
      startDate: getFirstDayOfTheWeek(value),
      endDate: getLastDayOfTheWeek(value),
    });
  };

  const changeDevice = (e) => {
    if (e.target.value === 'HIGH_RESOLUTION_METER' || e.target.value === 'LOW_RESOLUTION_METER') {
      currentSearchOptions = searchOptions.filter(
        (option) => option.value !== 'DEVICE_TYPE'
      );
    } else {
      currentSearchOptions = searchOptions;
    }
    setState({
      [e.target.name]: e.target.value,
      searchBy: '',
      search: '',
    });
  };

  const changeReseller = (e) => {
    setState({ resellerName: e.target.value });
  };

  useEffect(() => {
    if (state.resellerName.length > 1 && state.resellerName.length < 30) {
      API.RESELLERS.SEARCH(state.resellerName, 'NAME').then((response) => {
        let resellers = response.data.map((item) => {
          return { id: item.id, name: item.name };
        });

        const matchIndex = resellers.findIndex(
          (reseller) => reseller.name === state.resellerName
        );
        if (matchIndex >= 0) {
          resellers = [];
        }

        setState({ resellers: resellers });
      });
    } else {
      setState({ resellers: [] });
    }
  }, [state.resellerName]);

  const selectReseller = (e) => {
    e.preventDefault();
    setState({
      resellerName: e.target.name,
      search: e.target.id,
      resellers: [],
    });
  };

  const validate = () => {
    const regex = /^[+]?\d+$/;
    let hasError = false;

    if (
      (state.search !== '' && state.searchBy === '') ||
      (state.search === '' && state.searchBy !== '')
    ) {
      hasError = true;
    } else if (state.searchBy === 'SENSOR_ID') {
      if (!regex.test(state.search.replace(/\s+/g, ''))) {
        hasError = true;
      }
    }

    setState({ hasError });

    return !hasError;
  };

  const handlePreviousPage = () => {
    pagesLastId.current.pop();
    handleSearch(pagesLastId.current[pagesLastId.current.length - 1]);
  };

  const handleNextPage = () => {
    pagesLastId.current.push(state.lastId);
    handleSearch(pagesLastId.current[pagesLastId.current.length - 1]);
  };

  const handleSearch = (lastId) => {
    if (!validate()) {
      window.scrollTo(0, 0);
      return false;
    }

    setState({ loading: true });

    API.ACCOUNTS.INSTALLATIONS(
      state.search,
      state.searchBy,
      state.device,
      state.granularity,
      state.granularity === 'MONTH'
        ? moment(state.date).format('YYYY-MM')
        : moment(state.date).format('YYYY-MM-DD'),
      moment(state.startDate).format('YYYY-MM-DD'),
      lastId
    )
      .then((response) => {
        if (!isMountedRef.current) {
          return;
        }

        setState({
          loading: false,
          devices: response.data.devices,
          lastId: response.data.pagesLastId,
        });
      })
      .catch((error) => {
        setState({
          loading: false,
          devices: [],
        });
      });
  };

  const submit = (e) => {
    e.preventDefault();
    e.target.querySelector('button[type="submit"]').blur();

    if (!validate()) {
      return false;
    }

    pagesLastId.current = [0];
    handleSearch();

    return false;
  };

  return (
    <section id="page-installations">
      <Title element="h4" text="Installations Reporting" />

      {state.hasError ? (
        <ErrorMessage message="Please, select a date, a property and fill the search field." />
      ) : null}

      <form action="#" onSubmit={submit}>
        <div>
          <Select
            change={changeDevice}
            disabled={state.loading}
            hasError={state.hasError}
            name="device"
            options={devicesOptions}
            placeholder="Device"
            noEmptyOption={true}
            value={state.device}
          />
        </div>
        <div>
          <Select
            change={change}
            disabled={state.loading}
            hasError={state.hasError}
            name="granularity"
            options={INSTALLATIONS_GRANULARITY_OPTIONS}
            placeholder="Time period"
            noEmptyOption={true}
            value={state.granularity}
          />
        </div>
        <div>
          {
            {
              DAY: (
                <InputDate
                  change={changeDate}
                  disabled={state.loading}
                  hasError={state.hasError}
                  selected={state.date}
                  name="date"
                  todayButton="Today"
                  placeholder="First event date"
                />
              ),
              WEEK: (
                <InputDate
                  change={changeWeek}
                  disabled={state.loading}
                  hasError={state.hasError}
                  selected={state.startDate}
                  name="date"
                  todayButton="This week"
                  weekMode
                  startDate={state.startDate}
                  endDate={state.endDate}
                  placeholder="First event date"
                  value={`${moment(state.startDate).format(
                    'DD/MM/YYYY'
                  )} - ${moment(state.endDate).format('DD/MM/YYYY')}`}
                />
              ),
              MONTH: (
                <InputDate
                  change={changeDate}
                  disabled={state.loading}
                  hasError={state.hasError}
                  selected={state.date}
                  name="date"
                  todayButton="This month"
                  dateFormat="LLLL yyyy"
                  monthMode
                  placeholder="First event date"
                />
              ),
            }[state.granularity]
          }
        </div>
        <div>
          <Select
            change={changeBy}
            disabled={state.loading}
            hasError={state.hasError}
            name="searchBy"
            options={currentSearchOptions}
            placeholder="Property"
            value={state.searchBy}
          />
        </div>
        <div>
          {{
            SENSOR_STATUS: (
              <Select
                change={change}
                disabled={state.loading}
                hasError={state.hasError}
                name="search"
                options={sensorStatusOptions}
                placeholder="Search"
                value={state.search}
              />
            ),
            DEVICE_TYPE: (
              <Select
                change={change}
                disabled={state.loading}
                hasError={state.hasError}
                name="search"
                options={HARDWARE_TYPE_OPTIONS}
                placeholder="Search"
                value={state.search}
              />
            ),
            RESELLER_ID: (
              <Input
                autocomplete="off"
                change={changeReseller}
                data={state.resellers}
                disabled={state.loading}
                name="resellerName"
                placeholder="Search"
                select={selectReseller}
                type="autoComplete"
                value={state.resellerName}
              />
            ),
          }[state.searchBy] || (
            <Input
              change={change}
              disabled={state.loading}
              hasError={state.hasError}
              name="search"
              placeholder="Search"
              type="text"
              value={state.search}
            />
          )}
        </div>

        <div>
          <Button
            action="search"
            disabled={state.loading}
            type="submit"
            aria-label="search"
          />
        </div>
      </form>

      <Table
        columns={columns}
        data={data}
        isLoading={state.loading}
        showPagination
        hasPrevious={pagesLastId.current.length > 1}
        hasNext={state.lastId !== 0}
        handlePreviousPage={handlePreviousPage}
        handleNextPage={handleNextPage}
      />
    </section>
  );
}

export default Installations;
