import { useRef, useReducer, useEffect, useMemo } from 'react';
import ReactTooltip from 'react-tooltip';

import API from '../../components/api';

import ErrorMessage from '../../elements/error-message';
import Input from '../../elements/forms/input';
import Text from '../../elements/text';
import Title from '../../elements/title';
import Button from '../../elements/button';
import Select from '../../elements/forms/select';
import Table from '../../elements/table';

import SubscriptionOrderBrowse from '../subscription/order/browse';

import './summary.sass';

const filterObject = {
  search: '',
  searchBy: '',
  searchOptions: Object.assign(
    [],
    [
      {
        label: 'Reseller ID',
        value: 'ID',
      },
      {
        label: 'Reseller Name',
        value: 'NAME',
      },
      {
        label: 'Country',
        value: 'COUNTRY',
      },
      {
        label: 'Contact Name',
        value: 'CONTACT_NAME',
      },
      {
        label: 'Immutable ID',
        value: 'IMMUTABLE_ID',
      },
    ]
  ),
  resellerName: '',
  lastId: 0,
};

function ResellersSummary() {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      filter: filterObject,
      loading: true,
      resellers: [],
      hasError: false,
      validationMessage: 'Please, select a property and fill the search field.',
      allResellers: [],
    }
  );
  const isMountedRef = useRef(null);
  const pagesLastResellerId = useRef([0]);

  useEffect(() => {
    isMountedRef.current = true;
    search();

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const columns = useMemo(
    () => [
      {
        id: 'id',
        title: 'ID',
        align: 'center',
      },
      {
        id: 'name',
        title: 'Reseller Name',
        align: 'left',
      },
      {
        id: 'country',
        title: 'Country',
        align: 'left',
      },
      {
        id: 'email',
        title: 'Email',
        align: 'left',
      },
      {
        id: 'contactName',
        title: 'Contact Name',
        align: 'left',
      },
    ],
    []
  );

  const data = useMemo(() => {
    return state.resellers.map((row) => {
      const getDetails = () => {
        const resellerId = row && row.id ? row.id : null;
        const resellerName = row && row.name ? row.name : null;
        return (
          <div className="expandable-section">
            <h6>{resellerName || 'Reseller'} Orders</h6>

            {resellerId ? (
              <SubscriptionOrderBrowse
                resellerId={resellerId}
                tableOnlyMode={true}
              />
            ) : (
              <Text text={`There are no orders for this reseller.`} />
            )}
          </div>
        );
      };
      return { ...row, country: row.country.name, details: getDetails() };
    });
  }, [state.resellers]);

  const changeBy = (e) => {
    setState({
      filter: {
        ...state.filter,
        searchBy: e.target.value,
        search: '',
        resellerName: '',
      },
    });
  };

  const change = (e) => {
    setState({ filter: { ...state.filter, search: e.target.value } });
  };

  const changeReseller = (e) => {
    setState({
      filter: {
        ...state.filter,
        resellerName: e.target.value,
        search: e.target.value,
      },
    });
  };

  useEffect(() => {
    if (
      state.filter.resellerName.length > 1 &&
      state.filter.resellerName.length < 30
    ) {
      API.RESELLERS.SEARCH(state.filter.resellerName, 'NAME').then(
        (response) => {
          if (!isMountedRef.current) {
            return;
          }
          let resellers = response.data.map((item) => {
            return { id: item.id, name: item.name };
          });

          const matchIndex = resellers.findIndex(
            (reseller) => reseller.name === state.filter.resellerName
          );
          if (matchIndex >= 0) {
            resellers = [];
          }

          setState({
            allResellers: resellers,
          });
        }
      );
    } else {
      setState({ allResellers: [] });
    }
  }, [state.filter.resellerName]);

  const selectReseller = (e) => {
    e.preventDefault();
    setState({
      filter: {
        ...state.filter,
        resellerName: e.target.name,
        search: e.target.name,
      },
      allResellers: [],
    });
  };

  const validate = () => {
    const filter = state.filter;
    let hasError = false;
    let validationMessage =
      'Please, select a property and fill the search field.';
    if (
      (filter.search !== '' && filter.searchBy === '') ||
      (filter.search === '' && filter.searchBy !== '')
    ) {
      hasError = true;
    } else if (filter.searchBy === 'ID') {
      const numberRegex = /^\d+$/;
      if (!numberRegex.test(filter.search.replace(/\s+/g, ''))) {
        hasError = true;
        validationMessage = 'Please, type a number for the ID.';
      }
    }
    setState({ hasError, validationMessage });
    return !hasError;
  };

  const search = (lastId) => {
    setState({ loading: true });

    API.RESELLERS.SEARCH_PAGINATION(
      state.filter.search,
      state.filter.searchBy,
      lastId
    )
      .then((response) => {
        if (!isMountedRef.current) {
          return;
        }

        setState({
          loading: false,
          resellers: response.data.resellers,
          lastId: response.data.lastResellerIdOfThePage,
        });
      })
      .catch((error) => {
        console.error(error);
        setState({
          loading: false,
          resellers: [],
          lastId: 0,
        });
      });
  };

  const handlePreviousPage = () => {
    pagesLastResellerId.current.pop();
    search(pagesLastResellerId.current[pagesLastResellerId.current.length - 1]);
  };

  const handleNextPage = () => {
    pagesLastResellerId.current.push(state.lastId);
    search(pagesLastResellerId.current[pagesLastResellerId.current.length - 1]);
  };

  const submit = (e) => {
    e.preventDefault();
    e.target.querySelector('button[type="submit"]').blur();

    if (!validate()) {
      return false;
    }

    pagesLastResellerId.current = [0];
    search();

    return false;
  };

  return (
    <section id="page-resellers-summary">
      <Title element="h4" text="Resellers - Summary" />

      <Text
        bold={true}
        text={`Search for a reseller by ID, Name, Country or
                        Contact Name.`}
      />

      {state.hasError ? (
        <ErrorMessage message={state.validationMessage} />
      ) : null}

      <form action="#" onSubmit={submit}>
        <div>
          <Select
            change={changeBy}
            disabled={state.loading}
            hasError={state.hasError}
            name="searchBy"
            options={state.filter.searchOptions}
            placeholder="Property"
            value={state.filter.searchBy}
          />
        </div>
        <div>
          {{
            NAME: (
              <Input
                autocomplete="off"
                change={changeReseller}
                data={state.allResellers}
                disabled={state.loading}
                hasError={state.hasError}
                name="resellerName"
                placeholder="Search"
                select={selectReseller}
                type="autoComplete"
                value={state.filter.resellerName}
              />
            ),
          }[state.filter.searchBy] || (
            <Input
              change={change}
              disabled={state.loading}
              hasError={state.hasError}
              name="search"
              placeholder="Search"
              type="text"
              value={state.filter.search}
            />
          )}
        </div>
        <div>
          <Button
            action="search"
            disabled={state.loading}
            type="submit"
            aria-label="search"
          />
        </div>
      </form>

      <ReactTooltip />
      <Table
        columns={columns}
        data={data}
        isLoading={state.loading}
        showDetails
        showPagination
        hasPrevious={pagesLastResellerId.current.length > 1}
        hasNext={state.lastId !== 0}
        handlePreviousPage={handlePreviousPage}
        handleNextPage={handleNextPage}
      />
    </section>
  );
}

export default ResellersSummary;
