import { useRef, useReducer, useEffect } from 'react';
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

import API from '../components/api';
import {
  CATEGORIES_OPTIONS,
  PHASES_OPTIONS,
  STATUS_OPTIONS,
  DEVICES_OPTIONS,
} from '../components/constants';
import {
  isReseller,
  hasSmartMeters,
  hasVoltawareSensors,
} from '../components/utils';

import ErrorMessage from '../elements/error-message';
import Input from '../elements/forms/input';
import Loading from '../elements/loading';
import Select from '../elements/forms/select';
import Text from '../elements/text';
import Title from '../elements/title';

import './summary.sass';

const COLORS = ['#00c49f', '#ffbb28', '#0088fe'];

function Summary() {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      category: '',
      chartData: [],
      chartNoData: true,
      chartRawData: [],
      countriesOptions: [],
      country: '',
      lastSearch: '',
      loading: false,
      loadingCountries: true,
      loadingReseller: false,
      phases: '',
      resellerId: '',
      resellerName: '',
      resellers: [],
      status: '',
      device:
        hasSmartMeters() && hasVoltawareSensors()
          ? DEVICES_OPTIONS[0].value
          : null,
    }
  );

  const isMountedRef = useRef(null);

  useEffect(() => {
    isMountedRef.current = true;
    loadCountries();
    window.addEventListener('resize', resize.bind(this));
    resize();

    return () => {
      window.removeEventListener('resize', resize.bind(this));
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    search();
  }, [
    state.device,
    state.resellerId,
    state.status,
    state.country,
    state.phases,
    state.category,
  ]);

  const resize = () => {
    if (!isMountedRef.current) {
      return;
    }

    let currentIsMobile = window.innerWidth <= 768;
    if (currentIsMobile !== state.isMobile) {
      setState({ isMobile: currentIsMobile });
    }
  };

  const loadCountries = () => {
    let fn = isReseller() ? API.COUNTRY.GENERAL : API.COUNTRY.SUMMARY;

    fn()
      .then((response) => {
        if (!isMountedRef.current) {
          return;
        }

        if (response.status === 200) {
          setState({
            countriesOptions: response.data.map((country) => {
              return { label: country.name, value: country.isoCode };
            }),
            loadingCountries: false,
          });
        } else {
          setState({ loadingCountries: false });
          console.error('Error while loading countries.');
        }
      })
      .catch((response) => {
        setState({ loadingCountries: false });
        console.error('Error while loading countries.');
      });
  };

  const change = (e) => {
    const target = e.target.name;
    setState({
      [target]: e.target.value,
      loading: true,
    });
  };

  const changeReseller = (e) => {
    setState({ resellerName: e.target.value });
  };

  useEffect(() => {
    if (state.resellerName === '') {
      setState({ resellerId: '', resellers: [] });
    } else if (
      state.resellerName.length > 1 &&
      state.resellerName.length < 30
    ) {
      API.RESELLERS.SEARCH(state.resellerName, 'NAME').then((response) => {
        const resellers = response.data.map((item) => {
          return { id: item.id, name: item.name };
        });
        setState({ resellers: resellers });
      });
    } else {
      setState({ resellers: [] });
    }
  }, [state.resellerName]);

  const selectReseller = (e) => {
    e.preventDefault();
    setState({
      resellerName: e.target.name,
      resellerId: e.target.id,
      resellers: [],
    });
  };

  const search = () => {
    setState({ loading: true });

    API.STOCK.GENERAL_STATUS(
      state.category,
      state.country,
      state.phases,
      state.resellerId,
      state.status === 'ALL' ? '' : state.status,
      state.device
    )
      .then((response) => {
        if (!isMountedRef.current) {
          return;
        }

        let data = [],
          noData = true;

        
        
        if (state.device === 'LOW_RESOLUTION_METER') {
          data.push({
            name: 'INSTALLED',
            value: response.data.reduce((acc, curr) => {
              curr.count <= 0 ? (curr.count = 0) : (noData = false);
              return acc+curr.count;
            }, 0),
          })
        } else {
          response.data.map((obj) => {
            obj.count <= 0 ? (obj.count = 0) : (noData = false);
            return data.push({ name: obj.sisStatus, value: obj.count });
          });
        }

        setState({
          chartData: data,
          chartNoData: noData,
          chartRawData: data,
          lastSearch: state.resellerId,
          loading: false,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <section id="page-summary">
      <Title element="h4" text="Summary" />

      <Text
        bold={true}
        text={`You can filter the results to see specific information by selecting one or several filters below.`}
      />

      <section className={isReseller() ? 'col-4' : ''}>
        {hasSmartMeters() && hasVoltawareSensors() ? (
          <div>
            <Select
              change={change}
              disabled={state.loading}
              noEmptyOption={true}
              name="device"
              options={DEVICES_OPTIONS}
              placeholder="Device"
              value={state.device}
            />
          </div>
        ) : null}

        {!isReseller() ? (
          <div>
            <Input
              autocomplete="off"
              change={changeReseller}
              data={state.resellers}
              disabled={state.loading}
              name="resellerName"
              placeholder="Reseller"
              select={selectReseller}
              type="autoComplete"
              value={state.resellerName}
            />
          </div>
        ) : null}

        <div>
          <Select
            change={change}
            disabled={state.loading}
            name="status"
            options={STATUS_OPTIONS}
            placeholder="Status"
            value={state.status}
          />
        </div>
        <div>
          <Select
            change={change}
            disabled={state.loading}
            name="country"
            options={state.countriesOptions}
            placeholder="Country"
            value={state.country}
          />
        </div>
        <div>
          <Select
            change={change}
            disabled={state.loading}
            name="phases"
            options={PHASES_OPTIONS}
            placeholder="Number of phases"
            value={state.phases}
          />
        </div>

        <div>
          <Select
            change={change}
            disabled={state.loading}
            name="category"
            options={CATEGORIES_OPTIONS}
            placeholder="Category"
            value={state.category}
          />
        </div>
      </section>

      {state.loading ? (
        <Loading />
      ) : state.chartNoData ? (
        <ErrorMessage
          align="center"
          message="No sensor matches your search. Please verify your filters."
        />
      ) : (
        <div className="chart-wrapper">
          {state.isMobile ? (
            <ResponsiveContainer height={400} width="100%">
              <PieChart>
                <Legend
                  align="center"
                  iconSize={30}
                  layout="vertical"
                  verticalAlign="bottom"
                />
                <Pie data={state.chartData} dataKey="value" label>
                  {state.chartData.map((entry, index) => (
                    <Cell
                      fill={COLORS[index % COLORS.length]}
                      key={index}
                      name={`${entry.name}`}
                    />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          ) : (
            <PieChart height={400} width={800}>
              <Legend
                align="right"
                iconSize={30}
                layout="vertical"
                verticalAlign="middle"
              />
              <Pie data={state.chartData} dataKey="value" label>
                {state.chartData.map((entry, index) => (
                  <Cell
                    fill={COLORS[index % COLORS.length]}
                    key={index}
                    name={`${entry.name}`}
                  />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          )}
        </div>
      )}
    </section>
  );
}

export default Summary;
