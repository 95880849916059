import { NavLink } from 'react-router-dom';

import Private from './private';

function PrivateLink(props) {
  const { children, to, onClick } = props;

  return (
    <Private to={to}>
      <NavLink to={to} exact onClick={onClick}>
        {children}
      </NavLink>
    </Private>
  );
}

export default PrivateLink;
