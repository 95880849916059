import Loading from '../../elements/loading';
import Text from '../../elements/text';
import SensorWifiStrength from '../../elements/sensor-wifi-strength';

import './sensor-info.sass';

function SensorInfo(props) {
  const loadingSensor = props.loadingSensor;
  const sensorLoadingSuccess = props.sensorLoadingSuccess;
  const sensor = props.sensor;
  return loadingSensor ? (
    <Loading />
  ) : sensorLoadingSuccess ? (
    <div id="sensor-details">
      <div>
        <Text bold={true} text={'Device Status'} />
        <Text
          classes={
            sensor.connectionState === 'connected' ? 'online' : 'offline'
          }
          bold={true}
          text={sensor.connectionState === 'connected' ? 'Online' : 'Offline'}
        />
      </div>

      <div>
        <Text bold={true} text={'Last Restart'} />
        <Text text={sensor.lastBoot} />
      </div>

      <div>
        <Text bold={true} text={'WiFi Signal Strength'} />
        <SensorWifiStrength value={sensor.wifiSignal} />
      </div>

      <div>
        <Text bold={true} text={'Firmware Version'} />
        <Text text={sensor.firmwareVersion} />
      </div>
    </div>
  ) : (
    <div id="sensor-details">
      <Text bold={true} text={'Device information currently unavailable.'} />
    </div>
  );
}

export default SensorInfo;
