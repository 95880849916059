import { useReducer } from 'react';
import { useLocation } from 'react-router-dom';

import API from '../../../components/api';
import ErrorMessage from '../../../elements/error-message';
import StockBlockIDs from './ids';
import StockBlockConfirm from './confirm';
import StockBlockEnd from './end';
import Title from '../../../elements/title';

import './index.sass';

export const MODES = { block: 'block', unblock: 'unblock' };

const initialState = {
  errors: undefined,
  feedbackMessage: '',
  loading: false,
  step: 1,
  stock: '',
};

function StockBlock(props) {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    initialState
  );
  const location = useLocation();

  const getMode = (props) => {
    return location.pathname.includes('unblock') ? MODES.unblock : MODES.block;
  };
  const getTitle = (mode) => {
    return mode === MODES.unblock ? 'Devices - Unblock' : 'Devices - Block';
  };

  const mode = getMode(props);
  const title = getTitle(mode);

  const reset = () => {
    setState(initialState);
  };

  const change = (e) => {
    setState({ [e.target.name]: e.target.value });
  };

  const backToStep = (e, step) => {
    e.preventDefault();
    setState({ step: step });
    return false;
  };

  const submitStepOne = async (e) => {
    setState({ loading: false, step: 2, errors: null });
  };

  const block = (e) => {
    e.preventDefault();
    setState({ loading: true });
    API.STOCK[mode.toUpperCase()]
      .CONFIRM(state.stock)
      .then(() => {
        setState({ loading: false, step: 3 });
      })
      .catch((error) => {
        if (error && error.response && error.response.status === 400) {
          const { data } = error.response;
          const errors = {
            errorMessage: `One or more stocks are already ${mode}ed, no ${mode} action can be taken.`,
            stockBlockStatus:
              data && data.message ? JSON.parse(data.message) : null,
          };
          setState({ errors: errors, loading: false });
        } else {
          const message = (
            <ErrorMessage message="Something went wrong. Please, try again or contact support." />
          );
          console.error(error);
          setState({ feedbackMessage: message, loading: false });
        }
      });
    return false;
  };

  return (
    <section id="page-stock-block">
      <Title element="h4" text={title} />

      {state.step === 1 ? (
        <StockBlockIDs
          back={backToStep}
          change={change}
          disabled={state.loading}
          submit={submitStepOne}
          value={state.stock}
          mode={mode}
        />
      ) : null}

      {state.step === 2 ? (
        <StockBlockConfirm
          back={backToStep}
          disabled={state.loading}
          stock={state.stock}
          submit={block}
          errors={state.errors}
          mode={mode}
        />
      ) : null}

      {state.step === 3 ? (
        <StockBlockEnd reset={reset} stock={state.stock} mode={mode} />
      ) : null}

      {state.feedbackMessage ? state.feedbackMessage : null}
    </section>
  );
}

export default StockBlock;
