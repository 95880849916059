import { useReducer, useEffect } from 'react';
import { Link } from 'react-router-dom';

import API from '../../components/api';
import { isAdmin, isResellerAdmin } from '../../components/utils';

import Button from '../../elements/button';
import ErrorMessage from '../../elements/error-message';
import Input from '../../elements/forms/input';
import InputSelect from '../../elements/forms/input-select';
import Loading from '../../elements/loading';
import Text from '../../elements/text';
import Title from '../../elements/title';

import './property-details.sass';

const peopleDetailNames = {
  ZERO_TO_FIVE: "Zero to Five",
  SIX_TO_EIGHTEEN: "Six to Eighteen",
  ADULT: "Adult",
  PENSIONER:"Pensioner",
};

function PropertyDetails(props) {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      feedbackMessage: '',
      fieldsWithError: [],
      loading: false,
      loadingData: props.property.loading,
      property: props.property,
    }
  );
  const userIsAdminOrResellerAdmin = isAdmin() || isResellerAdmin();

  useEffect(() => {
    setState({ loadingData: true });
  }, []);

  const resetPeopleDetail = () => {
    state.property.people = [
      { age_range: "ZERO_TO_FIVE", amount: 0 },
      { age_range: "SIX_TO_EIGHTEEN", amount: 0 },
      { age_range: "ADULT", amount: 0 },
      { age_range: "PENSIONER", amount: 0 },
    ];
  }

  useEffect(() => {
    setState({
      loadingData: props.property.loading,
      property: props.property,
    });
  }, [props.property.exists]);

  const change = (e) => {
    if (parseInt(e.target.value, 10) < 0) {
      return;
    }

    let property = state.property;

    if (e.target.name === 'property_type') {
      resetPeopleDetail();
    }

    if (e.target.name === 'people_amount') {
      // People amount decreased -> reset people age range amounts
      if (parseInt(e.target.value, 10) < parseInt(property.people_amount, 10)) {
       resetPeopleDetail();
      }
      // If people amount in day time is greater than upcoming people amount, set the former to equal the latter
      if (parseInt(property.people_amount_in_day_time, 10) > parseInt(e.target.value, 10)) {
        property.people_amount_in_day_time = e.target.value;
      }
    }

    const shouldIncreasePeopleAmount = 
      e.target.name === 'people_amount_in_day_time' &&
      parseInt(e.target.value, 10) > parseInt(property.people_amount, 10);
    if (shouldIncreasePeopleAmount) {
      property.people_amount = e.target.value;
    }

    property[e.target.name] =
      e.target.value === 'true' || e.target.value === 'false'
        ? e.target.value === 'true'
        : e.target.value;
    setState({ property: property });
  };

  const changePeopleDetail = (range, amount) => {
    if (parseInt(amount, 10) < 0) {
      return;
    }

    let property = state.property;
    const index = property.people.findIndex(age => age.age_range === range);
    property.people[index].amount = parseInt(amount, 10);

    const peopleDetailSet = state.property?.people?.reduce((acc, range) => acc+range.amount, 0) ?? 0;
    const currentPersonAmount = parseInt(property.people_amount, 10);
    if (peopleDetailSet > currentPersonAmount) {
      property.people_amount = currentPersonAmount + 1;
    }
    setState({ property: property });
  }

  const validate = () => {
    const { property_type, home_size, home_type, home_usage_type, bedroom_amount, people_amount, power_generator_source } =
      state.property;
    let fieldsWithError = [];

    if (!property_type) {
      fieldsWithError.push('property_type');
    } else {
      if (property_type === 'HOME') {
        if (parseInt(home_size, 10) < 1 || parseInt(home_size, 10) > 20000)
          fieldsWithError.push('home_size');

        if (!home_type)
          fieldsWithError.push('home_type');
        
        if (!home_usage_type)
          fieldsWithError.push('home_usage_type');
        
        if (bedroom_amount === undefined)
          fieldsWithError.push('bedroom_amount');

        if (parseInt(people_amount, 10) < 1)
          fieldsWithError.push('people_amount');
        
        if (!home_usage_type)
          fieldsWithError.push('home_usage_type');
      }

      if (!power_generator_source)
        fieldsWithError.push('power_generator_source');
    }

    setState({ loading: false, fieldsWithError: fieldsWithError });

    return !fieldsWithError.length;
  };

  const save = () => {
    let apiFunction,
      params;
    
    let payload;
    
    if (state.property.property_type === 'HOME') {
      payload = {
        ...state.property,
        bedroom_amount: parseInt(state.property.bedroom_amount, 10),
        home_size: parseInt(state.property.home_size, 10),
        people: [ ...state.property.people ]
          .map(range => ({ ...range, amount: parseInt(range.amount, 10) }))
          .filter(range => range.amount > 0),
        people_amount: parseInt(state.property.people_amount, 10),
        people_amount_in_day_time: parseInt(state.property.people_amount_in_day_time, 10),
      };
      delete payload.exists;
      delete payload.loading;
    } else {
      payload = {
        property_type: state.property.property_type,
        power_generator_source: state.property.power_generator_source,
        version: state.property.version,
      };
    }

    if (payload.power_generator_source === 'NO') {
      payload.power_generator_source = 'NONE';
    }

    if (props.isSmartMeter) {
      apiFunction = API.SMART_METERS.PROPERTY_DETAILS.PUT;
      params = [props.client.property.sensorId, props.integratorId, payload];
    } else {
      apiFunction = API.STOCK.PROPERTY_DETAILS.PUT;
      params = [props.client.property.sensorId, payload];
    }

    apiFunction(...params)
      .then((response) => {
        const msg = 'Property Details saved successfully.';
        const data = state.property;
        data.exists = true;
        setState({
          feedbackMessage: <Text bold={true} highlight={true} text={msg} />,
          loading: false,
          property: data,
        });
        props.propertyChanged(data);
      })
      .catch((error) => {
        console.error(error);

        setState({
          feedbackMessage: (
            <ErrorMessage message="An error occurred. Please try again later or contact support." />
          ),
          loading: false,
        });
      });
  };

  const submit = (e) => {
    e.preventDefault();

    e.target.querySelector('button[type="submit"]').blur();

    if (userIsAdminOrResellerAdmin) {
      setState({ feedbackMessage: '', loading: true });
      if (validate()) {
        save();
      }
    }

    return false;
  };

  const createBackButtonPath = () => {
    const { client, isSmartMeter, integratorId } = props;
    if (isSmartMeter) {
      return `/smart-meter/${integratorId}/${client.id}`;
    } else {
      return `/sensor/${client.account.id}`;
    }
  };

  const { property_type } = state.property;

  return (
    <div id="propertydetails">
      <Title element="h6" text="Property Details" />

      {state.feedbackMessage ? state.feedbackMessage : null}

      {state.loadingData ? (
        <Loading />
      ) : (
        <form action="#" onSubmit={submit}>
          <fieldset disabled={!userIsAdminOrResellerAdmin}>
            <div>
              <InputSelect
                change={change}
                checked={property_type}
                disabled={state.loading}
                hasError={state.fieldsWithError.indexOf('property_type') >= 0}
                options={[
                  { label: 'Home', value: 'HOME' },
                  { label: 'Office', value: 'OFFICE' },
                  { label: 'Commercial', value: 'COMMERCIAL' },
                  { label: 'Industry', value: 'INDUSTRY' },
                ]}
                name="property_type"
                placeholder="Property Type:"
                type="radio"
              />
            </div>

            {property_type === 'HOME' ? (
              <div>
                <Input
                  change={change}
                  disabled={state.loading}
                  hasError={state.fieldsWithError.indexOf('home_size') >= 0}
                  name="home_size"
                  placeholder={`${
                    property_type === 'HOME'
                      ? 'House'
                      : property_type === 'OFFICE'
                      ? 'Office'
                      : 'Space'
                  } size (approximated in m²):`}
                  type="number"
                  value={state.property.home_size}
                />

                <Input
                  change={change}
                  disabled={state.loading}
                  hasError={
                    state.fieldsWithError.indexOf('bedroom_amount') >= 0
                  }
                  name="bedroom_amount"
                  placeholder="Number of bedrooms:"
                  type="number"
                  value={state.property.bedroom_amount}
                />

                <InputSelect
                  change={change}
                  checked={state.property.home_usage_type}
                  disabled={state.loading}
                  hasError={state.fieldsWithError.indexOf('home_usage_type') >= 0}
                  options={[
                    { label: 'Primary', value: 'PRIMARY' },
                    { label: 'Secondary', value: 'SECONDARY' },
                  ]}
                  placeholder="Is this your primary or secondary home?"
                  name="home_usage_type"
                  type="radio"
                />

                <InputSelect
                  change={change}
                  checked={state.property.home_type}
                  disabled={state.loading}
                  hasError={state.fieldsWithError.indexOf('home_type') >= 0}
                  options={[
                    { label: 'Flat', value: 'FLAT' },
                    { label: 'House', value: 'HOUSE' },
                  ]}
                  placeholder="Home type:"
                  name="home_type"
                  type="radio"
                />
                <Input
                  change={change}
                  disabled={state.loading}
                  hasError={
                    state.fieldsWithError.indexOf('people_amount') >= 0
                  }
                  name="people_amount"
                  placeholder={`Number of people ${
                    property_type === 'HOME'
                      ? 'living in the house'
                      : 'working in the office'
                  }:`}
                  type="number"
                  value={state.property.people_amount}
                />
                <Input
                  change={change}
                  disabled={state.loading}
                  name="people_amount_in_day_time"
                  placeholder="Number of people during the daytime"
                  type="number"
                  value={state.property.people_amount_in_day_time}
                />
                
                <Text text="Age Details" classes="small-size" />
                {state.property.people.map((detail, index) => (
                  <Input
                    change={(e) => changePeopleDetail(detail.age_range, e.target.value)}
                    key={detail.age_range}
                    disabled={state.loading}
                    name={`people.${detail.age_range}.amount`}
                    placeholder={peopleDetailNames[detail.age_range]}
                    type="number"
                    value={state.property.people[index].amount}
                  />
                ))}
              </div>
            ) : null}
            <InputSelect
              change={change}
              checked={state.property.power_generator_source}
              disabled={state.loading}
              hasError={
                state.fieldsWithError.indexOf('power_generator_source') >= 0
              }
              name="power_generator_source"
              options={[
                { label: "I Don't", value: 'NO' },
                { label: 'Solar', value: 'SOLAR' },
                {
                  label: 'Hydroelectricity',
                  value: 'HYDROELECTRICITY',
                },
                { label: 'Wind', value: 'WIND' },
                { label: 'Other', value: 'OTHER' },
              ]}
              placeholder="How do you generate power?"
              type="radio"
            />

            {userIsAdminOrResellerAdmin ? (
              <div>
                <div className="btn-actions">
                  <Link to={{ pathname: `${createBackButtonPath()}` }}>
                    <Button
                      action="cancel"
                      disabled={state.loading}
                      label="Cancel"
                      loading={false}
                    />
                  </Link>
                  <Button disabled={state.loading} label="Save" type="submit" />
                </div>
              </div>
            ) : null}
          </fieldset>
        </form>
      )}
    </div>
  );
}

export default PropertyDetails;
