import { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './input-date.sass';

function InputDate(props) {
  const [isMobile, setIsMobile] = useState(false);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    window.addEventListener('resize', resize.bind(this));
    resize();

    return window.removeEventListener('resize', resize.bind(this));
  }, []);

  const resize = () => {
    let currentIsMobile = window.innerWidth <= 768;
    if (currentIsMobile !== isMobile) {
      setIsMobile(currentIsMobile);
    }
  };

  const handleFocus = () => {
    setIsActive(true);
  };

  const handleBlur = () => {
    setIsActive(false);
  };

  let spanClass = 'placeholder';
  if (isActive) {
    spanClass += ' active';
  } else if (props.value !== '' || props.selected) {
    spanClass += ' deactive';
  }

  return (
    <label className={props.hasError ? 'error' : ''}>
      <span className={spanClass}>{props.placeholder}</span>
      <DatePicker
        onBlur={handleBlur}
        onFocus={handleFocus}
        disabled={props.disabled}
        maxDate={props.maxDate || new Date()}
        name={props.name}
        onChange={props.change}
        selected={props.weekMode ? props.startDate : props.selected}
        dateFormat={props.dateFormat || 'dd/MM/yyyy'}
        withPortal={isMobile}
        todayButton={props.todayButton || false}
        showMonthYearPicker={props.monthMode || false}
        startDate={props.startDate || false}
        endDate={props.endDate || false}
        value={props.value || null}
      />
    </label>
  );
}

export default InputDate;
