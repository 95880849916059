import { useRef, useReducer, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';

import API from '../../components/api';
import {
  CATEGORIES_OPTIONS,
  CLAMP_SIZES_OPTIONS,
  PHASES_OPTIONS,
  STATUS_OPTIONS,
} from '../../components/constants';
import { isAdmin, isMachineLearning } from '../../components/utils';

import Button from '../../elements/button';
import ErrorMessage from '../../elements/error-message';
import Input from '../../elements/forms/input';
import Select from '../../elements/forms/select';
import Text from '../../elements/text';
import Title from '../../elements/title';
import Table from '../../elements/table';

import './browse.sass';

const filterObject = {
  hasError: false,
  search: '',
  searchBy: '',
  searchOptions: Object.assign(
    [],
    [
      {
        label: 'ID',
        value: 'SENSOR_ID',
      },
      {
        label: 'Status',
        value: 'STATUS',
      },
      {
        label: 'Category',
        value: 'CATEGORY',
      },
      {
        label: 'Clamp Size',
        value: 'CLAMP',
      },
      {
        label: 'Phases',
        value: 'PHASES',
      },
      {
        label: 'Reseller ID',
        value: 'RESELLER_ID',
      },
      {
        label: 'Batch ID',
        value: 'BATCH_NUMBER',
      },
    ]
  ),
};

function StockBrowse() {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      filters: [filterObject],
      loading: true,
      noResultsMessage: 'No results found.',
      validationMessage: 'Please, select a property and fill the search field.',
      page: 0,
      pages: 1,
      pagesLastSensor: [0],
      stocks: [],
      isSearching: false,
    }
  );
  const isMountedRef = useRef(null);
  const pagesLastId = useRef([0]);

  useEffect(() => {
    isMountedRef.current = true;
    handleSearch();

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const columns = useMemo(
    () => [
      ...(isAdmin()
        ? [{ id: 'edit', title: '', align: 'center', minWidth: 50 }]
        : []),
      {
        id: 'sensorId',
        title: 'ID',
        align: 'center',
        minWidth: 50,
      },
      {
        id: 'macAddress',
        title: 'MAC Address',
        align: 'left',
      },
      {
        id: 'ssid',
        title: 'SSID',
        align: 'left',
      },
      {
        id: 'key',
        title: 'Key',
        align: 'center',
      },
      {
        id: 'phases',
        title: '# Phases',
        align: 'center',
      },
      {
        id: 'category',
        title: 'Category',
        align: 'center',
      },
      {
        id: 'status',
        title: 'Status',
        align: 'left',
      },
      {
        id: 'clampSize',
        title: 'Clamp (A)',
        align: 'center',
      },
      {
        id: 'productionDate',
        title: 'Production date',
        align: 'center',
      },
      {
        id: 'batchNumber',
        title: 'Batch ID',
        align: 'center',
      },
      {
        id: 'resellerId',
        title: 'Reseller ID',
        align: 'center',
      },
    ],
    [isAdmin]
  );

  const data = useMemo(() => {
    return state.stocks.map((row) => {
      return {
        ...row,
        edit: (
          <Link
            to={{
              pathname: `/inventory/edit`,
              state: { stock: row, editing: true },
            }}
          >
            <div className="text-center">
              <Button action="edit" />
            </div>
          </Link>
        ),
        sensorId: row.stock.sensorId,
        macAddress: row.stock.macAddress,
        ssid: row.stock.ssid,
        key: row.stock.key,
        phases: row.stock.phases,
        category: row.stock.category,
        status: row.stock.status,
        clampSize: row.stock.clampSize,
        productionDate: row.stock.productionDate,
        batchNumber: row.batch.batchNumber,
        resellerId:
          row.resellerId && !isMachineLearning() ? (
            <Link
              to={{
                pathname: '/resellers/edit',
                state: { resellerId: row.resellerId },
              }}
            >
              {row.resellerId}
            </Link>
          ) : (
            row.resellerId
          ),
        id: row.stock.sensorId,
      };
    });
  }, [state.stocks]);

  const getFiltersSelected = (filters) => filters.map((obj) => obj.searchBy);

  const getDisabledFilters = (filter, filtersSelected) =>
    filter.searchOptions.map((filter) => {
      filter.disabled = filtersSelected.indexOf(filter.value) >= 0;
      return filter;
    });

  const getNewFilter = () => {
    let filter = { ...filterObject };
    filter.searchOptions = getDisabledFilters(
      filter,
      getFiltersSelected(state.filters)
    );
    return filter;
  };

  const changeBy = (e, index) => {
    let { filters } = state;
    if (e.target.value !== '' && filters[index].searchBy !== '')
      filters[index].search = '';
    filters[index].searchBy = e.target.value;

    filters = changeFilters(filters);
    setState({ filters });
  };

  const change = (e, index) => {
    let { filters } = state;
    filters[index][e.target.name] = e.target.value;
    setState({ filters });
  };

  const changeFilters = (filters) =>
    filters.map((obj) => {
      obj.searchOptions = getDisabledFilters(obj, getFiltersSelected(filters));
      return obj;
    });

  const addFilter = () => {
    let { filters } = state;
    filters.push(getNewFilter());
    setState({ filters });
  };

  const removeFilter = (index) => {
    let { filters } = state;
    filters.splice(index, 1);
    filters = changeFilters(filters);
    setState({ filters });
  };

  const validate = () => {
    const regex = /^[0-9]+(?:[-,]?[0-9]+)?/;
    let filters = state.filters;
    let hasError = false;
    let validationMessage =
      'Please, select a property and fill the search field.';

    filters.forEach((filter) => {
      filter.hasError = false;

      if (
        (filter.search !== '' && filter.searchBy === '') ||
        (filter.search === '' && filter.searchBy !== '')
      ) {
        filter.hasError = true;
      } else if (filter.searchBy === 'SENSOR_ID') {
        if (!regex.test(filter.search.replace(/\s+/g, ''))) {
          filter.hasError = true;
        }
      } else if (
        filter.searchBy === 'RESELLER_ID' ||
        filter.searchBy === 'BATCH_NUMBER'
      ) {
        const numberRegex = /^\d+$/;
        if (!numberRegex.test(filter.search.replace(/\s+/g, ''))) {
          filter.hasError = true;
          validationMessage = 'Please, type a number for the ID.';
        }
      }
      if (filter.hasError) hasError = true;
    });
    setState({ filters, hasError, validationMessage });

    return !hasError;
  };

  const handlePreviousPage = () => {
    pagesLastId.current.pop();
    handleSearch(pagesLastId.current[pagesLastId.current.length - 1]);
  };

  const handleNextPage = () => {
    pagesLastId.current.push(state.lastId);
    handleSearch(pagesLastId.current[pagesLastId.current.length - 1]);
  };

  const handleSearch = (lastId) => {
    if (!validate()) {
      window.scrollTo(0, 0);
      return false;
    }

    setState({ loading: true });

    API.STOCK.SEARCH(state.filters, lastId)
      .then((response) => {
        if (!isMountedRef.current) {
          return;
        }

        setState({
          loading: false,
          stocks: response.data.stocks,
          lastId: response.data.lastSensorIdOfThePage,
        });
      })
      .catch((error) => {
        console.error(error);

        setState({
          loading: false,
          stocks: [],
        });
      });
  };

  const submit = (e) => {
    e.preventDefault();
    e.target.querySelector('button[type="submit"]').blur();

    if (!validate()) {
      return false;
    }

    pagesLastId.current = [0];
    handleSearch();

    return false;
  };

  return (
    <section id="page-stock-browse">
      <Title element="h4" text="Devices" />

      <Text
        bold={true}
        text={`Search for a device by ID, Status, Category, Clamp Size, # Phases, Reseller and Batch ID.`}
      />

      {state.hasError ? (
        <ErrorMessage message={state.validationMessage} />
      ) : null}

      <form action="#" onSubmit={submit}>
        <div className="filters-wrapper">
          {state.filters.map((filter, index) => {
            let options = [];
            if (filter.searchBy === 'CATEGORY') options = CATEGORIES_OPTIONS;
            else if (filter.searchBy === 'CLAMP') options = CLAMP_SIZES_OPTIONS;
            else if (filter.searchBy === 'PHASES') options = PHASES_OPTIONS;
            else if (filter.searchBy === 'STATUS') options = STATUS_OPTIONS;

            return (
              <div key={index}>
                <Select
                  change={(e) => changeBy(e, index)}
                  disabled={state.loading}
                  hasError={filter.hasError}
                  name="searchBy"
                  options={filter.searchOptions}
                  placeholder="Property"
                  value={filter.searchBy}
                />
                {options.length ? (
                  <Select
                    change={(e) => change(e, index)}
                    disabled={state.loading}
                    hasError={filter.hasError}
                    name="search"
                    options={options}
                    placeholder="Search"
                    value={filter.search}
                  />
                ) : (
                  <Input
                    change={(e) => change(e, index)}
                    disabled={state.loading}
                    hasError={filter.hasError}
                    name="search"
                    placeholder="Search"
                    type="text"
                    value={filter.search}
                  />
                )}
                {index < 2 && index + 1 === state.filters.length ? (
                  <Button
                    action="add"
                    click={addFilter}
                    disabled={state.loading}
                    aria-label="add filter"
                  />
                ) : null}
                {state.filters.length > 1 ? (
                  <Button
                    action="remove"
                    click={() => removeFilter(index)}
                    disabled={state.loading}
                    aria-label="remove filter"
                  />
                ) : null}
              </div>
            );
          })}
        </div>
        <div>
          <Button
            action="search"
            disabled={state.loading}
            type="submit"
            aria-label="search"
          />
        </div>
      </form>

      <Table
        columns={columns}
        data={data}
        isLoading={state.loading}
        showPagination
        hasPrevious={pagesLastId.current.length > 1}
        hasNext={state.lastId !== 0}
        handlePreviousPage={handlePreviousPage}
        handleNextPage={handleNextPage}
      />
    </section>
  );
}

export default StockBrowse;
